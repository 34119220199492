import { useContext } from "react";
import { Advert, SkillContext } from "./App";

export const AdvertLayout = ({ children }) => {
  const { userdata } = useContext(SkillContext);

  return (
    <div className="advert-main-wrapper">
      <div className="advert-top">
        {!userdata[0]?.premium && (
          <Advert
            src="./images/upload.svg"
            srcw="100%"
            srch="400px"
            w="100%"
          />
        )}
      </div>

      <div className="advert-bottom">
        <div className="advert-left">
          {!userdata[0]?.premium && (
            <Advert src="./images/upload.svg" srcw="100%" srch="250px" />
          )}
        </div>

        <div className="content">{children}</div>

        <div className="advert-right">
          {!userdata[0]?.premium && (
            <Advert src="./images/upload.svg" srcw="100%" srch="250px" />
          )}
        </div>
      </div>
    </div>
  );
};
