import { useState } from "react";

//WITH THIS FUNCTION WE CAN SENT FORM DATA ONLY!!!

export function usePostData(url) {
  const [data, setdata] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [err, seterr] = useState(false);

  const addData = async (body, callback) => {
    try {
      setisloading(true);

      const data = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain",
          "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
        },
        credentials: "include",
        body: body,
      });

      const resdata = await data.json();
      setdata(resdata);

      //after adding the Data we can (but mustnt refetch the corresponding data)
      callback && callback();
    } catch (error) {
      seterr(error);
    } finally {
      setisloading(false);
    }
  };

  return { data, isloading, err, addData };
}
