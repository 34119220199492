import { useContext, useEffect, useState } from "react";
import "./Css/profile.css";
import { ContentBox } from "./Service";
import { Comment } from "./ServiceDescription";
import { ShowlessData, ShowmoreData, SkillContext } from "./App";
import { useGetData } from "./Hooks/getData";
import { useNavigate, useParams } from "react-router-dom";
import { usePostData } from "./Hooks/postData";
import { useDeleteData } from "./Hooks/deleteData";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faEyeSlash,
  faPlusCircle,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";

export const Profile = () => {
  const [shoption, setshoption] = useState(0);

  const { isloggedin, setisloggedin, City, Region, userdata, apiUrl } =
    useContext(SkillContext);

  const navigate = useNavigate();

  /*CHECK IF USER IS LOGGEDIN*/
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );

  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);

      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  const params = useParams();
  const {
    data: fetchedServices,
    isloading,
    err,
    refetch: refetchServices,
  } = useGetData(`  ${apiUrl}/api/services/user/${params.usertoken}`);

  const {
    data: UserswrittenReviews,
    isloading: loading,
    err: error,
    refetch: refetchwrittenReviews,
  } = useGetData(
    `${apiUrl}/api/services/user/created/reviews/${params.usertoken}`
  );

  useEffect(() => {
    console.log("UserswrittenReviews", UserswrittenReviews);
  }, [UserswrittenReviews]);

  const {
    data: usersData,
    isloading: userloading,
    err: usererr,
    refetch: refetchUserData,
  } = useGetData(`${apiUrl}/api/users/single/${params.usertoken}`);

  const { data: averageRating, refetch: refetchaverageRating } = useGetData(
    `${apiUrl}/api/services/average/rating/${params.usertoken}`
  );

  const { data: qualifications, refetch: refetchqualifications } = useGetData(
    `${apiUrl}/api/users/null/qualifications/${params.usertoken}`
  );

  useEffect(() => {
    console.log("qualifications");
    console.log(qualifications);
  }, [qualifications]);

  const { data: addedqualifications, addData: addQualification } = usePostData(
    `${apiUrl}/api/users/null/qualifications/${params.usertoken}`
  );

  useEffect(() => {
    addedqualifications?.err
      ? toast.error(addedqualifications?.err)
      : toast.success(addedqualifications?.msg);
  }, [addedqualifications]);

  const [shdataamount, setshdataamount] = useState(5);
  const [shdataamount2, setshdataamount2] = useState(5);

  const [img, setimg] = useState("");

  const formData = new FormData();
  formData.append("image", img);

  const {
    data: settingsData,
    err: serr,
    isloading: sloading,
    refetch: fetchSettings,
  } = useGetData(`${apiUrl}/api/users/settings/${params.usertoken}`);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(`${apiUrl}/api/users/images/${usersData[0]?.uimg}/type/users`);

  useEffect(() => {
    refetchImage();
  }, [usersData]);

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  return (
    <div className="user-profile-wrapper">
      {!settingsData[0]?.active &&
      params.usertoken != userdata[0]?.usertoken ? (
        <div className="private-wrapper">
          <div className="icon">
            <FontAwesomeIcon icon={faEyeSlash} />
          </div>

          <h2>Privates Profil</h2>
          <p>Dieses Profil ist nicht öffentlich einsehbar.</p>
        </div>
      ) : (
        <>
          <div className="profile-top">
            {isImageLoading ? (
              <div className="loader">
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            ) : (
              <img src={Image} alt="user-image" loading="lazy" />
            )}

            <div className="user-description">
              <h2>{usersData[0]?.username}</h2>
              <p>📫 {usersData[0]?.email}</p>
              <a href="tel: +491748278589">📞 {usersData[0]?.tel}</a>
              <button
                style={{
                  display:
                    userdata[0]?.usertoken === usersData[0]?.usertoken
                      ? "block"
                      : "none",
                }}
                onClick={() => {
                  navigate("/user/settings");
                }}
              >
                Profil bearbeiten
              </button>
            </div>
          </div>

          <div className="profile-option-wrapper">
            <div className="profile-options">
              <div
                className="option"
                onClick={() => {
                  setshoption(0);
                }}
                style={{
                  borderBottom:
                    shoption === 0 && "1px solid var(--main-bg-accent-light)",
                }}
              >
                🛠️ angebotene Dienstleistungen
              </div>

              <div
                className="option"
                onClick={() => {
                  setshoption(1);
                }}
                style={{
                  borderBottom:
                    shoption === 1 && "1px solid var(--main-bg-accent-light)",
                }}
              >
                💬 geschriebene Kommentare
              </div>

              <div
                className="option"
                onClick={() => {
                  setshoption(2);
                }}
                style={{
                  borderBottom:
                    shoption === 2 && "1px solid var(--main-bg-accent-light)",
                }}
              >
                ⭐ durchschnittliche Bewertung
              </div>

              <div
                className="option"
                onClick={() => {
                  setshoption(3);
                }}
                style={{
                  borderBottom:
                    shoption === 3 && "1px solid var(--main-bg-accent-light)",
                }}
              >
                💡 Qualifizierung
              </div>
            </div>

            <div className="option-content">
              {shoption === 0 ? (
                <div className="content-bottom">
                  <div className="content-box-grid">
                    {fetchedServices.length > 0
                      ? fetchedServices.map((data, index) => {
                          if (index < shdataamount) {
                            return (
                              <ContentBox
                                img={data.simg}
                                userstatus={data.status}
                                username={data.screator}
                                userimg={data.uimg}
                                rating={data.rating}
                                comments={data.commcount}
                                desc={data.ssmalldesc}
                                isliked={data.isliked}
                                id={data.sid}
                                title={data.stitle}
                                region={data.sregion}
                                city={data.scity}
                                Region={Region}
                                City={City}
                                reviewcount={data.ReviewCount}
                                reviewsum={data.ReviewSum}
                              />
                            );
                          }
                        })
                      : "Keine angebotenen Services."}
                  </div>

                  <div className="load-more">
                    <button
                      style={{
                        display:
                          fetchedServices.length > 5 &&
                          shdataamount != fetchedServices.length
                            ? "block"
                            : "none",
                      }}
                      onClick={() => {
                        ShowmoreData(
                          shdataamount,
                          setshdataamount,
                          fetchedServices,
                          5
                        );
                      }}
                    >
                      {" "}
                      {"Mehr anzeigen"}
                    </button>
                  </div>
                </div>
              ) : shoption === 1 ? (
                <div className="rating-commtents">
                  {UserswrittenReviews && UserswrittenReviews.length > 0
                    ? UserswrittenReviews.map((rating, index) => {
                        if (index < shdataamount2) {
                          return (
                            <Comment
                              id={rating.rid}
                              ratingdata={rating}
                              user={rating.username}
                              token={rating.usertoken}
                              img={rating.uimg}
                              date={rating.rdate}
                              rating={rating.rrating}
                              text={rating.rtext}
                              userdata={userdata}
                              refetchReviews={refetchwrittenReviews}
                            />
                          );
                        }
                      })
                    : "Keine geschriebenen Reviews."}

                  <div className="load-more">
                    <button
                      style={{
                        display:
                          UserswrittenReviews.length > 5 &&
                          shdataamount != UserswrittenReviews.length
                            ? "block"
                            : "none",
                      }}
                      onClick={() => {
                        ShowmoreData(
                          shdataamount,
                          setshdataamount,
                          UserswrittenReviews,
                          5
                        );
                      }}
                    >
                      {" "}
                      {"Mehr anzeigen"}
                    </button>
                  </div>
                </div>
              ) : shoption === 2 ? (
                <div className="rating-box-wrapper">
                  <OverAllRating
                    raverage={averageRating[0]?.ReviewSum}
                    rcount={averageRating[0]?.ReviewsCount}
                  />
                </div>
              ) : (
                <div className="qualification-wrapper">
                  {qualifications.length > 0
                    ? qualifications.map((qual) => (
                        <QualItem
                          img={qual.qimg}
                          id={qual.qid}
                          uref={qual.userref}
                          userdata={userdata}
                          qualrefetch={refetchqualifications}
                        />
                      ))
                    : "Keine Qualifizierung hinterlegt."}

                  <div
                    className="qualification-add"
                    style={{
                      display:
                        userdata[0]?.usertoken === usersData[0]?.usertoken
                          ? "flex"
                          : "none",
                    }}
                  >
                    <label htmlFor="file">
                      <div className="icon">
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </div>

                      <h2>{img?.name}</h2>
                      <p>Wähle deine Qualifikation aus</p>

                      <input
                        type="file"
                        name="file"
                        id="file"
                        onChange={(e) => {
                          setimg(e.target.files[0]);
                        }}
                      />
                    </label>
                    <span
                      onClick={() => {
                        addQualification(formData);
                      }}
                    >
                      Hinzufügen
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

const QualItem = (props) => {
  const { apiUrl } = useContext(SkillContext);

  const { data: deletedQualifications, deleteData } = useDeleteData(
    `${apiUrl}/api/users/${props.id}/qualifications/null`,
    () => {
      props.qualrefetch();
    }
  );

  useEffect(() => {
    deletedQualifications?.err
      ? toast.error(deletedQualifications?.err)
      : toast.success(deletedQualifications?.msg);
  }, [deletedQualifications]);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(`${apiUrl}/api/users/images/${props.img}/type/qualifications`);

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  return (
    <div className="qual-item">
      {isImageLoading ? (
        <div className="loader">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      ) : (
        <img src={Image} alt="qualifizierung" loading="lazy" />
      )}

      <div
        className="icon"
        onClick={() => {
          deleteData();
        }}
        style={{
          display:
            props.userdata[0]?.usertoken === props.uref ? "flex" : "none",
        }}
      >
        <FontAwesomeIcon icon={faXmark} />
      </div>
    </div>
  );
};

const OverAllRating = (props) => {
  return (
    <div className="rating-box">
      <h1>⭐ {props.raverage}</h1>
      <p>{props.rcount} Bewertungen (auf alle Dienstleistungen gesehen)</p>
    </div>
  );
};
