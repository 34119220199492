// Define the PdfGenerator component
import jsPDF from "jspdf";
import "jspdf-autotable";


export const pdfGenerator = (data) => {
  console.log("ORDERDATA");
  console.log(data);
  
  // Create a new jsPDF instance
  const doc = new jsPDF();

 // Setze Schriftart und Schriftgröße für den Titel
 doc.setFont('helvetica', 'bold');
 doc.setFontSize(22);

 // Schreibe 'Find' in Schwarz und 'myService' in Lila
 const title = 'Find';
 const subtitle = 'my';
 const title2 = 'Service';
 const titleWidth = doc.getTextWidth(title);
 const subtitleWidth = doc.getTextWidth(subtitle);

 doc.text(title, 20, 20); // Positioniere 'Find'
 doc.setTextColor(123, 102, 206); // Setze Farbe auf Lila (RGB für lila: 128, 0, 128)
 doc.text(subtitle, 20 + titleWidth, 20); // Positioniere 'myService' direkt daneben
 // Setze Farbe zurück auf Schwarz
 doc.setTextColor(0, 0, 0);
 doc.text(title2, 20 + titleWidth +subtitleWidth, 20); // Positioniere 'myService' direkt daneben

 // Firmeninformationen
 doc.setFontSize(12);
 doc.text('FindMyService UG', 20, 30);
 doc.text('teststraße 15', 20, 35);
 doc.text('85356 München', 20, 40);

 // Rechnungsinformationen
 doc.setFontSize(14);
 doc.text('Rechnung', 20, 55);

 doc.setFontSize(12);
 doc.text(`Rechnungsnummer: #${data?.said}`, 20, 65);
 doc.text(`Kunde: ${data?.username}`, 20, 70);
 doc.text(`Rechnungsdatum: ${
  new Date(data.billCreatedon)
    .toLocaleString("de-DE", {
      timeZone: "Europe/Berlin",
    })
    .split(",")[0]
}`, 20, 75);
 doc.text(`Fälligkeitsdatum: ${
      new Date(
        new Date(data.billCreatedon).setDate(
          new Date(data.billCreatedon).getDate() + 30
        )
      )
        .toLocaleString("de-DE", {
          timeZone: "Europe/Berlin",
        })
        .split(",")[0]
    }`, 20, 80);

 // Tabelle Kopfzeile
 doc.setFontSize(10);
 doc.text('Name/Beschreibung', 20, 95);
 doc.text('Menge', 90, 95);
 doc.text('Preis', 110, 95);
 doc.text('USt.', 130, 95);
 doc.text('Pauschale', 150, 95);
 doc.text('Gesamtpreis', 170, 95);

const price = data?.price;
const ust = Math.round((price - price * 0.81) * 100) / 100;
const pauschale = Math.round((price - price * 0.95) * 100) / 100;

 // Tabelle Inhalte
 doc.text(`${data?.plan}`, 20, 105);
 doc.text(`${1}`, 90, 105);
 doc.text(`${data?.price}`, 110, 105);
 doc.text('19 %', 130, 105);
 doc.text('5 %', 150, 105);
 doc.text(`${(price + ust + pauschale)}`, 170, 105);



 // Summenbereich
 doc.setFontSize(12);
 doc.text('Gesamtbetrag exkl. USt und Pauschale:', 90, 130);
 doc.text( `${price} €`, 175, 130);

 doc.text('USt. 19 %:', 125, 135);
 doc.text(`${ust} €`, 175, 135);

 doc.text('Servicepauschale 5%:', 125, 140);
 doc.text(`${pauschale} €`, 175, 140);

 doc.setFontSize(14);
 doc.text('Gesamtbetrag inkl. USt.:', 115, 150);
 doc.text(`${(price + ust + pauschale)} €`, 175, 150);

 // Fußzeile
 doc.setFontSize(10);
 doc.text(`Bitte leisten Sie die Zahlung innerhalb von 30 Tagen auf unser Bankkonto, andernsfalls wird die Summe 
automatisch abgerechnet.`, 20, 160);
 doc.text('Rechnungsnummer: #' + data?.said, 20, 170);

 doc.text('Telefon: 0049123456789', 20, 185);
 doc.text('Email: findymservice@gmail.com', 20, 190);

 doc.text('USt-IdNr.: DE123456789', 130, 185);
 


  const totalPages = doc.internal.getNumberOfPages();
  for (let i = 1; i <= totalPages; i++) {
    doc.line(10, 283, 200, 283);
    doc.setPage(i);
    doc.text(
      `Seite ${i} von ${totalPages}`,
      185,
      doc.internal.pageSize.getHeight() - 5
    );
  }

  // Save the PDF
  doc.save(
    `Rechnung_#${data.said}_${new Date().toISOString().split("T")[0]}.pdf`
  );

  // pdf open in a new tab
  const pdfDataUri = doc.output("datauristring");
  const newTab = window.open();
  newTab?.document.write(
    `<iframe width='100%' height='100%' src='${pdfDataUri}'></iframe>`
  );
};
