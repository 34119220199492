import { useState } from "react";

export function useUpdateJSONData(url, callback) {
  const [data, setdata] = useState([]);
  const [err, seterr] = useState("");
  const [isloading, setisloading] = useState(false);

  const update = async (body) => {
    try {
      setisloading(true);

      const data = await fetch(url, {
        method: "PUT",
        headers: {
          Accept: "application/json, text/plain",
          "Access-Control-Allow-Methods": "OPTIONS,POST,GET, PUT, DELETE",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body),
      });
      const resdata = await data.json();
      setdata(resdata);

      callback && callback();

    } catch (error) {
      seterr(error);
    } finally {
      setisloading(false);
    }
  };

  return { data, isloading, err, update };
}
