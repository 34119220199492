import "./Css/service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowRight,
  faBolt,
  faBoltLightning,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
  faEllipsisVertical,
  faFilter,
  faHeart,
  faMoneyBillTrendUp,
  faPenNib,
  faPenToSquare,
  faPencilSquare,
  faRefresh,
  faSearch,
  faStar,
  faTrashArrowUp,
  faTrashCan,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { lazy, Suspense, useContext, useEffect, useState } from "react";
import { loadComponent, ShowlessData, ShowmoreData, SkillContext } from "./App";
import { useGetData } from "./Hooks/getData";
import { useNavigate } from "react-router-dom";
//import { ContentBox } from "./Service";
import LazyLoad from "react-lazyload";

export const SearchServices = () => {
  const { isloggedin, setisloggedin, City, Region, Service, apiUrl } =
    useContext(SkillContext);

  /*CHECK IF USER IS LOGGEDIN*/
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );

  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  const navigate = useNavigate();

  const [sinput, setsinput] = useState("");

  //Fetch all Services for display
  const { data, err, isloading } = useGetData(`${apiUrl}/api/services/all`);

  /*NEW SEARCH*/
  const [region, setregion] = useState("");
  const [city, setcity] = useState("");
  const [service, setservice] = useState("");
  const [searchData, setsearchData] = useState([]);

  useEffect(() => {
    data.length > 0 && setsearchData(data); //data
  }, [data]);

  useEffect(() => {
    console.log("MY Search Data:", searchData);
  }, [searchData]);

  const existsData = (arr, data) => {
    //check if data already on arr if not add to arr
    const check = arr.some((item) => item.id === data.id);
    return check;
  };

  const TextSearch = (text, searchdata) => {
    const TextData = [];
    let currentval = "";
    let isIncluded;

    //loop through data
    searchdata.forEach((sdata) => {
      isIncluded = true;

      if (sdata.stitle.toLowerCase().includes(text.toLowerCase())) {
        //check if region not 0, then check if city not 0 then if service not

        if (region == "" && city == "" && service == "") {
          isIncluded = true;
        } else {
          //if region set check if data aligns with
          if (region != "") {
            sdata.sregion == region
              ? (isIncluded = true)
              : (isIncluded = false);
          } else {
            console.log("Region is empty: ");
          }

          if (city != "") {
            isIncluded == false
              ? (isIncluded = false)
              : sdata.scity == city
              ? (isIncluded = true)
              : (isIncluded = false);
          } else {
            console.log("city is  empty: ");
          }

          if (service != "") {
            isIncluded == false
              ? (isIncluded = false)
              : sdata.scategory == service
              ? (isIncluded = true)
              : (isIncluded = false);
          } else {
            console.log("service is  empty: ");
          }
        }
      }

      isIncluded && TextData.push(sdata);

      //  sdata.stitle.toLowerCase().includes(text.toLowerCase()) && TextData.push(sdata);
    });

    //neben textsearch auch prüfen ob region, stadt und oder kategorie ausgewählt
    //region, city, service

    setsearchData(TextData);
  };

  const ResetSearch = () => {
    setsinput(""); //text input leeren
    setregion("");
    setcity("");
    setservice("");
    setsearchData(data); //basedata reset
  };

  useEffect(() => {
    TextSearch(sinput, data);
  }, [sinput, region, city, service]);

  /*NEW SEARCH*/

  const [shdataamount, setshdataamount] = useState(10);

  useEffect(() => {
    setcity("");
    console.log("resetted city");
  }, [region]);

  const ContentBox = lazy(() =>
    loadComponent(import("./Service")).then((module) => ({
      default: module.ContentBox,
    }))
  );

  return (
    <div className="service-wrapper">
      <div className="content-wrapper">
        <div className="content-top">
          <div className="top-search">
            <div className="search-box">
              <div className="icon">
                <FontAwesomeIcon icon={faSearch} />
              </div>

              <input
                type="text"
                id="search"
                value={sinput}
                placeholder="Suche Dienstleistung ..."
                onChange={(e) => {
                  setsinput(e.target.value);
                }}
              />

              <div
                className="icon"
                style={{ display: sinput.length === 0 ? "none" : "flex" }}
                onClick={() => {
                  ResetSearch();
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>

            <div className="options">
              <select
                onChange={(e) => {
                  setregion(e.target.value);
                }}
                value={region}
              >
                <option value={""}>Wähle eine Region</option>
                {Region.length > 0 &&
                  Region.map((region) => (
                    <option value={region.id}>{region.name}</option>
                  ))}
              </select>

              <select
                onChange={(e) => {
                  setcity(e.target.value);
                }}
                value={city}
              >
                <option value={""}>Wähle eine Stadt</option>
                {City.length > 0 &&
                  City.map((city) => {
                    if (region != "" && region == city.regionref) {
                      return <option value={city.cid}>{city.cname}</option>;
                    }
                  })}
              </select>

              <select
                onChange={(e) => {
                  setservice(e.target.value);
                }}
                value={service}
              >
                <option value={""}>Wähle eine Kategorie</option>
                {Service.length > 0 &&
                  Service.map((service) => (
                    <option value={service.id}>{service.name}</option>
                  ))}
              </select>
            </div>
          </div>
        </div>

        <div className="content-bottom">
          {err != "" ? (
            <p>{err?.message}</p>
          ) : (
            <>
              <div className="content-box-grid">
                {searchData.length > 0
                  ? searchData.map((data, index) => {
                      if (index < shdataamount) {
                        return (
                          <Suspense
                            fallback={
                              <div className="load-comp-childs">
                                <div className="load-img"></div>

                                <div className="load-line"></div>
                                <div className="load-line"></div>
                                <div className="load-line"></div>
                              </div>
                            }
                          >
                            <ContentBox
                              img={data.simg}
                              userstatus={data.status}
                              username={data.screator}
                              userimg={data.uimg}
                              rating={data.rating}
                              comments={data.commcount}
                              desc={data.ssmalldesc}
                              isliked={data.isliked}
                              id={data.sid}
                              title={data.stitle}
                              region={data.sregion}
                              city={data.scity}
                              Region={Region}
                              City={City}
                              reviewcount={data.ReviewCount}
                              reviewsum={data.ReviewSum}
                              premium={data.premium}
                            />
                          </Suspense>
                        );
                      }
                    })
                  : "Kein angebotener Service gefunden"}
              </div>

              <div className="load-more">
                <button
                  style={{
                    display:
                      searchData.length > 10 &&
                      shdataamount != searchData.length
                        ? "block"
                        : "none",
                  }}
                  onClick={() => {
                    ShowmoreData(shdataamount, setshdataamount, searchData, 10);
                  }}
                >
                  {" "}
                  {"Mehr anzeigen"}
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
