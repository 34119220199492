import { useNavigate } from "react-router-dom";
import "./Css/favouriteservice.css";
import { ContentBox } from "./Service";
import { useContext, useEffect, useState } from "react";
import { ShowlessData, ShowmoreData, SkillContext } from "./App";
import { useGetData } from "./Hooks/getData";
import { faHeartBroken } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export const Favouriteservice = () => {
  const { isloggedin, setisloggedin, userdata, City, Region, apiUrl } =
    useContext(SkillContext);
  const navigate = useNavigate();

  /*CHECK IF USER IS LOGGEDIN*/
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );

  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  const {
    data: favourites,
    isloading,
    err,
    refetch: refetchFavourits,
  } = useGetData(
    `  ${apiUrl}/api/services/user/${userdata[0]?.usertoken}/favourites`
  );

  useEffect(() => {
    userdata.length > 0 && refetchFavourits();
  }, [userdata]);

  const [shdataamount, setshdataamount] = useState(5);

  return (
    <div className="favourite-service-wrapper">
      <div className="top">
        <h2>Favouriten ❤️</h2>
        <p>Deine Favourisierten Dienstleistungen. </p>
      </div>

      <div className="content-bottom">
        <div className="content-box-grid">
          {favourites.length > 0
            ? favourites.map((data, index) => {
                if (index < shdataamount) {
                  return (
                    <ContentBox
                      img={data.simg}
                      userstatus={data.status}
                      username={data.screator}
                      userimg={data.uimg}
                      rating={data.rating}
                      comments={data.commcount}
                      desc={data.ssmalldesc}
                      isliked={true}
                      id={data.sid}
                      title={data.stitle}
                      region={data.sregion}
                      city={data.scity}
                      Region={Region}
                      City={City}
                      reviewcount={data.ReviewCount}
                      reviewsum={data.ReviewSum}
                      refetchFavs={refetchFavourits}
                    />
                  );
                }
              })
            : 
            <div className="grid-err-wrapper">
            <div className="fetch-err-box">
            <div className="icon">
              <FontAwesomeIcon icon={faHeartBroken}/>
            </div>
            <p>Keine Favouriten bisher.</p>
          </div>
          </div>
          }
        </div>

        <div className="load-more">
          <button
            style={{
              display:
                favourites.length > 5 && shdataamount != favourites.length
                  ? "block"
                  : "none",
            }}
            onClick={() => {
              ShowmoreData(shdataamount, setshdataamount, favourites, 5);
            }}
          >
            {" "}
            {"Mehr anzeigen"}
          </button>
        </div>
      </div>
    </div>
  );
};
