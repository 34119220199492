import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Css/servicedescription.css";
import {
  fa1,
  fa2,
  fa3,
  fa4,
  faAdd,
  faBuildingColumns,
  faCheck,
  faChevronDown,
  faChevronRight,
  faChevronUp,
  faCircleNotch,
  faEllipsis,
  faEllipsisVertical,
  faMinus,
  faPencil,
  faStar,
  faTrash,
  faX,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { ServiceDrop, ServiceInput } from "./ServiceAdd";
import { useNavigate, useParams } from "react-router-dom";
import { useGetData } from "./Hooks/getData";
import {
  banDates,
  banDateTime,
  sendMail,
  ShowlessData,
  ShowmoreData,
  SkillContext,
} from "./App";
import { usePostJSONData } from "./Hooks/postjsonData";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { Link as ScrollLink } from "react-scroll";
import { useUpdateJSONData } from "./Hooks/updatejsonData";
import { useDeleteData } from "./Hooks/deleteData";
import emailjs from "@emailjs/browser";
import { faStripe } from "@fortawesome/free-brands-svg-icons";
import { CardCheckout, CheckoutPage } from "./Checkout";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { EditorState, convertFromRaw } from "draft-js";
import DOMPurify from "dompurify";
import { stateToHTML } from "draft-js-export-html";
import { ConfirmDelete } from "./Settings";

export const ServiceDescription = () => {
  const { isloggedin, setisloggedin, userdata, apiUrl } =
    useContext(SkillContext);

  const navigate = useNavigate();

  //CHECK IF USER IS LOGGEDIN
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );

  /* useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  //get param
  const params = useParams();

  //Fetch the Service of this serviceID
  const { data, err, isloading } = useGetData(
    `${apiUrl}/api/services/single/${params.sid}`
  );

  useEffect(() => {
    console.log("MYDATA:", data);
    // console.log("json parse data: ", JSON.parse(data[0]?.smaindesc));

    try {
      console.log("json parse data: ", JSON.parse(data[0]?.smaindesc));
    } catch (err) {
      console.log("Error parsing JSON:", err.message);
      console.log("Raw data content:", data[0]?.smaindesc); // Inspect the raw data
    }

    /* EditorState.createWithContent(
      convertFromRaw(JSON.parse(data[0]?.smaindesc))
    )*/
  }, [data]);

  //get ComparePlans
  const {
    data: cdata,
    err: cerr,
    isloading: cisloading,
  } = useGetData(`${apiUrl}/api/services/compares/${params.sid}`);

  //get FAQ ITEMS
  const {
    data: fdata,
    err: ferr,
    isloading: fisloading,
  } = useGetData(`${apiUrl}/api/services/faqs/${params.sid}`);

  //get Review ITEMS
  const {
    data: rdata,
    err: rerr,
    isloading: risloading,
    refetch: refetchReviews,
  } = useGetData(`${apiUrl}/api/services/null/reviews/${params.sid}`);

  useEffect(() => {
    console.log(rdata);
  }, [rdata]);

  const Reviews = [1, 2, 3, 4, 5];

  const [reviewcol, setreviewcol] = useState("var(--main-bg-accent-light)");
  const [hovindex, sethovindex] = useState(0); //is also current amoutn of stars

  const checkReviews = (index) => {
    if (index === 0) {
      setreviewcol("red");
    } else if (index === 1) {
      setreviewcol("orange");
    } else if (index === 2) {
      setreviewcol("rgb(227, 227, 98)");
    } else if (index === 3) {
      setreviewcol("green");
    } else {
      setreviewcol("darkgreen");
    }
  };

  const [addreview, setaddreview] = useState(false);

  const {
    data: addedReview,
    err: rerror,
    isloading: rloading,
    addjsonData,
  } = usePostJSONData(
    ` ${apiUrl}/api/services/null/reviews/${params.sid}`,
    () => {
      refetchReviews();
    }
  );

  const {
    data: updatedReview,
    err: urerror,
    isloading: urloading,
    update: updateReview,
  } = useUpdateJSONData(
    ` ${apiUrl}/api/services/null/reviews/${params.sid}`,
    () => {
      refetchReviews();
    }
  );

  useEffect(() => {
    addedReview?.err
      ? toast.error(addedReview?.err)
      : toast.success(addedReview?.msg);
  }, [addedReview]);

  useEffect(() => {
    updatedReview?.err
      ? toast.error(updatedReview?.err)
      : toast.success(updatedReview?.msg);
  }, [updatedReview]);

  const {
    data: addedNotification,
    err: nerror,
    isloading: nloading,
    addjsonData: addNotification,
  } = usePostJSONData(
    `   ${apiUrl}/api/users/user/null/notifications/${params.sid}`
  );

  const [reviewtext, setreviewtext] = useState("");

  const [rcalc, setrcalc] = useState("");

  useEffect(() => {
    let cal = 0;

    //everytime we add or review data we recalc
    rdata.length > 0 &&
      rdata.forEach((data, index) => {
        cal += data.rrating;
      });

    setrcalc(rdata.length > 0 ? Number(cal / rdata.length).toFixed(1) : 0);
  }, [addedReview, rdata]);

  const [shdataamount, setshdataamount] = useState(5);

  const [rdubarr, setrdubarr] = useState([]);

  const [choose, setchoose] = useState(0);

  useEffect(() => {
    rdata.length > 0 && setrdubarr(rdata);
  }, [rdata]);

  useEffect(() => {
    console.log("sort activateds");
    console.log(choose);

    if (choose == 0) {
      console.log("base data");
      setrdubarr(rdata);
    } else if (choose == 1) {
      console.log("date sort");
      setrdubarr(
        rdata.length > 0 &&
          [...rdata].sort((a, b) => new Date(b.rdate) - new Date(a.rdate))
      );
    } else if (choose == 2) {
      console.log("low rating sort");
      setrdubarr(
        rdata.length > 0 && [...rdata].sort((a, b) => a.rrating - b.rrating)
      );
    } else {
      console.log("high rating sort");
      setrdubarr(
        rdata.length > 0 && [...rdata].sort((a, b) => b.rrating - a.rrating)
      );
    }
  }, [choose, rdata]);

  const [step, setstep] = useState(0);
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const [requestdesc, setrequestdesc] = useState("");

  const [shsrequest, setshsrequest] = useState(false);

  useEffect(() => {
    addedNotification?.err
      ? toast.error(addedNotification?.err)
      : toast.success(addedNotification?.msg);
  }, [addedNotification]);

  const { data: appointments, refetch: refetchAppointments } = useGetData(
    `${apiUrl}/api/services/appointments/${params.sid}`
  );

  const { data: plans, refetch: refetchPlans } = useGetData(
    `${apiUrl}/api/services/service/${params.sid}/plan`
  );

  const [planchoose, setplanchoose] = useState(0);
  const [selectedplan, setselectedplan] = useState(null);

  useEffect(() => {
    console.log("plans", plans);

    if (plans.length > 0) {
      console.log(plans);
      console.log(plans[0]);
      setselectedplan(plans[0]?.sppid);
      setplanchoose(1);
      setamount(plans[0]?.price);
    }
  }, [plans]);

  const [bannedDates, setbanneddates] = useState([]);
  const excludedTimes = [];

  useEffect(() => {
    appointments.length > 0 &&
      banDates(appointments, setbanneddates, bannedDates);
  }, [appointments]);

  useEffect(() => {
    startDate &&
      banDateTime(excludedTimes, startDate, bannedDates, appointments);
  }, [startDate, appointments, bannedDates]);

  useEffect(() => {
    endDate && banDateTime(excludedTimes, endDate, bannedDates, appointments);
  }, [endDate, appointments, bannedDates]);

  const [editcomm, seteditcomm] = useState([]);

  useEffect(() => {
    if (editcomm.length > 0) {
      checkReviews(editcomm[0]?.rrating - 1);
      sethovindex(editcomm[0]?.rrating - 1);
      setreviewtext(editcomm[0]?.rtext);
    }

    console.log("EDITCOMM");
    console.log(editcomm);
  }, [editcomm]);

  const {
    data: settingsData,
    err: serr,
    isloading: sloading,
    refetch: fetchSettings,
  } = useGetData(`${apiUrl}/api/users/settings/${data[0]?.usertoken}`);

  useEffect(() => {
    data.length > 0 && fetchSettings();
  }, [data]);

  const stripePromise = loadStripe(
    process.env.REACT_APP_FIND_MY_SERVICE_STRIPE_PUB_KEY
  );

  const [amount, setamount] = useState(plans[0]?.price);
  const [ust, setust] = useState(0);
  const [pauschale, setpauschale] = useState(0);
  const [fullprice, setfullprice] = useState(0);

  useEffect(() => {
    //console.log(plans[0].price);
    setamount(plans[planchoose - 1]?.price);
  }, [planchoose]);

  useEffect(() => {
    console.log("current amount");
    console.log(amount);
    setust(Math.round((amount - amount * 0.81) * 100) / 100);
    setpauschale(Math.round((amount - amount * 0.95) * 100) / 100);
  }, [amount]);

  useEffect(() => {
    setfullprice(Math.round((amount + ust + pauschale) * 100) / 100);
  }, [amount, ust, pauschale]);

  const [paymentchoose, setpaymentchoose] = useState(0);

  const blurs = new Array(12).fill(0);

  const [clienterr, setclienterr] = useState("");

  const {
    data: createdCustomer,
    isloading: customercreatingloading,
    addjsonData: createCustomers,
  } = usePostJSONData(`${apiUrl}/api/payments/create-customer`);

  const {
    data: paymentDetails,
    err: paymentDetailserr,
    isloading: paymentDetailsloading,
    refetch: fetchPaymentDetails,
  } = useGetData(
    ` ${apiUrl}/api/payments/customer/${userdata[0]?.customer_id}`
  );

  useEffect(() => {
    userdata.length > 0 && fetchPaymentDetails();
  }, [userdata]);

  useEffect(() => {
    console.log("paymentDetails", paymentDetails);
    console.log(paymentDetails);
  }, [paymentDetails]);

  const { data: confirmedPAyment, addjsonData: confirmPayments } =
    usePostJSONData(`${apiUrl}/api/payments/confirm-payment`);

  const { data: IntentStatus, addjsonData: addIntentStatus } = usePostJSONData(
    `${apiUrl}/api/payments/check-intent/status`
  );

  useEffect(() => {
    clienterr != "" && toast.error(clienterr);
  }, [clienterr]);

  const payNow = async (
    event,
    stripe,
    elements,
    clientSecret,
    seterr,
    setisloading,
    paymentElement,
    intentid
  ) => {
    event.preventDefault();

    setisloading(true);

    if (!stripe || !elements) return;

    const { error } = await elements.submit();

    if (error) {
      seterr(error);
      setisloading(false);
      return;
    }

    //check if descr, startdate and endate is set
    if (!requestdesc || requestdesc === "") {
      setclienterr("Beschreibung ist leer.");
      setisloading(false);
      return;
    }

    if (!startDate || startDate === "") {
      setclienterr("Startdatum ist leer.");
      setisloading(false);
      return;
    }

    if (!endDate || endDate === "") {
      setclienterr("Enddatum ist leer.");
      setisloading(false);
      return;
    }

    const { error: err, setupIntent } = await stripe.confirmSetup({
      elements,
      clientSecret: clientSecret, // The client secret from the server
      confirmParams: {
        return_url: `http://localhost:3000/service/details/${params.sid}`, //`http://localhost:3000/service/details/${params.sid}`
        //receipt_email: userdata[0]?.email,
      },
      redirect: "if_required",
    });

    if (err) {
      seterr(err.message);
      console.log("Problem by confirming setup: " + err.message);
      setisloading(false);
    } else {
      // The SetupIntent has been confirmed and payment method is saved

      //get Status of Intent
      console.log("setupIntent ");
      console.log(setupIntent);
      console.log(setupIntent.id);
      await addIntentStatus({
        intent_type: "future",
        IntentID: setupIntent.id, //setupIntent.id
        usertoken: userdata[0]?.usertoken,
      });

      console.log("Payment method saved successfully");

      toast.info(
        "Ihre Serviceanfrage wurde gesendet. Verfolgen Sie den Status über Ihren Posteingang."
      );
      setisloading(false);
      setshsrequest(false);
    }
  };

  const getUrlParams = (param) => {
    const searchParams = new URLSearchParams(window.location.search);
    return searchParams.get(param);
  };

  /*
  useEffect(() => {
    //get Status of Intent
    console.log("Intent Status: ");
    console.log(IntentStatus);

    if (IntentStatus[0]?.status === "succeeded") {
      toast.success("Zahlung efolgreich.");

      addNotification({
        typ: 1,
        subject: `Anfrage für Service "${data[0]?.stitle}"`,
        text: requestdesc,
        startdate: startDate,
        enddate: endDate,
        sender: userdata[0]?.usertoken,
        reicever: data[0]?.usertoken,
        choosenPlan: selectedplan,
        sidref: params.sid,
        nusecase: 0,
        nlink: null,
        paymentOptionchoosed: 0, //1 = später zahlen, 0 = direkt zahlen
        payment_method: IntentStatus[0]?.payment_method,
      });

      settingsData[2]?.active && settingsData.length > 0
        ? emailjs
            .send(
              process.env.REACT_APP_FIND_MY_SERVICE_ID,
              process.env.REACT_APP_FIND_MY_SERVICE_TEMPLATE_ID,
              sendMail(
                requestdesc,
                `Anfrage für Service "${data[0]?.stitle}"`,
                data[0]?.email
              ),
              process.env.REACT_APP_FIND_MY_SERVICE_PUB_KEY
            )
            .then(
              (res) => {
                console.log("Email sent successfully:", res.text);
              },
              (rej) => {
                console.log("Error sending email:", rej.text);
              }
            )
        : console.log("its not active its: " + settingsData[2]?.active);
    } else if (IntentStatus[0]?.status === "canceled") {
      toast.error("Zahlung gecancelet.");
    } else if (IntentStatus[0]?.status === "failed") {
      toast.error("Zahlung fehlgeschlagen.");
    } else {
      console.log("kein korrekter intent Status");
    }
  }, [IntentStatus]);*/

  const { data: sentMail, addjsonData: sendMail } = usePostJSONData(
    `${apiUrl}/api/services/user/send/mail`
  );

  const payLaterwCustomer = async (
    event,
    stripe,
    elements,
    clientSecret,
    seterr,
    setisloading
  ) => {
    console.log("send notification");

    //check if user has customerID set
    if (userdata[0]?.customer_id == null)
      return toast.error("Keine Karte hinterlegt.");

    //if nor error create notification???
    addNotification({
      typ: 1,
      subject: `Anfrage für Service "${data[0]?.stitle}"`,
      text: requestdesc,
      startdate: startDate,
      enddate: endDate,
      sender: userdata[0]?.usertoken,
      reicever: data[0]?.usertoken,
      choosenPlan: selectedplan,
      sidref: params.sid,
      nusecase: 0,
      nlink: null,
      paymentOptionchoosed: 1, //1 = später zahlen, 0 = direkt zahlen
    });

    settingsData[2]?.active && settingsData.length > 0
      ? /*sendMail({
          to: data[0]?.email,
          subject: `Anfrage für Service "${data[0]?.stitle}"`,
          text: requestdesc,
          html: "Test",
        })*/ console.log("send mail")
      : console.log("its not active its: " + settingsData[2]?.active);

    setshsrequest(false);
  };

  const handleSubmit = async (
    event,
    stripe,
    elements,
    setIsLoading,
    setError,
    cardElem,
    clientSecret
  ) => {
    event.preventDefault();
    setIsLoading(true);

    if (!stripe || !elements) {
      setError("Stripe.js has not loaded yet.");
      setIsLoading(false);
      return;
    }

    const cardElement = cardElem;

    //check if descr, startdate and endate is set
    if (!requestdesc || requestdesc === "") {
      setclienterr("Beschreibung ist leer.");
      setIsLoading(false);
      return;
    }

    if (!startDate || startDate === "") {
      setclienterr("Startdatum ist leer.");
      setIsLoading(false);
      return;
    }

    if (!endDate || endDate === "") {
      setclienterr("Enddatum ist leer.");
      setIsLoading(false);
      return;
    }

    try {
      // Create a PaymentMethod with the card details
      const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: "card",
        card: cardElement,
      });

      if (error) {
        console.error("Error creating payment method:", error);
        setError(error.message);
        setIsLoading(false);
        return;
      }

      // Payment method created successfully
      console.log("PaymentMethod:", paymentMethod);

      //now create the customer
      //ONLY DO THIS IF THERE ISNT YET A ACCOUNT CONNECTET
      await createCustomers({
        paymentMethodId: paymentMethod.id,
        usertoken: userdata[0]?.usertoken,
      });

      toast.info(
        "Ihre Serviceanfrage wurde gesendet. Verfolgen Sie den Status über Ihren Posteingang."
      );

      setIsLoading(false);
      setshsrequest(false);
    } catch (error) {
      console.error("Unexpected error:", error);
      setError(error.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    //VALIDTE THAT THERE IS ACTAULLY CORRECT DATA IN HERE
    console.log("actually customer information: ");
    console.log(createdCustomer);

    if (createdCustomer?.err || createdCustomer.length === 0) {
      console.log("error: " + createdCustomer?.err);
    } else {
      //now add notifications
      console.log("created Customer successfully: ", createdCustomer);

      addNotification({
        typ: 1,
        subject: `Anfrage für Service "${data[0]?.stitle}"`,
        text: requestdesc,
        startdate: startDate,
        enddate: endDate,
        sender: userdata[0]?.usertoken,
        reicever: data[0]?.usertoken,
        choosenPlan: selectedplan,
        sidref: params.sid,
        nusecase: 0,
        nlink: null,
        paymentOptionchoosed: 1, //1 = später zahlen, 0 = direkt zahlen
      });

      settingsData[2]?.active && settingsData.length > 0
        ? /* sendMail({
            to: data[0]?.email,
            subject: `Anfrage für Service "${data[0]?.stitle}"`,
            text: requestdesc,
            html: "Test",
          })*/ console.log("send mail")
        : console.log("its not active its: " + settingsData[2]?.active);
    }
  }, [createdCustomer]);

  const [shcardbottom, setshcardbottom] = useState(false);
  const [shicondrop, setshicondrop] = useState(false);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(`${apiUrl}/api/users/images/${data[0]?.uimg}/type/users`);

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  const {
    data: ServiceImage,
    isloading: isServiceImageLoading,
    refetch: refetchServiceImage,
  } = useGetData(`${apiUrl}/api/users/images/${data[0]?.simg}/type/services`);

  useEffect(() => {
    console.log("received image = ", ServiceImage);
    console.log(ServiceImage);

    return () => {
      URL.revokeObjectURL(ServiceImage);
    };
  }, [ServiceImage]);

  useEffect(() => {
    refetchImage();
    refetchServiceImage();
  }, [data]);

  return (
    <>
      <div
        className="request-overlay"
        style={{ display: shsrequest ? "flex" : "none" }}
      >
        <div className="service-request-modal">
          <div className="request-modal-top">
            <h2>Service Anfrage</h2>

            <div
              className="close"
              onClick={() => {
                setshsrequest(false);
              }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>

          <div className="reqest-modal-bottom">
            <div className="progress">
              <div className="progress-item">
                <div className="icon">
                  <FontAwesomeIcon icon={fa1} />
                </div>
                <p
                  style={{
                    textDecoration: step > 0 ? "line-through" : "none",
                  }}
                >
                  Plan wählen
                </p>
              </div>

              <div className="icon">
                <FontAwesomeIcon icon={faChevronRight} />
              </div>

              <div className="progress-item">
                <div className="icon">
                  <FontAwesomeIcon icon={fa2} />
                </div>
                <p
                  style={{
                    textDecoration: step > 1 ? "line-through" : "none",
                  }}
                >
                  Service Details
                </p>
              </div>

              <div className="icon">
                <FontAwesomeIcon icon={faChevronRight} />
              </div>

              <div className="progress-item">
                <div className="icon">
                  <FontAwesomeIcon icon={fa3} />
                </div>
                <p
                  style={{
                    textDecoration: step > 2 ? "line-through" : "none",
                  }}
                >
                  Bestätigen & Zahlungsabwicklung
                </p>
              </div>
            </div>

            {step === 0 ? (
              <div className="plan-wrapper">
                {plans.length > 0 &&
                  plans.map((plan, index) => {
                    return (
                      <PlanBox
                        planchoose={planchoose}
                        setplanchoose={setplanchoose}
                        index={index + 1}
                        title={plan.plan}
                        price={plan.price}
                        setselectedplan={setselectedplan}
                        id={plan.sppid}
                      />
                    );
                  })}
              </div>
            ) : step === 1 ? (
              <div className="request-desc-wrapper">
                <div className="desc-area">
                  <label> Beschreibung</label>
                  <div className="text-area">
                    <textarea
                      value={requestdesc}
                      placeholder="Zusätzliche Infomrationen, die Sie berücksichtigen sollten ..."
                      onChange={(e) => {
                        setrequestdesc(e.target.value);
                      }}
                    ></textarea>
                    <p>
                      <span
                        style={{
                          color:
                            requestdesc.length > 3000
                              ? "rgb(207, 70, 70)"
                              : "white",
                        }}
                      >
                        {requestdesc.length}
                      </span>{" "}
                      / 3000
                    </p>
                  </div>
                </div>

                <div className="date-area">
                  <div className="date-wrapper">
                    <label>
                      Terminstart <span>*</span>
                    </label>

                    <DatePicker
                      selected={startDate}
                      onChange={(date) => setStartDate(date)}
                      placeholderText="Wähle einen Starttermin"
                      dateFormat="yyyy-MM-dd"
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      excludeTimes={excludedTimes}
                    />
                  </div>

                  <div className="date-wrapper">
                    <label>
                      Terminende <span>*</span>
                    </label>

                    <DatePicker
                      selected={endDate}
                      minDate={startDate}
                      onChange={(date) => setendDate(date)}
                      excludeScrollbar
                      placeholderText="Wähle einen Endtermin"
                      dateFormat="yyyy-MM-dd"
                      showTimeSelect
                      timeFormat="HH:mm"
                      timeIntervals={15}
                      excludeTimes={excludedTimes}
                    />
                  </div>
                </div>
              </div>
            ) : (
              <>
                <div className="request-desc-wrapper">
                  <div className="desc-area">
                    <label> Beschreibung</label>
                    <div className="text-area">
                      <textarea value={requestdesc} disabled></textarea>
                      <p>{requestdesc.length} / 3000</p>
                    </div>
                  </div>

                  <div className="date-area">
                    <div className="date-wrapper">
                      <label>
                        Terminstart <span>*</span>
                      </label>

                      <input type="text" value={startDate} disabled />
                    </div>

                    <div className="date-wrapper">
                      <label>
                        Terminende <span>*</span>
                      </label>

                      <input type="text" value={endDate} disabled />
                    </div>
                  </div>
                </div>

                <div className="paymen-options-wrapper">
                  <div className="paymen-option">
                    <div
                      className="top"
                      style={{
                        paddingBottom: paymentchoose == 0 ? "20px" : "0",
                      }}
                    >
                      <input
                        type="radio"
                        value={0}
                        name="paychoose"
                        onClick={(e) => {
                          setpaymentchoose(e.target.value);
                        }}
                        checked={paymentchoose == 0 ? true : false}
                      />

                      <div className="pay-descr">
                        <h2>Sofort bezahlen</h2>
                        <p>
                          Zahle deinen Service im Voraus. Deine Zahlung wird
                          erst abgebucht, wenn deine Diesntleistung in Auftrag
                          gegeben ist. <a href="#">Mehr erfahren</a>
                        </p>
                      </div>
                    </div>

                    <div
                      className="bottom"
                      style={{
                        display: paymentchoose == 0 ? "flex" : "none",
                      }}
                    >
                      <Elements
                        stripe={stripePromise}
                        options={{
                          appearance: {
                            theme: "night",
                            variables: {
                              colorPrimary: "rgb(123, 102, 206)",
                            },
                          },
                          mode: "setup",
                          currency: "eur",
                        }}
                      >
                        <CheckoutPage
                          amount={Math.round(fullprice * 100)}
                          handleSumbit={payNow}
                          btnaction={`Jetzt ${fullprice}€ (inkl. 19 % MwSt. und 5 % Servicegebühr) bezahlen und vereinbaren`}
                          intent_type="future"
                          metadata={{
                            customer_token: userdata[0]?.usertoken, //service requester
                            provider_token: data[0]?.screator, //service provider
                            noti_data: JSON.stringify({
                              typ: [1, 4], //1 = anfrage die an den provider geht, 4 = success nachricht für den sender
                              title: data[0]?.stitle,
                              text: requestdesc,
                              start: startDate,
                              end: endDate,
                              sender: [userdata[0]?.usertoken, "system"], //system
                              reicever: [
                                data[0]?.screator,
                                userdata[0]?.usertoken,
                              ],
                              choosenPlan: selectedplan,
                              sidref: params.sid,
                              nusecase: 0,
                            }),
                          }}
                        />
                      </Elements>
                    </div>
                  </div>

                  {userdata[0]?.customer_id &&
                  userdata[0]?.customer_id != "null" ? (
                    <div className="paymen-option">
                      <div
                        className="top"
                        style={{
                          paddingBottom: paymentchoose == 1 ? "20px" : "0",
                        }}
                      >
                        <input
                          type="radio"
                          value={1}
                          name="paychoose"
                          onClick={(e) => {
                            setpaymentchoose(e.target.value);
                          }}
                          checked={paymentchoose == 1 ? true : false}
                        />

                        <div className="pay-descr">
                          <h2>Später bezahlen</h2>
                          <p>
                            Zahle deinen Service nachdem er abgeschlossen ist.
                            Du hast 30 Tage nach Serviceabschluss deine Rechnung
                            zu begleichen, ansonsten wird die Zahlung
                            automatisch getätgt, solange es kein Problem mit dem
                            Service gab. <a href="#">Mehr erfahren</a>
                          </p>
                        </div>
                      </div>

                      <div
                        className="bottom"
                        style={{
                          display: paymentchoose == 1 ? "flex" : "none",
                        }}
                      >
                        {paymentDetailsloading ? (
                          <div className="loader">
                            <FontAwesomeIcon icon={faCircleNotch} spin />
                          </div>
                        ) : (
                          <>
                            <div className="card-wrapper">
                              <div className="card-top">
                                <div
                                  className="card-left"
                                  onClick={() => {
                                    setshcardbottom(!shcardbottom);
                                  }}
                                >
                                  <div className="icon">
                                    <FontAwesomeIcon
                                      icon={
                                        shcardbottom
                                          ? faChevronUp
                                          : faChevronDown
                                      }
                                    />
                                  </div>

                                  <div className="right">
                                    <div className="icon">
                                      <FontAwesomeIcon
                                        icon={faBuildingColumns}
                                      />
                                    </div>

                                    <div className="middle">
                                      <p>Stripe Bank Account</p>
                                      <p>
                                        {paymentDetails?.paymentMethod?.method}
                                      </p>
                                    </div>

                                    <div className="labels">
                                      <div className="label">EUR</div>
                                      <div className="label">Standard</div>
                                    </div>
                                  </div>
                                </div>

                                <div className="card-right">
                                  <div
                                    className="icon"
                                    onClick={() => {
                                      setshicondrop(!shicondrop);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faEllipsis} />

                                    <div
                                      className="icon-drop"
                                      style={{
                                        display: shicondrop ? "block" : "none",
                                      }}
                                    >
                                      <div
                                        className="drop-item"
                                        onClick={() => {
                                          navigate("/user/settings");
                                        }}
                                      >
                                        <p>Anpassen</p>
                                        <div className="icon">
                                          {" "}
                                          <FontAwesomeIcon icon={faPencil} />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div
                                className="card-bottom"
                                style={{
                                  display: shcardbottom ? "block" : "none",
                                }}
                              >
                                <div className="bottom-item">
                                  <p>Karte:</p>
                                  <p>
                                    <p>
                                      {" "}
                                      {
                                        paymentDetails?.paymentMethod
                                          ?.card_brand
                                      }
                                    </p>
                                  </p>
                                </div>

                                <div className="bottom-item">
                                  <p>Nutzer:</p>
                                  <p> {userdata[0]?.username}</p>
                                </div>

                                <div className="bottom-item">
                                  <p>CustomerID:</p>
                                  <p>
                                    {" "}
                                    {paymentDetails?.paymentMethod?.customerID}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>

                      <button
                        style={{
                          display: paymentchoose == 1 ? "block" : "none",
                        }}
                        onClick={() => {
                          payLaterwCustomer();
                        }}
                      >
                        Jetzt vereinbaren, später bezahlen
                      </button>
                    </div>
                  ) : (
                    <div className="paymen-option">
                      <div
                        className="top"
                        style={{
                          paddingBottom: paymentchoose == 1 ? "20px" : "0",
                        }}
                      >
                        <input
                          type="radio"
                          value={1}
                          name="paychoose"
                          onClick={(e) => {
                            setpaymentchoose(e.target.value);
                          }}
                          checked={paymentchoose == 1 ? true : false}
                        />

                        <div className="pay-descr">
                          <h2>Später bezahlen</h2>
                          <p>
                            Zahle deinen Service nachdem er abgeschlossen ist.
                            Lege dafür einen{" "}
                            <a href="#">Stripe Customer Account</a> kostenlos
                            an. Darüber können wir sicher deine zukünftigen
                            Zahlungen abwickeln. <a href="#">Mehr erfahren</a>
                          </p>
                        </div>
                      </div>

                      <div
                        className="bottom"
                        style={{
                          display: paymentchoose == 1 ? "flex" : "none",
                        }}
                      >
                        <Elements
                          stripe={stripePromise}
                          options={{
                            appearance: {
                              theme: "night",
                              variables: {
                                colorPrimary: "rgb(123, 102, 206)",
                              },
                            },
                            mode: "payment",
                            amount: Math.round(fullprice * 100),
                            currency: "eur",
                          }}
                        >
                          <CardCheckout
                            amount={Math.round(fullprice * 100)}
                            handleSumbit={handleSubmit}
                            btnaction="Anlegen und Termin vereinbaren"
                            intent_type="future"
                            metadata={{
                              customer_token: userdata[0]?.usertoken, //service requester
                              provider_token: data[0]?.screator, //service provider
                              noti_data: JSON.stringify({
                                typ: [1, 4], //1 = anfrage die an den provider geht, 4 = success nachricht für den sender
                                title: data[0]?.stitle,
                                text: requestdesc,
                                start: startDate,
                                end: endDate,
                                sender: [userdata[0]?.usertoken, "system"], //system
                                reicever: [
                                  data[0]?.screator,
                                  userdata[0]?.usertoken,
                                ],
                                choosenPlan: selectedplan,
                                sidref: params.sid,
                                nusecase: 0,
                              }),
                            }}
                          />
                        </Elements>
                      </div>
                    </div>
                  )}

                  <div className="stripe-pay-banner">
                    <div className="left">
                      <div className="icon">
                        <span>
                          {" "}
                          <FontAwesomeIcon icon={faStripe} />
                        </span>
                        <p>Connect Pay</p>
                      </div>

                      <p>
                        Zahle später mit Stripe. Hinterlege sicher deine
                        Bankdaten und zahle ganz entspannt später.
                      </p>
                    </div>

                    <a
                      href="https://docs.stripe.com/connect/how-connect-works"
                      target="_blank"
                    >
                      <button>Mehr erfahren</button>
                    </a>
                  </div>
                </div>
              </>
            )}

            <div className="step-wrapper">
              <button
                onClick={() => {
                  setstep(step - 1);
                }}
                style={{ display: step > 0 ? "inline" : "none" }}
              >
                vorheriger Schritt
              </button>

              <button
                onClick={() => {
                  setstep(step + 1);
                }}
                style={{ display: step < 2 ? "inline" : "none" }}
              >
                nächster Schritt
              </button>
            </div>
          </div>
        </div>
      </div>

      {isloading ? (
        "Loading ..."
      ) : err != "" ? (
        <p>{err.message}</p>
      ) : data.length > 0 ? (
        <div className="service-description-wrapper">
          <div className="service-description-top">
            {isServiceImageLoading ? (
              <div className="loader">
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            ) : (
              <img src={ServiceImage} alt="service-img" loading="lazy" />
            )}
          </div>

          <div className="service-description-bottom">
            <h1>{data[0]?.stitle}</h1>

            <div className="service-description-nexto">
              <div className="service-creator">
                {isImageLoading ? (
                  <div className="loader">
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                  </div>
                ) : (
                  <img src={Image} alt="user-img" loading="lazy" />
                )}

                <div className="creator-infos">
                  <p
                    onClick={() => {
                      navigate(`/user/${data[0]?.usertoken}/profile`);
                    }}
                  >
                    {data[0]?.username}
                  </p>
                  <div className="rating">
                    <div className="rating-item">
                      <p>⭐ {rcalc} </p>
                    </div>
                    <div className="rating-item">
                      💬 {rdata.length} Bewertungen
                    </div>

                    <div
                      className="rating-item"
                      style={{ display: data[0]?.premium ? "flex" : "none" }}
                    >
                      🤝 Verifiziert
                    </div>
                  </div>
                  <a href="tel: +491748278589">📞 {data[0]?.tel}</a>
                  <p>Erstellt: {data[0]?.screated}</p>
                  <button
                    onClick={() => {
                      /*Check if User is logged in, später option geben entweder stripe acc oder direkt zahlen (jetzt erstmal stripe acc verpflichten) */
                      checkedSession?.err
                        ? navigate("/auth/login")
                        : setshsrequest(true);
                    }}
                  >
                    Termin vereinbaren
                  </button>
                </div>
              </div>

              <div className="service-description-content">
                <ContentDescription
                  title="Beschreibung"
                  type={0}
                  descr={
                    data[0]?.smaindesc && data[0]?.smaindesc != "null"
                      ? EditorState.createWithContent(
                          convertFromRaw(JSON.parse(data[0]?.smaindesc))
                        )
                      : null
                  }
                />

                <ContentDescription
                  title="Inhalt"
                  type={1}
                  conarr={cdata}
                  loading={cisloading}
                  err={cerr}
                />

                <ContentDescription
                  title="FAQ"
                  type={2}
                  conarr={fdata}
                  loading={fisloading}
                  err={ferr}
                />

                <div className="rating-commtents">
                  <div className="rating-comments-top">
                    <h2>Bewertungen</h2>

                    <select
                      onChange={(e) => {
                        setchoose(e.target.value);
                      }}
                    >
                      <option value={0}>Sotiere nach</option>
                      <option value={1}>Datum</option>
                      <option value={2}>Niedrigste Bewertungen</option>
                      <option value={3}>Höchste Bewertungen</option>
                    </select>
                  </div>

                  {risloading ? (
                    "Loading ..."
                  ) : rerr != "" ? (
                    <p>{rerr.message} </p>
                  ) : rdubarr && rdubarr.length > 0 ? (
                    rdubarr.map((rating, index) => {
                      if (index < shdataamount) {
                        return (
                          <Comment
                            id={rating.rid}
                            ratingdata={rating}
                            user={rating.username}
                            token={rating.usertoken}
                            img={rating.uimg}
                            date={rating.rdate}
                            rating={rating.rrating}
                            text={rating.rtext}
                            edit={true}
                            userdata={userdata}
                            seteditcomm={seteditcomm}
                            refetchReviews={refetchReviews}
                          />
                        );
                      }
                    })
                  ) : (
                    "Keine Bewerungen bis jetzt."
                  )}

                  <div className="load-more">
                    <button
                      style={{
                        display:
                          rdubarr.length > 5 && shdataamount != rdubarr.length
                            ? "block"
                            : "none",
                      }}
                      onClick={() => {
                        ShowmoreData(shdataamount, setshdataamount, rdubarr, 5);
                      }}
                    >
                      {" "}
                      {"Mehr anzeigen"}
                    </button>
                  </div>

                  {!checkedSession?.err ? (
                    editcomm.length > 0 ? (
                      <div className="add-commtent" id="editwrap">
                        <div className="review-part">
                          <h2>Update deinen Kommentar</h2>
                          <div className="review-box">
                            {Reviews.map((rev, index) => (
                              <div
                                className="icon"
                                style={{
                                  backgroundColor:
                                    index <= hovindex
                                      ? reviewcol
                                      : "var(--main-bg-accent-light)",
                                }}
                                onMouseEnter={() => {
                                  checkReviews(index);
                                  sethovindex(index);
                                }}
                              >
                                <FontAwesomeIcon icon={faStar} />
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="review-part">
                          <div className="desc-area">
                            <div className="text-area">
                              <textarea
                                value={reviewtext}
                                onChange={(e) => {
                                  setreviewtext(e.target.value);
                                }}
                              ></textarea>
                              <p>
                                <span
                                  style={{
                                    color:
                                      reviewtext.length > 3000
                                        ? "rgb(207, 70, 70)"
                                        : "white",
                                  }}
                                >
                                  {reviewtext.length}
                                </span>{" "}
                                / 3000
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="load-more">
                          <button
                            onClick={() => {
                              updateReview({
                                rid: editcomm[0]?.rid,
                                rrating: hovindex + 1,
                                rtext: reviewtext,
                              });

                              seteditcomm([]);
                              setreviewtext("");
                              checkReviews(0);
                              sethovindex(0);
                            }}
                          >
                            Kommentar Updaten
                          </button>
                          <button
                            onClick={() => {
                              seteditcomm([]);
                              setreviewtext("");
                              checkReviews(0);
                              sethovindex(0);
                            }}
                          >
                            Abbrechen
                          </button>
                        </div>
                      </div>
                    ) : (
                      <div className="add-commtent">
                        <div className="review-part">
                          <h2>Bewerte diesen Service</h2>
                          <div className="review-box">
                            {Reviews.map((rev, index) => (
                              <div
                                className="icon"
                                style={{
                                  backgroundColor:
                                    index <= hovindex
                                      ? reviewcol
                                      : "var(--main-bg-accent-light)",
                                }}
                                onMouseEnter={() => {
                                  checkReviews(index);
                                  sethovindex(index);
                                }}
                              >
                                <FontAwesomeIcon icon={faStar} />
                              </div>
                            ))}
                          </div>
                        </div>

                        <div className="review-part">
                          <div className="desc-area">
                            <div className="text-area">
                              <textarea
                                value={reviewtext}
                                onChange={(e) => {
                                  setreviewtext(e.target.value);
                                }}
                              ></textarea>
                              <p>
                                <span
                                  style={{
                                    color:
                                      reviewtext.length > 3000
                                        ? "rgb(207, 70, 70)"
                                        : "white",
                                  }}
                                >
                                  {reviewtext.length}
                                </span>{" "}
                                / 3000
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="load-more">
                          <button
                            onClick={() => {
                              addjsonData({
                                rcreator: userdata[0]?.usertoken,
                                rrating: hovindex + 1,
                                rtext: reviewtext,
                              });
                            }}
                          >
                            Bewertung Hinzufügen
                          </button>
                          <button
                            onClick={() => {
                              setaddreview(false);
                              setreviewtext("");
                              checkReviews(0);
                              sethovindex(0);
                            }}
                          >
                            Abbrechen
                          </button>
                        </div>
                      </div>
                    )
                  ) : (
                    <>
                      <div className="login-notify-wrapper">
                        <h2>Du bist nicht angemeldet.</h2>
                        <p>Melde dich an um eine Bewertung abzugeben.</p>
                        <button
                          onClick={() => {
                            navigate("/auth/login");
                          }}
                        >
                          Anmelden
                        </button>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        "No Service Information found!"
      )}
    </>
  );
};

const PlanBox = (props) => {
  const price = props.price;
  const ust = Math.round((price - price * 0.81) * 100) / 100;
  const pauschale = Math.round((price - price * 0.95) * 100) / 100;

  return (
    <div className="plan-item">
      <input
        type="checkbox"
        name="plan"
        value={props.index}
        onClick={(e) => {
          props.setplanchoose(e.target.value);
          props.setselectedplan(props.id);
        }}
        checked={props.planchoose == props.index ? true : false}
      />

      <div className="left">
        <p>{props.title}</p>
        <div className="bottom">
          <p>
            {price + ust + pauschale}€ (inkl. 19 % MwSt. und 5 % Servicegebühr)
          </p>
        </div>
      </div>
    </div>
  );
};

export const ContentDescription = (props) => {
  const [shdesc, setshdesc] = useState(true);

  const [tdata, setdata] = useState([]);

  let [sanitizedHTML, setsanitizedHTML] = useState("");

  useEffect(() => {
    try {
      if (props?.descr && typeof props.descr.getCurrentContent === "function") {
        setsanitizedHTML(
          DOMPurify.sanitize(stateToHTML(props?.descr?.getCurrentContent()))
        ); // Sanitize the HTML
      }
    } catch (error) {
      console.log("error: " + error.message);
    }
  }, [props.descr]);

  return (
    <div className="content-desc-wrapper">
      <div
        className="top"
        onClick={() => {
          setshdesc(!shdesc);
        }}
      >
        <p>{props.title}</p>
        <div className="icon">
          <FontAwesomeIcon icon={shdesc ? faChevronUp : faChevronDown} />
        </div>
      </div>

      <div className="content" style={{ display: shdesc ? "block" : "none" }}>
        {props.type === 0 ? (
          <div
            className="innerContent"
            dangerouslySetInnerHTML={{
              __html: sanitizedHTML,
            }}
          />
        ) : props.type === 1 ? (
          <ContentTable
            dataarr={props.conarr}
            trows={tdata}
            settrows={setdata}
          />
        ) : (
          <div className="faq-wrapper">
            {props.loading ? (
              "Loading ..."
            ) : props.err != "" ? (
              <p>{props.err.message} </p>
            ) : props.conarr && props.conarr.length > 0 ? (
              props.conarr.map((item) => {
                return (
                  <FAQWrapper
                    title={item.fquestion}
                    answer={item.fanswer}
                    id={item.fid}
                  />
                );
              })
            ) : (
              "No FAQ Item"
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export const ContentTable = (props) => {
  const [shaddtable, setshaddtable] = useState(false);

  useEffect(() => {
    props.dataarr && props.settrows(props.dataarr);
  }, [props.dataarr]);

  useEffect(() => {
    console.log("TROWS");
    console.log(props.trows);
  }, [props.trows]);

  const [rowedit, setrowedit] = useState(false);

  const [editcontent, seteditcontent] = useState([]);

  const [service, setservice] = useState("");
  const [basic, setbasic] = useState(0);
  const [standard, setstandard] = useState(0);
  const [allinc, setallinc] = useState(0);

  useEffect(() => {
    if (editcontent.length > 0 && editcontent[0].plans.length > 0) {
      setservice(editcontent[0]?.service);
      setbasic(editcontent[0]?.plans[0]);
      setstandard(editcontent[0]?.plans[1]);
      setallinc(editcontent[0]?.plans[2]);
    }

    console.log("EDITCONTENT:");
    console.log(editcontent);
  }, [editcontent]);

  useEffect(() => {
    console.log("TROWS");
    console.log(props.trows);
  }, [props.trows]);

  return (
    <div className="t-desc">
      <table>
        <thead>
          <tr>
            <th>Tätigkeit</th>
            <th>Basis Plan</th>
            <th>Standard Plan</th>
            <th>All Inclusive Plan</th>
          </tr>
        </thead>

        <tbody>
          {props.trows && props.trows.length > 0 ? (
            props.trows.map((row) => {
              return (
                <ContentRow
                  id={row?.id}
                  rowinfo={row}
                  settrows={props.settrows}
                  trows={props.trows}
                  edit={props.edit}
                  setrowedit={setrowedit}
                  rowedit={rowedit}
                  seteditcontent={seteditcontent}
                />
              );
            })
          ) : (
            <tr>
              <td colSpan={4}>
                <p>Kein Vergleich angelegt 😞</p>
              </td>
            </tr>
          )}

          <tr
            className="main-tr"
            style={{ display: shaddtable || rowedit ? "table-row" : "none" }}
          >
            <td>
              <ServiceInput
                label="Tätigkeit"
                type="text"
                name="reply"
                setvalue={setservice}
                val={service}
              />
            </td>
            <td>
              <ServiceDrop
                label="Basis Plan"
                setvalue={setbasic}
                select={editcontent[0]?.plans[0]}
              />
            </td>
            <td>
              <ServiceDrop
                label="Standard Plan"
                setvalue={setstandard}
                select={editcontent[0]?.plans[1]}
              />
            </td>
            <td>
              <div className="td-nexto">
                <ServiceDrop
                  label="All Inclusive Plan"
                  setvalue={setallinc}
                  select={editcontent[0]?.plans[2]}
                />
                <button
                  type="button"
                  onClick={() => {
                    if (!rowedit) {
                      props.settrows([
                        ...props.trows,
                        {
                          id: props.trows.length,
                          service: service ? service : "",
                          plans: [
                            basic ? basic : 0,
                            standard ? standard : 0,
                            allinc ? allinc : 0,
                          ],
                        },
                      ]);
                    } else {
                      //filter diese id die wir updaten raus und adde geupdatedes neu hinzu
                      const olddata = props.trows.filter(
                        (row) => row.id != editcontent[0]?.id
                      );

                      //update Row State
                      props.settrows([
                        ...olddata,
                        {
                          id: editcontent[0]?.id,
                          service: service,
                          plans: [basic, standard, allinc],
                        },
                      ]);
                    }
                  }}
                >
                  {rowedit ? "Ändern" : "Hinzufügen"}
                </button>
              </div>
            </td>
          </tr>

          <tr
            className="media-tr"
            style={{ display: shaddtable || rowedit ? "table-row" : "none" }}
          >
            <td colSpan={4}>
              <ServiceInput
                label="Tätigkeit"
                type="text"
                name="reply"
                setvalue={setservice}
                val={service}
              />
            </td>
          </tr>

          <tr
            className="media-tr"
            style={{ display: shaddtable || rowedit ? "table-row" : "none" }}
          >
            <td colSpan={4}>
              <ServiceDrop
                label="Basis Plan"
                setvalue={setbasic}
                select={editcontent[0]?.plans[0]}
              />
            </td>
          </tr>

          <tr
            className="media-tr"
            style={{ display: shaddtable || rowedit ? "table-row" : "none" }}
          >
            <td colSpan={4}>
              <ServiceDrop
                label="Standard Plan"
                setvalue={setstandard}
                select={editcontent[0]?.plans[1]}
              />
            </td>
          </tr>

          <tr
            className="media-tr"
            style={{ display: shaddtable || rowedit ? "table-row" : "none" }}
          >
            <td colSpan={4}>
              <div className="td-nexto">
                <ServiceDrop
                  label="All Inclusive Plan"
                  setvalue={setallinc}
                  select={editcontent[0]?.plans[2]}
                />
                <button
                  type="button"
                  onClick={() => {
                    if (!rowedit) {
                      props.settrows([
                        ...props.trows,
                        {
                          id: props.trows.length,
                          service: service ? service : "",
                          plans: [
                            basic ? basic : 0,
                            standard ? standard : 0,
                            allinc ? allinc : 0,
                          ],
                        },
                      ]);
                    } else {
                      //filter diese id die wir updaten raus und adde geupdatedes neu hinzu
                      const olddata = props.trows.filter(
                        (row) => row.id != editcontent[0]?.id
                      );

                      //update Row State
                      props.settrows([
                        ...olddata,
                        {
                          id: editcontent[0]?.id,
                          service: service,
                          plans: [basic, standard, allinc],
                        },
                      ]);
                    }
                  }}
                >
                  {rowedit ? "Ändern" : "Hinzufügen"}
                </button>
              </div>
            </td>
          </tr>
        </tbody>

        <div
          className="table-add-icon"
          style={{ display: !props.edit ? "none" : "flex" }}
          onClick={() => {
            setshaddtable(!shaddtable);
            rowedit && setrowedit(false);
            !shaddtable && seteditcontent([]);
          }}
        >
          <FontAwesomeIcon icon={shaddtable || rowedit ? faMinus : faAdd} />
        </div>
      </table>
    </div>
  );
};

export const ContentRow = (props) => {
  useEffect(() => {
    console.log("rowinfo");
    console.log(props.rowinfo);
  }, [props.rowinfo]);

  return (
    <tr>
      <td>
        <p>{!props.edit ? props.rowinfo[0]?.ctitle : props.rowinfo?.service}</p>
      </td>

      {!props.edit
        ? props.rowinfo.length > 0 &&
          props.rowinfo.map((row) => {
            return (
              <td>
                <div className="icon">{row.isincluded == 1 ? "✔️" : "❌"}</div>
              </td>
            );
          })
        : props.rowinfo.plans.length > 0 &&
          props.rowinfo.plans.map((row) => {
            return (
              <td>
                <div className="icon">{row == 1 ? "✔️" : "❌"}</div>
              </td>
            );
          })}

      <div
        className="table-icons"
        style={{ display: !props.edit ? "none" : "flex" }}
      >
        <div
          className="table-del-icon"
          onClick={() => {
            props.settrows(props.trows.filter((row) => row.id != props.id));
          }}
        >
          <FontAwesomeIcon icon={faTrash} />
        </div>

        <div
          className="table-del-icon"
          onClick={() => {
            props.setrowedit(true);
            props.seteditcontent(
              props.trows.filter((row) => row.id === props.id)
            );

            console.log("EDITCONTETN");
            console.log(props.trows.filter((row) => row.id === props.id));
          }}
        >
          <FontAwesomeIcon icon={faPencil} />
        </div>
      </div>
    </tr>
  );
};

export const FAQWrapper = (props) => {
  const [shanswer, setshanswer] = useState(true);

  return (
    <div className="content-desc-wrapper">
      <div className="top">
        <p>{props.title}</p>
        <div className="icons">
          <div
            className="icon"
            onClick={() => {
              setshanswer(!shanswer);
            }}
          >
            <FontAwesomeIcon icon={shanswer ? faChevronUp : faChevronDown} />
          </div>

          <div
            className="icon"
            style={{ display: !props.close && "none" }}
            onClick={() => {
              props.setfaqarr(
                props.faqarr.filter((item) => item.fid != props.id)
              );
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>
      </div>

      <div className="content" style={{ display: shanswer ? "block" : "none" }}>
        <p>{props.answer}</p>
      </div>
    </div>
  );
};

export const Comment = (props) => {
  const { apiUrl } = useContext(SkillContext);

  const {
    data: deletedReview,
    err: drerror,
    isloading: drloading,
    deleteData: deleteReview,
  } = useDeleteData(` ${apiUrl}/api/services/${props.id}/reviews/null`, () => {
    props.refetchReviews();
  });

  useEffect(() => {
    deletedReview?.err
      ? toast.error(deletedReview?.err)
      : toast.success(deletedReview?.msg);
  }, [deletedReview]);

  const [shDelOv, setshDelOv] = useState(false);

  const dropList = [
    {
      title: "Editieren",
      func: () => {
        props.seteditcomm([props.ratingdata]);
      },
      scroll: true,
      scrollto: "editwrap",
    },
    {
      title: "Löschen",
      func: () => {
        console.log("delete stuff");
        setshDelOv(true);
      },
    },
  ];

  const [shdrop, setshdrop] = useState(false);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(`${apiUrl}/api/users/images/${props.img}/type/users`);

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  return (
    <>
      <ConfirmDelete
        shov={shDelOv}
        setshov={setshDelOv}
        img="trash.jpg"
        title="Willst du diese Bewertung wirklich lösen ?"
        message="Diese Bewertung wird unwiederruflich gelöscht."
        action={() => {
          deleteReview();
        }}
        isdelete
      />

      <div className="comment-wrapper">
        <div className="comment-top">
          {isImageLoading ? (
            <div className="loader">
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
          ) : (
            <img src={Image} alt="user-img" loading="lazy" />
          )}

          <div className="comment-creator">
            <div className="user">
              <div className="left">
                <p>{props.user}</p>
                <span>&#x2022; </span>
                <p>
                  📅{" "}
                  {
                    new Date(props.date)
                      .toLocaleString("de-DE", {
                        timeZone: "Europe/Berlin",
                      })
                      .split(",")[0]
                  }
                </p>
              </div>

              <div
                className="right"
                onClick={() => {
                  setshdrop(!shdrop);
                }}
                style={{
                  display:
                    props.token === props.userdata[0]?.usertoken
                      ? "flex"
                      : "none",
                }}
              >
                <div
                  className="icon"
                  style={{ display: props.edit ? "block" : "none" }}
                >
                  <FontAwesomeIcon icon={faEllipsisVertical} />
                </div>

                {shdrop && (
                  <DropDown
                    drops={dropList}
                    setshdrop={setshdrop}
                    shdrop={shdrop}
                  />
                )}
              </div>
            </div>
            <p>⭐ {props.rating}</p>
          </div>
        </div>

        <div className="comment-section">
          <p>{props.text}</p>
        </div>
      </div>
    </>
  );
};

export const DropDown = (props) => {
  return (
    <div className="dropdown-wrapper">
      {props.drops.length > 0 &&
        props.drops.map((drop) => {
          return drop.scroll ? (
            <ScrollLink
              className="drop-item"
              to={drop.scrollto}
              smooth={true}
              duration={500}
              onClick={() => {
                drop.func();
                props.setshdrop(!props.shdrop);
              }}
            >
              {drop.title}
            </ScrollLink>
          ) : (
            <div
              className="drop-item"
              onClick={() => {
                drop.func();
                props.setshdrop(!props.shdrop);
              }}
            >
              {drop.title}
            </div>
          );
        })}
    </div>
  );
};

export const StripeOverlay = (props) => {
  const { userdata, apiUrl } = useContext(SkillContext);

  return (
    <div
      className="overlay-wrapper"
      style={{ display: props.shov ? "flex" : "none" }}
    >
      <div className="stripe-warning-wrapper">
        <div className="top">
          <div className="left">
            <FontAwesomeIcon icon={faStripe} />
          </div>
          <div
            className="right"
            onClick={() => {
              props.setshov(false);
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </div>
        </div>

        <div className="bottom">
          <h2>Hallo {userdata[0]?.username.split(",")}, </h2>
          <p>
            {props.context} Mit Stripe ermöglichen wir Ihnen eine sichere
            Zahlungsabwicklung. Bei Unklarheiten lesen Sie unsere{" "}
            <a href="#">AGBs</a> bezüglich Stripe oder besuchen Sie{" "}
            <a href="https://stripe.com">Stripe</a> selbst. Führen Sie folgende
            Schritte durch um Ihre Stripe Verknüpfung zu erstellen:
          </p>

          <div className="steps-wrapper">
            <div className="step">
              <div className="left">
                <div className="icon">
                  {" "}
                  <FontAwesomeIcon icon={fa1} />
                </div>
                <div className="stripe-line"></div>
              </div>

              <div
                className="right
"
              >
                <h2>Einstellung</h2>
                <p>
                  Klicken Sie oben rechts auf Ihren Account Namen und wählen Sie
                  Einstellungen aus.
                </p>
              </div>
            </div>

            <div className="step">
              <div className="left">
                <div className="icon">
                  {" "}
                  <FontAwesomeIcon icon={fa2} />
                </div>
                <div className="stripe-line"></div>
              </div>

              <div
                className="right
"
              >
                <h2>Bankonto Einstellung</h2>
                <p>Navigieren Sie zu dem Tab 'Bankkonto'.</p>
              </div>
            </div>

            <div className="step">
              <div className="left">
                <div className="icon">
                  {" "}
                  <FontAwesomeIcon icon={fa3} />
                </div>
                <div className="stripe-line"></div>
              </div>

              <div
                className="right
"
              >
                <h2>Verknüpfung erstellen</h2>
                <p>
                  Klicken Sie auf Verknüpfung erstellen oder Fertigstellen, bei
                  Fertigstellen beachten Sie oben welche Punkte Ihnen noch
                  fehlen. Füllen Sie alle Werte aus, bis Sie jeden Punkt
                  abgearbeitet haben.
                </p>
              </div>
            </div>

            <div className="step">
              <div className="left">
                <div className="icon">
                  {" "}
                  <FontAwesomeIcon icon={fa4} />
                </div>
              </div>

              <div
                className="right
"
              >
                <h2>Termin Vereinbaren</h2>
                <p>
                  Das wars schon 🥳. Viel Spaß beim vereinbaren von Services,
                  bei irgendwelchen Fragen melden Sie sich gerne beim{" "}
                  <a href="#">Support</a>, wir helfen Ihnen jederzeit gerne aus.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
