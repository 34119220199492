import './Css/datenschutz.css';

export const Impressum = () => {

return (
<div className='legal-notice-wrapper'>

<div className='notice-section'>

<h2>Impressum</h2>
    
<div  className='notice-section'>
<p>Angaben gemäß § 5 TMG:</p>
</div>
<p>Tizian Grabert</p>
<p>Storchenweg 15</p>
<p>90547 Stein</p>

<p> Telefon: +49 174 8278589</p>
<p> E-Mail: tizian.grabert@gmail.com</p>

<div  className='notice-section'>
<p style={{color: "red"}}>Hinweis: Diese Webseite ist eine Testversion und wird zur Erprobung des zukünftigen Projekts "FindmyService" verwendet. 
    Die rechtliche Verantwortung liegt bis zur Gründung des Unternehmens vollständig bei Tizian Grabert als Privatperson.</p>
    </div>
 

<h3>Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV: </h3>

<p>Tizian Grabert</p>
<p>Storchenweg 15</p>
<p>90547 Stein</p>
</div>


<div  className='notice-section'>

<h2>Hinweis auf EU-Plattform zur Online-Streitbeilegung</h2>

<p>Die Europäische Kommission stellt eine Plattform zur Online-Streitbeilegung (OS) bereit, die Sie hier finden:</p>

<p><a href='https://ec.europa.eu/consumers/odr'>Online Streitbeilegung</a>.</p>

<p>Unsere E-Mail-Adresse lautet: [email]</p>

</div>

</div>
)

}