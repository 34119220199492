import { useCallback, useContext, useEffect } from "react";
import { usePostJSONData } from "./Hooks/postjsonData";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import "./Css/verification.css";
import { SkillContext } from "./App";

export const Accountverification = () => {
  const params = useParams();
  const { apiUrl } = useContext(SkillContext);

  const {
    data: verifiedAccount,
    isloading,
    err,
    addjsonData: verifyAccount,
  } = usePostJSONData(`${apiUrl}/api/payments/verify/account`);

  const accid = params.accountid;

  useEffect(() => {
    verifyAccount({
      accountid: accid,
      action: "signup",
    });
  }, []);

  useEffect(() => {
    console.log("verified:");
    console.log(verifiedAccount);
  }, [verifiedAccount]);

  const navigate = useNavigate();
  return (
    <div className="verification-wrapper">
      {isloading ? (
        <div className="loader">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      ) : err != "" ? (
        <p>{err}</p>
      ) : (
        <>
          {verifiedAccount?.err && !verifiedAccount?.eventually_due ? (
            <div className="verfication-box-error">
              <h2>Oh nein ...</h2>
              <p>
                Etwas ist wohl bei deiner Verifizierung deines Stripe Accounts
                fehlgeschlagen. Du hast folgenden Fehlercode:
              </p>
              <div className="err-quote">
                {verifiedAccount?.err?.raw?.message
                  ? verifiedAccount?.err?.raw?.message
                  : verifiedAccount?.err}
              </div>
            </div>
          ) : verifiedAccount?.err && verifiedAccount?.eventually_due ? (
            <div className="verfication-box-error">
              <h2>Fast fertig ...</h2>
              <p>
                Dein Stripe Account ist fast fertig angelegt. Du kannst dich
                zwar schon bei FindmyService anmelden allerdings musst du deinen
                Stripe Account fertigstellen um Zahlungen tätigen zu können. Du
                kannst deinen Stripe Account immer noch später in den
                Einstellungen fertigstellen
              </p>

              <div className="box-option">
                <button
                  onClick={() => {
                    window.location.href = verifiedAccount?.onboard_link;
                  }}
                >
                  Jetzt fertigstellen
                </button>
                <button
                  onClick={() => {
                    navigate("/auth/login");
                  }}
                >
                  Später erledigen
                </button>
              </div>
            </div>
          ) : (
            <div className="verfication-box-success">
              <h2>Account erfolgreich angelegt.</h2>
              <p>
                Dein FindmyService und Stripe Account wurden beide erfolgreich
                angelegt. Vielen Dank, dass du unsere Plattform nutzt 😎.
              </p>

              <div className="box-option">
                <button
                  onClick={() => {
                    navigate("/auth/login");
                  }}
                >
                  Anmelden
                </button>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};

//if user didnt has completed the verification process completly he can redo the rest here???

/*When Setup is not completed

möglichkeit geben direkt hier weiter zu machen oder später anpassen (in den settings dann)

when setup complete:
anzeigen: Account erfolgreich bei findmyservice und stripe angelegt

*/

/*USERSE CONNECT ACCOUNT: https://connect.stripe.com/express/accountid -> das bei settings hinterlegen */
