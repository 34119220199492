import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Css/nav.css";
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faBoxesPacking,
  faBriefcase,
  faChevronDown,
  faChevronUp,
  faCircleInfo,
  faCircleNotch,
  faCog,
  faCrown,
  faDashboard,
  faEnvelope,
  faEnvelopesBulk,
  faGripVertical,
  faHeart,
  faHome,
  faInfo,
  faLightbulb,
  faMailForward,
  faPersonRunning,
  faQuestion,
  faSearchLocation,
  faStar,
  faTicket,
  faTruckFast,
  faTruckRampBox,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { SkillContext } from "./App";
import { useDeleteData } from "./Hooks/deleteData";
import { useGetData } from "./Hooks/getData";

export const Nav = () => {
  const [shdrop, setshdrop] = useState(false);
  const { isloggedin, setisloggedin, userdata, apiUrl } =
    useContext(SkillContext);

  useEffect(() => {
    console.log("userdata");
    console.log(userdata);
  }, [userdata]);

  //name ideas:
  //skillchange
  //quick help

  //Fetch the Service of this serviceID
  const { data, err, isloading, deleteData } = useDeleteData(
    `${apiUrl}/api/users/signout`
  );

  useEffect(() => {
    if (data.length != 0 && !data?.err) {
      setisloggedin(false);
    }
  }, [data]);

  const [shHamburgDrop, setshHamburgDrop] = useState(false);

  const sdrop = [
    {
      link: "/my/services",
      icon: faTruckRampBox,
      title: "Meine Dienstleistungen",
    },
    {
      link: "/users/service/pending",
      icon: faBoxesPacking,
      title: "Bestellungen",
    },
    {
      link: "/service/favourite",
      icon: faHeart,
      title: "Favouriten",
    },
  ];

  const idrop = [
    {
      link: "/user/settings",
      icon: faCog,
      title: "Einstellung",
    },
    {
      link: `/inbox/user/${userdata[0]?.usertoken}`,
      icon: faEnvelope,
      title: "Posteingang",
    },
    {
      link: "/support/tickets",
      icon: faTicket,
      title: "Support",
    },
  ];

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(`${apiUrl}/api/users/images/${userdata[0]?.uimg}/type/users`);

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  useEffect(() => {
    refetchImage();
  }, [userdata]);

  return (
    <div className="nav-wrapper">
      <a href="#" className="left">
        <h1>
          Find<span>my</span>Service
        </h1>
      </a>

      <div className="hamburger-menu">
        <div
          className="icon"
          onClick={() => {
            setshHamburgDrop(!shHamburgDrop);
          }}
        >
          <FontAwesomeIcon icon={faGripVertical} />
        </div>
      </div>

      <div
        className="hamburger-drop"
        style={{ display: shHamburgDrop ? "block" : "none" }}
      >
        <a className="drop-wrapper" href="/search/services">
          <div className="icon">
            <FontAwesomeIcon icon={faSearchLocation} />
          </div>
          Dienstleistungen
        </a>

        <a className="drop-wrapper" href="/service/add">
          <div className="icon">
            <FontAwesomeIcon icon={faAdd} />
          </div>
          Neuer Service
        </a>

        <a className="drop-wrapper" href="/premium">
          <div className="icon">
            <FontAwesomeIcon icon={faStar} />
          </div>
          Premium
        </a>

        {isloggedin ? (
          <div className="profile-wrapper">
            {isImageLoading ? (
              <div className="loader">
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            ) : (
              <img
                src={Image}
                alt="qualifizierung"
                loading="lazy"
                onClick={() => {
                  setshdrop(!shdrop);
                }}
              />
            )}
            <div
              className="user-inf"
              onClick={() => {
                setshdrop(!shdrop);
              }}
            >
              <span>
                {userdata[0]?.username}{" "}
                <div
                  className="premium-wrapper"
                  style={{ display: userdata[0]?.premium ? "flex" : "none" }}
                >
                  <div className="icon">
                    <FontAwesomeIcon icon={faStar} />
                  </div>
                </div>
              </span>
              <p>{userdata[0]?.email}</p>
            </div>

            <div
              className="profile-dropdown"
              style={{ display: shdrop ? "block" : "none" }}
            >
              <div className="triangle"></div>

              <DropSection
                title="Allgemeines"
                icon={faCircleInfo}
                dropitems={idrop}
              />

              <DropSection
                title="Dienstleistungen"
                icon={faTruckFast}
                dropitems={sdrop}
              />

              {userdata[0]?.isAdmin ? (
                <a href="/support/ticket/dashboard" className="dropdown-item">
                  <div className="icon">
                    <FontAwesomeIcon icon={faDashboard} />{" "}
                  </div>
                  <p>Ticket Dashboard</p>
                </a>
              ) : (
                ""
              )}

              <a
                className="dropdown-item"
                onClick={() => {
                  deleteData();
                  //window.location.reload(false);
                }}
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
                <p>Abmelden</p>
              </a>
            </div>
          </div>
        ) : (
          <a className="drop-wrapper" href="/auth/login">
            <div className="icon">
              <FontAwesomeIcon icon={faArrowRight} />{" "}
            </div>
            Anmelden
          </a>
        )}
      </div>

      <div className="right">
        <a className="drop-wrapper" href="/search/services">
          <div className="icon">
            <FontAwesomeIcon icon={faSearchLocation} />
          </div>
          Dienstleistungen
        </a>

        <a className="drop-wrapper" href="/service/add">
          <div className="icon">
            <FontAwesomeIcon icon={faAdd} />
          </div>
          Neuer Service
        </a>

        <a className="drop-wrapper" href="/premium">
          <div className="icon">
            <FontAwesomeIcon icon={faStar} />
          </div>
          Premium
        </a>

        {isloggedin ? (
          <div className="profile-wrapper">
            {isImageLoading ? (
              <div className="loader">
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            ) : (
              <img
                src={Image}
                alt="qualifizierung"
                loading="lazy"
                onClick={() => {
                  setshdrop(!shdrop);
                }}
              />
            )}

            <div
              className="user-inf"
              onClick={() => {
                setshdrop(!shdrop);
              }}
            >
              <span>
                {userdata[0]?.username}{" "}
                <div
                  className="premium-wrapper"
                  style={{ display: userdata[0]?.premium ? "flex" : "none" }}
                >
                  <div className="icon">
                    <FontAwesomeIcon icon={faStar} />
                  </div>
                </div>
              </span>
              <p>{userdata[0]?.email}</p>
            </div>

            <div
              className="profile-dropdown"
              style={{ display: shdrop ? "block" : "none" }}
            >
              <div className="triangle"></div>

              <DropSection
                title="Allgemeines"
                icon={faCircleInfo}
                dropitems={idrop}
              />

              <DropSection
                title="Dienstleistungen"
                icon={faTruckFast}
                dropitems={sdrop}
              />

              {userdata[0]?.isAdmin ? (
                <a href="/support/ticket/dashboard" className="dropdown-item">
                  <div className="icon">
                    <FontAwesomeIcon icon={faDashboard} />{" "}
                  </div>
                  <p>Ticket Dashboard</p>
                </a>
              ) : (
                ""
              )}

              <a
                className="dropdown-item"
                onClick={() => {
                  deleteData();
                  // window.location.reload(false);
                }}
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faArrowLeft} />
                </div>
                <p>Abmelden</p>
              </a>
            </div>
          </div>
        ) : (
          <a className="drop-wrapper" href="/auth/login">
            <div className="icon">
              <FontAwesomeIcon icon={faArrowRight} />{" "}
            </div>
            Anmelden
          </a>
        )}
      </div>
    </div>
  );
};

const DropSection = (props) => {
  const [shsection, setshsection] = useState(false);

  return (
    <div className="dropdown-section">
      <div
        className="top"
        onClick={() => {
          setshsection(!shsection);
        }}
      >
        <div className="left">
          <div className="icon">
            <FontAwesomeIcon icon={props.icon} />
          </div>
          <p>{props.title}</p>
        </div>

        <div className="icon">
          <FontAwesomeIcon icon={shsection ? faChevronUp : faChevronDown} />
        </div>
      </div>

      <div className="bottom" style={{ display: shsection ? "block" : "none" }}>
        {props.dropitems && props.dropitems.length > 0
          ? props.dropitems.map((ditem, index) => {
              return (
                <a href={ditem.link} className="dropdown-item">
                  <div className="icon">
                    <FontAwesomeIcon icon={ditem.icon} />{" "}
                  </div>
                  <p>{ditem.title}</p>
                </a>
              );
            })
          : ""}
      </div>
    </div>
  );
};
