import { ServiceInput, Switch } from "./ServiceAdd";
import "./Css/settings.css";
import { useContext, useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpRightFromSquare,
  faBank,
  faBuildingColumns,
  faCamera,
  faCheck,
  faChevronDown,
  faCircleNotch,
  faCog,
  faEdit,
  faEnvelope,
  faGlobe,
  faIdBadge,
  faLock,
  faPencil,
  faPlay,
  faShield,
  faStop,
  faThumbsDown,
  faThumbsUp,
  faWarning,
  faX,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { SkillContext } from "./App";
import { useGetData } from "./Hooks/getData";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useUpdateJSONData } from "./Hooks/updatejsonData";
import { useUpdateData } from "./Hooks/updateData";
import { usePostJSONData } from "./Hooks/postjsonData";
import { faStripe } from "@fortawesome/free-brands-svg-icons";
import { useDeleteData } from "./Hooks/deleteData";

export const Settings = () => {
  const {
    isloggedin,
    setisloggedin,
    userdata,
    fetchUserData,
    settingsData,
    fetchSettings,
    apiUrl,
  } = useContext(SkillContext);

  const navigate = useNavigate();

  /*CHECK IF USER IS LOGGEDIN*/
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );
  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  const { data: verifiedAccount, addjsonData: verifyAccount } = usePostJSONData(
    `${apiUrl}/api/payments/verify/account`
  );

  const { data: addedstripeacc, addjsonData: addstripeacc } = usePostJSONData(
    `${apiUrl}/api/payments`
  );

  const {
    data: paymentDetails,
    err: paymentDetailserr,
    isloading: paymentDetailsloading,
    refetch: fetchPaymentDetails,
  } = useGetData(
    ` ${apiUrl}/api/payments/customer/${userdata[0]?.customer_id}`
  );

  const { data: removedLink, update: removeLink } = useUpdateJSONData(
    ` ${apiUrl}/api/payments/remove/stripe/account/${userdata[0]?.usertoken}`,
    () => {
      verifyAccount({
        accountid: userdata[0]?.stripe_account,
      });

      fetchPaymentDetails();
    }
  );

  useEffect(() => {
    removedLink?.err
      ? toast.error(removedLink?.err)
      : toast.success(removedLink?.msg);
  }, [removedLink]);

  /*STRIPE VERIFICATION*/
  useEffect(() => {
    if (userdata.length > 0) {
      fetchPaymentDetails();
      verifyAccount({
        accountid: userdata[0]?.stripe_account,
      });
      fetchSettings();
    }
  }, [userdata]);

  useEffect(() => {
    if (addedstripeacc?.msg) {
      console.log(
        "Erfolgreich Stripe Account angelegt, nun Restdaten befüllen:"
      );

      console.log("MY link is: ");
      console.log(addedstripeacc?.link);

      window.location.href = addedstripeacc?.link;
    } else {
      console.error("Fehler beim Speichern des Stripe Accounts.");
    }
  }, [addedstripeacc]);

  const [settingsOptions, setsettingsOptions] = useState([]);

  //Settingsdata
  //Fetch the settings
  useEffect(() => {
    console.log("Settings");
    console.log(settingsData);
  }, [settingsData]);

  const [currselected, setcurrselected] = useState(0);

  const SettingOptions = [
    {
      id: 0,
      icon: faIdBadge,
      title: "Profil und Sichtbarkeit",
    },
    {
      id: 1,
      icon: faEnvelope,
      title: "E-Mail",
    },
    {
      id: 2,
      icon: faLock,
      title: "Passwort und Sicherheit",
    },
    {
      id: 3,
      icon: faShield,
      title: "Datenschutz",
    },
    {
      id: 4,
      icon: faCog,
      title: "Konto",
    },
    {
      id: 5,
      icon: faBank,
      title: "Bankdaten",
    },
  ];

  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [email, setemail] = useState("");
  const [phone, setphone] = useState("");
  const [password, setpassword] = useState("");
  const [newpassword, setnewpassword] = useState("");
  const [img, setimg] = useState("");
  const [location, setlocation] = useState("");
  const [visibility, setvisibility] = useState(false);
  const [mailsettings, setmailsettings] = useState(false);

  useEffect(() => {
    if (userdata.length > 0 && settingsData.length > 0) {
      setfirstname(userdata[0]?.username.split(",")[0]);
      setlastname(userdata[0]?.username.split(",")[1]);
      setemail(userdata[0]?.email);
      setphone(userdata[0]?.tel);
      setlocation(userdata[0]?.location);
      setvisibility(settingsData[0]?.active);
      setmailsettings(settingsData[2]?.active);
    }
  }, [userdata, settingsData]);

  const [Options, setOptions] = useState([]);
  const [MailOptions, setMailOptions] = useState([]);

  useEffect(() => {
    setOptions([
      {
        id: 0,
        icon: faLock,
        title: "Nur Sie",
        desc: "Profil ist nur für Sie sichtbar.",
        setstate: setvisibility,
        state: visibility,
      },
      {
        id: 1,
        icon: faGlobe,
        title: "Jeder",
        desc: "Für jeden sichtbar, der auf Ihr Profil geht.",
        setstate: setvisibility,
        state: visibility,
      },
    ]);

    setMailOptions([
      {
        id: 0,
        icon: faThumbsDown,
        title: "Nein",
        desc: "Sende mir nur eine Nachricht in mein Postfach.",
        setstate: setmailsettings,
        state: mailsettings,
      },
      {
        id: 1,
        icon: faThumbsUp,
        title: "Ja",
        desc: "Sende mir jede Nachricht auch als E-Mail.",
        setstate: setmailsettings,
        state: mailsettings,
      },
    ]);
  }, [visibility, mailsettings]);

  const {
    data: resetedPassword,
    err: rerr,
    isloading: rloading,
    update: resetPassword,
  } = useUpdateJSONData(
    ` ${apiUrl}/api/users/reset/user/${userdata[0]?.usertoken}/password`
  );

  const {
    data: updatedSettings,
    err: uerr,
    isloading: uloading,
    updateData: updateSettings,
  } = useUpdateData(
    `   ${apiUrl}/api/users/settings/${userdata[0]?.usertoken}`,
    () => {
      fetchUserData();
      fetchSettings();
    }
  );

  const blurs = new Array(12).fill(0);

  useEffect(() => {
    updatedSettings?.err
      ? toast.error(updatedSettings?.err)
      : toast.success(updatedSettings?.msg);
  }, [updatedSettings]);

  useEffect(() => {
    resetedPassword?.err
      ? toast.error(resetedPassword?.err)
      : toast.success(resetedPassword?.msg);
  }, [resetedPassword]);

  const formData = new FormData();
  formData.append("image", img);
  formData.append("firstname", firstname);
  formData.append("lastname", lastname);
  formData.append("phone", phone);
  formData.append("email", email);
  formData.append("location", location);
  formData.append("visibility", visibility);
  formData.append("mailsettings", mailsettings);

  //Fetch the Service of this serviceID
  const {
    data: deletedUser,
    err,
    isloading,
    deleteData: deleteUser,
  } = useDeleteData(`${apiUrl}/api/users/single/${userdata[0]?.usertoken}`);

  useEffect(() => {
    deletedUser?.err
      ? toast.error(deletedUser?.err)
      : toast.success(deletedUser?.msg);
  }, [deletedUser]);

  const [shCustomDel, setshCustomDel] = useState(false);
  const [shConnectDel, setshConnectDel] = useState(false);
  const [shUserDel, setshUserDel] = useState(false);
  return (
    <>
      <ConfirmDelete
        shov={shCustomDel}
        setshov={setshCustomDel}
        img="unplug.jpg"
        title="Willst du die Verknüpfung wirklich lösen ?"
        message="Wenn du deine verknüpfte Karte löst, löscht du unwiederruflich deinen Customer Account."
        action={() => {
          removeLink({
            account_typ: "customer",
            customerID: userdata[0]?.customer_id,
            userdata: userdata[0]?.usertoken,
          });
        }}
        isdelete
      />

      <ConfirmDelete
        shov={shConnectDel}
        setshov={setshConnectDel}
        img="unplug.jpg"
        title="Willst du die Verknüpfung wirklich lösen ?"
        message="Wenn du deinen verknüpften Connect Account löst, wird dieser unwiederruflich gelöscht."
        action={() => {
          removeLink({
            account_typ: "connect",
            connectID: userdata[0]?.stripe_account,
            userdata: userdata[0]?.usertoken,
          });
        }}
        isdelete
      />

      <ConfirmDelete
        shov={shUserDel}
        setshov={setshUserDel}
        img="del.jpg"
        title="Willst du deinen Account wirklich lösen ?"
        message="Wenn du deinen verknüpften Account löscht, wird dieser und alle darin verknüpften Daten unwiederruflich entfernt."
        action={() => {
          deleteUser();
          // window.location.reload();
        }}
        isdelete
      />

      <div className="settings-wrapper">
        <div className="settings-option-top">
          {SettingOptions.map((opt) => {
            return (
              <div
                className="option-item"
                style={{
                  backgroundColor:
                    opt.id === currselected
                      ? "var(--main-bg-accent-light)"
                      : "var(--main-bg-accent)",
                }}
                onClick={() => {
                  setcurrselected(opt.id);
                }}
              >
                <div className="icon">
                  <FontAwesomeIcon icon={opt.icon} />
                </div>
                <p>{opt.title}</p>
              </div>
            );
          })}
        </div>

        <div className="settings-content-f">
          <div className="settings-content">
            {currselected === 0 ? (
              <>
                <div className="setting-section">
                  <div className="section-h">
                    <h2>Allgemeine Informationen</h2>
                    <p>
                      Diese allgemeinen Informationen sind sichtbar für alle
                      Nutzer auf dieser Plattform.
                    </p>
                  </div>

                  <div className="setting-parts">
                    <SettingPart
                      label="Profilbild"
                      desc="  Fügen Sie Ihrem Profil mit Ihrem Profilbild eine
                    persönliche Note hinzu."
                      img={true}
                      imgval={userdata[0]?.uimg}
                      setimg={setimg}
                    />

                    <SettingPart
                      label="Vorname"
                      type={0}
                      value={{
                        value: firstname,
                        type: "text",
                        name: "firstname",
                        setvalue: setfirstname,
                        disabled: true,
                      }}
                      edit
                    />

                    <SettingPart
                      label="Nachname"
                      type={0}
                      value={{
                        value: lastname,
                        type: "text",
                        name: "lastname",
                        setvalue: setlastname,
                        disabled: true,
                      }}
                      edit
                    />

                    <SettingPart
                      label="Telefonnummer"
                      type={0}
                      value={{
                        value: phone,
                        type: "text",
                        name: "phone",
                        setvalue: setphone,
                        disabled: true,
                      }}
                      edit
                    />

                    <SettingPart
                      label="Standort"
                      type={0}
                      value={{
                        value: location,
                        type: "text",
                        name: "location",
                        setvalue: setlocation,
                        disabled: true,
                      }}
                      edit
                    />
                  </div>
                </div>

                <div className="setting-section">
                  <div className="section-h">
                    <h2>Sichtbarkeit</h2>
                    <p>
                      Hier können Sie einstellen,ob Ihr Profil öffentlich oder
                      privat sein soll.
                    </p>
                  </div>

                  <div className="setting-parts">
                    <SettingPart
                      desc="Entscheiden Sie wer Ihr Profil einsehen kann."
                      label="Sichtbarkeit"
                      type={1}
                      Options={Options}
                    />
                  </div>
                </div>

                <button
                  onClick={() => {
                    updateSettings(formData);
                  }}
                >
                  {uloading ? (
                    <div className="loader">
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    </div>
                  ) : uerr != "" ? (
                    uerr
                  ) : (
                    "Änderung speichern"
                  )}
                </button>
              </>
            ) : currselected === 1 ? (
              <>
                <div className="setting-section">
                  <div className="section-h">
                    <h2>E-Mail</h2>
                    <p>Passen Sie Ihre E-Mail Einstellungen an.</p>
                  </div>

                  <div className="setting-parts">
                    <SettingPart
                      label="E-Mail"
                      type={0}
                      value={{
                        value: email,
                        type: "email",
                        name: "email",
                        setvalue: setemail,
                        disabled: true,
                      }}
                      edit
                    />

                    <SettingPart
                      desc="Entscheiden Sie ob Ihnen zusätzlich zu Ihren Nachrichten aus dem Postfach Mails geschickt werden."
                      label="Benachrichtigung"
                      type={1}
                      Options={MailOptions}
                    />
                  </div>
                </div>

                <button
                  onClick={() => {
                    updateSettings(formData);
                  }}
                >
                  {uloading ? (
                    <div className="loader">
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    </div>
                  ) : uerr != "" ? (
                    uerr
                  ) : (
                    "Änderung speichern"
                  )}
                </button>
              </>
            ) : currselected === 2 ? (
              <>
                <div className="setting-section">
                  <div className="section-h">
                    <h2>Passwort und Sicherheit</h2>
                    <p>
                      Ändern Sie Ihr Passwort, welches zum Schutz Ihres Kontos
                      dient.
                    </p>
                  </div>

                  <div className="setting-parts">
                    <SettingPart
                      label="Aktuelles Passwort"
                      type={0}
                      value={{
                        value: password,
                        type: "password",
                        name: "password",
                        setvalue: setpassword,
                        disabled: true,
                      }}
                      edit
                    />

                    <SettingPart
                      label="Neues Passwort"
                      type={0}
                      value={{
                        value: newpassword,
                        type: "password",
                        name: "newpassword",
                        setvalue: setnewpassword,
                        disabled: true,
                      }}
                      edit
                    />
                  </div>
                </div>

                <button
                  onClick={() => {
                    resetPassword({
                      oldpass: password,
                      newpass: newpassword,
                    });
                  }}
                >
                  {rloading ? (
                    <div className="loader">
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    </div>
                  ) : rerr != "" ? (
                    rerr
                  ) : (
                    "Passwort ändern"
                  )}
                </button>
              </>
            ) : currselected === 3 ? (
              <>
                <div className="preference-wrapper">
                  <h2>Cookie Einstellungen</h2>
                  <p>
                    Wenn Sie unsere Seite besuchen, werden möglicherweise
                    Informationen von Ihrem Browser gespeichert oder abgerufen.
                    Dies geschieht in der Regel in Form von Cookies. Diese
                    Informationen können sich auf Sie, Ihre Einstellungen oder
                    Ihr Gerät beziehen. Sie werden hauptsächlich dazu verwendet,
                    dass die Seite Ihren Erwartungen entsprechend funktioniert.
                    Sie sind über diese Informationen normalerweise nicht zu
                    identifizieren. Sie bieten lediglich ein persönlicheres
                    Web-Erlebnis. Wir respektieren Ihr Recht auf Datenschutz.
                    Daher können Sie wählen, welche Cookies sie zulassen
                    möchten. <a href="#">Hinweise zu Cookies</a>
                  </p>
                  <button>Cookie Einstellungen öffnen</button>
                </div>

                <div className="preference-wrapper">
                  <h2>Datenschutz Einstellungen</h2>
                  <p>
                    Wenn Sie ein unsere Seite besuchen, werden möglicherweise
                    personenbezogene Daten erhoben. Wir sind sehr transparent im
                    Umgang mit Ihren personenbezogenen Daten, sie können sich
                    jederzeit informieren, welche wir erheben.{" "}
                  </p>
                  <button>Datenschutz</button>
                </div>
              </>
            ) : currselected === 4 ? (
              <>
                <h2>Kontoeinstellung</h2>
                <p>Einstellung für Ihr Konto.</p>

                <div className="del-wrapper">
                  <h2>Konto Löschung</h2>
                  <p>
                    Wenn Sie Ihr Konto löschen, haben Sie keinen Zugriff mehr
                    auf dieses Konto, und Ihre personenbezogenen Daten werden
                    dauerhaft gelöscht. Sie können in den{" "}
                    <a href="#">Datenschutz</a> Einstellungen alle Informationen
                    bei einer Löschung nachschauen.
                  </p>

                  <button
                    onClick={() => {
                      setshUserDel(true);
                    }}
                  >
                    Konto löschen
                  </button>
                </div>
              </>
            ) : (
              <div className="stripe-integration-wrapper">
                {verifiedAccount?.err && !verifiedAccount?.eventually_due ? (
                  <>
                    <div className="connected-wrapper">
                      <div className="left">
                        <FontAwesomeIcon icon={faStripe} />
                      </div>

                      <div className="right">
                        <h2>Stripe Connect Account</h2>
                        <button
                          onClick={() => {
                            addstripeacc({
                              firstname: userdata[0]?.username.split(",")[0],
                              lastname: userdata[0]?.username.split(",")[1],
                              tel: userdata[0]?.tel,
                              email: userdata[0]?.email,
                              currlocation: userdata[0]?.location,
                            });
                            console.log("add stripe account");
                          }}
                        >
                          Verknüpfung herstellen
                        </button>
                      </div>
                    </div>
                  </>
                ) : verifiedAccount?.err && verifiedAccount?.eventually_due ? (
                  <>
                    <div className="left-todo-wrapper">
                      <div className="right">
                        <h4>
                          Schritte die fehlen um Account fertig zu stellen:
                        </h4>

                        <ul>
                          {verifiedAccount?.eventually_due.map((to_do) => {
                            return <li>{to_do}</li>;
                          })}
                        </ul>
                      </div>
                    </div>

                    <div className="connected-wrapper">
                      <div className="left">
                        <FontAwesomeIcon icon={faStripe} />
                      </div>

                      <div className="right">
                        <h2>Stripe Connect Account</h2>
                        <button
                          onClick={() => {
                            window.location.href = verifiedAccount?.link;
                          }}
                        >
                          Fertig stellen
                        </button>
                      </div>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="connected-wrapper">
                      <div className="left">
                        <div className="icon">
                          <FontAwesomeIcon icon={faStripe} />
                        </div>
                      </div>

                      <div className="right">
                        <h2>Stripe Connect Account</h2>

                        <div className="right-opt-btns">
                          <button
                            onClick={() => {
                              window.location.href = verifiedAccount?.link;
                            }}
                          >
                            Account anpassen
                          </button>

                          <button
                            onClick={() => {
                              /*verknüpfung lösen === accountid aus db entfernen + status auf 0 (stripe acc muss individuell gelöscht werden -> in agbs sagen!!!)*/
                              setshConnectDel(true);
                            }}
                          >
                            Verknüpfung lösen
                          </button>
                        </div>
                      </div>
                    </div>
                  </>
                )}

                {userdata[0]?.customer_id &&
                userdata[0]?.customer_id != "null" ? (
                  <div className="connected-wrapper">
                    <div className="left">
                      <p>
                        <FontAwesomeIcon icon={faBuildingColumns} />
                      </p>
                    </div>

                    <div className="right">
                      <h2>Verknüpfte Bankkarte</h2>

                      {paymentDetailsloading ? (
                        <div className="loader">
                          <FontAwesomeIcon icon={faCircleNotch} spin />
                        </div>
                      ) : (
                        <div className="card-middle">
                          <div className="card-numbers">
                            {blurs.map((item) => (
                              <p>*</p>
                            ))}{" "}
                            <p> {paymentDetails?.paymentMethod?.card?.last4}</p>
                          </div>
                        </div>
                      )}

                      <div className="right-opt-btns">
                        <button
                          onClick={() => {
                            /*verknüpfung lösen === accountid aus db entfernen + status auf 0 (stripe acc muss individuell gelöscht werden -> in agbs sagen!!!)*/
                            setshCustomDel(true);
                          }}
                        >
                          Verknüpfung lösen
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const SettingPart = (props) => {
  const { apiUrl } = useContext(SkillContext);

  const [edit, setedit] = useState(false);

  const [disabled, setdisabled] = useState(true);

  useEffect(() => {
    setdisabled(props.value?.disabled);
  }, []);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(`${apiUrl}/api/users/images/${props.imgval}/type/users`);

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  useEffect(() => {
    refetchImage();
  }, [props.imgval]);

  return (
    <div className="setting-part">
      <p>{props.label}</p>

      <p style={{ display: props.desc ? "block" : "none" }}>{props.desc}</p>

      {props.type === 0 ? (
        <ServiceInput
          val={props.value?.value}
          type={props.value?.type}
          name={props.value?.name}
          setvalue={props.value?.setvalue}
          disabled={disabled}
        />
      ) : props.type === 1 ? (
        <SettingOption Options={props.Options} />
      ) : (
        ""
      )}

      <div
        className="setting-img"
        style={{ display: props.img ? "block" : "none" }}
      >
        <div className="img-wrapper">
          {isImageLoading ? (
            <div className="loader">
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
          ) : (
            <img src={Image} alt="user-img" loading="lazy" />
          )}

          <div className="img-overlay">
            <label htmlFor="file">
              <FontAwesomeIcon icon={faCamera} />
            </label>
            <input
              type="file"
              name="file"
              id="file"
              onChange={(e) => {
                props.setimg(e.target.files[0]);
              }}
            />
          </div>
        </div>
      </div>

      <div
        className="edit-wrapper"
        style={{ display: props.edit ? "block" : "none" }}
      >
        <div
          className="icon"
          onClick={() => {
            setedit(true);
            setdisabled(false);
          }}
          style={{ display: edit ? "none" : "block" }}
        >
          <FontAwesomeIcon icon={faPencil} />
        </div>

        <div className="edit-item" style={{ display: edit ? "flex" : "none" }}>
          <div
            className="icon"
            onClick={() => {
              setedit(false);
              setdisabled(true);
            }}
          >
            <FontAwesomeIcon icon={faX} />
          </div>
        </div>
      </div>
    </div>
  );
};

const SettingOption = (props) => {
  const [shdrop, setshdrop] = useState(false);
  const [curroption, setcurroption] = useState(0);

  useEffect(() => {
    console.log("options");
    console.log(props.Options[0]?.state);
    setcurroption(props.Options[0]?.state);
  }, [props.Options]);

  return (
    <div
      className="setting-option-drop"
      onClick={() => {
        setshdrop(!shdrop);
      }}
    >
      <p>
        <div className="icon">
          <FontAwesomeIcon icon={props.Options[curroption]?.icon} />
        </div>
        {props.Options[curroption]?.title}
      </p>

      <div className="icon">
        <FontAwesomeIcon icon={faChevronDown} />
      </div>

      <div
        className="opt-drop-wrapper"
        style={{ display: shdrop ? " block" : "none" }}
      >
        {props.Options.map((opt) => {
          return (
            <div
              className="opt-drop-item"
              onClick={() => {
                setcurroption(opt.id);
                props.Options[0]?.setstate(opt.id);
              }}
            >
              <div className="icon">
                <FontAwesomeIcon icon={opt.icon} />
              </div>
              <div className="right">
                <h2>{opt.title}</h2>
                <p>{opt.desc}</p>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export const ConfirmDelete = (props) => {
  return (
    <div
      className="confirm-delete-overlay"
      style={{ display: props.shov ? "flex" : "none" }}
    >
      <div className="confirm-delete-wrapper">
        <div
          className="del-icon"
          onClick={() => {
            props.setshov(false);
          }}
        >
          <FontAwesomeIcon icon={faXmark} />
        </div>

        <div className="top">
          <img src={"./images/" + props.img} alt="del-img" />
        </div>

        <div className="bottom">
          <div className="bottom-descr">
            <h2>{props.title}</h2>
            <p> {props.message}</p>
          </div>

          <div className="bottom-btns">
            <button
              onClick={() => {
                props.setshov(false);
              }}
            >
              Abbrechen
            </button>
            <button
              onClick={() => {
                props.action();
                props.setshov(false);
              }}
              style={{
                border: props.isdelete
                  ? "1px solid rgb(212, 31, 31)"
                  : "1px solid lightgray",
                color: props.isdelete ? "rgb(212, 31, 31)" : "white",
              }}
            >
              {props.isdelete ? "Löschen" : "Bestätigen"}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
