import "./App.css";
import { Nav } from "./Nav";
import { Services } from "./Service";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useSearchParams,
} from "react-router-dom";
import { ServiceDescription } from "./ServiceDescription";
import { Layout } from "./Layout";
import { Profile } from "./Profile";
import { ServiceAdd } from "./ServiceAdd";
import { Settings } from "./Settings";
import { Favouriteservice } from "./favouriteservice";
import { OwnService } from "./ownservice";
import { Login } from "./Login";
import { SignUp } from "./SignUp";
import React, {
  createContext,
  useEffect,
  useState,
  Suspense,
  lazy,
} from "react";
import { useGetData } from "./Hooks/getData";
import { SearchServices } from "./SearchServices";
import { EditService } from "./EditService";
import { Inbox } from "./Inbox";
import { Calendar } from "./Calendar";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ServicePending } from "./ServicePending";
import { UsersPendingService } from "./UsersPendingService";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAd,
  faCheck,
  faWarning,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { AdvertLayout } from "./AdvertLayout";
import { Premium } from "./Premium";
import { UserTicket } from "./UserTicket";
import { Accountverification } from "./Accountverification";
import { CheckOut } from "./Checkout";
import { TicketDashboard } from "./TicketDashboard";
import { Footer } from "./Footer";
import { Impressum } from "./Impressum";
import { Datenschutzerklärung } from "./Datenscchutzerklärung";
import { AGB } from "./AGB";
export const SkillContext = createContext();

export const ShowmoreData = (amount, setamount, data, increment) => {
  if (amount + increment >= data.length - 1) {
    setamount(data.length);
  } else {
    setamount(amount + increment);
  }
};

export const ShowlessData = (amount, setamount, data, increment) => {
  if (amount - increment < increment) {
    setamount(increment);
  } else {
    setamount(amount - increment);
  }
};

const checkPush = (arr, item) => {
  if (arr.some((i) => i.getTime() == item.getTime())) {
    return true;
  } else {
    return false;
  }
};

export const sendMail = (message, title, receiver) => {
  return {
    email_to: receiver,
    message: message,
    title: title,
  };
};

export const banDateTime = (
  excludedTimes,
  date,
  bannedDateTimes,
  appointments
) => {
  let start = "";
  let end = "";
  let timestart = "";
  let timeend = "";
  let uptime = "";

  bannedDateTimes.length > 0 &&
    bannedDateTimes.forEach((bdate) => {
      if (
        new Date(date).getTime() / 1000 ===
        new Date(bdate).getTime() / 1000
      ) {
        //if a banned date is selected we loop through the appointments and check if the date is between the indexes range if so we have the start and enddate for this date
        let bd = new Date(date).getTime() / 1000;
        appointments.length > 0 &&
          appointments.forEach((app) => {
            start = new Date(app.start.slice(0, 10)).getTime() / 1000;
            end = new Date(app.end.slice(0, 10)).getTime() / 1000;
            timestart =
              new Date().toISOString().split("T")[0] +
              "T" +
              app.start.split("T")[1];
            timeend =
              new Date().toISOString().split("T")[0] +
              "T" +
              app.end.split("T")[1];
            uptime = new Date(timestart).getTime();

            if (bd >= start && bd <= end) {
              //-- UPTIME START --
              //push first time also
              const [shour, sminute, sseconds] = new Date(
                new Date(app.start).getTime()
              )
                .toISOString()
                .split("T")[1]
                .split(":");
              excludedTimes.push(new Date().setHours(shour, sminute, 0, 0));

              while (new Date(timeend).getTime() > uptime) {
                uptime = new Date(uptime).getTime() + 15 * 60 * 1000;
                //    console.log("curr uptime: " + new Date(uptime).toISOString());

                const [hour, minute, seconds] = new Date(uptime)
                  .toISOString()
                  .split("T")[1]
                  .split(":");

                excludedTimes.push(new Date().setHours(hour, minute, 0, 0));
              }

              // -- UPTIME END --
            }
          });
      }
    });

  console.log("excluded Times");
  console.log(excludedTimes);
};

export const banDates = (arr, setstate, endarr) => {
  let currdate = "";
  let nextdate = "";

  arr.length > 0 &&
    arr.forEach((app, index) => {
      currdate = new Date(app.start);
      nextdate = new Date(app.start);

      //loop through startdate till enddate

      if (app.end.slice(0, 10) == app.start.slice(0, 10)) {
        !checkPush(endarr, new Date(app.start.slice(0, 10) + "T00:00:00")) &&
          endarr.push(new Date(app.start.slice(0, 10) + "T00:00:00"));
      } else {
        //push first date too
        !checkPush(
          endarr,
          new Date(nextdate.toISOString().slice(0, 10) + "T00:00:00")
        ) &&
          endarr.push(
            new Date(nextdate.toISOString().slice(0, 10) + "T00:00:00")
          );

        while (new Date(app.end) > nextdate) {
          //make higher nextdate
          nextdate = new Date(currdate.setDate(currdate.getDate() + 1));

          !checkPush(
            endarr,
            new Date(nextdate.toISOString().slice(0, 10) + "T00:00:00")
          ) &&
            endarr.push(
              new Date(nextdate.toISOString().slice(0, 10) + "T00:00:00")
            );
        }
      }
    });

  setstate(endarr); //setbanneddates
};

//LAZY STUFF
// Add a fixed delay so you can see the loading state
export function loadComponent(promise) {
  return new Promise((resolve) => {
    setTimeout(resolve, 1000);
  }).then(() => promise);
}

const ServiceSearch = lazy(() =>
  loadComponent(import("./SearchServices")).then((module) => ({
    default: module.SearchServices,
  }))
);




function App() {
  const [isloggedin, setisloggedin] = useState(false);
  const [userdata, setuserdata] = useState([]);

  const apiUrl = "https://elegant-carson.85-215-218-190.plesk.page"; //http://localhost:4000 process.env.REACT_APP_FIND_MY_API_PROD_URL;

  //Fetch the Service of this serviceID
  const {
    data: udata,
    err,
    isloading,
    refetch: fetchUserData,
  } = useGetData(`${apiUrl}/api/users/info`);

  //${apiUrl}/api/users/info/:usertoken

  useEffect(() => {
    isloggedin && fetchUserData();
  }, [isloggedin]);

  useEffect(() => {
    setuserdata(udata); //set this to cookie data
  }, [udata]);

  useEffect(() => {
    console.log("current USERDATA: ", userdata);
  }, [userdata]);

  /*CHECK IF USER IS LOGGEDIN*/
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );

  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  const City = [
    { cid: "1", cname: "Ansbach", regionref: "1" },
    { cid: "2", cname: "Nürnberg", regionref: "1" },
    { cid: "3", cname: "Erlangen", regionref: "1" },
    { cid: "4", cname: "Fürth", regionref: "1" },
    { cid: "5", cname: "Schwabach", regionref: "1" },
    { cid: "6", cname: "Landshut", regionref: "2" },
    { cid: "7", cname: "Passau", regionref: "2" },
    { cid: "8", cname: "Straubing", regionref: "2" },
    { cid: "9", cname: "München", regionref: "3" },
    { cid: "10", cname: "Ingolstadt", regionref: "3" },
    { cid: "11", cname: "Rosenheim", regionref: "3" },
    { cid: "12", cname: "Bamberg", regionref: "4" },
    { cid: "13", cname: "Bayreuth", regionref: "4" },
    { cid: "14", cname: "Coburg", regionref: "4" },
    { cid: "15", cname: "Hof\r\n ", regionref: "4" },
    { cid: "16", cname: "Amberg", regionref: "5" },
    { cid: "17", cname: "Regensburg", regionref: "5" },
    { cid: "18", cname: "Weiden", regionref: "5" },
    { cid: "19", cname: "Augsburg", regionref: "6" },
    { cid: "20", cname: "Kaufbeuren", regionref: "6" },
    { cid: "21", cname: "Kempten", regionref: "6" },
    { cid: "22", cname: "Memmingen", regionref: "6" },
    { cid: "23", cname: "Aschaffenburg", regionref: "7" },
    { cid: "24", cname: "Schweinfurt", regionref: "7" },
    { cid: "25", cname: "Würzburg", regionref: "7" },
  ];

  const Region = [
    {
      name: "Mittelfranken",
      id: 1,
    },
    {
      name: "Niederbayern",
      id: 2,
    },

    {
      name: "Oberbayern",
      id: 3,
    },

    {
      name: "Oberfranken",
      id: 4,
    },

    {
      name: "Oberpfalz",
      id: 5,
    },
    {
      name: "Schwaben",
      id: 6,
    },

    {
      name: "Unterfranken",
      id: 7,
    },
  ];

  const Service = [
    {
      name: "Haushalt",
      id: 1, //1=Haushalt
    },
    {
      name: "Pflege",
      id: 2,
    },
    {
      name: "Gartenarbeit",
      id: 3,
    },

    {
      name: "IT / Technik",
      id: 4,
    },

    {
      name: "Anderes",
      id: 5,
    },
  ];

  const {
    data: settingsData,
    err: serr,
    isloading: sloading,
    refetch: fetchSettings,
  } = useGetData(`${apiUrl}/api/users/settings/${userdata[0]?.usertoken}`);

  useEffect(() => {
    userdata.length > 0 && fetchSettings();
  }, [userdata]);

  const [shbanner, setshbanner] = useState(true);
  const [shpassbanner, setshpassbanner] = useState(true);
  const [checkpass, setcheckpass] = useState("");

  const hashString = async (str)  => {
    // Hash the input again to compare with the stored hash
    const encoder = new TextEncoder();
    const data = encoder.encode(str);
    const hashBuffer = await crypto.subtle.digest("SHA-256", data);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hashHex = hashArray.map(byte => byte.toString(16).padStart(2, '0')).join('');
    
    // Compare and return the result
    return hashHex;
  }

  useEffect(() => {
    async function checkStoredPassword() {
     
      const check = await hashString(process.env.REACT_APP_SECRETPASS);

    const getHashedPass = localStorage.getItem("password");
    if (getHashedPass === check) setshpassbanner(false);
    }

    checkStoredPassword(); // Call the async function inside useEffect
  }, []); // Empty dependency array to run only on mount


  useEffect(() => {

    async function logStoredPassword() {
     
    const pass = await hashString(checkpass);
    const check = await hashString(process.env.REACT_APP_SECRETPASS);

console.log("hashed pass: " + pass)
console.log("check pass: " + check)
    }

    logStoredPassword();

  }, [checkpass])

  return (
    <div className="App">


	<div className="login-ov" style={{ display: shpassbanner ? "flex" : "none" }}>
	<h2> Enter Password: </h2>
<input type="password" onChange={ (e) => { setcheckpass(e.target.value); } } />
<button onClick={ async () => {

  const pass = await hashString(checkpass);
  const check = await hashString(process.env.REACT_APP_SECRETPASS);

if (pass === check){
  localStorage.setItem("password", pass);
  setshpassbanner(false);
  console.log("password checked")
} else{
  console.log("password not checked")
}
} }>
Submit
</button>

</div>

      <SkillContext.Provider
        value={{
          isloggedin,
          setisloggedin,
          userdata,
          setuserdata,
          fetchUserData,
          fetchSettings,
          settingsData,
          City,
          Region,
          Service,
          apiUrl,
        }}
      >
        <ToastContainer theme="colored" stacked />

        <Router>
          <Nav />

          <div
            className="banner"
            style={{ display: shbanner ? "flex" : "none" }}
          >
            <div className="left">
              <FontAwesomeIcon icon={faWarning} />
            </div>

            <div className="right">
              <h2>ACHTUNG!</h2>
              <p>
                Dies ist eine Alpha Version der App und ist momentan nur für
                internes Testen verfügbar. Zugriff für unberechtigte ist
                eingeschränkt und untersagt. Daten werden testweise intern
                verarbeitet. Zur Sicherstellung des Zugangs verarbeiten wir Ihre
                IP-Adresse, speichern diese jedoch nicht.
              </p>
              <button
                onClick={() => {
                  setshbanner(false);
                }}
              >
                Schließen
              </button>
            </div>
          </div>

          <Routes>
            <Route
              path="/"
              element={
                <Layout>
                  <Services />
                </Layout>
              }
            />

            <Route
              path="/unauthorized"
              element={
                <p>
                  Zugriff verweigert. Seite befindet sich in geschlossener
                  Testphase! Zugriff für unauthoirzierte verweigert!
                </p>
              }
            />

            <Route path="/auth/login" element={<Login />} />

            <Route path="/auth/signup" element={<SignUp />} />

            <Route
              path="/verify/account/:accountid"
              element={
                <Layout>
                  <Accountverification />
                </Layout>
              }
            />

            <Route
              path="/success"
              element={
                <Layout>
                  <div className="fetch-err-box">
                    <div
                      className="icon"
                      style={{ backgroundColor: "lightgreen" }}
                    >
                      <FontAwesomeIcon icon={faCheck} />
                    </div>
                    <p>Bestellung erfolgreich bezahlt.</p>
                    <a href="/premium">
                      <button>Zurück</button>
                    </a>
                  </div>
                </Layout>
              }
            />

            <Route
              path="/cancel"
              element={
                <Layout>
                  <div className="fetch-err-box">
                    <div className="icon" style={{ backgroundColor: "red" }}>
                      <FontAwesomeIcon icon={faXmark} />
                    </div>
                    <p>Bezahlung der Bestellung fehlgeschlagen.</p>
                    <a href="/premium">
                      <button>Zurück</button>
                    </a>
                  </div>
                </Layout>
              }
            />

            <Route
              path="/checkout/:orderid"
              element={
                <Layout>
                  <CheckOut />
                </Layout>
              }
            />

            <Route
              path="/service/:sid/pending"
              element={
                <Layout>
                  <AdvertLayout>
                    <ServicePending />
                  </AdvertLayout>
                </Layout>
              }
            />

            <Route
              path="/users/service/pending"
              element={
                <Layout>
                  <AdvertLayout>
                    <UsersPendingService />
                  </AdvertLayout>
                </Layout>
              }
            />

            <Route
              path="/search/services"
              element={
                <Layout>
                  <AdvertLayout>
                    <SearchServices />
                  </AdvertLayout>
                </Layout>
              }
            />

            <Route
              path="/support/tickets"
              element={
                <Layout>
                  <UserTicket />
                </Layout>
              }
            />

            <Route
              path="/support/ticket/dashboard"
              element={
                <Layout>
                  <TicketDashboard />
                </Layout>
              }
            />

            <Route
              path="/service/details/:sid"
              element={
                <Layout>
                  <AdvertLayout>
                    <ServiceDescription />
                  </AdvertLayout>
                </Layout>
              }
            />

            <Route
              path="/appointments/:sid"
              element={
                <Layout>
                  <AdvertLayout>
                    <Calendar />
                  </AdvertLayout>
                </Layout>
              }
            />

            <Route path="/inbox/user/:userid" element={<Inbox />} />

            <Route
              path="/service/add"
              element={
                <Layout>
                  <AdvertLayout>
                    <ServiceAdd />
                  </AdvertLayout>
                </Layout>
              }
            />

            <Route
              path="/premium"
              element={
                <Layout>
                  <Premium />
                </Layout>
              }
            />

            <Route
              path="/service/edit/:serviceid"
              element={
                <Layout>
                  <EditService />
                </Layout>
              }
            />

            <Route path="/user/settings" element={<Settings />} />

            <Route
              path="/user/:usertoken/profile"
              element={
                <Layout>
                  <Profile />
                </Layout>
              }
            />

            <Route
              path="/service/favourite"
              element={
                <Layout>
                  <Favouriteservice />
                </Layout>
              }
            />

            <Route
              path="/my/services"
              element={
                <Layout>
                  <OwnService />
                </Layout>
              }
            />

            <Route
              path="/impressum"
              element={
                <Layout>
                  <Impressum />
                </Layout>
              }
            />

            <Route
              path="/datenschutzerklärung"
              element={
                <Layout>
                  <Datenschutzerklärung />
                </Layout>
              }
            />

            <Route
              path="/AGBs"
              element={
                <Layout>
                  <AGB />
                </Layout>
              }
            />

            <Route
              path="*"
              element={
                <Layout>
                  <h1>404 Site not found!</h1>
                </Layout>
              }
            />
          </Routes>

          <Footer />
        </Router>
      </SkillContext.Provider>
    </div>
  );
}

export const Advert = (props) => {
  return (
    <div className="advert-f-wrapper">
      <div
        className="advert-wrapper"
        style={{ width: props.w, height: props.h }}
      >
        <a href="#">
          <img
            src={props.src}
            alt="Werbung"
            style={{ width: props.srcw, height: props.srch }}
          />
        </a>

        <div className="ad">
          <p>Werbung</p>
        </div>
      </div>
    </div>
  );
};

export default App;
