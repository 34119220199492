import './Css/datenschutz.css';

export const AGB = () => {

return (
<div className='legal-notice-wrapper'>

<h2>2. Allgemeine Geschäftsbedingungen (AGBs)</h2>

<div className='notice-section'>

<h3>2.1. Vertragsparteien und Geltungsbereich</h3>
    

<p>
Diese AGB regeln das Vertragsverhältnis zwischen:
</p>  


<p>
Anbieter der App: Tizian Grabert
</p>


<p>
Nutzer der App: Kunden, die über die App Dienstleistungen buchen, und Serviceanbieter, die über die App Dienstleistungen anbieten.
</p>

<p>
Durch die Nutzung der App erklären Sie sich mit diesen AGBs einverstanden.
</p>

</div>



<div className='notice-section'>

<h3>2.2. Registrierung und Pflichten der Nutzer</h3>


<div className='notice-section'>

<h4>2.2.1 Kunden</h4>

<p>
    Kunden müssen sich mit ihrem Vor- und Nachnamen, ihrer E-Mail-Adresse und einem Passwort registrieren. Die Nutzung der App setzt voraus, dass die eingegebenen Daten korrekt und vollständig sind. Bei einer Zahlung wird, wenn nicht existierend, ein Stripe Customer Account angelegt und die Zahlungsmethoden des Nutzers bei Stripe, sowie ein Verweis darauf bei uns automatisch hinterlegt.
</p>  

</div>

<div className='notice-section'>

<h4>2.2.2 Serviceanbieter</h4>

<p>
Anbieter müssen zusätzlich zu den Daten, die bei einem Kunden erhoben werden ein Stripe Connect-Konto erstellen, um Zahlungen über die App zu empfangen. Sie sind für die ordnungsgemäße Erbringung der Dienstleistungen gegenüber den Kunden, sowie den korrekten Angaben zu Ihrer Person verantwortlich.
</p> 

</div>



</div>


<div className='notice-section'>

<h3>2.3. Vertragsgegenstand</h3>
    

<p>
Unsere App fungiert als Vermittlungsplattform zwischen Kunden und Serviceanbietern. Kunden können über die App Dienstleistungen buchen, die von Anbietern angeboten werden. Die App stellt lediglich die technische Plattform zur Verfügung und ist nicht selbst Vertragspartner der vermittelten Verträge.

</p>  


</div>

<div className='notice-section'>

<h3>2.4. Rechte und Pflichten der Kunden</h3>
    

<div className='notice-section'>

<h4>2.4.1 Nutzung der App</h4>

<p>
Kunden haben das Recht, Dienstleistungen über die Plattform zu buchen. Sie sind verpflichtet, die von den Anbietern festgelegten Konditionen (z. B. Preis und Verfügbarkeit) zu beachten.
</p>  

</div>


<div className='notice-section'>

<h4>2.4.2 Zahlungsabwicklung</h4>
<p>

Die Zahlung erfolgt ausschließlich über Stripe. Mit der Buchung einer Dienstleistung geht der Kunde eine verbindliche Zahlungspflicht ein. Die Zahlung wird nach Annahme der Dienstleistung durch den Anbieter des bestellten Service abgewickelt.

</p>
</div>



</div>


<div className='notice-section'>

<h3>2.5. Rechte und Pflichten der Serviceanbieter</h3>
    

<div className='notice-section'>

<h4>2.5.1 Anbieten von Dienstleistungen</h4>

<p>
 Anbieter dürfen über die Plattform Dienstleistungen in den vorgesehenen Kategorien anbieten. Sie sind verantwortlich für die ordnungsgemäße Erbringung der gebuchten Leistungen.
</p> 
</div>


<div className='notice-section'>

<h4>2.5.2 Vertragliche Verpflichtungen</h4>

<p>
 Der Anbieter verpflichtet sich, die gebuchten Dienstleistungen in der vereinbarten Qualität und zum vereinbarten Zeitpunkt zu erbringen. Die Nicht- oder Schlechterfüllung kann zu Streitigkeiten und gegebenenfalls zur Rückerstattung führen.
</p>
</div>



</div>


<div className='notice-section'>

<h3>2.6. Rechte und Pflichten des Plattformanbieters</h3>
    
<div className='notice-section'>

<h4>2.6.1 Vermittlungsleistung</h4>

<p>
Der Plattformanbieter stellt die technische Infrastruktur bereit, um die Kommunikation und den Vertragsabschluss zwischen Kunden und Anbietern zu ermöglichen.
</p>  
</div>

<div className='notice-section'>

<h4>2.6.2 Zahlungsabwicklung</h4>

<p>
Der Anbieter behält eine Servicegebühr von 5 % des Transaktionsbetrags ein. Diese Gebühr wird automatisch bei der Abwicklung über Stripe einbehalten.
</p> 
</div>


<div className='notice-section'>

<h4>2.6.3 Haftungsausschluss</h4>

<p>
Der Anbieter haftet nicht für die ordnungsgemäße Durchführung der über die App vermittelten Dienstleistungen. Für Mängel der Leistungserbringung sind die Anbieter verantwortlich.
</p>
</div>



</div>




<div className='notice-section'>

<h3>2.7. Rückerstattungen und Support</h3>


<div className='notice-section'>

<h4>2.7.1 Rückerstattungen</h4>

<p>
Kunden haben das Recht, innerhalb von 30 Tagen nach Rechnungsstellung eine Rückerstattung über das bereitgestellte Support-Portal zu beantragen.  Der Antrag auf Rückerstattung muss schriftlich erfolgen und die Gründe für die Rückerstattung darlegen. Nach Ablauf dieser Frist ist eine Rückerstattung nicht mehr möglich. Wenn ein Kunde einen Widerruf oder eine Rückerstattung verlangt, wird die Zahlung durch Stripe rückabgewickelt. Rückerstattungen werden innerhalb von 14 Tagen bearbeitet.
</p>
</div>  

<div className='notice-section'>

<h4>2.7.2 Support</h4>

<p>
Kunden können innerhalb von 30 Tagen nach Rechnungsstellung Unterstützung für den erworbenen Service anfordern. Anfragen müssen schriftlich über das bereitgestellte Support-Portal oder per E-Mail erfolgen. Nach Ablauf dieser Frist kann kein Anspruch auf Unterstützung für den betreffenden Service geltend gemacht werden.
</p>
</div>  

<div className='notice-section'>

<h4>2.7.3 Ausnahmen</h4>
<p>
Bei Dienstleistungen, die bereits vollständig erbracht wurden, ist eine Rückerstattung nach Zahlungseingang nicht möglich.

</p>
</div>  


</div>

<div className='notice-section'>

<h3>2.8. Streitbeilegung</h3>
     
<p>
Streitigkeiten zwischen Kunden und Anbietern können über das in der App integrierte Ticketsystem gelöst werden. Der Plattformanbieter bietet Unterstützung bei der Lösung der Streitigkeiten, übernimmt jedoch keine rechtliche Verantwortung für den Ausgang.

</p>

</div>

<div className='notice-section'>

<h3>2.9. Haftungsausschluss</h3>
     
<p>
Der Plattformanbieter haftet nicht für Schäden, die durch die Nutzung der App entstehen, es sei denn, sie beruhen auf Vorsatz oder grober Fahrlässigkeit.

</p>

</div>

<div className='notice-section'>

<h3>2.10. Haftungsausschluss für externe Links</h3>
     
<p>
Unsere App enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen Einfluss haben. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt der Verlinkung nicht erkennbar.

Eine permanente inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.

</p>

</div>

<h2>3. Widerrufsbelehrung</h2>


<div className='notice-section'>

<h4>3.1. Widerrufsrecht</h4>
     
<p>
Kunden haben das Recht, innerhalb von 30 Tagen nach Rechnungsstellung ohne Angabe von Gründen den Vertrag zu widerrufen.
Anfragen müssen schriftlich über das bereitgestellte Support-Portal oder per E-Mail erfolgen.
</p>

</div>

<div className='notice-section'>

<h4>3.2. Folgen des Widerrufs</h4>
     
<p>

Im Falle eines Widerrufs erstatten wir alle Zahlungen, die wir vom Kunden erhalten haben, unverzüglich zurück, spätestens jedoch innerhalb von 14 Tagen nach Erhalt des Widerrufs. Die Rückzahlung erfolgt über das gleiche Zahlungsmittel, das der Kunde bei der ursprünglichen Transaktion verwendet hat.

</p>

</div>

<div className='notice-section'>

<h4>3.3. Ausschluss des Widerrufs</h4>
     
<p>

Das Widerrufsrecht erlischt, wenn der Service vollständig erbracht wurde und die Leistung mit Zustimmung des Kunden vor Ablauf der Widerrufsfrist begonnen wurde.

</p>

</div>



<h2>4. Urheberrecht</h2>


<div className='notice-section'>

<h3>4.1. Eigene Inhalte</h3>
     
<p>
Alle Inhalte und Werke, die durch die Betreiber der App erstellt wurden (einschließlich, aber nicht beschränkt auf Texte, Bilder, Grafiken, Logos, Designs und Software), unterliegen dem deutschen Urheberrecht. Jegliche Vervielfältigung, Bearbeitung, Verbreitung oder sonstige Nutzung bedarf der schriftlichen Zustimmung des Rechteinhabers, sofern die Nutzung nicht ausdrücklich durch das Urheberrecht erlaubt ist.

</p>

</div>

<div className='notice-section'>

<h3>4.2. Fremde Inhalte und Bilder</h3>
     
<p>
Die in der App verwendeten Bilder und Grafiken, die nicht von den Betreibern der App erstellt wurden, unterliegen dem Urheberrecht der jeweiligen Rechteinhaber. Die Nutzungsrechte wurden ordnungsgemäß erworben. Die Quelle und der Rechteinhaber des jeweiligen Bildes sind nachfolgend aufgeführt:
</p>

<div className='links'>
<p>User by tracy tam from <a href="https://thenounproject.com/browse/icons/term/user/" target="_blank" title="User Icons">Noun Project</a> (CC BY 3.0)</p>

<p><a href="https://www.freepik.com/free-vector/customer-support-illustration_13237271.htm#fromView=search&page=2&position=8&uuid=2d79a9ff-e718-48fd-9b16-233315d80346">Image by freepik</a></p>
<p><a href="https://www.freepik.com/free-vector/hand-drawn-flat-design-international-trade_19962390.htm#fromView=search&page=4&position=50&uuid=02736abe-5655-43ac-8efd-43dae46b4495">Image by freepik</a></p>
<p><a href="https://www.freepik.com/free-vector/organic-flat-man-customer-support_13105774.htm#fromView=search&page=1&position=19&uuid=10ba70da-0367-45c6-85d3-e2fb2ed2e209">Image by freepik</a></p>


<p>GOOGLE ICON: <a target="_blank" href="https://icons8.com/icon/V5cGWnc9R4xj/google">Google</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a></p>
<p>LINKEDIN ICON: <a target="_blank" href="https://icons8.com/icon/xuvGCOXi8Wyg/linkedin">LinkedIn</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a></p>
<p>FACEBOOK ICON: <a target="_blank" href="https://icons8.com/icon/yGcWL8copNNQ/facebook">Facebook</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a></p>
<p>credit card chip: <a href="https://www.flaticon.com/free-icons/credit-card" title="credit card icons">Credit card icons created by Icon Place - Flaticon</a></p>



<p>DELETION IMG: <a href="https://www.freepik.com/free-vector/girl-erasing-data-cartoon-style-illustration_12953872.htm">Image by jcomp on Freepik</a></p>
<p>UNPLUG: <a href="https://www.freepik.com/free-vector/offline-concept-illustration_16790503.htm#fromView=search&page=2&position=52&uuid=2cb7d297-ac5b-40d7-a9d1-2a8f797ecc17">Image by storyset on Freepik</a></p>
<p>TRASH: <a href="https://www.freepik.com/free-vector/flat-trash-can-with-waste-plastic-bottles_23824432.htm#fromView=search&page=1&position=5&uuid=67033963-11e8-484a-9004-8f9fedb072ab">Image by redgreystock on Freepik</a></p>

<p>suchen von service: <a href="https://storyset.com/web">Web illustrations by Storyset</a></p>
<p>anfrage/bestellen von service: <a href="https://storyset.com/communication">Communication illustrations by Storyset</a></p>
<p>zahlen des service: <a href="https://storyset.com/money">Money illustrations by Storyset</a></p>

<p>anbieten von service: <a href="https://storyset.com/online">Online illustrations by Storyset</a></p>
<p>erhalt von anfragen: <a href="https://storyset.com/work">Work illustrations by Storyset</a></p>
<p>eingetragene bestellung (auch in kalendar) durchführen: <a href="https://storyset.com/work">Work illustrations by Storyset</a></p>
<p>zahlung erhalten: <a href="https://storyset.com/online">Online illustrations by Storyset</a></p>


<p>top homepage: <a href="https://storyset.com/online">Online illustrations by Storyset</a></p>

<p>default icon: <a target="_blank" href="https://icons8.com/icon/7820/male-user">Male User</a> icon by <a target="_blank" href="https://icons8.com">Icons8</a></p>
</div>

</div>


<div className='notice-section'>

<h3>4.3. Verlinkung zu externen Inhalten</h3>
     
<p>

Sofern in der App auf Inhalte Dritter (z. B. Bilder, Videos, Grafiken) verwiesen wird, die unter einer Creative Commons-Lizenz oder einer anderen freien Lizenz zur Verfügung stehen, findest du die entsprechenden Informationen und Links zu den Lizenzbedingungen in den jeweiligen Bildbeschreibungen.

</p>

</div>

</div>
)

}