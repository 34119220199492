import { useContext, useEffect, useState } from "react";
import "./Css/auth.css";
import { Notification, ServiceInput } from "./ServiceAdd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCircleNotch,
  faImages,
  faLocationArrow,
  faLocationPin,
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";

import {
  faGoogle,
  faStripe,
  faStripeS,
} from "@fortawesome/free-brands-svg-icons";

import { usePostJSONData } from "./Hooks/postjsonData";
import { usePostData } from "./Hooks/postData";
import { toast } from "react-toastify";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useNavigate } from "react-router-dom";
import { SkillContext } from "./App";

export const SignUp = () => {
  const { apiUrl } = useContext(SkillContext);

  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [password, setpassword] = useState("");
  const [image, setimage] = useState("");
  const [tel, settel] = useState("");
  const [email, setemail] = useState("");
  const [location, setlocation] = useState({});

  const [currlocation, setcurrlocation] = useState("");
  const [islocationvalid, setislocationvalid] = useState(false);

  const formData = new FormData();
  formData.append("image", image);
  formData.append("firstname", firstname);
  formData.append("lastname", lastname);
  formData.append("password", password);
  formData.append("tel", tel);
  formData.append("email", email);
  formData.append("location", currlocation);
  formData.append("validlocation", islocationvalid);

  //Fetch the Service of this serviceID
  const { data, err, isloading, addData } = usePostData(
    `${apiUrl}/api/users/signup`
  );

  useEffect(() => {
    console.log(data);
    //when this is successfull we redirect to login
    if(data.length != 0 && !data?.err){
      navigate("/auth/login")
    }
  }, [data]);

  const [shnotify, setshnotify] = useState(false);
  const [agree, setagree] = useState(false);

  const [loading, setloading] = useState("");
  const navigate = useNavigate();

  const fetchCoords = () => {
    // Get user's current position
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (pos) => {
          setlocation({
            latitude: pos.coords.latitude,
            longitude: pos.coords.longitude,
          });
        },
        (err) => {
          setlocation({ error: err.message });
        },
        {
          enableHighAccuracy: true, // Request high accuracy
          timeout: 30000, // Time in milliseconds to wait for a position
          maximumAge: 0,
        }
      );
    } else {
      console.log("GEOLOCATION UNSUPPORTED");
    }
  };

  const getCoords = async (url) => {
    try {
      const data = await fetch(url);
      const resdata = await data.json();

      setcurrlocation(
        resdata.address.state +
          ", " +
          resdata.address.postcode +
          " " +
          resdata.address.town +
          ", " +
          resdata.address.road
      );
    } catch {
      console.log("error while fetching coords");
    }
  };

  useEffect(() => {
    setloading(currlocation ? true : false);
  }, [currlocation]);

  useEffect(() => {
    getCoords(
      `https://nominatim.openstreetmap.org/reverse?format=jsonv2&lat=${location.latitude}&lon=${location.longitude}`
    );
  }, [location]);

  useEffect(() => {
    data?.err ? toast.error(data?.err) : toast.success(data?.msg);
  }, [data]);

  const checkAddress = async (address) => {
    if (address && address != undefined) {
      console.log(address[0]);
      console.log(address[1]);
      console.log(address[2]);

      const state = address[0] != undefined && address[0];
      const postcode = address[1] != undefined && address[1].split(" ")[1];
      const city = address[1] != undefined && address[1].split(" ")[2];
      const street = address[2] != undefined && address[2];

      const query = `${street}, ${postcode}, ${city}, ${state}`;

      // Encode the query to ensure it's safe for the URL
      const encodedQuery = encodeURIComponent(query);

      // Send the request to the Nominatim API
      const url = `https://nominatim.openstreetmap.org/search?q=${encodedQuery}&format=json&addressdetails=1&limit=1`;

      try {
        const data = await fetch(url);
        const resdata = await data.json();

        // Check if any results are returned
        if (resdata.length > 0) {
          setislocationvalid(true);
        } else {
          setislocationvalid(false);
          console.log("Invalid location");
        }
      } catch (error) {
        console.error("Error fetching location data:", error);
        return false;
      }
    }
  };

  useEffect(() => {
    const locparts = currlocation ? currlocation.split(",") : undefined;

    checkAddress(locparts);
  }, [currlocation]);

  const [currprocess, setcurrprocess] = useState(0);

  const stripePromise = loadStripe(
    process.env.REACT_APP_FIND_MY_SERVICE_STRIPE_PUB_KEY
  ); //

  const { data: addedstripeacc, addjsonData: addstripeacc } = usePostJSONData(
    `${apiUrl}/api/payments`
  );

  const [stripelogin, setstripelogin] = useState(false);

  const plattformSignUp = async (e) => {
    e.preventDefault();

    addData(formData);
    setstripelogin(false);
    //  addstripeacc({});
  };

  const stripeSignUp = (e) => {
    //firstoff create normal account
    e.preventDefault();
    addData(formData);

    setstripelogin(true);
  };

  useEffect(() => {
    //check if stripelogin is set to true
    if (stripelogin) {
      if (data?.msg) {
        console.log("Platform Account created now lets create Stripe Account");
        //now add stripe account
        addstripeacc({
          firstname,
          lastname,
          tel,
          email,
          currlocation,
        });
      } else {
        console.log("Platform Account not yet created");
      }
    } else {
      console.log("no stripe login");
    }
  }, [stripelogin, data]);

  useEffect(() => {
    if (addedstripeacc?.msg) {
      console.log(
        "Erfolgreich Stripe Account angelegt, nun Restdaten befüllen:"
      );

      console.log("MY link is: ");
      console.log(addedstripeacc?.link);

      window.location.href = addedstripeacc?.link;
    } else {
      console.error("Fehler beim Speichern des Stripe Accounts.");
    }
  }, [addedstripeacc]);

  const [currsite, setcurrsite] = useState(0);

  return (
    <div className="auth-wrapper">
      <div className="auth-box">
        <div className="top">
          <h1>Account erstellen</h1>
          <p>Erstelle deinen Account - Beginne noch heute.</p>
        </div>
        <div className="m-grid">
          <div className="service-img-wrapper">
            <label htmlFor="image">
              <div className="icon">
                <FontAwesomeIcon icon={faImages} />
              </div>

              <p>Drag and drop oder nach Bildern suchen</p>

              <p>PNG, JPG, PDF, SVG</p>
            </label>

            <input
              type="file"
              name="image"
              id="image"
              onChange={(e) => {
                setimage(e.target.files[0]);
              }}
            />
          </div>

          <ServiceInput
            label="Vorname"
            type="text"
            name="vname"
            setvalue={setfirstname}
            important={true}
          />

          <ServiceInput
            label="Nachname"
            type="text"
            name="lname"
            setvalue={setlastname}
            important={true}
          />

          <ServiceInput
            label="Passwort"
            type="password"
            name="pword"
            setvalue={setpassword}
            important={true}
          />

          <ServiceInput
            label=" Telefonnummer"
            type="phone"
            name="utel"
            setvalue={settel}
            important={false}
          />

          <ServiceInput
            label=" E-Mail"
            type="email"
            name="email"
            setvalue={setemail}
            important={true}
          />

          <div className="inp-wrapper">
            <ServiceInput
              label="Standort"
              type="text"
              name="standort"
              setvalue={setcurrlocation}
              val={
                loading
                  ? currlocation
                    ? currlocation
                    : "Standort lädt ..."
                  : ""
              }
            />

            <div className="location-info">
              <span
                onClick={() => {
                  fetchCoords();
                }}
              >
                <FontAwesomeIcon icon={faLocationArrow} /> aktuellen Standort
                verwenden
              </span>
            </div>
          </div>
        </div>

        <div className="auth-btns">
          <button
            onClick={(e) => {
              plattformSignUp(e);
            }}
          >
            {isloading ? (
              <div className="loader">
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            ) : (
              "Account erstellen"
            )}
          </button>
        </div>

        <div className="auth-option">
          <p>Bereits ein vorhandener Account?</p>
          <a href="/auth/login">Hier anmelden!</a>
        </div>

        <p>
          Beim Anmelden stimmen Sie unseren{" "}
          <a href="#">Privatsphäreeinstellungen</a> und unserem{" "}
          <a href="#">Datenschutz</a> zu.
        </p>
      </div>
    </div>
  );
};

const PaymentForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!stripe || !elements) {
      return;
    }

    setLoading(true);

    if (signoption === 0) {
      console.log("create nromal account with customer account");
      props.plattformSignUp();
    } else {
      console.log("create connect account with customer account");
      props.stripeSignUp();
    }

    // Create Customer and Connect accounts on the server
    //  const { data: { clientSecret, customerId, connectAccountId } } = await axios.post('/create-customer-and-connect-account');

    // Confirm card payment
    /*   const { error: paymentError } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: elements.getElement(CardElement),
      }
    });

    if (paymentError) {
      setError(paymentError.message);
      setLoading(false);
      return;
    }

    // Attach the card to the customer
    await axios.post('/attach-card-to-customer', { customerId, connectAccountId });

    setLoading(false);
    alert('Payment successful and card attached!');*/
  };

  // Custom styling for the CardElement
  const cardElementOptions = {
    style: {
      base: {
        color: "#ffffff", // Set text color to white
        fontSize: "16px",
        "::placeholder": {
          color: "#d1d1d1", // Light gray placeholder text
        },
      },
      invalid: {
        color: "#e5424d", // Color for invalid input
      },
    },
  };

  const [signoption, setsignoption] = useState(0);

  return (
    <form onSubmit={handleSubmit}>
      <div className="card-wrapper">
        <CardElement options={cardElementOptions} />
      </div>

      {error && <div>{error}</div>}

      <div className="form-sign-btns">
        <div className="next-btn">
          <button
            onClick={() => {
              props.currsite === 0 && props.currsite < 1
                ? props.setcurrsite(props.currsite + 1)
                : props.setcurrsite(props.currsite - 1);
            }}
          >
            {props.currsite === 0 ? "Nächstes" : "Zurück"}
          </button>
        </div>
      </div>
    </form>
  );
};
