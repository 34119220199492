import "./Css/service.css";

export const Footer = () => {

return (
    <footer>
            <div className="f-top-wrapper">
              <div className="top">
                <div className="top-item">
                  <h4>Navigation</h4>
                  <ul>
                    <li>
                      <a href="#">Home</a>
                    </li>
                    <li>
                      <a href="#">Neuer Service</a>
                    </li>
                  </ul>
                </div>

                <div className="top-item">
                  <h4>Über Skillshare</h4>
                  <ul>
                    <li>
                      <a href="#">Über uns</a>
                    </li>
                    <li>
                      <a href="#">Warum wir</a>
                    </li>
                  </ul>
                </div>

                <div className="top-item">
                  <h4>Feedback</h4>
                  <ul>
                    <li>
                      <a href="#">Was wir verbessern könnten</a>
                    </li>
                    <li>
                      <a href="#">Neuer Service</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="bottom">
              <div className="f-info">
                <a href="/datenschutzerklärung">Datenschutzerklärung</a>
                <a href="#">Nutzungsbedingungen</a>
                <a href="/support/tickets">Support</a>
                <a href="/AGBs">AGB</a>
                <a href="/impressum">Impressum</a>
              </div>

              <p>@ Copyright SkillShare 2024</p>
            </div>
          </footer>
)

}