import { useState } from "react";

export function usePostJSONData(url, callback) {
  const [data, setdata] = useState([]);
  const [isloading, setisloading] = useState(false);
  const [err, seterr] = useState(false);

  const addjsonData = async (body) => {
    try {
      setisloading(true);

      const data = await fetch(url, {
        method: "POST",
        headers: {
          Accept: "application/json, text/plain",
          "Access-Control-Allow-Methods": "OPTIONS,POST,GET",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(body),
      });

      const resdata = await data.json();
      setdata(resdata);
      //after adding the Data we can (but mustnt refetch the corresponding data)

      callback && callback();
    } catch (error) {
      seterr(error);
    } finally {
      setisloading(false);
    }
  };

  return { data, isloading, err, addjsonData };
}
