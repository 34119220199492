import "./Css/service.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBolt,
  faBoltLightning,
  faCalculator,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
  faClock,
  faCrown,
  faEllipsisVertical,
  faFilter,
  faHeart,
  faHelmetSafety,
  faInfo,
  faMinus,
  faMoneyBillTrendUp,
  faPenNib,
  faPenToSquare,
  faPencil,
  faPencilSquare,
  faPlus,
  faRefresh,
  faSearch,
  faSquareUpRight,
  faStar,
  faTrashArrowUp,
  faTrashCan,
  faTruckMoving,
  faWarning,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";

import { Suspense, useContext, useEffect, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getData, useGetData, usegetData } from "./Hooks/getData";
import { ShowlessData, ShowmoreData, SkillContext } from "./App";
import { usePostJSONData } from "./Hooks/postjsonData";
import { useDeleteData } from "./Hooks/deleteData";
import { toast } from "react-toastify";
import { ConfirmDelete } from "./Settings";

export const Services = () => {
  const { isloggedin, setisloggedin, City, Region, Service, apiUrl } =
    useContext(SkillContext);

  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );

  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
    }
  }, [checkedSession]);

  const Slider = [
    {
      img: "service_reqest.jpg",
      title: "Dienstleistung gefällig?",
      desc: "Suche nach Dienstleistung in deiner Umgebung. Alles was du brauchts an einem Ort!",
    },
    {
      img: "serviceprovider.png",
      title: "Vertrag abschließen.",
      desc: "Schließe einen verbindlichen Vertrag mit deinem Gegenüber.",
    },
    {
      img: "service_trade.jpg",
      title: "Dienstleistung lieber selber anbieten?",
      desc: "Starte noch heute und biete deine Dienstleistung an. Schnell und kostenlos!",
    },
  ];

  const [index, setindex] = useState(0);

  const nextSlide = (i, seti, arr) => {
    if (i === arr.length - 1) {
      seti(0);
    } else {
      seti(i + 1);
    }
  };

  const beforeSlide = (i, seti, arr) => {
    if (i === 0) {
      seti(arr.length - 1);
    } else {
      seti(i - 1);
    }
  };

  let s = 0;

  const [w, setw] = useState(0);

  useEffect(() => {
    //reset width
    setw(0);

    const timer = setTimeout(() => {
      nextSlide(index, setindex, Slider);
      // clearInterval(interval);
    }, 10000);

    return () => clearTimeout(timer);
  }, [index]);

  useEffect(() => {
    const interval = setInterval(() => {
      setw(w + 10);
    }, 800);

    return () => clearInterval(interval);
  }, [w]);

  /*    <div className="slider-nexto">
                {Slider.map((item, i) => (
                  <SliderPart index={i} currindex={index} />
                ))}
              </div>*/

  const Rows = new Array(10).fill(0);
  const Boxes = new Array(10).fill(0);

  const navigate = useNavigate();

  const year = new Date().getFullYear();

  //Fetch all Services for display
  const { data, err, isloading } = useGetData(
    `${apiUrl}/api/services/services/limit/20`
  );

  const { data: allProvider, refetch: refetchProviders } = useGetData(
    `${apiUrl}/api/services/all-provider`
  );

  useEffect(() => {
    console.log("allProvider :", allProvider);
  }, [allProvider]);

  return (
    <>
      <div className="service-wrapper">
        <div className="content-wrapper">
          <div className="content-top">
            <div className="hero-section-top">
              <div className="hero-item">
                <h2>
                  Find <span>your</span>Service
                </h2>
                <p>
                  Biete Services an oder nimm Sie in Anspruch. Schnell. Einfach.
                  Flexibel. Alle Angebote können Sie völlig kostenlos einsehen.
                </p>
                <button>Starte noch jetzt</button>
              </div>

              <div className="hero-item">
                <img src={"./images/service_reqest.jpg"} alt="hero-service" />
              </div>

              <div className="hero-item">
                <div className="hero-item-part">
                  <h2>{allProvider.length > 0 ? allProvider.length : 0} +</h2>
                  <p>verschiedene angebotene Dienstleistungen.</p>
                </div>

                <div className="services-f-next">
                  {allProvider.length > 0 && allProvider
                    ? allProvider.map((providers, index) => {
                        if (index < 5) {
                          return (
                            <FeatureImage
                              img={allProvider[index]?.simg}
                              title={allProvider[index]?.stitle}
                            />
                          );
                        }
                      })
                    : ""}
                </div>
              </div>
            </div>

            <div className="newest-service-f">
              <div className="newest-service-wrapper">
                <div className="scroll-wrapper">
                  <div className="side">
                    <div className="side-scroll">
                      <div className="service-box-wrapper-left">
                        {data.length > 0 &&
                          data.map((data) => {
                            return (
                              <ServiceBox
                                img={data.simg}
                                userstatus={data.status}
                                username={data.screator}
                                userimg={data.uimg}
                                rating={data.rating}
                                comments={data.commcount}
                                desc={data.ssmalldesc}
                                isliked={data.isliked}
                                id={data.sid}
                                title={data.stitle}
                                region={data.sregion}
                                city={data.scity}
                                Region={Region}
                                City={City}
                                reviewcount={data.ReviewCount}
                                reviewsum={data.ReviewSum}
                                premium={data.premium}
                              />
                            );
                          })}
                      </div>

                      <div className="service-box-wrapper-left">
                        {data.length > 0 &&
                          data.map((data) => {
                            return (
                              <ServiceBox
                                img={data.simg}
                                userstatus={data.status}
                                username={data.screator}
                                userimg={data.uimg}
                                rating={data.rating}
                                comments={data.commcount}
                                desc={data.ssmalldesc}
                                isliked={data.isliked}
                                id={data.sid}
                                title={data.stitle}
                                region={data.sregion}
                                city={data.scity}
                                Region={Region}
                                City={City}
                                reviewcount={data.ReviewCount}
                                reviewsum={data.ReviewSum}
                                premium={data.premium}
                              />
                            );
                          })}
                      </div>
                    </div>
                  </div>

                  <div className="side">
                    <div className="side-scroll">
                      <div className="service-box-wrapper-right">
                        {data.length > 0 &&
                          data.map((data) => {
                            return (
                              <ServiceBox
                                img={data.simg}
                                userstatus={data.status}
                                username={data.screator}
                                userimg={data.uimg}
                                rating={data.rating}
                                comments={data.commcount}
                                desc={data.ssmalldesc}
                                isliked={data.isliked}
                                id={data.sid}
                                title={data.stitle}
                                region={data.sregion}
                                city={data.scity}
                                Region={Region}
                                City={City}
                                reviewcount={data.ReviewCount}
                                reviewsum={data.ReviewSum}
                                premium={data.premium}
                              />
                            );
                          })}
                      </div>

                      <div className="service-box-wrapper-right">
                        {data.length > 0 &&
                          data.map((data) => {
                            return (
                              <ServiceBox
                                img={data.simg}
                                userstatus={data.status}
                                username={data.screator}
                                userimg={data.uimg}
                                rating={data.rating}
                                comments={data.commcount}
                                desc={data.ssmalldesc}
                                isliked={data.isliked}
                                id={data.sid}
                                title={data.stitle}
                                region={data.sregion}
                                city={data.scity}
                                Region={Region}
                                City={City}
                                reviewcount={data.ReviewCount}
                                reviewsum={data.ReviewSum}
                                premium={data.premium}
                              />
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="newest-s-desc">
                  <p>
                    (Diese Services sind einige der angebotenen Premium Services
                    auf unserer Seite)
                  </p>
                </div>
              </div>
            </div>

            <div className="service-sec-c">
              <div className="service-sec-w">
                <div className="top-picks-section">
                  <div className="header">
                    <div className="header-wrapper">
                      <h1>Warum wir?</h1>
                      <h2>Warum wir?</h2>
                    </div>
                  </div>

                  <div className="picks">
                    <ExpBox
                      icon={faSearch}
                      title="Schnelle Suche"
                      text="Finde schnell und leicht Dienstleistungen in deiner Umgebung."
                    />

                    <ExpBox
                      icon={faHelmetSafety}
                      title="Safety First"
                      text="Sichere Vermittlung zwischen Anbieter und Nachfrager."
                    />

                    <ExpBox
                      icon={faBoltLightning}
                      title="Kostenlos nutzbar"
                      text="Nutze unsere Vermittlung kostenlos."
                    />

                    <ExpBox
                      icon={faCalculator}
                      title="Tools"
                      text="Nutze Tools die dich bei der Servicebearbeitung unterstützen."
                    />

                    <ExpBox
                      icon={faMoneyBillTrendUp}
                      title="Leicht Geld verdienen"
                      text="Verdiene Dir schnell und leicht etwas Geld dazu. "
                    />
                  </div>
                </div>

                <div className="explanation-wrapper">
                  <div className="header">
                    <div className="header-wrapper">
                      <h1>Kunde</h1>
                      <h2>Kunde</h2>
                    </div>
                  </div>

                  <div className="explanation-item">
                    <img src="./images/search.svg" />

                    <div className="text">
                      <h2>Suche eine Dienstleistung</h2>
                      <p>
                        Suche Dir eine passende Dienstleistung für deinen Use
                        Case.
                      </p>
                    </div>
                  </div>

                  <div className="explanation-item">
                    <div className="text">
                      <h2>Dienstleister anfragen</h2>
                      <p>
                        Wenn du deine passende Dienstleistung gefunden hast,
                        dann kannst du mit dem Anbieter einen Termin
                        vereinbaren.
                      </p>
                    </div>

                    <img src="./images/texting.svg" />
                  </div>

                  <div className="explanation-item">
                    <img src="./images/payment.svg" />

                    <div className="text">
                      <h2>Dienstleistung bezahlen</h2>
                      <p>
                        Du kannst entscheiden, ob du deine Dienstleistung sofort
                        oder nach Abschluss des Service bezahlst.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="explanation-wrapper">
                  <div className="header">
                    <div className="header-wrapper">
                      <h1>Anbieter</h1>
                      <h2>Anbieter</h2>
                    </div>
                  </div>

                  <div className="explanation-item">
                    <div className="text">
                      <h2>Biete eine Dienstleistung an</h2>
                      <p>
                        Du hast ein Hobby oder eine Berufung und willst
                        zusätzlich Geld verdienen? Dann bist du hier richtig.
                        Biete schnell und kostenlos Dienstleistungen an.
                      </p>
                    </div>

                    <img src="./images/upload.svg" />
                  </div>

                  <div className="explanation-item">
                    <img src="./images/inbox.svg" />

                    <div className="text">
                      <h2>Bearbeite Anfragen</h2>
                      <p>
                        Wenn du eine Termin Anfrage erhältst kannst du diesen
                        Termin festsetzen, umändern oder ablehnen.
                      </p>
                    </div>
                  </div>

                  <div className="explanation-item">
                    <div className="text">
                      <h2>Dienstleistung abarbeiten</h2>
                      <p>
                        Dein Termin wird automatisch in deinen Service Kalendar
                        eingetragen. Bei Beginn des Service beginnst du dann
                        diesen abzuarbeiten.
                      </p>
                    </div>

                    <img src="./images/coding.svg" />
                  </div>

                  <div className="explanation-item">
                    <img src="./images/card.svg" />

                    <div className="text">
                      <h2>Zahlung erhalten</h2>
                      <p>
                        Nach erfolgreichem Abschluss deines Service erhälst du
                        deine Bezahlung. Wir behalten nur 5% Bearbeitungsgebühr
                        ein. Deine Zahlungen kannst du per Dashboard leicht
                        verfolgen.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="faq-wrapper-all">
              <div className="header">
                <div className="header-wrapper">
                  <h1>FAQ</h1>
                  <h2>FAQ</h2>
                </div>
              </div>

              <div className="faq-center">
                <div className="faq-wrapper">
                  <FAQItem
                    question="Wie erstelle ich eine Dienstleistung?"
                    answer="Navigiere oben zu 'neuer Service'. Folge nun allen angegebenen Schritten und fülle das Formular vollständig aus und voila Sie haben einen Service angeboten."
                  />
                  <FAQItem
                    question="Wieso werden manche Dienstleistungen über anderen angezeigt?"
                    answer="Services von Nutzern mit einem Premium Abo werden über normalen Services angezeigt und können auch auf der HomePage teilweise gefunden werden."
                  />
                  <FAQItem
                    question="Ein Kunde will nicht zahlen oder ein Anbieter liefer nicht korrekt?"
                    answer="Versuchen Sie immer erst Probleme mit dem Kunden/Anbieter direkt zu lösen. Scheint die Lage aussichtslos können Sie im Support Center ein Ticket eröffnen."
                  />
                  <FAQItem
                    question="Ist dieser Service wirklich kostenlos?"
                    answer="Ja, Sie können komplett kostenlos Services anbieten und in Anspruch nehmen. LEdiglich nach der Abwicklung eines Geschäfts werden Servicebeiträge erhoben und Sie haben die Möglichkeit sich Premium Vorteile zu sichern."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const FeatureImage = (props) => {
  const { apiUrl } = useContext(SkillContext);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(`${apiUrl}/api/users/images/${props.img}/type/services`);

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  return (
    <div className="f-img">
      {isImageLoading ? (
        <div className="loader">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      ) : (
        <img src={Image} alt="service-img" loading="lazy" />
      )}
      <Label text={props.title} />
    </div>
  );
};

const FAQItem = (props) => {
  const [edit, setedit] = useState(false);

  return (
    <div
      className="faq-item"
      onClick={() => {
        setedit(!edit);
      }}
    >
      <div className="top">
        <p>{props.question}</p>
        <div className="icon">
          <FontAwesomeIcon icon={edit ? faMinus : faPlus} />
        </div>
      </div>

      <div className="content" style={{ display: edit ? "block" : "none" }}>
        <p>{props.answer}</p>
      </div>
    </div>
  );
};

const ServiceBox = (props) => {
  const navigate = useNavigate();
  const { userdata, isloggedin, apiUrl } = useContext(SkillContext);

  //get Informations about the User with the Service ID
  //Fetch all Services for display
  const { data, err, isloading } = useGetData(
    `${apiUrl}/api/services/creator/service/${props.id}`
  );

  const region =
    props.Region && props.Region.find((r) => r.id == props.region).name;
  const city = props.City && props.City.find((c) => c.cid == props.city).cname;

  const [stars, setstars] = useState(0);

  useEffect(() => {
    setstars(props.reviewsum ? props.reviewsum : 0);
  }, [props.reviewsum]);

  const [Stars, setStars] = useState([1, 2, 3, 4, 5]);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(`${apiUrl}/api/users/images/${data[0]?.uimg}/type/users`);

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  useEffect(() => {
    refetchImage();
  }, [data]);

  return (
    <div
      className="s-box-wrapper"
      onClick={() => {
        navigate(`/service/details/${props.id}`);
      }}
    >
      <div className="right">
        <div className="top">
          <p>{props.title}</p>
        </div>

        <div className="middle">
          <div className="middle-item">
            {" "}
            {isImageLoading ? (
              <div className="loader">
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            ) : (
              <img src={Image} alt="user-img" loading="lazy" />
            )}{" "}
            {data[0]?.username}
          </div>{" "}
          <p> &#x2022;</p>
          <div className="middle-item">
            📌 {region}, {city}
          </div>{" "}
          <p style={{ display: data[0]?.premium ? "flex" : "none" }}>
            {" "}
            &#x2022;
          </p>
          <div
            className="middle-item"
            style={{ display: data[0]?.premium ? "flex" : "none" }}
          >
            🤝 Verifiziert
          </div>
        </div>

        <div className="l-bottom">
          <div className="l-bottom-top">
            <p>{stars} </p>
            <p>({props.reviewcount})</p>
          </div>
          <div className="star-rating-wrapper">
            {Stars.length > 0 &&
              Stars.map((star, index) => (
                <div
                  className="star-rating"
                  style={{
                    color: index < stars ? "rgb(212, 212, 64)" : "gray",
                  }}
                >
                  <FontAwesomeIcon icon={faStar} />
                </div>
              ))}
          </div>{" "}
        </div>
      </div>
    </div>
  );
};

const ExpBox = (props) => {
  return (
    <div className="exp-wrapper">
      <div className="top">
        <p>
          <FontAwesomeIcon icon={props.icon} />
        </p>
      </div>

      <div className="bottom">
        <h2>{props.title}</h2>
        <p>{props.text}</p>
      </div>
    </div>
  );
};

export const Label = (props) => {
  return (
    <div className="label-wrapper">
      <div className="triangle"></div>
      <p>{props.text}</p>
    </div>
  );
};

export const ContentBox = (props) => {
  const navigate = useNavigate();
  const { userdata, isloggedin, apiUrl } = useContext(SkillContext);

  //get Informations about the User with the Service ID
  //Fetch all Services for display
  const { data, refetch, err, isloading } = useGetData(
    `${apiUrl}/api/services/creator/service/${props.id}`
  );

  useEffect(() => {
    refetch();
  }, [props.id]);

  useEffect(() => {
    console.log(data);
  }, [data]);

  const { data: postedfavourites, addjsonData } = usePostJSONData(
    `${apiUrl}/api/services/user/${userdata[0]?.usertoken}/favourites`,
    props.refetchFavs && props.refetchFavs()
  );

  useEffect(() => {
    postedfavourites?.err
      ? toast.error(postedfavourites?.err)
      : toast.success(postedfavourites?.msg);
  }, [postedfavourites]);

  const region =
    props.Region && props.Region.find((r) => r.id == props.region).name;
  const city = props.City && props.City.find((c) => c.cid == props.city).cname;

  const [stars, setstars] = useState(0);

  useEffect(() => {
    setstars(props.reviewsum ? props.reviewsum : 0);
  }, [props.reviewsum]);

  const [Stars, setStars] = useState([1, 2, 3, 4, 5]);

  /*
  useEffect(() => {
    const S = [];

    for (let i = 0; i < stars; i++) {
      S.push(i);
    }

    setStars(S);
  }, [stars]);*/

  const { data: deletedService, deleteData: deleteService } = useDeleteData(
    `  ${apiUrl}/api/services/single/${props.id}`
  );

  useEffect(() => {
    deletedService?.err
      ? toast.error(deletedService?.err)
      : toast.success(deletedService?.msg);
  }, [deletedService]);

  const [shDelOv, setshDelOv] = useState(false);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(`${apiUrl}/api/users/images/${props.img}/type/services`);

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  useEffect(() => {
    refetchImage();
  }, [props.img]);

  return (
    <>
      <ConfirmDelete
        shov={shDelOv}
        setshov={setshDelOv}
        img="trash.jpg"
        title="Willst du diesen Service wirklich lösen ?"
        message="Wenn du diesen Service löscht werden alle Informationen mit diesem Service entfernt."
        action={() => {
          deleteService();
        }}
        isdelete
      />

      <div className="service-box-wrapper">
        <div className="box-wrapper-top">
          {isImageLoading ? (
            <div className="loader">
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
          ) : (
            <img
              src={Image}
              alt="service-img"
              loading="lazy"
              onClick={() => {
                navigate(`/service/details/${props.id}`);
              }}
            />
          )}

          <div
            className="premium-wrapper"
            style={{ display: props.premium ? "flex" : "none" }}
          >
            <div className="icon">
              <FontAwesomeIcon icon={faStar} />
            </div>

            <div className="premium-drop">
              <p>
                Dieser Service ist ein Premium Service. Premium Anbieter
                genießen mehr Vertrauen und viele Vorteile. Werden Sie jezt ein
                Teil davon:
              </p>
              <a href="#">Premium erwerben</a>
            </div>
          </div>

          <div
            className="info-box"
            style={{ display: props.edit ? "flex" : "none" }}
          >
            <div
              onClick={() => {
                navigate(`/service/${props.id}/pending`);
              }}
              className="icon"
            >
              <FontAwesomeIcon icon={faTruckMoving} />

              <Label text="Aufträge" />
            </div>

            <div
              onClick={() => {
                navigate(`/service/edit/${props.id}`);
              }}
              className="icon"
            >
              <FontAwesomeIcon icon={faPencil} />

              <Label text="Editieren" />
            </div>

            <div
              className="icon"
              onClick={() => {
                navigate(`/appointments/${props.id}`);
              }}
            >
              <FontAwesomeIcon icon={faClock} />
              <Label text="Terminplan" />
            </div>

            <div
              className="icon"
              onClick={() => {
                setshDelOv(true);
              }}
            >
              <FontAwesomeIcon icon={faTrashCan} />
              <Label text="Löschen" />
            </div>
          </div>
        </div>

        <div className="box-wrapper-bottom">
          <div className="top">
            <p>{props.title}</p>
          </div>

          <div className="middle">
            <div className="middle-item">
              {" "}
              <img src={"./images/users/" + data[0]?.uimg} />{" "}
              {data[0]?.username}
            </div>{" "}
            <p> &#x2022;</p>
            <div className="middle-item">
              📌 {region}, {city}
            </div>{" "}
            <p style={{ display: data[0]?.premium ? "flex" : "none" }}>
              {" "}
              &#x2022;
            </p>
            <div
              className="middle-item"
              style={{ display: data[0]?.premium ? "flex" : "none" }}
            >
              🤝 Verifiziert
            </div>
          </div>

          <div className="bottom">
            <div className="left">
              <div className="l-bottom">
                <div className="l-bottom-top">
                  <p>{stars} </p>
                  <p>({props.reviewcount})</p>
                </div>
                <div className="star-rating-wrapper">
                  {Stars.length > 0 &&
                    Stars.map((star, index) => (
                      <div
                        className="star-rating"
                        style={{
                          color: index < stars ? "rgb(212, 212, 64)" : "gray",
                        }}
                      >
                        <FontAwesomeIcon icon={faStar} />
                      </div>
                    ))}
                </div>{" "}
              </div>
            </div>

            <div className="right">
              <div
                className="icon"
                onClick={() => {
                  isloggedin && addjsonData({ sid: props.id });
                }}
                style={{ color: props.isliked ? "red" : "gray" }}
              >
                <FontAwesomeIcon icon={faHeart} />
              </div>

              <div
                className="icon"
                onClick={() => {
                  navigate(`/service/details/${props.id}`);
                }}
              >
                <FontAwesomeIcon icon={faArrowRight} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
