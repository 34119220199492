import './Css/datenschutz.css';

export const Datenschutzerklärung = () => {

return (
<div className='legal-notice-wrapper'>

<div className='notice-section'>

<h2>1. Datenschutzerklärung</h2>
    
<p>Verantwortlicher für die Datenverarbeitung</p>

<p>Anbieter der App: Tizian Grabert (FindmyService)</p>
<ul>Anschrift:
<li>Tizian Grabert </li>
<li>Telefon: +49 174 8278589</li>
<li>E-Mail: tizian.grabert@gmail.com</li>
</ul>

</div>


<div className='notice-section'>

<h3>1.1. Welche personenbezogenen Daten erheben wir?</h3>
    
<p>
Wir erheben, verarbeiten und nutzen personenbezogene Daten, wenn Sie unsere App verwenden, um eine reibungslose Vermittlung zwischen Kunden und Serviceanbietern zu gewährleisten. Die von uns erhobenen Daten umfassen:

<ul>
<li>Registrierungsdaten (Pflichtangaben): Vorname, Nachname (gespeichert als Benutzername), E-Mail-Adresse, Passwort </li>
<li>Optionale Registrierungsdaten: Telefonnummer, Standort, Profilbild</li>
<li>Zahlungsdaten: Stripe Connect Account (Pflicht für Serviceanbieter) und Stripe Customer Account (für Nutzer die Zahlungen durchführen)</li>
<li>Kommunikationsdaten: Nachrichten, Kommentare und Tickets, die Sie mit anderen Nutzern (Kunden/Anbietern) oder mit uns über unsere App austauschen</li>

-> AUSFÜHRLICHER!!!
</ul>


</p>

</div>



<div className='notice-section'>

<h3>1.2. Zweck und Rechtsgrundlage der Datenverarbeitung</h3>
    
<p>Wir erheben und verarbeiten personenbezogene Daten ausschließlich für die folgenden Zwecke:

<ol>
<li>
<span>Zur Erfüllung unserer vertraglichen Pflichten (Art. 6 Abs. 1 lit. b DSGVO): </span>
<span>Dies umfasst die Bereitstellung von Vermittlungsdiensten zwischen Kunden und Anbietern, die Zahlungsabwicklung und die Verwaltung Ihres Benutzerkontos.
</span>
    
     </li>
<li> <span>Zur Einhaltung gesetzlicher Vorgaben (Art. 6 Abs. 1 lit. c DSGVO): </span> 
<span> Hierzu zählen insbesondere steuerrechtliche Pflichten, wie die Aufbewahrung von Rechnungsdaten. </span></li>
<li>

<span>
Zur Wahrung berechtigter Interessen (Art. 6 Abs. 1 lit. f DSGVO):
</span>

<span>
Wir speichern Kommunikationsdaten, um Missbrauch zu verhindern und zur Beilegung von Streitigkeiten.
</span>

</li>
</ol>


</p>

</div>


<div className='notice-section'>

<h3>1.3. Speicherung und Löschung von Daten</h3>
    



<div className='notice-section'>

<h4>1.3.1 Kontodaten</h4>
     
<p>

Ihr Konto kann jederzeit gelöscht werden. Wir bewahren personenbezogene Daten, die für die Zahlungsabwicklung erforderlich sind (wie Name, Adresse, Transaktionsdaten) für die gesetzlich vorgeschriebene Dauer (in der Regel 10 Jahre) auf.
</p>

</div>


<div className='notice-section'>

<h4>1.3.2 Kommunikationsdaten</h4>
     
<p>
Nachrichten, Kommentare und Tickets werden intern bis zu 10 Jahre gespeichert, aber nach spezifischer Löschung oder Löschung Ihres Kontos für Sie und andere Nutzer nicht mehr sichtbar gemacht, bzw. anonymisiert.
</p>

</div>

<div className='notice-section'>

<h4>1.3.3 Nutzerprofile</h4>
     
<p>

Nach der Löschung eines Kontos wird Ihr Profil anonymisiert. Informationen zu getätigten Bewertungen und Ticket Aktivitäten werden weiterhin angezeigt, jedoch anonymisiert.
</p>

</div>

<div className='notice-section'>

<h4>1.3.4 Weitere Daten</h4>
     
<p>

WAS PASSIERT MIT RESTLICHEN DATEN????
</p>

</div>


</div>




<div className='notice-section'>

<h3>1.4. Rechte der Nutzer</h3>
    
<p>

Als betroffene Person haben Sie folgende Rechte in Bezug auf Ihre personenbezogenen Daten:</p>

<div className='notice-section'>

<h4>1.4.1  Auskunftsrecht</h4>
     
<p>
Sie können eine Bestätigung darüber verlangen, ob Ihre personenbezogenen Daten verarbeitet werden und gegebenenfalls Auskunft über diese Daten erhalten.
</p>

</div>

<div className='notice-section'>

<h4>1.4.2  Recht auf Berichtigung</h4>
     
<p>
Sie können die Berichtigung unrichtiger oder Vervollständigung unvollständiger Daten verlangen.
</p>

</div>



<div className='notice-section'>

<h4>1.4.3  Recht auf Löschung</h4>
     
<p>
Sie haben das Recht, die Löschung Ihrer personenbezogenen Daten zu verlangen, wenn diese für die Zwecke, für die sie erhoben oder verarbeitet wurden, nicht mehr notwendig sind.
</p>

</div> 



<div className='notice-section'>

<h4>1.4.4  Recht auf Einschränkung der Verarbeitung</h4>
     
<p>
Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen, wenn bestimmte Bedingungen erfüllt sind.
</p>

</div> 

<div className='notice-section'>

<h4>1.4.5  Recht auf Datenübertragbarkeit</h4>
     
<p>
Sie haben das Recht, die Übertragung Ihrer personenbezogenen Daten an Sie oder an einen anderen Verantwortlichen zu verlangen.
</p>

</div> 


 



</div>


<div className='notice-section'>

<h3>1.5. Weitergabe an Dritte</h3>
    
<p>

Personenbezogene Daten werden ausschließlich im Rahmen der notwendigen Vertragsabwicklung an Dritte weitergegeben. Dazu gehört insbesondere die Weitergabe von Zahlungsdaten an Stripe, unseren Zahlungsanbieter, zur Abwicklung der Transaktionen. Mehr Informationen zur Verarbeitung von Daten durch Stripe finden Sie in deren Datenschutzerklärung.

</p>

</div>


<div className='notice-section'>

<h3>1.6. Cookies, Analyse-Tools und Tracking</h3>
    
<p>


Unsere App verwendet Cookies und ähnliche Technologien, um die Benutzererfahrung zu verbessern und die Nutzung der App zu analysieren.

Technisch notwendige Cookies: Diese Cookies sind erforderlich, um die grundlegenden Funktionen der App zu gewährleisten, wie z. B. die Speicherung Ihrer Anmeldedaten.
Analyse-Tools und Tracking: Wir verwenden aktuell keine Form von Analyse-Tools zur Nachverfolgung oder zum Profiling Ihrer Aktivitäten.

</p>

</div>


<div className='notice-section'>

<h3>1.7. Kontakt</h3>
    
<p>
Wenn Sie Fragen oder Anliegen zum Datenschutz haben, kontaktieren Sie uns bitte unter:
</p>

<div className='notice-section'>
<p>
Tizian Grabert
</p>
<p>
E-Mail: tizian.grabert@gmail.com
</p>
</div>


</div>



</div>
)

}