import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Css/ticket.css";
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faArrowRightArrowLeft,
  faArrowUpRightFromSquare,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
  faCommentDots,
  faEllipsis,
  faEllipsisVertical,
  faFlag,
  faM,
  faMailForward,
  faMinus,
  faPencil,
  faScrewdriverWrench,
  faTentArrowLeftRight,
  faTrashCan,
  faTruckFast,
  faUser,
  faUserCog,
  faWandMagicSparkles,
  faXmark,
  faXmarkSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { ServiceDrop, ServiceInput, ServiceTextArea } from "./ServiceAdd";
import { SkillContext } from "./App";
import { useGetData } from "./Hooks/getData";
import { useNavigate } from "react-router-dom";
import {
  BubbleChatEditIcon,
  PencilEdit01Icon,
  Share01Icon,
  StartUp02Icon,
} from "hugeicons-react";
import { usePostJSONData } from "./Hooks/postjsonData";
import { toast } from "react-toastify";
import { useUpdateJSONData } from "./Hooks/updatejsonData";
import { useDeleteData } from "./Hooks/deleteData";
import { DefaultDrop, IconDrop, TableItem } from "./UserTicket";
import { ConfirmDelete } from "./Settings";

export const TicketDashboard = () => {
  const { isloggedin, setisloggedin, userdata, apiUrl } =
    useContext(SkillContext);

  const navigate = useNavigate();

  /*CHECK IF USER IS LOGGEDIN*/
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );

  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  //sent him back to main screen when hes not a Admin

  useEffect(() => {
    if (userdata.length > 0) {
      !userdata[0]?.isAdmin && navigate("/search/services");
    }
  }, [userdata]);

  const [ticketcreator, setticcketcreator] = useState("");

  const [shticketoption, setshticketoption] = useState(false);

  const [actionChoose, setactionChoose] = useState(0);

  const [astep, setastep] = useState(0);

  const [shtoverlay, setshtoverlay] = useState(false);

  const [shticketdetails, setshticketdetails] = useState(false);
  const [tchoosed, settchoosed] = useState(0);
  const [myTickets, setmyTickets] = useState([]);

  useEffect(() => {
    console.log("TCHOOSED");

    if (tchoosed === 0) {
      //all arr data
      setticketDub(getTickets);
    } else {
      setticketDub(myTickets);
    }
  }, [tchoosed]);

  const ActionItem = [
    {
      id: 0,
      title: "Rückerstattung",
      icon: faTentArrowLeftRight,
    },
    {
      id: 1,
      title: "Serviceproblem",
      icon: faTruckFast,
    },
    {
      id: 2,
      title: "Anderes Anliegen",
      icon: faWandMagicSparkles,
    },
  ];

  //Fetch loggedin Users services
  const { data: orders, refetch: refetchOrders } = useGetData(
    `${apiUrl}/api/services/users/${userdata[0]?.usertoken}/pending/services`
  );

  useEffect(() => {
    console.log(orders);
  }, [orders]);

  const [title, settitle] = useState("");
  const [descr, setdescr] = useState("");
  const [choosed, setchoosed] = useState("");
  const [OrderendDate, setOrderendDate] = useState("");
  const [err, seterr] = useState(false);

  useEffect(() => {
    console.log("VALUES");
    console.log(title);
    console.log(descr);
    console.log(choosed);
    if (orders && orders.length > 0)
      setOrderendDate(orders.find((order) => order.said == choosed));
  }, [title, descr, choosed]);

  useEffect(() => {
    console.log("Todays Date");
    console.log(new Date().getTime());
    const newDate = new Date().getTime();

    console.log("End Order date:");

    console.log("created on:");
    console.log(OrderendDate?.billCreatedon);
    //if billcreatedOn is null or undefined we set oldDate to todaysDate -< so we dont get unsupported error
    const oldDate = OrderendDate?.billCreatedon
      ? new Date(OrderendDate?.billCreatedon).getTime()
      : newDate;

    const oldDate2Weeks = oldDate + 14 * 24 * 60 * 60 * 1000;

    console.log("Date Difference:");
    console.log(new Date(oldDate2Weeks));

    if (newDate > oldDate2Weeks) {
      //Bestellung/Rechnung gibt es seit länger als 14 Tagen
      console.log("Support abgelaufen");
      seterr(true);
    } else {
      console.log("Support aktiv");
      seterr(false);
    }
  }, [OrderendDate]);

  //Fetch  all Tickets
  const {
    data: getTickets,
    isloading: ticketsLoading,
    err: ticketserr,
    refetch: refetchTickets,
  } = useGetData(`${apiUrl}/api/services/tickets/all`);

  //active Orders
  const {
    data: activeOrder,
    isloading: activeOrderload,
    err: activeOrdererr,
    refetch: refetchactiveOrders,
  } = useGetData(`${apiUrl}/api/services/active-orders`);

  //add new Ticket
  const {
    data: addedTicket,
    isloading: addTikcetloading,
    err: addTicketerr,
    addjsonData: addTicket,
  } = usePostJSONData(
    `${apiUrl}/api/services/users/${userdata[0]?.usertoken}/support/tickets`,
    () => {
      //add Standard Start Comment and refetch Tickets
      refetchTickets();
    }
  );

  useEffect(() => {
    if (userdata.length > 0) {
      refetchTickets();
      refetchOrders();
    }
  }, [userdata]);

  const [currentItem, setcurrentItem] = useState(0);

  const [itemIndex, setitemIndex] = useState(0);

  //Fetch Single Users Tickets
  const {
    data: getTicket,
    isloading: ticketLoading,
    err: ticketErr,
    refetch: refetchTicket,
  } = useGetData(`${apiUrl}/api/services/user/null/ticket/${currentItem}`);

  //Update Single Users Tickets
  const {
    data: updatedTicket,
    isloading: ticketupdateLoading,
    err: ticketupdateErr,
    update: updateTicket,
  } = useUpdateJSONData(
    `${apiUrl}/api/services/user/${userdata[0]?.usertoken}/ticket/${currentItem}`,
    () => {
      refetchTicket();
      refetchTickets();
    }
  );

  useEffect(() => {
    if (updatedTicket?.err || updatedTicket?.msg) {
      if (updatedTicket?.err) {
        toast.error(updatedTicket?.err);
      } else {
        toast.success(updatedTicket?.msg);
      }
    }
  }, [updatedTicket]);

  //Fetch  Single Tickets Comments
  const {
    data: getActivity,
    isloading: activityLoad,
    err: actErr,
    refetch: refetchActivity,
  } = useGetData(
    `${apiUrl}/api/services/ticket/${getTicket[0]?.ticket_id}/comments/null`
  );

  //add  Ticket Comments
  const {
    data: addedActivity,
    isloading: loadingActivityadd,
    err: aadderr,
    addjsonData: addActivity,
  } = usePostJSONData(
    `${apiUrl}/api/services/ticket/${getTicket[0]?.ticket_id}/comments/null`,
    () => {
      refetchActivity();

      //update Ticket wenn no error
      !addedActivity?.err &&
        updateTicket({
          ticket_last_updated: new Date(),
        });
    }
  );

  useEffect(() => {
    addedActivity?.err
      ? toast.error(addedActivity?.err)
      : toast.success(addedActivity?.msg);
  }, [addedActivity]);

  const [editID, seteditID] = useState("");
  const [editTitle, seteditTitle] = useState("");
  const [editDescr, seteditDescr] = useState("");
  const [editB, seteditB] = useState("");
  const [editStatus, seteditStatus] = useState("");

  useEffect(() => {
    if (userdata.length > 0 && currentItem != undefined) {
      console.log("ticket refetching");
      refetchTicket();
    } else {
      console.log("ticket not refetching");
    }
  }, [userdata, currentItem]);

  useEffect(() => {
    refetchActivity();

    seteditID(getTicket[0]?.ticket_id);
    seteditTitle(getTicket[0]?.ticket_title);
    seteditDescr(getTicket[0]?.ticket_descr);
    seteditB(getTicket[0]?.ticket_bearbeiter);
    seteditStatus(getTicket[0]?.ticket_status);
  }, [getTicket]);

  useEffect(() => {
    console.log("getActivity:", getActivity);
  }, [getActivity]);

  const [ticketDub, setticketDub] = useState([]);

  useEffect(() => {
    console.log("Active Tickets: ", getTickets);

    setticketDub(getTickets);

    setmyTickets(
      getTickets && getTickets.length > 0
        ? getTickets.filter(
            (ticket) => ticket.ticket_bearbeiter === userdata[0]?.usertoken
          )
        : []
    );
  }, [getTickets]);

  //index update getTickets[index]

  useEffect(() => {
    setcurrentItem(getTickets[itemIndex]?.ticket_id);
  }, [itemIndex]);

  const [shticketedit, setshticketedit] = useState(false);

  const moveUp = () => {
    if (itemIndex > 0) {
      //0  -> 2
      setitemIndex(itemIndex - 1);
    } else {
      setitemIndex(getTickets.length - 1);
    }

    //resetDataValues for Edit
    seteditID("");
    seteditTitle("");
    seteditDescr("");
    seteditB("");
    seteditStatus("");
  };

  const moveDown = () => {
    if (itemIndex < getTickets.length - 1) {
      //0  -> 2
      setitemIndex(itemIndex + 1);
    } else {
      setitemIndex(0);
    }

    //resetDataValues for Edit
    seteditID("");
    seteditTitle("");
    seteditDescr("");
    seteditB("");
    seteditStatus("");
  };

  const statusChoose = [
    {
      said: "Abgeschlossen",
      title: "Abgeschlossen",
    },
    {
      said: "in Bearbeitung",
      title: "in Bearbeitung",
    },
  ];

  const chooseProcessor = [
    {
      said: userdata[0]?.usertoken,
      title: "Mir",
    },
  ];

  useEffect(() => {
    //CHeck Ticket that is currently choosed???

    if (getTicket.length === 0 || getTicket?.err) {
      console.log("ticket not found", getTicket);
    } else {
      console.log("ticket found", getTicket);

      //Ticket is done
      if (getTicket[0]?.ticket_status === "Abgeschlossen") {
        console.log("ticket done");

        if (!getTicket[0]?.isClosed) {
          console.log("ticket not closed, closing ....");

          addActivity({
            comment_title: "hat Ticket abgeschlossen.",
            comment_text: null,
            comment_creator: getTicket[0]?.ticket_bearbeiter,
            activity_type: "end",
            ticket_ref: getTicket[0]?.ticket_id,
          });

          //eigentlich unnötig
          updateTicket({
            isClosed: 1,
            ticket_last_updated: new Date(),
          });
        } else {
          console.log("ticket already closed");
        }
      } else {
        console.log("ticket not done");
        //ticket is not done -> check isClose
        getTicket[0]?.isClosed &&
          updateTicket({
            isClosed: 0,
            ticket_last_updated: new Date(),
          });
      }

      //if Ticket is not done , and we have a ticker proccessor -< we set ticket to 'in Bearbeitung'
      if (
        getTicket[0]?.ticket_status === "Unbearbeitet" &&
        getTicket[0]?.ticket_bearbeiter != "null" &&
        getTicket[0]?.ticket_bearbeiter
      ) {
        console.log("update status: " + true);

        updateTicket({
          ticket_status: "in Bearbeitung",
          ticket_last_updated: new Date(),
        });

        addActivity({
          comment_title: "hat sich das Ticket zugewiesen.",
          comment_text: null,
          comment_creator: getTicket[0]?.ticket_bearbeiter,
          activity_type: "exception",
          ticket_ref: getTicket[0]?.ticket_id,
        });

        //add activity that ticket processor is processing ticket zugeweisen
      }
    }
  }, [getTicket]);

  const [firstsender, setfirstsender] = useState("");
  const [firstreceiver, setfirstreceiver] = useState("");
  const [secondsender, setsecondsender] = useState("");
  const [secondreceiver, setsecondreceiver] = useState("");

  //getAllUsers
  const { data: getAllUsers, refetch: refetchAllUSers } = useGetData(
    `${apiUrl}/api/users/all/users`
  );

  const [Userdata, setUserdata] = useState([]);

  useEffect(() => {
    console.log("All USers: ", getAllUsers);
    const users = [];

    if (getAllUsers.length > 0) {
      //create obj to use later
      getAllUsers.map((user) => {
        users.push({
          said: user.usertoken,
          title: user.username,
        });
      });

      setUserdata(users);
    }
  }, [getAllUsers]);

  //Fetch  Single Tickets Notis
  const { data: getFirstNotis, refetch: refetchFirstNotis } = useGetData(
    ` ${apiUrl}/api/services/notification/sender/${firstsender}/receiver/${firstreceiver}`
  );

  //Fetch  Single Tickets Notis
  const { data: getSecondNotis, refetch: refetchSecondNotis } = useGetData(
    ` ${apiUrl}/api/services/notification/sender/${secondsender}/receiver/${secondreceiver}`
  );

  useEffect(() => {
    console.log("SENDERS ACTIVATED");

    if (firstsender != "" && firstreceiver != "") {
      refetchFirstNotis();
      console.log("refetch first");
    }

    if (secondsender != "" && secondreceiver != "") {
      refetchSecondNotis();
      console.log("refetch second");
    }
  }, [firstsender, firstreceiver, secondsender, secondreceiver]);

  useEffect(() => {
    console.log("FirstNotis: ", getFirstNotis);
    console.log("SecondNotis: ", getSecondNotis);
  }, [getFirstNotis, getSecondNotis]);

  useEffect(() => {
    console.log("formed usersdata: ", Userdata);
  }, [Userdata]);

  useEffect(() => {
    console.log("activeOrder:", activeOrder);
  }, [activeOrder]);

  return (
    <>
      <TicketDescOverlay
        shoverlay={shticketdetails}
        setshoverlay={setshticketdetails}
        itemIndex={itemIndex}
        setitemIndex={setitemIndex}
        getTicket={getTicket}
        ticketLoading={ticketLoading}
        ticketErr={ticketErr}
        getTicketsLEN={getTickets.length > 0 ? getTickets.length : 0} // getTickets.length > 0 ? getTickets.length : 0
        addedTicket={addedTicket}
        moveUp={moveUp}
        moveDown={moveDown}
        refetchActivity={refetchActivity}
        addActivity={addActivity}
        loadingActivityadd={loadingActivityadd}
        getActivity={getActivity}
        activityLoad={activityLoad}
      />

      <div
        className="ticket-add-overlay"
        style={{ display: shticketedit ? "flex" : "none" }}
      >
        <div className="overlay-adder-wrapper">
          <div className="content-top">
            <div className="left">
              <h2>Ticket Bearbeiten</h2>

              <div className="move-nxt-btns">
                <div
                  className="icon"
                  onClick={() => {
                    moveUp();
                  }}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
                <div
                  className="icon"
                  onClick={() => {
                    moveDown();
                  }}
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </div>
              </div>

              <p>
                <span>{itemIndex + 1}</span> von {getTickets.length}
              </p>
            </div>

            <div className="right">
              <div
                className="icon"
                onClick={() => {
                  setshticketedit(false);
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
          </div>

          {ticketLoading ? (
            <div className="loader">
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
          ) : ticketErr != "" ? (
            <p>{ticketErr}</p>
          ) : (
            <div className="content-grid">
              <ServiceInput label="TicketID" val={"#" + editID} disabled />

              <ServiceInput
                label="Titel"
                val={editTitle}
                setvalue={seteditTitle}
                disabled
              />

              <ServiceTextArea
                label="Beschreibung"
                val={editDescr}
                setvalue={seteditDescr}
                disabled
              />

              <DefaultDrop
                label="Zuweisen"
                val={editB}
                setvalue={seteditB}
                optionChoose={chooseProcessor}
              />

              <DefaultDrop
                label="Status"
                val={editStatus}
                setvalue={seteditStatus}
                optionChoose={statusChoose}
                disabled={
                  getTicket[0]?.ticket_bearbeiter != userdata[0]?.usertoken
                    ? true
                    : false
                }
              />
            </div>
          )}

          <button
            onClick={() => {
              updateTicket({
                ticket_title: editTitle,
                ticket_descr: editDescr,
                ticket_status: editStatus,
                ticket_bearbeiter: editB,
                ticket_last_updated: new Date(),
              });
            }}
          >
            {ticketupdateLoading ? (
              <div className="loader">
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            ) : (
              "Bestätigen"
            )}
          </button>
        </div>
      </div>
      <div className="ticket-wrapper">
        <div className="ticket-center">
          <div className="top">
            <div className="left">
              <h2>Hallo {userdata[0]?.username}, </h2>
              <p>
                Hier findest alle erstellten Tickets non Nutzern. Wenn du Dir
                ein Ticket zuweist, findest du es in der Spalte 'meine Tickets'.
              </p>

              <div className="choose-tickets-wrapper">
                <div
                  className="ticket-choose"
                  onClick={() => {
                    settchoosed(0);
                  }}
                  style={{
                    borderBottom:
                      tchoosed === 0
                        ? "1px solid var(--light-text-col)"
                        : "1px solid transparent",
                    backgroundColor:
                      tchoosed === 0
                        ? "var(--main-bg-accent-light)"
                        : "transparent",
                  }}
                >
                  <p>Alle Tickets</p>
                  <span>{getTickets.length > 0 ? getTickets.length : 0}</span>
                </div>

                <div
                  className="ticket-choose"
                  onClick={() => {
                    settchoosed(1);
                  }}
                  style={{
                    borderBottom:
                      tchoosed === 1
                        ? "1px solid var(--light-text-col)"
                        : "1px solid transparent",
                    backgroundColor:
                      tchoosed === 1
                        ? "var(--main-bg-accent-light)"
                        : "transparent",
                  }}
                >
                  <p>Meine Tickets</p>
                  <span>{myTickets.length > 0 ? myTickets.length : 0}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="ticket-table-sec">
            <div className="left">
              <table>
                <thead>
                  <tr>
                    <th>Ticket ID</th>
                    <th>Ticket Title</th>
                    <th>Bearbeiter</th>
                    <th>letzte Veränderung</th>
                    <th>Status</th>
                    <th>Aktion</th>
                  </tr>
                </thead>

                <tbody>
                  {ticketsLoading ? (
                    <div className="loader">
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    </div>
                  ) : ticketserr != "" ? (
                    <p>{ticketserr}</p>
                  ) : ticketDub && ticketDub.length > 0 ? (
                    ticketDub.map((ticket, index) => {
                      return (
                        <TableItem
                          ticket={ticket}
                          setshticketdetails={setshticketdetails}
                          setcurrentItem={setcurrentItem}
                          setitemIndex={setitemIndex}
                          index={index}
                          getTickets={getTickets}
                          currentItem={currentItem}
                          refetchTickets={refetchTickets}
                          setshticketedit={setshticketedit}
                          delete={false}
                        />
                      );
                    })
                  ) : (
                    ""
                  )}
                </tbody>
              </table>
            </div>
          </div>

          <div className="tool-section-wrapper">
            <div className="chat-tool-wrapper">
              <div className="top">
                <h2>Nutzer Posteingang Austausch</h2>
                <p>
                  Im folgenden kannst du Dir geteilte Nachrichten von Sender zu
                  Empfänger ausgeben lassen, um Aussagen von Ticketerstellern zu
                  untermauern.
                </p>
              </div>

              <div className="bottom">
                <div className="chat-section">
                  <div className="top">
                    <DefaultDrop
                      label="Sender"
                      val={firstsender}
                      setvalue={setfirstsender}
                      optionChoose={Userdata}
                    />

                    <DefaultDrop
                      label="Empfänger"
                      val={firstreceiver}
                      setvalue={setfirstreceiver}
                      optionChoose={Userdata}
                    />
                  </div>

                  <div className="bottom">
                    {getFirstNotis && getFirstNotis.length > 0 ? (
                      getFirstNotis.map((note, index) => {
                        return <NotificationWrapper note={note} />;
                      })
                    ) : (
                      <div className="bottom-not-found">
                        <div className="icon">
                          <FontAwesomeIcon icon={faCommentDots} />
                        </div>

                        <p>Keine Nachricht gefunden.</p>
                      </div>
                    )}
                  </div>
                </div>

                <div className="icon">
                  <FontAwesomeIcon icon={faArrowRightArrowLeft} />
                </div>

                <div className="chat-section">
                  <div className="top">
                    <DefaultDrop
                      label="Sender"
                      val={secondsender}
                      setvalue={setsecondsender}
                      optionChoose={Userdata}
                    />

                    <DefaultDrop
                      label="Empfänger"
                      val={secondreceiver}
                      setvalue={setsecondreceiver}
                      optionChoose={Userdata}
                    />
                  </div>

                  <div className="bottom">
                    {getSecondNotis && getSecondNotis.length > 0 ? (
                      getSecondNotis.map((note, index) => {
                        return <NotificationWrapper note={note} />;
                      })
                    ) : (
                      <div className="bottom-not-found">
                        <div className="icon">
                          <FontAwesomeIcon icon={faCommentDots} />
                        </div>

                        <p>Keine Nachricht gefunden.</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="refund-tool-wrapper">
              <div className="top">
                <h2>Rückerstattung</h2>
                <p>
                  Nutze dieses Tool um einen Nutzer schnell und einfach sein
                  Geld wieder zu erstatten.
                </p>
              </div>

              <div className="bottom">
                <div className="b-grid">
                  <ServiceInput
                    label="Titel der Rückerstattung"
                    val={editTitle}
                    setvalue={seteditTitle}
                    important
                  />

                  <DefaultDrop
                    label="Nutzer"
                    val={editB}
                    setvalue={seteditB}
                    optionChoose={Userdata}
                    important
                  />

                  <ServiceTextArea
                    label="Grund der Rückerstattung"
                    val={editDescr}
                    setvalue={seteditDescr}
                  />
                </div>

                <button>Rückerstattung durchführen</button>
              </div>
            </div>
          </div>

          <div className="ticket-table-wrapper">
            <h2>Laufende Bestellungen</h2>

            <div className="ticket-table-sec">
              <div className="left">
                <table>
                  <thead>
                    <tr>
                      <th>Bestellungs ID</th>
                      <th>Bestellungs Title</th>
                      <th>Anbieter</th>
                      <th>Kunde</th>
                      <th>Rechnung erstellt am</th>
                      <th>Rechnung pausiert am</th>
                      <th>Rechnung fällig am</th>
                      <th>Service abgeschlossen</th>
                      <th>Status</th>
                    </tr>
                  </thead>

                  <tbody>
                    {activeOrderload ? (
                      <div className="loader">
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                      </div>
                    ) : activeOrdererr != "" ? (
                      <p>{activeOrdererr}</p>
                    ) : activeOrder && activeOrder.length > 0 ? (
                      activeOrder.map((order, index) => {
                        return <OrderItem order={order} />;
                      })
                    ) : (
                      ""
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

const NotificationWrapper = (props) => {
  const { apiUrl } = useContext(SkillContext);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(`${apiUrl}/api/users/images/${props.note.uimg}/type/users`);

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  return (
    <div className="notification-wrapper">
      <div className="left">
        {isImageLoading ? (
          <div className="loader">
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        ) : (
          <img src={Image} alt="user-img" loading="lazy" />
        )}
      </div>

      <div className="right">
        <div className="top">
          <p>{props.note.username}</p>
          <p>&#x2022;</p>
          <p>{props.note.nsenddate.split("T")[0]}</p>
        </div>

        <div className="bottom">
          <p>{props.note.ntext}</p>
        </div>
      </div>
    </div>
  );
};

const OrderItem = (props) => {
  const { apiUrl } = useContext(SkillContext);

  const billStart = new Date(props.order.billCreatedon).getTime(); //start
  let billEnd = new Date(new Date(billStart)).setDate(
    new Date(new Date(billStart)).getDate() + 30
  ); //end
  const paused = new Date(props.order.payment_paused_at).getTime();
  const DAysLeft = Math.floor((paused - billStart) / (24 * 60 * 60 * 1000));
  const billPauseEnd =
    props.order.payment_paused_at != "0000-00-00 00:00:00" &&
    props.order.payment_paused_at != null
      ? new Date(new Date(paused)).setDate(
          new Date(new Date(paused)).getDate() + (30 - 0)
        )
      : 0;

  const newEnd = new Date(billPauseEnd).toISOString();

  useEffect(() => {
    console.log("billstART: " + billStart);
    console.log(new Date(props.order.billCreatedon));
    console.log("billEnd: " + billEnd);
    console.log("paused: " + paused);
    console.log("DAysLeft: " + DAysLeft);
  }, []);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(
    `${apiUrl}/api/users/images/${props.order.Anbieterimg}/type/users`
  );

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  const {
    data: Image2,
    setdata: setImage2,
    isloading: isImageLoading2,
    refetch: refetchImage2,
  } = useGetData(`${apiUrl}/api/users/images/${props.order.uimg}/type/users`);

  useEffect(() => {
    console.log("received image = ", Image2);
    console.log(Image2);

    return () => {
      URL.revokeObjectURL(Image2);
    };
  }, [Image2]);

  return (
    <tr>
      <td># {props.order.said}</td>

      <td>{props.order.title}</td>

      <td>
        <div className="user-wrapper">
          {isImageLoading ? (
            <div className="loader">
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
          ) : (
            <img src={Image} alt="user-img1" loading="lazy" />
          )}

          <p> {props.order.Anbieter}</p>
        </div>
      </td>

      <td>
        <div className="user-wrapper">
          {isImageLoading2 ? (
            <div className="loader">
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
          ) : (
            <img src={Image2} alt="user-img2" loading="lazy" />
          )}

          <p> {props.order.username}</p>
        </div>
      </td>

      <td>
        {props.order.billCreatedon == "0000-00-00" ||
        props.order.billCreatedon == null
          ? "/"
          : new Date(props.order.billCreatedon)
              .toLocaleString("de-DE", {
                timeZone: "Europe/Berlin",
              })
              .split(",")[0]}
      </td>

      <td>
        {props.order.payment_paused_at == "0000-00-00 00:00:00" ||
        props.order.payment_paused_at == null
          ? "/"
          : new Date(props.order.payment_paused_at)
              .toLocaleString("de-DE", {
                timeZone: "Europe/Berlin",
              })
              .split(",")[0]}
      </td>

      <td>
        {props.order.billCreatedon == "0000-00-00" ||
        props.order.billCreatedon == null
          ? "/"
          : props.order.payment_paused_at != "0000-00-00 00:00:00" &&
            props.order.payment_paused_at != null
          ? props.order.billCreatedon == "0000-00-00" ||
            props.order.billCreatedon == null
            ? "Rechnungsdatum ausstehend"
            : new Date(newEnd)
                .toLocaleString("de-DE", {
                  timeZone: "Europe/Berlin",
                })
                .split(",")[0]
          : new Date(billEnd)
              .toLocaleString("de-DE", {
                timeZone: "Europe/Berlin",
              })
              .split(",")[0]}
      </td>

      <td>{props.order.orderstatus === 4 ? "Ja" : "Nein"}</td>

      <td>
        <span
          style={{
            backgroundColor: !props.order.paid
              ? "rgb(218, 107, 107)"
              : "rgb(132, 207, 132)",
            color: !props.order.paid ? "rgb(174, 53, 53)" : "rgb(44, 166, 44)",
          }}
        >
          {props.order.paid ? "Bezahlt" : "Unbezahlt"}
        </span>
      </td>
    </tr>
  );
};

export const EditIcon = (props) => {
  const [shIconDrop, setshIconDrop] = useState(false);

  return (
    <div
      className="icon"
      onClick={() => {
        setshIconDrop(!shIconDrop);
        props.setcurrentIndex && props.setcurrentIndex(props.index);
      }}
    >
      <FontAwesomeIcon icon={faEllipsisVertical} />
      <IconDrop shstate={shIconDrop} drop_items={props.DropItem} />
    </div>
  );
};

export const TicketDescOverlay = (props) => {
  const { userdata, apiUrl } = useContext(SkillContext);

  const [ActivityEdit, setActivityEdit] = useState([]);

  const [comment_edit_title, setcomment_edit_title] = useState("");
  const [comment_edit_text, setcomment_edit_text] = useState("");
  const [comment_edit_id, setcomment_edit_id] = useState(0);

  const [comment_title, setcomment_title] = useState("");
  const [comment_text, setcomment_text] = useState("");
  const [currCount, setcurrCount] = useState(5);

  const [currentIndex, setcurrentIndex] = useState(0);

  //update  Ticket Comments
  const {
    data: updatedActivity,
    isloading: loadingActivityupdate,
    err: aupdateerr,
    update: updateActivity,
  } = useUpdateJSONData(
    `${apiUrl}/api/services/ticket/${props.getTicket[0]?.ticket_id}/comments/${comment_edit_id}`,
    () => {
      props.refetchActivity();
    }
  );

  //delete  Ticket Comments
  const {
    data: deletedActivity,
    isloading: delActLoad,
    err: delActErr,
    deleteData: deleteActivity,
  } = useDeleteData(
    `${apiUrl}/api/services/ticket/${props.getTicket[0]?.ticket_id}/comments/${props.getActivity[currentIndex]?.comment_id}`,
    () => {
      props.refetchActivity();
    }
  );

  useEffect(() => {
    updatedActivity?.err
      ? toast.error(updatedActivity?.err)
      : toast.success(updatedActivity?.msg);
  }, [updatedActivity]);

  useEffect(() => {
    deletedActivity?.err
      ? toast.error(deletedActivity?.err)
      : toast.success(deletedActivity?.msg);
  }, [deletedActivity]);

  /*
  useEffect(() => {
    if (props.addedTicket?.err || props.addedTicket?.msg) {
      if (props.addedTicket?.err) {
        toast.error(props.addedTicket?.err);
      } else {
        toast.success(props.addedTicket?.msg);

        props.addActivity({
          comment_title: ` hat Aktivität '${props.getTicket[0]?.ticket_title}' begonnen.`,
          comment_text: null,
          comment_creator: userdata[0]?.usertoken,
          activity_type: "start",
          ticket_ref: "auto", //latest getTickets SAID + 1 is newest ticket ID
        });
      }
    }
  }, [props.addedTicket]);*/

  const [DropItem, setDropItem] = useState([]);

  const [shDelOv, setshDelOv] = useState(false);

  useEffect(() => {
    if (
      props.getActivity &&
      props.getActivity.length > 0 &&
      currentIndex != undefined
    ) {
      //IF ticket fdound
      setDropItem([
        {
          id: 0,
          icon: faPencil,
          title: "Bearbeiten",
          action: () => {
            setActivityEdit([props.getActivity[currentIndex]]);
            console.log("edit activated");
          },
        },

        {
          id: 1,
          icon: faTrashCan,
          title: "Löschen",
          action: () => {
            console.log("Aktivität gelöscht!");
            setshDelOv(true);
          },
        },
      ]);
    }
  }, [currentIndex, props.getActivity]);

  useEffect(() => {
    console.log("check aktivity edit: ", ActivityEdit);

    setcomment_edit_title(ActivityEdit[0]?.comment_title);
    setcomment_edit_text(ActivityEdit[0]?.comment_text);
    setcomment_edit_id(ActivityEdit[0]?.comment_id);
  }, [ActivityEdit]);

  return (
    <>
      <ConfirmDelete
        shov={shDelOv}
        setshov={setshDelOv}
        img="trash.jpg"
        title="Willst du diese Aktivität wirklich lösen ?"
        message="Diese Aktivität wird unwiederruflich gelöscht."
        action={() => {
          deleteActivity();
        }}
        isdelete
      />

      <div
        className="ticket-add-overlay"
        style={{ display: props.shoverlay ? "flex" : "none" }}
      >
        <div className="overlay-adder-wrapper">
          <div className="content-top">
            <div className="left">
              <h2>Ticket Details</h2>

              <div className="move-nxt-btns">
                <div
                  className="icon"
                  onClick={() => {
                    props.moveUp();
                    setActivityEdit([]);
                  }}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
                <div
                  className="icon"
                  onClick={() => {
                    props.moveDown();
                    setActivityEdit([]);
                  }}
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </div>
              </div>

              <p>
                <span>{props.itemIndex + 1}</span> von {props.getTicketsLEN}
              </p>
            </div>

            <div className="right">
              <div
                className="icon"
                onClick={() => {
                  props.setshoverlay(false);
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
          </div>

          {props.ticketLoading ? (
            <div className="loader">
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
          ) : props.ticketErr != "" ? (
            <p>{props.ticketErr}</p>
          ) : (
            <>
              <div className="processor-info-wrapper">
                <div className="top">
                  <h2>{props.getTicket[0]?.ticket_title}</h2>

                  <p>{props.getTicket[0]?.ticket_descr}</p>
                </div>

                <div className="bottom">
                  <div className="help-section">
                    <p>Bearbeiter</p>
                    <div className="help-right">
                      <h2>
                        {!props.getTicket[0]?.ticket_bearbeiter ||
                        props.getTicket[0]?.ticket_bearbeiter == "null" ? (
                          <div className="icon">
                            <FontAwesomeIcon icon={faMinus} />
                          </div>
                        ) : (
                          props.getTicket[0]?.b_name
                        )}
                      </h2>
                    </div>
                  </div>

                  <div className="help-section">
                    <p>Status</p>
                    <div className="help-right">
                      <span
                        style={{
                          backgroundColor:
                            props.getTicket[0]?.ticket_status === "Unbearbeitet"
                              ? "rgb(175, 60, 60)"
                              : props.getTicket[0]?.ticket_status ===
                                "in Bearbeitung"
                              ? " rgb(172, 173, 73)"
                              : "rgb(132, 207, 132)",
                          color:
                            props.getTicket[0]?.ticket_status === "Unbearbeitet"
                              ? "rgb(223, 109, 109)"
                              : props.getTicket[0]?.ticket_status ===
                                "in Bearbeitung"
                              ? "rgb(221, 223, 109)"
                              : "rgb(44, 166, 44)",
                        }}
                      >
                        {" "}
                        {props.getTicket[0]?.ticket_status}
                      </span>
                    </div>
                  </div>

                  <div className="help-section">
                    <p>Anzahl Aktivitäten</p>
                    <div className="help-right">
                      <h2>
                        {" "}
                        {props.getActivity.length > 0 && props.getActivity
                          ? props.getActivity.length
                          : 0}
                      </h2>
                    </div>
                  </div>

                  <div className="help-section">
                    <p>letzte Änderung</p>
                    <div className="help-right">
                      <h2>
                        {" "}
                        {
                          new Date(props.getTicket[0]?.ticket_last_updated)
                            .toLocaleString("de-DE", {
                              timeZone: "Europe/Berlin",
                            })
                            .split(",")[0]
                        }
                      </h2>
                    </div>
                  </div>
                </div>
              </div>

              <div className="information-wrapper">
                <div className="info-part">
                  <h2>Kunden Informationen</h2>

                  <div className="inform-item">
                    <p>Telefonnummer:</p>
                    <p>{props.getTicket[0]?.c_tel}</p>
                  </div>

                  <div className="inform-item">
                    <p>E-Mail:</p>
                    <p>{props.getTicket[0]?.c_email}</p>
                  </div>

                  <div className="inform-item">
                    <p>Standort:</p>
                    <p>{props.getTicket[0]?.c_loc}</p>
                  </div>
                </div>

                <div className="info-part">
                  <h2>Ticket Informationen</h2>

                  <div className="inform-item">
                    <p>TicketID:</p>
                    <p>#{props.getTicket[0]?.ticket_id}</p>
                  </div>

                  <div className="inform-item">
                    <p>Ersteller:</p>
                    <p>{props.getTicket[0]?.c_name}</p>
                  </div>
                </div>
              </div>

              <div className="activity-wrapper">
                <h2>Aktivitäten</h2>

                {ActivityEdit.length > 0 && (
                  <div className="edit-activity-wrapper" id="editwrap">
                    <div className="activit-add">
                      <ServiceInput
                        label="Title"
                        val={comment_edit_title}
                        setvalue={setcomment_edit_title}
                        important
                      />

                      <ServiceTextArea
                        label="Beschreibung"
                        val={comment_edit_text}
                        setvalue={setcomment_edit_text}
                      />
                    </div>

                    <div className="more-activity">
                      <button
                        onClick={() => {
                          setActivityEdit([]);
                        }}
                      >
                        Abbrechen
                      </button>

                      <button
                        onClick={() => {
                          console.log("BEARBEITEN");
                          updateActivity({
                            comment_title: comment_edit_title,
                            comment_text: comment_edit_text,
                          });

                          setActivityEdit([]);
                        }}
                      >
                        Bearbeiten
                      </button>
                    </div>
                  </div>
                )}

                <div className="activites-box-wrapper">
                  <div className="activities">
                    {props.activityLoad ? (
                      <div className="loader">
                        <FontAwesomeIcon icon={faCircleNotch} spin />
                      </div>
                    ) : props.getActivity && props.getActivity.length > 0 ? (
                      props.getActivity.map((activity, index) => {
                        if (index < currCount) {
                          return (
                            <ActivityItem
                              activity={activity}
                              DropItem={DropItem}
                              setcurrentIndex={setcurrentIndex}
                              index={index}
                            />
                          );
                        }
                      })
                    ) : (
                      <p>Keine Aktivität bis jetzt ...</p>
                    )}
                  </div>

                  {props.getTicket[0]?.ticket_status != "Abgeschlossen" && (
                    <div className="activit-add">
                      <ServiceInput
                        label="Title"
                        val={comment_title}
                        setvalue={setcomment_title}
                        important
                      />

                      <ServiceTextArea
                        label="Beschreibung"
                        val={comment_text}
                        setvalue={setcomment_text}
                      />
                    </div>
                  )}

                  <div className="more-activity">
                    <button
                      style={{
                        display:
                          props.getTicket[0]?.ticket_status != "Abgeschlossen"
                            ? "inline"
                            : "none",
                      }}
                      onClick={() => {
                        props.addActivity({
                          comment_title,
                          comment_text,
                          comment_creator: userdata[0]?.usertoken,
                          activity_type: "ongoing",
                          ticket_ref: props.getTicket[0]?.ticket_id,
                        });
                      }}
                    >
                      {" "}
                      {props.loadingActivityadd ? (
                        <div className="loader">
                          <FontAwesomeIcon icon={faCircleNotch} spin />
                        </div>
                      ) : (
                        "Aktivität hinzufügen"
                      )}
                    </button>
                    <button
                      style={{
                        display:
                          props.getActivity &&
                          props.getActivity.length > 5 &&
                          currCount != props.getActivity.length &&
                          ActivityEdit.length === 0
                            ? "inline"
                            : "none",
                      }}
                      onClick={() => {
                        currCount + 5 > props.getActivity.length
                          ? setcurrCount(props.getActivity.length)
                          : setcurrCount(currCount + 5);
                      }}
                    >
                      mehr Aktivität anzeigen
                    </button>
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const ActivityItem = (props) => {
  const { apiUrl, userdata } = useContext(SkillContext);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(
    `${apiUrl}/api/users/images/${props.activity.c_img}/type/users`
  );

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  return (
    <div className="activity-item">
      <div className="left">
        {props.activity.activity_type === "start" ? (
          <div className="icon">
            <StartUp02Icon size={20} />
          </div>
        ) : props.activity.activity_type === "end" ? (
          <div className="icon">
            <Share01Icon size={20} />
          </div>
        ) : isImageLoading ? (
          <div className="loader">
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        ) : (
          <img src={Image} alt="user-img" loading="lazy" />
        )}

        <div className="act-line"></div>
      </div>
      <div className="right">
        <div className="r-left">
          <h2>
            {props.activity.activity_type === "start" ||
            props.activity.activity_type === "end" ||
            props.activity.activity_type === "exception" ? (
              <span>{props.activity.c_name}</span>
            ) : (
              ""
            )}{" "}
            {props.activity.comment_title}
          </h2>
          <p
            style={{
              display:
                props.activity.comment_text ||
                props.activity.comment_text != "null"
                  ? "block"
                  : "none",
            }}
          >
            {props.activity.comment_text &&
            props.activity.comment_text != "null"
              ? props.activity.comment_text
              : ""}
          </p>
          <p> {props.activity.comment_created.split("T")[0]}</p>
        </div>

        <div className="r-right">
          {props.activity.activity_type != "start" &&
          props.activity.activity_type != "end" &&
          props.activity.activity_type != "exception" &&
          userdata[0]?.usertoken === props.activity.comment_creator ? (
            <EditIcon
              DropItem={props.DropItem}
              setcurrentIndex={props.setcurrentIndex}
              index={props.index}
            />
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
