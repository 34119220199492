import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Css/ticket.css";
import {
  faAdd,
  faArrowLeft,
  faArrowRight,
  faArrowUpRightFromSquare,
  faChevronDown,
  faChevronUp,
  faCircleNotch,
  faCommentDots,
  faCrown,
  faEllipsis,
  faEllipsisVertical,
  faFlag,
  faM,
  faMailForward,
  faMinus,
  faPencil,
  faScrewdriverWrench,
  faStar,
  faTentArrowLeftRight,
  faTicket,
  faTrashCan,
  faTruckFast,
  faUser,
  faUserCog,
  faWandMagicSparkles,
  faXmark,
  faXmarkSquare,
} from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { ServiceDrop, ServiceInput, ServiceTextArea } from "./ServiceAdd";
import { SkillContext } from "./App";
import { useGetData } from "./Hooks/getData";
import { useNavigate } from "react-router-dom";
import {
  BubbleChatEditIcon,
  PencilEdit01Icon,
  Share01Icon,
  StartUp02Icon,
} from "hugeicons-react";
import { usePostJSONData } from "./Hooks/postjsonData";
import { toast } from "react-toastify";
import { useUpdateJSONData } from "./Hooks/updatejsonData";
import { useDeleteData } from "./Hooks/deleteData";
import { ScrollLink } from "react-scroll";
import { TicketDescOverlay } from "./TicketDashboard";

export const UserTicket = () => {
  const { isloggedin, setisloggedin, userdata, apiUrl } =
    useContext(SkillContext);

  const navigate = useNavigate();

  /*CHECK IF USER IS LOGGEDIN*/
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );

  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  const [ticketcreator, setticcketcreator] = useState("");

  const [shticketoption, setshticketoption] = useState(false);

  const [actionChoose, setactionChoose] = useState(0);

  const [astep, setastep] = useState(0);

  const [shtoverlay, setshtoverlay] = useState(false);

  const [shticketdetails, setshticketdetails] = useState(false);

  const ActionItem = [
    {
      id: 0,
      title: "Rückerstattung",
      icon: faTentArrowLeftRight,
    },
    {
      id: 1,
      title: "Serviceproblem",
      icon: faTruckFast,
    },
    {
      id: 2,
      title: "Anderes Anliegen",
      icon: faWandMagicSparkles,
    },
  ];

  //Fetch loggedin Users services
  const { data: orders, refetch: refetchOrders } = useGetData(
    `${apiUrl}/api/services/users/${userdata[0]?.usertoken}/pending/services`
  );

  useEffect(() => {
    console.log(orders);
  }, [orders]);

  const [title, settitle] = useState("");
  const [descr, setdescr] = useState("");
  const [choosed, setchoosed] = useState("");
  const [OrderendDate, setOrderendDate] = useState("");
  const [err, seterr] = useState(false);

  useEffect(() => {
    console.log("VALUES");
    console.log(title);
    console.log(descr);
    console.log(choosed);
    if (orders && orders.length > 0)
      setOrderendDate(orders.find((order) => order.said == choosed));
  }, [title, descr, choosed]);

  useEffect(() => {
    console.log("Todays Date");
    console.log(new Date().getTime());
    const newDate = new Date().getTime();

    console.log("End Order date:");

    console.log("created on:");
    console.log(OrderendDate?.billCreatedon);
    //if billcreatedOn is null or undefined we set oldDate to todaysDate -< so we dont get unsupported error
    const oldDate = OrderendDate?.billCreatedon
      ? new Date(OrderendDate?.billCreatedon).getTime()
      : newDate;

    const oldDate2Weeks = oldDate + 30 * 24 * 60 * 60 * 1000; //30 Days

    console.log("Date Difference:");
    console.log(new Date(oldDate2Weeks));

    if (newDate > oldDate2Weeks) {
      //Bestellung/Rechnung gibt es seit länger als 14 Tagen
      console.log("Support abgelaufen");
      seterr(true);
    } else {
      console.log("Support aktiv");
      seterr(false);
    }
  }, [OrderendDate]);

  //Fetch  Users Tickets
  const {
    data: getTickets,
    isloading: ticketsLoading,
    err: ticketserr,
    refetch: refetchTickets,
  } = useGetData(
    `${apiUrl}/api/services/users/${userdata[0]?.usertoken}/support/tickets`
  );

  //add new Ticket
  const {
    data: addedTicket,
    isloading: addTikcetloading,
    err: addTicketerr,
    addjsonData: addTicket,
  } = usePostJSONData(
    `${apiUrl}/api/services/users/${userdata[0]?.usertoken}/support/tickets`,
    () => {
      //add Standard Start Comment and refetch Tickets
      refetchTickets();
    }
  );

  useEffect(() => {
    console.log("getTickets: ", getTickets);
    console.log("getTickets_DATA: ", userdata);
  }, [getTickets]);

  useEffect(() => {
    console.log("addedTicket: ", addedTicket);

    if (addedTicket.length === 0) return;

    if (addedTicket?.err || addedTicket?.msg) {
      if (addedTicket?.err) {
        toast.error(addedTicket?.err);
      } else {
        toast.success(addedTicket?.msg);

        addActivity({
          comment_title: " hat Aktivität begonnen.",
          comment_text: null,
          comment_creator: userdata[0]?.usertoken,
          activity_type: "start",
          ticket_ref: "auto", //latest getTickets SAID + 1 is newest ticket ID
        });
      }
    }
  }, [addedTicket]);

  useEffect(() => {
    if (userdata.length > 0) {
      refetchTickets();
      refetchOrders();
    }
  }, [userdata]);

  const [currentItem, setcurrentItem] = useState(0);

  const [itemIndex, setitemIndex] = useState(0);

  //Fetch Single Users Tickets
  const {
    data: getTicket,
    isloading: ticketLoading,
    err: ticketErr,
    refetch: refetchTicket,
  } = useGetData(
    `${apiUrl}/api/services/user/${userdata[0]?.usertoken}/ticket/${currentItem}`
  );

  //Update Single Users Tickets
  const {
    data: updatedTicket,
    isloading: ticketupdateLoading,
    err: ticketupdateErr,
    update: updateTicket,
  } = useUpdateJSONData(
    `${apiUrl}/api/services/user/${userdata[0]?.usertoken}/ticket/${currentItem}`,
    () => {
      refetchTicket();
      refetchTickets();
    }
  );

  useEffect(() => {
    if (updatedTicket?.err || updatedTicket?.msg) {
      if (updatedTicket?.err) {
        toast.error(updatedTicket?.err);
      } else {
        toast.success(updatedTicket?.msg);
      }
    }
  }, [updatedTicket]);

  //Fetch  Single Tickets Comments
  const {
    data: getActivity,
    isloading: activityLoad,
    err: actErr,
    refetch: refetchActivity,
  } = useGetData(
    `${apiUrl}/api/services/ticket/${getTicket[0]?.ticket_id}/comments/null`
  );

  //add  Ticket Comments
  const {
    data: addedActivity,
    isloading: loadingActivityadd,
    err: aadderr,
    addjsonData: addActivity,
  } = usePostJSONData(
    `${apiUrl}/api/services/ticket/null/comments/null`,
    () => {
      refetchActivity();

      //update Ticket???
      updateTicket({
        ticket_last_updated: new Date(),
      });
    }
  );

  useEffect(() => {
    addedActivity?.err
      ? toast.error(addedActivity?.err)
      : toast.success(addedActivity?.msg);
  }, [addedActivity]);

  const [editID, seteditID] = useState("");
  const [editTitle, seteditTitle] = useState("");
  const [editDescr, seteditDescr] = useState("");
  const [editB, seteditB] = useState("");
  const [editStatus, seteditStatus] = useState("");

  useEffect(() => {
    if (userdata.length > 0 && currentItem != undefined) {
      refetchTicket();
    }
  }, [userdata, currentItem]);

  useEffect(() => {
    if (getTicket.length > 0) {
      seteditID(getTicket[0]?.ticket_id);
      seteditTitle(getTicket[0]?.ticket_title);
      seteditDescr(getTicket[0]?.ticket_descr);
      seteditB(getTicket[0]?.b_name);
      seteditStatus(getTicket[0]?.ticket_status);

      refetchActivity();
    }
  }, [getTicket]);

  useEffect(() => {
    console.log("getActivity:", getActivity);
  }, [getActivity]);

  //index update getTickets[index]

  useEffect(() => {
    setcurrentItem(getTickets[itemIndex]?.ticket_id);
  }, [itemIndex]);

  const [shticketedit, setshticketedit] = useState(false);

  const moveUp = () => {
    if (itemIndex > 0) {
      //0  -> 2
      setitemIndex(itemIndex - 1);
    } else {
      setitemIndex(getTickets.length - 1);
    }

    //resetDataValues for Edit
    seteditID("");
    seteditTitle("");
    seteditDescr("");
    seteditB("");
    seteditStatus("");
  };

  const moveDown = () => {
    if (itemIndex < getTickets.length - 1) {
      //0  -> 2
      setitemIndex(itemIndex + 1);
    } else {
      setitemIndex(0);
    }

    //resetDataValues for Edit
    seteditID("");
    seteditTitle("");
    seteditDescr("");
    seteditB("");
    seteditStatus("");
  };

  const statusChoose = [
    {
      said: "Abgeschlossen",
      title: "Abgeschlossen",
    },
  ];

  useEffect(() => {
    //CHeck Ticket that is currently choosed???

    if (getTicket.length === 0 || getTicket?.err) {
      console.log("ticket not found", getTicket);
    } else {
      console.log("ticket found", getTicket);

      //Checck if Ticket is done
      if (getTicket[0]?.ticket_status === "Abgeschlossen") {
        console.log("ticket done");

        if (!getTicket[0]?.isClosed) {
          console.log("ticket not closed, closing ....");

          addActivity({
            comment_title: "hat Ticket abgeschlossen.",
            comment_text: null,
            comment_creator: getTicket[0]?.ticket_creator,
            activity_type: "end",
            ticket_ref: getTicket[0]?.ticket_id,
          });

          updateTicket({
            isClosed: 1,
            ticket_last_updated: new Date(),
          });
        } else {
          console.log("ticket already closed");
        }
      } else {
        console.log("ticket not done");
        //ticket is not done -> check isClose
        getTicket[0]?.isClosed &&
          updateTicket({
            isClosed: 0,
            ticket_last_updated: new Date(),
          });
      }

      //if Ticket is not done , and we have a ticker proccessor -< we set ticket to 'in Bearbeitung'
      if (
        getTicket[0]?.ticket_status === "Unbearbeitet" &&
        getTicket[0]?.ticket_bearbeiter != "null" &&
        getTicket[0]?.ticket_bearbeiter
      ) {
        console.log("update status: " + true);

        updateTicket({
          ticket_status: "in Bearbeitung",
          ticket_last_updated: new Date(),
        });
      }
    }
  }, [getTicket]);

  const DropItem = [
    {
      id: 0,
      icon: faUser,
      title: "Problem als Kunde",
      action: () => {
        setticcketcreator(0);
        setshticketoption(false);
        setshtoverlay(true);
        setastep(0);
      },
    },

    {
      id: 1,
      icon: faScrewdriverWrench,
      title: "Problem als Anbieter",
      action: () => {
        setticcketcreator(0);
        setshticketoption(false);
        setshtoverlay(true);
        setastep(0);
      },
    },
  ];

  return (
    <>
      <div
        className="ticket-add-overlay"
        style={{ display: shtoverlay ? "flex" : "none" }}
      >
        <div className="overlay-adder-wrapper">
          <div
            className="top"
            onClick={() => {
              setshtoverlay(false);
            }}
          >
            <div className="icon">
              <FontAwesomeIcon icon={faArrowLeft} />
            </div>
            <p>Schließen</p>
          </div>

          <div
            className="chosse-action-wrapper"
            style={{ display: astep === 0 ? "block" : "none" }}
          >
            <h2>Wähle Ticket Art</h2>
            <p>
              Aus welchem Grund möchtest du ein Ticket erstellen? Gib uns hier
              deinen Grund an.
            </p>

            <div className="action-wrapper">
              {ActionItem.map((aitem, index) => {
                return (
                  <div
                    className="action-item"
                    onClick={() => {
                      setactionChoose(aitem.id);
                    }}
                    style={{
                      backgroundColor:
                        actionChoose === aitem.id
                          ? "var(--main-bg-accent-light)"
                          : "var(--dark-bg-hov)",
                    }}
                  >
                    <div className="icon">
                      <FontAwesomeIcon icon={aitem.icon} />
                    </div>
                    <p>{aitem.title}</p>
                  </div>
                );
              })}
            </div>
          </div>

          <div
            className="content-section"
            style={{ display: astep > 0 ? "block" : "none" }}
          >
            <div className="top">
              <div className="icon">
                <FontAwesomeIcon icon={ActionItem[actionChoose]?.icon} />
              </div>
              <p>{ActionItem[actionChoose]?.title}</p>
            </div>

            <ServiceInput
              label="Titel des Tickets"
              setvalue={settitle}
              important
            />
            <ServiceTextArea
              label="Beschreibe dein Problem ..."
              setvalue={setdescr}
              important
            />
            <DefaultDrop
              label="Wähle die beschriebene Bestellung"
              setvalue={setchoosed}
              optionChoose={orders}
              important
              shId
            />
            {err && (
              <div className="err-msg">
                <p>
                  Rückerstattung, sowie Support für diese Bestellung ist nicht
                  mehr möglich (30 Tage Frist abgelaufen).
                </p>
              </div>
            )}
          </div>

          <div className="bottom">
            <button
              style={{ display: astep > 0 ? "flex" : "none" }}
              onClick={() => {
                astep > 0 && setastep(astep - 1);
              }}
            >
              <div className="icon">
                <FontAwesomeIcon icon={faArrowLeft} />
              </div>
              Zurück
            </button>

            <button
              onClick={() => {
                astep < 1
                  ? setastep(astep + 1)
                  : addTicket({
                      ticket_title: title,
                      ticket_descr: descr,
                      ticket_status: "Unbearbeitet",
                      ticket_bearbeiter: null,
                      ticket_creator: userdata[0]?.usertoken,
                      ticket_type:
                        actionChoose === 0
                          ? "Rückerstattung"
                          : actionChoose === 1
                          ? "Serviceproblem"
                          : "Anderes Anliegen",
                      creator_type: ticketcreator === 0 ? "Kunde" : "Anbieter",
                      order_id_ref: choosed,
                    });
              }}
              style={{
                opacity: err && astep > 0 ? "0.6" : "1",
                pointerEvents: err && astep > 0 ? "none" : "all",
              }}
            >
              {addTikcetloading ? (
                <div className="loader">
                  <FontAwesomeIcon icon={faCircleNotch} spin />
                </div>
              ) : astep < 1 ? (
                "Weiter"
              ) : (
                "Abschicken"
              )}
              <div className="icon">
                <FontAwesomeIcon
                  icon={astep < 1 ? faArrowRight : faMailForward}
                />
              </div>
            </button>
          </div>
        </div>
      </div>

      <TicketDescOverlay
        shoverlay={shticketdetails}
        setshoverlay={setshticketdetails}
        itemIndex={itemIndex}
        setitemIndex={setitemIndex}
        getTicket={getTicket}
        ticketLoading={ticketLoading}
        ticketErr={ticketErr}
        getTicketsLEN={getTickets.length > 0 ? getTickets.length : 0} // getTickets.length > 0 ? getTickets.length : 0
        addedTicket={addedTicket}
        moveUp={moveUp}
        moveDown={moveDown}
        refetchActivity={refetchActivity}
        addActivity={addActivity}
        loadingActivityadd={loadingActivityadd}
        getActivity={getActivity}
        activityLoad={activityLoad}
      />

      <div
        className="ticket-add-overlay"
        style={{ display: shticketedit ? "flex" : "none" }}
      >
        <div className="overlay-adder-wrapper">
          <div className="content-top">
            <div className="left">
              <h2>Ticket Bearbeiten</h2>

              <div className="move-nxt-btns">
                <div
                  className="icon"
                  onClick={() => {
                    moveUp();
                  }}
                >
                  <FontAwesomeIcon icon={faChevronDown} />
                </div>
                <div
                  className="icon"
                  onClick={() => {
                    moveDown();
                  }}
                >
                  <FontAwesomeIcon icon={faChevronUp} />
                </div>
              </div>

              <p>
                <span>{itemIndex + 1}</span> von {getTickets.length}
              </p>
            </div>

            <div className="right">
              <div
                className="icon"
                onClick={() => {
                  setshticketedit(false);
                }}
              >
                <FontAwesomeIcon icon={faXmark} />
              </div>
            </div>
          </div>

          {ticketLoading ? (
            <div className="loader">
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
          ) : ticketErr != "" ? (
            <p>{ticketErr}</p>
          ) : (
            <div className="content-grid">
              <ServiceInput label="TicketID" val={"#" + editID} disabled />

              <ServiceInput
                label="Titel"
                val={editTitle}
                setvalue={seteditTitle}
              />

              <ServiceTextArea
                label="Beschreibung"
                val={editDescr}
                setvalue={seteditDescr}
              />

              <ServiceInput
                label="Bearbeiter"
                val={editB}
                setvalue={seteditB}
                disabled
              />

              <DefaultDrop
                label="Status"
                val={editStatus}
                setvalue={seteditStatus}
                optionChoose={statusChoose}
              />
            </div>
          )}

          <button
            onClick={() => {
              updateTicket({
                ticket_title: editTitle,
                ticket_descr: editDescr,
                ticket_status: editStatus,
                ticket_last_updated: new Date(),
              });
            }}
          >
            {ticketupdateLoading ? (
              <div className="loader">
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            ) : (
              "Bestätigen"
            )}
          </button>
        </div>
      </div>

      <div className="ticket-wrapper">
        <div className="ticket-center">
          <div className="top">
            <div className="left">
              <h2>Ticket System</h2>
              <p>
                Finde alle deine aktiven Tickets hier. Bitte erstelle nur ein
                Support Ticket, falls sich das Problem nicht mit dem Kunden oder
                Anbieter direkt lösen lässt.
              </p>
            </div>

            <div className="ticket-create">
              <div className="create-box">
                <button
                  onClick={() => {
                    setshticketoption(!shticketoption);
                  }}
                >
                  Ticket erstellen
                </button>

                <IconDrop shstate={shticketoption} drop_items={DropItem} />
              </div>
            </div>
          </div>

          <div className="ticket-table-sec">
            <div className="left">
              <table>
                <thead>
                  <tr>
                    <th>Ticket ID</th>
                    <th>Ticket Title</th>
                    <th>Bearbeiter</th>
                    <th>letzte Veränderung</th>
                    <th>Status</th>
                    <th>Aktion</th>
                  </tr>
                </thead>

                <tbody>
                  {ticketsLoading ? (
                    <div className="loader">
                      <FontAwesomeIcon icon={faCircleNotch} spin />
                    </div>
                  ) : ticketserr != "" ? (
                    <p>{ticketserr}</p>
                  ) : getTickets && getTickets.length > 0 ? (
                    getTickets.map((ticket, index) => {
                      return (
                        <TableItem
                          ticket={ticket}
                          setshticketdetails={setshticketdetails}
                          setcurrentItem={setcurrentItem}
                          setitemIndex={setitemIndex}
                          index={index}
                          getTickets={getTickets}
                          currentItem={currentItem}
                          refetchTickets={refetchTickets}
                          setshticketedit={setshticketedit}
                          delete={true}
                        />
                      );
                    })
                  ) : (
                    <td colSpan={6}>
                      <div className="fetch-err-box">
                        <div className="icon">
                          <FontAwesomeIcon icon={faTicket} />
                        </div>
                        <p>Kein Ticket gefunden</p>
                      </div>
                    </td>
                  )}
                </tbody>
              </table>
            </div>

            <div className="table-desc"></div>
          </div>
        </div>
      </div>
    </>
  );
};

export const TableItem = (props) => {
  const { userdata, apiUrl } = useContext(SkillContext);

  //Delete Single Users Tickets
  const {
    data: deletedTicket,
    isloading: ticketdeleteLoading,
    err: ticketdeleteErr,
    deleteData: deleteTicket,
  } = useDeleteData(
    `${apiUrl}/api/services/user/${userdata[0]?.usertoken}/ticket/${props.ticket.ticket_id}`,
    () => {
      props.refetchTickets();
    }
  );

  useEffect(() => {
    if (deletedTicket?.err || deletedTicket?.msg) {
      if (deletedTicket?.err) {
        toast.error(deletedTicket?.err);
      } else {
        toast.success(deletedTicket?.msg);
      }
    }
  }, [deletedTicket]);

  useEffect(() => {
    console.log("TICKET INFOS");
    console.log(props.ticket);
  }, [props.ticket]);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(`${apiUrl}/api/users/images/${props.ticket.b_img}/type/users`);

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  return (
    <tr>
      <td>
        <div className="id-wrapper">
          <div
            className="icon"
            style={{
              display: props.ticket.c_premium ? "flex" : "none",
            }}
          >
            <FontAwesomeIcon icon={faStar} />
          </div>
          <p> # {props.ticket.ticket_id}</p>
        </div>
      </td>
      <td>{props.ticket.ticket_title}</td>
      <td>
        {!props.ticket.ticket_bearbeiter ||
        props.ticket.ticket_bearbeiter == "null" ? (
          <div className="icon">
            <FontAwesomeIcon icon={faMinus} />
          </div>
        ) : (
          <div className="user-wrapper">
            {isImageLoading ? (
              <div className="loader">
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            ) : (
              <img src={Image} alt="user-img" loading="lazy" />
            )}

            <p> {props.ticket.b_name}</p>
          </div>
        )}
      </td>
      <td>
        {
          new Date(props.ticket.ticket_last_updated)
            .toLocaleString("de-DE", {
              timeZone: "Europe/Berlin",
            })
            .split(",")[0]
        }
      </td>
      <td>
        <span
          style={{
            backgroundColor:
              props.ticket.ticket_status === "Unbearbeitet"
                ? "rgb(175, 60, 60)"
                : props.ticket.ticket_status === "in Bearbeitung"
                ? " rgb(172, 173, 73)"
                : "rgb(132, 207, 132)",
            color:
              props.ticket.ticket_status === "Unbearbeitet"
                ? "rgb(223, 109, 109)"
                : props.ticket.ticket_status === "in Bearbeitung"
                ? "rgb(221, 223, 109)"
                : "rgb(44, 166, 44)",
          }}
        >
          &#x2022; {props.ticket.ticket_status}
        </span>
      </td>
      <td>
        <div className="icon-wrapper">
          <div
            className="icon"
            onClick={() => {
              deleteTicket();
            }}
            style={{ display: props.delete ? "flex" : "none" }}
          >
            {ticketdeleteLoading ? (
              <div className="loader">
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            ) : (
              <FontAwesomeIcon icon={faTrashCan} />
            )}
          </div>
          <div
            className="icon"
            onClick={() => {
              console.log("INDEX: " + props.index);
              console.log("TICKET_ID: " + props.ticket.ticket_id);

              props.setcurrentItem(props.ticket.ticket_id);
              props.setitemIndex(props.index);
              props.setshticketedit(true);
            }}
            style={{
              //let user still edit if he isAdmin = true
              opacity:
                props.ticket.ticket_status === "Abgeschlossen" &&
                !userdata[0]?.isAdmin
                  ? "0.6"
                  : "1",
              pointerEvents:
                props.ticket.ticket_status === "Abgeschlossen" &&
                !userdata[0]?.isAdmin
                  ? "none"
                  : "all",
            }}
          >
            <FontAwesomeIcon icon={faPencil} />
          </div>

          <button
            onClick={() => {
              props.setshticketdetails(true);
              props.setcurrentItem(props.ticket.ticket_id);
              props.setitemIndex(props.index);
            }}
          >
            Anschauen
          </button>
        </div>
      </td>
    </tr>
  );
};

export const DefaultDrop = (props) => {
  return (
    <div className="input-wrapper">
      <label htmlFor={props.name && props.name}>
        {props.label && props.label} <span>{props.important && "*"}</span>
      </label>
      <select
        placeholder={props.placeholder && props.placeholder}
        name={props.name && props.name}
        onChange={(e) => {
          props.setvalue && props.setvalue(e.target.value);
        }}
        value={props.val && props.val}
        disabled={props.disabled ? true : false}
      >
        <option value={""}>Auswählen</option>

        {props.optionChoose && props.optionChoose.length > 0
          ? props.optionChoose.map((opt) => {
              return (
                <option value={opt?.said}>
                  {props.shId ? "#" + opt?.said : opt?.title}
                </option>
              );
            })
          : ""}
      </select>
    </div>
  );
};

export const IconDrop = (props) => {
  return (
    <div
      className="icon-drop"
      style={{
        display: props.shstate ? "block" : "none",
      }}
    >
      {props.drop_items && props.drop_items.length > 0
        ? props.drop_items.map((item, index) => {
            return (
              <div
                className="drop-item"
                onClick={() => {
                  item.action();
                }}
              >
                <div className="icon">
                  {" "}
                  {item.icon && <FontAwesomeIcon icon={item.icon} />}
                </div>
                <p>{item.title}</p>
              </div>
            );
          })
        : ""}
    </div>
  );
};
