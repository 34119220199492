import { useEffect, useState } from "react";

export function useGetData(url) {
  const [data, setdata] = useState([]);
  const [err, seterr] = useState("");
  const [isloading, setisloading] = useState(false);

  useEffect(() => {
    Datafetch();
  }, []);

  const Datafetch = async () => {
    try {
      setisloading(true);

      const data = await fetch(url, { method: "GET", credentials: "include" });

      const contentType = data.headers.get("Content-Type");

      let resdata;
      if (contentType && contentType.includes("image/")) {
        // If it's an image or blob, parse it as a blob
        resdata = await data.blob();
        setdata(URL.createObjectURL(resdata));
      } else {
        // If it's JSON, parse it as JSON
        resdata = await data.json();
        setdata(resdata);
      }

      if (
        resdata.status === 403 &&
        window.location != "http://localhost:3000/unauthorized"
      ) {
        window.location.href = "/unauthorized";
      }
    } catch (error) {
      seterr(error);
    } finally {
      //Data has loaded sucessfully
      setisloading(false);
    }
  };

  const refetch = () => {
    Datafetch();
  };

  return { data, setdata, err, isloading, refetch };
}
