import {
  faArrowUpWideShort,
  faCheck,
  faPhoneVolume,
  faSignHanging,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Css/premium.css";
import { Label } from "./Service";
import { useGetData } from "./Hooks/getData";
import { useContext, useEffect, useState } from "react";
import { SkillContext } from "./App";
import { useNavigate } from "react-router-dom";
import { usePostJSONData } from "./Hooks/postjsonData";
import { toast } from "react-toastify";

export const Premium = () => {
  const { isloggedin, setisloggedin, userdata, apiUrl } = useContext(SkillContext);

  //CHECK IF USER IS LOGGEDIN
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );
  const navigate = useNavigate();

  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  const { data: createdLink, addjsonData: createPaymentLink } = usePostJSONData(
    `${apiUrl}/api/payments/create-payment_link`
  );

  useEffect(() => {
    if (createdLink?.msg) {
      toast.success(createdLink?.msg);
      window.location.href = createdLink?.url;
    } else {
      toast.error(createdLink?.err);
    }
  }, [createdLink]);

  const PremiumPlans = [
    {
      pid: 1,
      price: 4.5,
      title: "Premium (monatlich)",
      desc: "      Sichere Dir die oben aufgelisteten Vorteile noch heute. Teste es jetzt eine Woche Gratis!",
      link: `https://buy.stripe.com/test_5kA7vr2jq4NS7HW6os?prefilled_email=${userdata[0]?.email}`,
      priceID: "price_1Q05VyGXIA5cCC89q27k18UK",
    },
    {
      pid: 2,
      price: 60.42,
      title: "Premium (jährlich)",
      desc: "Sichere Dir die oben aufgelisteten Vorteile noch heute. Teste es jetzt eine Woche Gratis!",
      link: `https://buy.stripe.com/test_4gwaHD4ry4NS2nC8wz?prefilled_email=${userdata[0]?.email}`,
      priceID: "price_1Q05fKGXIA5cCC89Szc9KFtv",
    },
  ];

  const [per, setper] = useState(0);

  useEffect(() => {

    const monthpay = Math.round(((Math.round((4.5 + Math.round((4.5 - 4.5 * 0.81) * 100) / 100) * 100) / 100) * 12) * 100) / 100;
    const yearpay = Math.round((  60.42 + Math.round((  60.42 -   60.42 * 0.81) * 100) / 100) * 100) / 100;
    setper(Math.round((((yearpay - monthpay) / monthpay) * 100)));

    console.log("monthpay: ", monthpay)
    console.log("yearpay: ", yearpay)

    console.log("per: ", per)
  }, [])

  return (
    <div className="prem-wrapper">
      <h2>Upgrade auf Premium</h2>
      <p> Sichere Dir jetzt viele Vorteile mit unserem Premium Abo. </p>

      <div className="premium-benefits">
        <div className="icon">
          <FontAwesomeIcon icon={faSignHanging} />
          <Label text="keine Werbung" />
        </div>
        <div className="icon">
          <FontAwesomeIcon icon={faArrowUpWideShort} />
          <Label text="Services werden höher angeboten" />
        </div>
        <div className="icon">
          <FontAwesomeIcon icon={faCheck} />
          <Label text="Verifiziert" />
        </div>
        <div className="icon">
          <FontAwesomeIcon icon={faPhoneVolume} />
          <Label text="schnellerer Support" />
        </div>
      </div>

      <div className="premium-item-wrapper">
        {PremiumPlans.map((item) => {

const fullprice = Math.round((item.price + Math.round((item.price - item.price * 0.81) * 100) / 100) * 100) / 100;

          return (
            <div className="premium-item">
              <p>{item.pid === 1 ? "MONAT" : "JAHR"}</p>
              <h2>{fullprice}€  </h2>          
              <p>(inkl. 19 % MwSt.)</p>
              <p>{item.desc}</p>

              {userdata[0]?.premium &&
              userdata[0]?.premium_product_ref == item.priceID ? (
                <button
                  onClick={() => {
                    window.location.href = `https://billing.stripe.com/p/login/test_4gw00xbFw3331ZC9AA?prefilled_email=${userdata[0]?.email}`;
                  }}
                >
                  Subscription anpassen
                </button>
              ) : (
                <button
                  onClick={() => {
                    createPaymentLink({
                      email: userdata[0]?.email,
                      priceID: item.priceID,
                    });
                    // window.location.href = item.link;
                  }}
                >
                  Starte jetzt
                </button>
              )}

              <span
                style={{ display: item.pid === 2 ? "inline-block" : "none" }}
              >
                Spare {per} %
              </span>
            </div>
          );
        })}
      </div>
    </div>
  );
};
