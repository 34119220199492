import { useContext, useEffect, useState } from "react";
import "./Css/auth.css";
import { Notification, ServiceInput } from "./ServiceAdd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { usePostData } from "./Hooks/postData";
import { usePostJSONData } from "./Hooks/postjsonData";
import { SkillContext } from "./App";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const Login = () => {
  const [firstname, setfirstname] = useState("");
  const [lastname, setlastname] = useState("");
  const [password, setpassword] = useState("");
  const [shnotify, setshnotify] = useState(false);
  const { setisloggedin, setuserdata, apiUrl } = useContext(SkillContext);
  const navigate = useNavigate();

  //Fetch the Service of this serviceID
  const { data, err, isloading, addjsonData } = usePostJSONData(
    `${apiUrl}/api/users/login`
  );

  //${apiUrl}/api/users/info/:usertoken

  useEffect(() => {
    console.log(data);

    console.log("err?");
    console.log(data?.err);

    console.log("msg?");
    console.log(data?.msg);

    //when loggedin sucessfully set loggedin state to true and sent me to main page
    if (data?.msg) {
      console.log("Logged in sucessfully!");
      setisloggedin(true);

      const timer = setTimeout(() => {
        navigate("/search/services");
      }, 2500);
    }

    console.log(data);
  }, [data]);

  useEffect(() => {
    data?.err ? toast.error(data?.err) : toast.success(data?.msg);
  }, [data]);

  return (
    <div className="auth-wrapper">
      <div className="auth-box">
        <div className="top">
          <h1>Anmelden</h1>
          <p>Melde dich kostenlos an - schnell und einfach.</p>
        </div>

        <div className="box-grid">
          <ServiceInput
            label="Vorname"
            type="text"
            name="vname"
            setvalue={setfirstname}
            important={true}
          />

          <ServiceInput
            label="Nachname"
            type="text"
            name="lname"
            setvalue={setlastname}
            important={true}
          />

          <ServiceInput
            label="Passwort"
            type="password"
            name="pword"
            setvalue={setpassword}
            important={true}
          />
        </div>

        <button
          onClick={() => {
            addjsonData({ firstname, lastname, password });
          }}
        >
          {isloading ? (
            <div className="loader">
              <FontAwesomeIcon icon={faCircleNotch} spin />
            </div>
          ) : (
            "Anmelden"
          )}
        </button>

        <div className="auth-option">
          <p>Noch kein Account?</p>
          <a href="/auth/signup">Jetzt Account erstellen!</a>
        </div>

        <p>
          Beim Anmelden stimmen Sie unseren{" "}
          <a href="#">Privatsphäreeinstellungen</a> und unserem{" "}
          <a href="#">Datenschutz</a> zu.
        </p>
      </div>
    </div>
  );
};
