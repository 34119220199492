import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Css/serviceadd.css";
import {
  faAdd,
  faAlignCenter,
  faAlignLeft,
  faAlignRight,
  faArrowRight,
  faBold,
  faBox,
  faBoxesPacking,
  faBoxesStacked,
  faBrush,
  faCheck,
  faCode,
  faEdit,
  faGripLines,
  faHeader,
  faHighlighter,
  faImage,
  faImages,
  faItalic,
  faLink,
  faListOl,
  faListUl,
  faPencil,
  faTurnDown,
  faUnderline,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { ContentTable, FAQWrapper, StripeOverlay } from "./ServiceDescription";
import { useContext, useEffect, useState } from "react";
import { usePostData } from "./Hooks/postData";
import { SkillContext } from "./App";
import { useGetData } from "./Hooks/getData";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Editor,
  EditorState,
  convertToRaw,
  convertFromRaw,
  RichUtils,
} from "draft-js";
import "draft-js/dist/Draft.css";

export const ServiceAdd = () => {
  const [addfaq, setaddfaq] = useState(false);

  //faq and comp arr
  const [faqarr, setfaqarr] = useState([]);
  const [trows, settrows] = useState([]);

  const [answer, setanswer] = useState("");
  const [question, setquestion] = useState("");

  const [img, setimg] = useState("");
  const [sname, setsname] = useState("");
  const [smalldesc, setsmalldesc] = useState("");

  const [htmlContent, sethtmlcontent] = useState(EditorState.createEmpty());
  const [htmlraw, sethtmlraw] = useState("");

  const [region, setregion] = useState("");
  const [city, setcity] = useState("");
  const [service, setservice] = useState("");

  //Plans
  const [bplan, setbplan] = useState(10.99);
  const [splan, setsplan] = useState(49.99);
  const [aiplan, setaiplan] = useState(109.99);

  const [plans, setplans] = useState([]);

  useEffect(() => {
    setplans([
      {
        plan: 1,
        price: bplan,
      },
      {
        plan: 2,
        price: splan,
      },
      {
        plan: 3,
        price: aiplan,
      },
    ]);
  }, [bplan, splan, aiplan]);

  const { isloggedin, setisloggedin, userdata, City, Region, Service, apiUrl } =
    useContext(SkillContext);

  const formData = new FormData();
  formData.append("image", img);
  formData.append("sname", sname);
  formData.append("ssmalldesc", smalldesc);
  formData.append("sbigdesc", htmlraw);

  formData.append("faqs", JSON.stringify(faqarr));
  formData.append("comparr", JSON.stringify(trows)); //-< arr gets stringified -< just sent faqs seperatly?
  formData.append("screator", userdata[0]?.usertoken);
  formData.append("sregion", region);
  formData.append("scity", city);
  formData.append("scategory", service);
  formData.append("plans", JSON.stringify(plans));

  //Fetch the Service of this serviceID
  const { data, err, isloading, addData } = usePostData(
    `${apiUrl}/api/services/single/null`
  );

  useEffect(() => {
    console.log("ADDED DATA");
    console.log(data);
  }, [data]);

  /*SHOWNOTIFICATIOn -> put in context hook*/
  const [shnotify, setshnotify] = useState(false);
  const navigate = useNavigate();

  /*CHECK IF USER IS LOGGEDIN*/
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );

  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  useEffect(() => {
    data?.err ? toast.error(data?.err) : toast.success(data?.msg);
  }, [data]);

  useEffect(() => {
    if (userdata.length > 0 && !userdata[0]?.stripe_setup_status)
      setstripeov(true);
  }, [userdata]);

  const [stripeov, setstripeov] = useState(false);

  //htmlContent = editorState
  useEffect(() => {
    console.log("editorState:", htmlContent);

    sethtmlraw(JSON.stringify(convertToRaw(htmlContent.getCurrentContent())));
  }, [htmlContent]);

  /*
                  <Editor
                    htmlContent={htmlContent}
                    sethtmlcontent={sethtmlcontent}
                  />*/

  return (
    <>
      {userdata.length > 0 && !userdata[0]?.stripe_setup_status ? (
        <StripeOverlay
          shov={stripeov}
          setshov={setstripeov}
          context=" Sie müssen einen Stripe Account besitzen, um einen Service zu
          erstellen."
        />
      ) : (
        <>
          <div className="service-add-wrapper">
            <div className="service-add-top">
              <h1>Service hinzufügen</h1>
              <p>
                Füge deine Dienstleistung hinzu. So wie du gerne arbeiten
                willst, dir sind keine Grenzen gesetzt, außer von deiner
                Konkurrenz 😁
              </p>
            </div>

            <form
              className="service-content-wrapper"
              onSubmit={(e) => {
                console.log("clicked");
                console.log("Event: ", e);

                e.preventDefault();

                addData(formData);
              }}
            >
              <div className="service-content-part">
                <div className="service-choose">
                  <div className="circle">1</div>
                  <div className="c-line"></div>
                </div>

                <div className="service-grid">
                  <div className="service-img-wrapper">
                    <label htmlFor="image">
                      {img?.name ? (
                        <span>{img.name}</span>
                      ) : (
                        <div className="icon">
                          <FontAwesomeIcon icon={faImages} />
                        </div>
                      )}

                      <p>Drag and drop oder nach Bildern suchen</p>

                      <p>PNG, JPG, PDF, SVG</p>
                    </label>

                    <input
                      type="file"
                      name="image"
                      id="image"
                      onChange={(e) => {
                        setimg(e.target.files[0]);
                      }}
                    />
                  </div>

                  <ServiceInput
                    label="Service Name"
                    type="text"
                    placeholder="Blumen gießen"
                    name="title"
                    important={true}
                    setvalue={setsname}
                  />
                  <ServiceInput
                    label="kurze  Beschreibung"
                    type="text"
                    placeholder="Ich gieße ihre Blumen"
                    name="smalldesc"
                    important={true}
                    setvalue={setsmalldesc}
                  />

                  <EditorDraft
                    editorState={htmlContent}
                    seteditorState={sethtmlcontent}
                  />

                  <div className="options">
                    <div className="select-drop">
                      <label htmlFor="region">
                        <p>Region</p> <span>*</span>
                      </label>
                      <select
                        onChange={(e) => {
                          setregion(e.target.value);
                        }}
                        value={region}
                        name="region"
                      >
                        <option value={""}>Wähle eine Region</option>
                        {Region.length > 0 &&
                          Region.map((region) => (
                            <option value={region.id}>{region.name}</option>
                          ))}
                      </select>
                    </div>

                    <div className="select-drop">
                      <label htmlFor="city">
                        <p>City</p> <span>*</span>
                      </label>
                      <select
                        onChange={(e) => {
                          setcity(e.target.value);
                        }}
                        value={city}
                        name="city"
                      >
                        <option value={""}>Wähle eine Stadt</option>
                        {City.length > 0 &&
                          City.map((city) => {
                            if (region != "" && region == city.regionref) {
                              return (
                                <option value={city.cid}>{city.cname}</option>
                              );
                            }
                          })}
                      </select>
                    </div>

                    <div className="select-drop">
                      <label htmlFor="category">
                        <p>Kategorie</p> <span>*</span>
                      </label>
                      <select
                        onChange={(e) => {
                          setservice(e.target.value);
                        }}
                        value={service}
                        name="category"
                      >
                        <option value={""}>Wähle eine Kategorie</option>
                        {Service.length > 0 &&
                          Service.map((service) => (
                            <option value={service.id}>{service.name}</option>
                          ))}
                      </select>
                    </div>
                  </div>
                </div>
              </div>

              <div className="add-faq">
                <div className="service-choose">
                  <div className="circle">2</div>
                  <div className="c-line"></div>
                </div>

                <div className="faq-content">
                  <div className="top">
                    <div className="left">
                      <div className="right">
                        <h3>FAQ</h3>
                        <p>
                          Füge ein FAQ hinzu, um mehr transparenz zu deinen
                          Kunden aufzubauen
                        </p>
                      </div>
                    </div>

                    <div
                      className="icon"
              
                    >
                  <div  onClick={() => {
                        setaddfaq(!addfaq);
                      }}>
                  <FontAwesomeIcon icon={addfaq ? faXmark : faAdd} />
                    </div>    
                 
                      <div
                      className="faq-add-overlay"
                      style={{ display: addfaq ? "flex" : "none" }}
                    >
                      <div className="arrow-left"></div>

                      <ServiceInput
                        label="Frage"
                        type="text"
                        placeholder="Komme ich auch am Wochenende?"
                        name="quest"
                        setvalue={setquestion}
                      />

                      <ServiceInput
                        label="Antwort"
                        type="text"
                        placeholder="Klar. Je nach Absprache."
                        name="reply"
                        setvalue={setanswer}
                      />

                      <button
                      type="button"
                        onClick={() => {
                          setfaqarr([
                            ...faqarr,
                            {
                              fquestion: question,
                              fanswer: answer,
                              fid: faqarr.length,
                            },
                          ]);
                        }}
                      >
                        Hinzufügen
                      </button>
                    </div>
                    </div>

                 
                  </div>

                  <div className="bottom">
                    {faqarr.length > 0 ? (
                      faqarr.map((elem, index) => {
                        console.log("elem");
                        console.log(elem);
                        return (
                          <div className="faq-nexto-wrapper">
                            <p>{index + 1}.</p>
                            <FAQWrapper
                              id={elem.fid}
                              title={elem.fquestion}
                              answer={elem.fanswer}
                              setfaqarr={setfaqarr}
                              faqarr={faqarr}
                              close={true}
                            />
                          </div>
                        );
                      })
                    ) : (
                      <p>Noch kein FAQ Element hinzugefügt. 😞</p>
                    )}
                  </div>
                </div>
              </div>

              <div className="compare-wrapper">
                <div className="service-choose">
                  <div className="circle">3</div>
                  <div className="c-line"></div>
                </div>

                <div className="plan-choose-wrapper">
                  <div className="plans">
                    <h2>Pläne anpassen</h2>
                    <p>
                      Wähle deinen Preis für dein Paket. Überlege Dir was du
                      genau anbieten möchtest für einen bestimmten Preis.
                    </p>

                    <div className="plan-flex">
                      <PlanItem
                        icon={faBox}
                        title="Basis Plan"
                        price={bplan}
                        setprice={setbplan}
                      />
                      <PlanItem
                        icon={faBoxesStacked}
                        title="Standard Plan"
                        price={splan}
                        setprice={setsplan}
                      />
                      <PlanItem
                        icon={faBoxesPacking}
                        title="All Inclusive Plan"
                        price={aiplan}
                        setprice={setaiplan}
                      />
                    </div>
                  </div>

                  <div className="compare-content">
                    <div className="top">
                      <div className="left">
                        <h3>Vergleich</h3>
                        <p>
                          Biete deinen möglichen Kunden die Möglichkeit zwischen
                          all deinen angebotenen Plänen zu unterscheiden.
                        </p>
                      </div>
                    </div>

                    <ContentTable
                      edit={true}
                      trows={trows}
                      settrows={settrows}
                    />
                  </div>
                </div>
              </div>

              <div className="add-service-wrapper">
                <div className="service-choose">
                  <div className="circle">
                    <FontAwesomeIcon icon={faArrowRight} />
                  </div>
                  <div className="line"></div>
                </div>

                <button type="submit">Erstellen</button>
              </div>
            </form>
          </div>
        </>
      )}
    </>
  );
};

export const PlanItem = (props) => {
  const [edit, setedit] = useState(false);
  const [price, setprice] = useState(props.price);

  return (
    <div className="plan-item">
      <div className="top">
        <div className="icon">
          <FontAwesomeIcon icon={props.icon} />
        </div>
        <h2>{props.title}</h2>
        <div className="desc">
          {!edit && <p>{props.price}</p>}
          {edit && (
            <input
              type="number"
              onChange={(e) => {
                setprice(parseFloat(e.target.value).toFixed(2));
              }}
            />
          )}
        </div>
      </div>

      <div className="bottom">
        {edit ? (
          <>
            <span
              onClick={() => {
                props.setprice(price);
                setedit(false);
              }}
            >
              Übernehmen
            </span>
            <span
              onClick={() => {
                setedit(false);
              }}
            >
              Abbrechen
            </span>
          </>
        ) : (
          <span
            onClick={() => {
              setedit(true);
            }}
            style={{ width: "100%" }}
          >
            {" "}
            Anpassen
            <div className="icon">
              <FontAwesomeIcon icon={faPencil} />
            </div>{" "}
          </span>
        )}
      </div>
    </div>
  );
};

export const Notification = (props) => {
  useEffect(() => {
    console.log(props.notify);

    const timer = setTimeout(() => {
      props.setnotify(false);
    }, 5000);

    return () => clearTimeout(timer);
  }, [props.notify]);

  return (
    <div
      className="notification-wrapper"
      style={{
        backgroundColor: props.msg?.err ? "rgb(212, 65, 65)" : "green",
        color: props.msg?.err ? "lightcoral" : "lightgreen",
      }}
    >
      <div className="icon">
        <FontAwesomeIcon icon={props.msg?.err ? faXmark : faCheck} />
      </div>

      <p>
        {props.msg?.err
          ? props.msg?.err
          : props.msg?.msg
          ? props.msg?.msg
          : props.success}
      </p>
    </div>
  );
};

export const EditorDraft = (props) => {
  // Function to handle inline styles (bold, italic, etc.)
  const handleKeyCommand = (command) => {
    const newState = RichUtils.handleKeyCommand(props.editorState, command);
    if (newState) {
      props.seteditorState(newState);
      return "handled";
    }
    return "not-handled";
  };

  // Function to toggle inline styles
  const toggleInlineStyle = (style) => {
    props.seteditorState(RichUtils.toggleInlineStyle(props.editorState, style));
  };

  // Function to toggle block types (like text alignment)
  const toggleBlockType = (blockType) => {
    props.seteditorState(
      RichUtils.toggleBlockType(props.editorState, blockType)
    );
  };

  const styleMap = {
    HIGHLIGHT: {
      backgroundColor: "rgb(155, 155, 102)",
    },
  };

  const blockStyleFn = (block) => {
    const type = block.getType();
    switch (type) {
      case "blockquote":
        return "block-quote";
      case "align-left":
        return "align-left";
      case "align-center":
        return "align-center";
      case "align-right":
        return "align-right";
      default:
        return null;
    }
  };

  const [charCount, setCharCount] = useState(0);
  const maxCharCount = 1000;

  const handleChange = (state) => {
    const content = state.getCurrentContent();
    const currentLength = content.getPlainText().length;

    if (currentLength <= maxCharCount) {
      props.seteditorState(state);
      setCharCount(currentLength);
    }
  };

  return (
    <div className="draft-editor-wrapper">
      <div className="editor-top">
        <div className="editor-item" onClick={() => toggleInlineStyle("BOLD")}>
          <FontAwesomeIcon icon={faBold} />
        </div>

        <div
          className="editor-item"
          onClick={() => toggleInlineStyle("ITALIC")}
        >
          <FontAwesomeIcon icon={faItalic} />
        </div>

        <div
          className="editor-item"
          onClick={() => toggleInlineStyle("HIGHLIGHT")}
        >
          <FontAwesomeIcon icon={faHighlighter} />
        </div>

        <div
          className="editor-item"
          onClick={() => toggleInlineStyle("UNDERLINE")}
        >
          <FontAwesomeIcon icon={faUnderline} />
        </div>

        <div
          className="editor-item"
          onClick={() => toggleBlockType("header-one")}
        >
          <FontAwesomeIcon icon={faHeader} />
        </div>

        <div
          className="editor-item"
          onClick={() => toggleBlockType("blockquote")}
        >
          <FontAwesomeIcon icon={faCode} />
        </div>

        <div
          className="editor-item"
          onClick={() => toggleBlockType("align-left")}
        >
          <FontAwesomeIcon icon={faAlignLeft} />
        </div>

        <div
          className="editor-item"
          onClick={() => toggleBlockType("align-center")}
        >
          <FontAwesomeIcon icon={faAlignCenter} />
        </div>

        <div
          className="editor-item"
          onClick={() => toggleBlockType("align-right")}
        >
          <FontAwesomeIcon icon={faAlignRight} />
        </div>

        <div
          className="editor-item"
          onClick={() => toggleBlockType("unordered-list-item")}
        >
          <FontAwesomeIcon icon={faListUl} />
        </div>

        <div
          className="editor-item"
          onClick={() => toggleBlockType("ordered-list-item")}
        >
          <FontAwesomeIcon icon={faListOl} />
        </div>
      </div>

      <div className="editor-bottom">
        <Editor
          editorState={props.editorState}
          onChange={(state) => {
            handleChange(state);
          }}
          handleKeyCommand={handleKeyCommand}
          customStyleMap={styleMap}
          blockStyleFn={blockStyleFn}
        />

        <p>
          {" "}
          <span style={{ color: charCount >= 1000 ? "red" : "white" }}>
            {charCount}
          </span>{" "}
          / 1000
        </p>
      </div>
    </div>
  );
};

export const MyEditor = (props) => {
  //nach ol br, nach p, nach

  const [show, setshow] = useState(false);

  const [selected, setselected] = useState("");

  const TagExists = (string, tag) => {
    //check if tag includes style

    //HARDcoded urghhh
    const endtag = tag.includes("<div")
      ? tag.slice(0, 1) + "/" + "div>"
      : tag.includes("<a")
      ? tag.slice(0, 1) + "/" + "a>"
      : tag.slice(0, 1) + "/" + tag.slice(1, tag.length);

    if (string.includes(tag)) {
      //this means its already existing so we replace the tag with "" (nothing)
      console.log("includes = true");

      const start = string.replace(tag, "");
      const result = start.replace(endtag, "");

      /*when there are multiple tags to remove

tags = ["ol", "li"] -> array wird übergeben auch für nur einen wert

for(let i = 0; i < tags.length; i++){

ENDTAG has to be generated for every index in array"!!!
let start = string,replace(tags[i], "");
let result = string.replace(endtag, "")

}

*/
      return result;
    }

    //return empty when tag isnt included
    return "";
  };

  const UpdateText = (string, old, news, setstring) => {
    const replaced_string = string.replace(old, news);
    props.sethtmlcontent(replaced_string);
  };

  const [col, setcol] = useState("#000000");

  const [links, setlinks] = useState("#");
  const [shlink, setshlink] = useState(false);
  // let l = "TEST/sdxvk&uvhasd/12";

  const brush = "<div style='color: " + col + "'>" + selected + "</div> ";
  const bold = "<b>" + selected + "</b>  ";
  const italic = "<i>" + selected + "</i>  ";
  const link = "<a href='" + links + "'>" + selected + "</a> ";
  const newline = selected + "<br>  ";
  const header = "<h2>" + selected + "</h2> ";
  const highlight = "<mark>" + selected + "</mark>";

  //THis changes if you switch between edit or non edit
  const [active, setactive] = useState(true);

  const [lastcol, setlastcol] = useState("");
  const [lastlink, setlastlink] = useState("");

  return (
    <div
      className="editor-wrapper"
      onMouseEnter={() => {
        active && setshow(true);
      }}
      onMouseLeave={() => {
        if (active || show) {
          setshow(false);
        }
      }}
    >
      <div className="editor-top">
        <div
          className="editor-item"
          style={{
            opacity: !active ? "0.4" : "1",
            pointerEvents: !active ? "none" : "all",
          }}
          onClick={() => {
            if (selected != "") {
              //col
              const exists = TagExists(
                selected,
                `<div style='color: ${lastcol}'>`
              );

              //Tag isnt included in string -> add the tag
              if (exists === "") {
                UpdateText(
                  props.htmlContent,
                  selected,
                  brush,
                  props.sethtmlcontent
                );
                setlastcol(col);
              } else {
                //if it is included then overwrite tag
                UpdateText(
                  props.htmlContent,
                  selected,
                  exists,
                  props.sethtmlcontent
                );
              }
            } else {
              alert("Select Text");
            }
          }}
        >
          <div className="icon">
            <FontAwesomeIcon icon={faBrush} />
          </div>
        </div>

        <div
          className="editor-item"
          style={{
            opacity: !active ? "0.4" : "1",
            pointerEvents: !active ? "none" : "all",
          }}
          onClick={() => {
            if (selected != "") {
              const exists = TagExists(selected, "<mark>");

              //Tag isnt included in string -> add the tag
              if (exists === "") {
                UpdateText(
                  props.htmlContent,
                  selected,
                  highlight,
                  props.sethtmlcontent
                );
              } else {
                //if it is included then overwrite tag
                UpdateText(
                  props.htmlContent,
                  selected,
                  exists,
                  props.sethtmlcontent
                );
              }
            } else {
              alert("Select Text");
            }
          }}
        >
          <div className="icon">
            <FontAwesomeIcon icon={faHighlighter} />
          </div>
        </div>

        <div
          className="editor-item"
          style={{
            opacity: !active ? "0.4" : "1",
            pointerEvents: !active ? "none" : "all",
          }}
          onClick={() => {
            if (selected != "") {
              const exists = TagExists(selected, "<h2>");

              //Tag isnt included in string -> add the tag
              if (exists === "") {
                UpdateText(
                  props.htmlContent,
                  selected,
                  header,
                  props.sethtmlcontent
                );
              } else {
                //if it is included then overwrite tag
                UpdateText(
                  props.htmlContent,
                  selected,
                  exists,
                  props.sethtmlcontent
                );
              }
            } else {
              alert("Select Text");
            }
          }}
        >
          <div className="icon">
            <FontAwesomeIcon icon={faHeader} />
          </div>
        </div>

        <div
          className="editor-item"
          style={{
            opacity: !active ? "0.4" : "1",
            pointerEvents: !active ? "none" : "all",
          }}
          onClick={() => {
            if (selected != "") {
              const exists = TagExists(selected, "<b>");

              //Tag isnt included in string -> add the tag
              if (exists === "") {
                UpdateText(
                  props.htmlContent,
                  selected,
                  bold,
                  props.sethtmlcontent
                );
              } else {
                //if it is included then overwrite tag
                UpdateText(
                  props.htmlContent,
                  selected,
                  exists,
                  props.sethtmlcontent
                );
              }
            } else {
              alert("Select Text");
            }
          }}
        >
          <div className="icon">
            <FontAwesomeIcon icon={faBold} />
          </div>
        </div>
        <div
          className="editor-item"
          style={{
            opacity: !active ? "0.4" : "1",
            pointerEvents: !active ? "none" : "all",
          }}
          onClick={() => {
            if (selected != "") {
              const exists = TagExists(selected, "<i>");

              //Tag isnt included in string -> add the tag
              if (exists === "") {
                UpdateText(
                  props.htmlContent,
                  selected,
                  italic,
                  props.sethtmlcontent
                );
              } else {
                //if it is included then overwrite tag
                UpdateText(
                  props.htmlContent,
                  selected,
                  exists,
                  props.sethtmlcontent
                );
              }
            } else {
              alert("Select Text");
            }
          }}
        >
          <div className="icon">
            <FontAwesomeIcon icon={faItalic} />
          </div>
        </div>
        <div
          className="editor-item"
          style={{
            opacity: !active ? "0.4" : "1",
            pointerEvents: !active ? "none" : "all",
          }}
        >
          <div
            className="icon"
            onClick={() => {
              const exists = TagExists(selected, `<a href='${lastlink}'>`);
              //Tag isnt included in string -> add the tag
              if (exists != "") {
                UpdateText(
                  props.htmlContent,
                  selected,
                  exists,
                  props.sethtmlcontent
                );
                setshlink(false);
              } else {
                setshlink(!shlink);
              }
            }}
          >
            <FontAwesomeIcon icon={faLink} />
          </div>

          <div
            className="link-search"
            style={{ display: shlink ? "flex" : "none" }}
          >
            <input
              type="text"
              onChange={(e) => {
                setlinks(e.target.value);
              }}
            />
            <button
              onClick={() => {
                if (selected != "") {
                  UpdateText(
                    props.htmlContent,
                    selected,
                    link,
                    props.sethtmlcontent
                  );
                  setlastlink(links);
                } else {
                  alert("Select Text");
                }
              }}
            >
              <FontAwesomeIcon icon={faAdd} />
            </button>
          </div>
        </div>

        <div
          className="editor-item"
          style={{
            opacity: !active ? "0.4" : "1",
            pointerEvents: !active ? "none" : "all",
          }}
          onClick={() => {
            if (selected != "") {
              const exists = TagExists(selected, "<br>");

              //Tag isnt included in string -> add the tag
              if (exists === "") {
                UpdateText(
                  props.htmlContent,
                  selected,
                  newline,
                  props.sethtmlcontent
                );
              } else {
                //if it is included then overwrite tag
                UpdateText(
                  props.htmlContent,
                  selected,
                  exists,
                  props.sethtmlcontent
                );
              }
            } else {
              alert("Select Text");
            }
          }}
        >
          <div className="icon">
            <FontAwesomeIcon icon={faTurnDown} />
          </div>
        </div>

        <div
          className="editor-item"
          style={{
            opacity: !active ? "0.4" : "1",
            pointerEvents: !active ? "none" : "all",
          }}
        >
          <input
            type="color"
            onChange={(e) => {
              setcol(e.target.value);
            }}
          />
        </div>
      </div>

      <div className="editor-bottom">
        <div className="editor-area">
          <textarea
            style={{
              display: show && active ? "block" : "none",
            }}
            value={props.htmlContent}
            onChange={(e) => {
              props.sethtmlcontent(e.target.value);
            }}
            onMouseUp={() => {
              const selection = window.getSelection().toString();
              setselected(selection);
            }}
          ></textarea>

          <div
            className="editor-content"
            style={{
              display: !show ? "block" : "none",
            }}
            dangerouslySetInnerHTML={{ __html: props.htmlContent }}
          />

          <div className="editor-info">
            <div className="info">
              <p>
                {" "}
                <span
                  style={{
                    color:
                      props.htmlContent.length > 3000
                        ? "rgb(207, 70, 70)"
                        : "white",
                  }}
                >
                  {props.htmlContent.length}
                </span>{" "}
                / 3000
              </p>
              <p>Characters</p>
            </div>

            <Switch active={active} setactive={setactive} setaction={setshow} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const ServiceInput = (props) => {
  return (
    <div className="input-wrapper">
      <label htmlFor={props.name && props.name}>
        {props.label && props.label} <span>{props.important && "*"}</span>
      </label>
      <input
        style={props.style && props.style}
        type={props.type && props.type}
        placeholder={props.placeholder && props.placeholder}
        name={props.name && props.name}
        id={props.name && props.name}
        value={props.val && props.val}
        onChange={(e) => {
          props.setvalue && props.setvalue(e.target.value);
        }}
        disabled={props.disabled ? true : false}
      />
    </div>
  );
};

export const ServiceTextArea = (props) => {
  return (
    <div className="input-wrapper">
      <label htmlFor={props.name && props.name}>
        {props.label && props.label} <span>{props.important && "*"}</span>
      </label>
      <textarea
        placeholder={props.placeholder && props.placeholder}
        name={props.name && props.name}
        value={props.val && props.val}
        onChange={(e) => {
          props.setvalue && props.setvalue(e.target.value);
        }}
        disabled={props.disabled ? true : false}
      ></textarea>
    </div>
  );
};

export const ServiceDrop = (props) => {
  return (
    <div className="input-wrapper">
      <label htmlFor={props.name && props.name}>
        {props.label && props.label}
      </label>
      <select
        placeholder={props.placeholder && props.placeholder}
        name={props.name && props.name}
        onChange={(e) => {
          props.setvalue && props.setvalue(e.target.value);
        }}
        value={props.val && props.val}
      >
        <option>Auswählen</option>
        <option value={1} selected={props.select == "1" ? true : false}>
          ✔️
        </option>
        <option value={0} selected={props.select == "0" ? true : false}>
          ❌
        </option>
      </select>
    </div>
  );
};

export const Switch = (props) => {
  return (
    <div
      className="switch-wrapper"
      style={{
        backgroundColor: props.active
          ? "var(--main-bg-accent-light)"
          : "var(--main-bg-accent)",
      }}
      onClick={() => {
        props.setactive(!props.active);
        props.setaction && props.setaction(true);
      }}
    >
      <div
        className="circle"
        style={{
          left: props.active ? "50%" : "0",
          backgroundColor: props.active
            ? "var(--light-bg-accent)"
            : "var(--main-bg-accent-light)",
        }}
      ></div>
    </div>
  );
};

/*

inhalt -> vergleich der deinstleistungen und in welchen plan enthalten (optional)
bild von service (optional) -> wenn leer dann standard image
*/
