import "./Css/calendar.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetData } from "./Hooks/getData";
import { banDates, banDateTime, sendMail, SkillContext } from "./App";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import listPlugin from "@fullcalendar/list";
import deLocale from "@fullcalendar/core/locales/de";
import multiMonthPlugin from "@fullcalendar/multimonth";
import DatePicker from "react-datepicker";
import { ServiceInput } from "./ServiceAdd";
import { usePostJSONData } from "./Hooks/postjsonData";
import { toast } from "react-toastify";
import emailjs from "@emailjs/browser";

export const Calendar = () => {
  const { isloggedin, setisloggedin, userdata, apiUrl } = useContext(SkillContext);

  const navigate = useNavigate();
  const params = useParams();

  /*CHECK IF USER IS LOGGEDIN*/
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );
  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  const { data: appointments, refetch: refetchAppointments } = useGetData(
    `${apiUrl}/api/services/appointments/${params.sid}`
  );

  useEffect(() => {
    console.log("Appointments");
    console.log(appointments);
  }, [appointments]);

  const events = appointments;

  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");

  const [shsrequest, setshsrequest] = useState(false);
  const [requestdesc, setrequestdesc] = useState("");

  const [bannedDates, setbanneddates] = useState([]);
  const excludedTimes = [];

  const {
    data: addedNotification,
    err: nerror,
    isloading: nloading,
    addjsonData: addNotification,
  } = usePostJSONData(
    `   ${apiUrl}/api/users/user/null/notifications/${params.sid}`
  );

  useEffect(() => {
    addedNotification?.err
      ? toast.error(addedNotification?.err)
      : toast.success(addedNotification?.msg);
  }, [addedNotification]);

  const [currevent, setcurrevent] = useState(null);

  const [currdates, setcurrdates] = useState("");

  useEffect(() => {
    console.log("currevent serviceref");
    console.log(currevent?.extendedProps.sidref);

    console.log("reicever");
    console.log(currevent?.extendedProps);

    console.log("CURREVENT MAIL");
    console.log(currevent?.extendedProps.email);

    appointments.length > 0 &&
      setcurrdates(
        appointments.find((app) => app.said === currevent?.extendedProps.said)
      );
  }, [currevent, appointments]);

  useEffect(() => {
    currdates && setStartDate(new Date(currdates.start));
    currdates && setendDate(new Date(currdates.end));
  }, [currdates]);

  useEffect(() => {
    if (appointments && appointments.length > 0)
      banDates(appointments, setbanneddates, bannedDates);
  }, [appointments]);

  useEffect(() => {
    startDate &&
      banDateTime(excludedTimes, startDate, bannedDates, appointments);
  }, [startDate, appointments, bannedDates]);

  useEffect(() => {
    endDate && banDateTime(excludedTimes, endDate, bannedDates, appointments);
  }, [endDate, appointments, bannedDates]);

  const {
    data: settingsData,
    err: serr,
    isloading: sloading,
    refetch: fetchSettings,
  } = useGetData(
    `${apiUrl}/api/users/settings/${currevent?.extendedProps.srequester}`
  );

  useEffect(() => {
    currevent && fetchSettings();
  }, [currevent]);

  useEffect(() => {
    console.log("settings");
    console.log(settingsData);
  }, [settingsData]);

  const { data: sentMail, addjsonData: sendMail } = usePostJSONData(
    `${apiUrl}/api/services/user/send/mail`
  );

  useEffect(() => {
    if (addedNotification?.err || addedNotification?.msg) {
      if (
        !addedNotification?.err &&
        settingsData[2]?.active &&
        settingsData.length > 0
      ) {
        /*  sendMail({
          to: currevent?.extendedProps.email,
          subject: `Terminänderung des Service "${currevent?.title}"`,
          text: requestdesc,
          html: "Test",
        });*/
      }
    }
  }, [addedNotification]);

  return (
    <>
      <div
        className="request-overlay"
        style={{ display: shsrequest ? "flex" : "none" }}
      >
        <div className="service-request-modal">
          <div className="request-modal-top">
            <div className="left">
              <h2>Termin ändern</h2>
              <p>
                Bitten Sie Ihren Auftraggeber Ihren Termin auf Ihr gewünschtes
                Datum abzuändern.
              </p>
            </div>

            <div
              className="close"
              onClick={() => {
                setshsrequest(false);
              }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>

          <div className="reqest-modal-bottom">
            <div className="request-desc-wrapper">
              <div className="desc-area">
                <label>
                  {" "}
                  Beschreibung <span>*</span>
                </label>
                <div className="text-area">
                  <textarea
                    value={requestdesc}
                    placeholder="Zusätzliche Infomrationen, die Sie berücksichtigen sollten ..."
                    onChange={(e) => {
                      setrequestdesc(e.target.value);
                    }}
                  ></textarea>
                  <p>
                    <span
                      style={{
                        color:
                          requestdesc.length > 3000
                            ? "rgb(207, 70, 70)"
                            : "white",
                      }}
                    >
                      {requestdesc.length}
                    </span>{" "}
                    / 3000
                  </p>
                </div>
              </div>

              <div className="date-area">
                <div className="date-wrapper">
                  <label>
                    Terminstart <span>*</span>
                  </label>

                  <DatePicker
                    selected={startDate}
                    onChange={(date) => setStartDate(date)}
                    excludeTimes={excludedTimes}
                    placeholderText="Wähle einen Starttermin"
                    dateFormat="yyyy-MM-dd"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                  />
                </div>

                <div className="date-wrapper">
                  <label>
                    Terminende <span>*</span>
                  </label>

                  <DatePicker
                    selected={endDate}
                    minDate={startDate}
                    onChange={(date) => setendDate(date)}
                    excludeTimes={excludedTimes}
                    placeholderText="Wähle einen Endtermin"
                    dateFormat="yyyy-MM-dd"
                    showTimeSelect
                    timeFormat="HH:mm"
                    timeIntervals={15}
                  />
                </div>
              </div>
            </div>

            <div className="step-wrapper">
              <button
                onClick={() => {
                  //addNotification typ anfrage
                  addNotification({
                    typ: 1,
                    subject: `Terminänderung des Service "${currevent?.title}"`,
                    text: requestdesc,
                    sender: userdata[0]?.usertoken,
                    startdate: startDate, //äquivalent zu null nur für datum
                    enddate: endDate, //äquivalent zu null nur für datum
                    reicever: currevent?.extendedProps.srequester,
                    choosenPlan: null, //when that is Null dont show?!
                    sidref: currevent?.extendedProps.sidref,
                    nusecase: 1, //1 = Terminänderung
                    appointmentref: currevent?.extendedProps.said, //references to appointment
                    paymentOptionchoosed:
                      currevent?.extendedProps.paid === 0 ? 1 : 0, //if paid is at this point still 0 so false it means user didnt pay right away
                  });
                }}
              >
                Abschicken
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="calendar-wrapper">
        <div className="calendar-top">
          <h2>Kalendar</h2>
          <p>
            Im folgenden findest du alle altiven Termine für deine angenommenen
            Dienstleitstungen.
          </p>
        </div>

        <FullCalendar
          plugins={[multiMonthPlugin]}
          initialView="multiMonthYear"
          multiMonthMaxColumns={1}
          weekends={true}
          events={events}
          eventColor="var(--light-text-col)"
          headerToolbar={{ start: "title", center: "", end: "prev,next" }}
          timeZone="local"
          locales={deLocale}
          locale="de"
          eventClick={(e) => {
            console.log("Serviceref :");
            console.log(e.event._def);

            console.log("SERVICE INFO");
            console.log(e.event._instance.range);

            console.log(
              new Date(e.event._instance.range.start).toLocaleString("de-DE", {
                timeZone: "Europe/Berlin",
              })
            );

            setshsrequest(true);
            setcurrevent(e.event._def);
          }}
        />
      </div>
    </>
  );
};
