import "./Css/servicepending.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fa1,
  fa2,
  fa3,
  fa4,
  faCheck,
  faCheckSquare,
  faChevronRight,
  faCircleQuestion,
  faCreditCard,
  faCube,
  faDownload,
  faFilePdf,
  faForward,
  faForwardStep,
  faLocation,
  faLocationArrow,
  faLocationPin,
  faMinus,
  faMoneyBill,
  faSearch,
  faTrashCan,
  faUsersRays,
  faWarning,
  faX,
  faXmark,
  faXmarkSquare,
} from "@fortawesome/free-solid-svg-icons";

import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetData } from "./Hooks/getData";
import { sendMail, SkillContext } from "./App";
import { ServiceInput } from "./ServiceAdd";
import { Label } from "./Service";
import { useUpdateJSONData } from "./Hooks/updatejsonData";
import { toast } from "react-toastify";
import { pdfGenerator } from "./pdfGenerator";
import { usePostJSONData } from "./Hooks/postjsonData";
import { OrderItem, ProgressItem } from "./ServicePending";
import emailjs from "@emailjs/browser";
import { useDeleteData } from "./Hooks/deleteData";
import { EditIcon } from "./TicketDashboard";
import { ConfirmDelete } from "./Settings";


export const Automatic_Pay = async (
  start,
  end,
  delay,
  paid,
  tickets,
  Order,
  userInfo,
  updateOrder,
  AutomaticPay,
  amount
) => {
  console.log("____ AUTPOMATIC PAY EXECUTED ____");
  console.log("ODERINFS: ", Order);

 /* console.log("ticket?", tickets);

  //muss der Start nicht das heutige Datum sein???
  const today = new Date().getTime();

  console.log("start: " + new Date(today));
  console.log("end: " + end);
  console.log("delay: " + delay);
  console.log("paid: " + paid);

  /*if we have either not paid yet or we dont have a billcreated we dont do it 
-< start value is when the billisCreated
-< used to calc the end when it needs to be payed
*/
/*
  if (!start || paid)
    return console.log("No Start Date set yet or Payment already done");

  console.log("automatic pay date: ");

  const TimeLeft = end - today;
  const DaysLeft = Math.floor(TimeLeft / (24 * 60 * 60 * 1000));

  //Check ticket Data
  //when error return
  if (tickets?.err) {
    //THIS PART ONLY ACTS FOR PROCESSING THE PAYMENT PROGRESS

    console.log("Kein offenes Ticket gefunden.");

    //If we dont have a ticket we have to check if we have a paused Time -< gon on from there or just use the normal end Time
    if (Order?.payment_paused_at) {
      const paused = new Date(Order?.payment_paused_at).getTime();
      const DAysLeft = Math.floor((paused - start) / (24 * 60 * 60 * 1000));

      console.log("Days between start and paused time " + DAysLeft);

      console.log("Paused time: " + new Date(paused));

      console.log("start " + new Date(start));

      console.log("end: " + new Date(end));

      /*Nachdem Ticket aufgelöst wird im Hintergrund berechnet wie viel Zeit vergangen ist von wann Bill ausgestellt wurde
        und wann der automatic pay gepaused wurde
      und dann ab heute hat man noch die restzeit zeit zu zahlen
      */
   /*   end = new Date(new Date(today)).setDate(
        new Date(new Date(today)).getDate() + (30 - DAysLeft)
      );

      console.log("new end: " + new Date(end));

      if (today >= new Date(end).getTime()) {
        //seit billCreated sind 30 Tage vergangen
        console.log("30days passed -< paying now ...");
        await AutomaticPay({
          customerID: userInfo[0]?.customer_id,
          amount: amount * 100,
          metadata: {
            customer_token: Order?.srequester, //customer
            provider_token: Order?.sprovider, //provider
            noti_data: JSON.stringify({
              typ: [3, 4], //rechnung to sender, success to provider
              title: Order?.title,
              price: amount * 100,
              start: Order?.start,
              end: Order?.end,
              sender: [
                process.env.REACT_APP_FIND_MY_SERVICE_SYSTEM_TOKEN,
                process.env.REACT_APP_FIND_MY_SERVICE_SYSTEM_TOKEN,
              ],
              reicever: [Order?.srequester, Order?.sprovider],
              choosenPlan: Order?.choosed_plan,
              sidref: Order?.sidref,
              nusecase: 0,
              orderID: Order?.said,
            }),
          },
        });
      } else {
        console.log(
          "We still have  " +
            (DAysLeft > 0 ? 30 - DAysLeft : 0) +
            " days  till we pay."
        );
      }
    } else {
      console.log("Using normal time");
      console.log("TODAY: " + today);
      console.log("END: " + end);

      if (today >= end) {
        //seit billCreated sind 30 Tage vergangen
        console.log("30days passed -< paying now ...");
        await AutomaticPay({
          customerID: userInfo[0]?.customer_id,
          amount: amount * 100,
          metadata: {
            customer_token: Order?.srequester, //customer
            provider_token: Order?.sprovider, //provider
            noti_data: JSON.stringify({
              typ: [3, 4], //rechnung to sender, success to provider
              title: Order?.title,
              price: amount * 100,
              start: Order?.start,
              end: Order?.end,
              sender: [
                process.env.REACT_APP_FIND_MY_SERVICE_SYSTEM_TOKEN,
                process.env.REACT_APP_FIND_MY_SERVICE_SYSTEM_TOKEN,
              ],
              reicever: [Order?.srequester, Order?.sprovider],
              choosenPlan: Order?.choosed_plan,
              sidref: Order?.sidref,
              nusecase: 0,
              orderID: Order?.said,
            }),
          },
        });
      } else {
        console.log("We still have " + DaysLeft + " days till we pay.");
      }
    }
  } else {
    //THIS PART ONLY CHECKS FOR PAUSING THE PAYMENT PROGRESS
    console.log(
      "Offenes Ticket gefunden, Payment Logik wird nicht durchgeführt: ",
      tickets
    );

    //lets check if we already have the payment pause
    console.log("paused: " + Order?.payment_paused_at);

    if (!Order?.payment_paused_at) {
      console.log("not yet paused");
      //Pause the System set it to todays date
      await updateOrder({
        payment_paused_at: new Date(),
      });
    }
  }*/
};

export const UsersPendingService = () => {
  const { isloggedin, setisloggedin, userdata, apiUrl } = useContext(SkillContext);
  const navigate = useNavigate();
  const params = useParams();

  /*CHECK IF USER IS LOGGEDIN*/
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );
  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  const [search, setsearch] = useState("");

  const [completedsteps, setcompletedsteps] = useState(2);

  const [Progresses, setProgresses] = useState([]);

  //ABÄNDERN DAS ALLES PENDING USER ORDERS GEFETCHET WERDEN
  const { data: orders, refetch: refetchOrders } = useGetData(
    `${apiUrl}/api/services/users/${userdata[0]?.usertoken}/pending/services`
  );

  useEffect(() => {
    userdata.length > 0 && refetchOrders();
  }, [userdata]);

  useEffect(() => {
    console.log("orders");
    console.log(orders);
  }, [orders]);

  const [selectedorder, setselectedorder] = useState(null);

  const { data: updatedorder, update: updateOrder } = useUpdateJSONData(
    `${apiUrl}/api/services/orders/${orders[selectedorder]?.said}`,
    () => {
      refetchOrders();
    }
  );

  //Fetch the Notifications of this User
  const {
    data: addedNotification,
    err: nerror,
    isloading: nloading,
    addjsonData: addNotification,
  } = usePostJSONData(
    `${apiUrl}/api/users/user/null/notifications/null`
  );

  const {
    data: removedOrder,
    err: oerr,
    isloading: oloading,
    deleteData: deleteOrder,
  } = useDeleteData(
    `  ${apiUrl}/api/services/orders/${orders[selectedorder]?.said}`,
    () => {
      refetchOrders();
    }
  );

  const { data: sentMail, addjsonData: sendMail } = usePostJSONData(
    `${apiUrl}/api/services/user/send/mail`
  );

  useEffect(() => {
    removedOrder?.err
      ? toast.error(removedOrder?.err)
      : toast.success(removedOrder?.msg);
  }, [removedOrder]);

  useEffect(() => {
    console.log("selected orders");
    console.log(selectedorder);

    if (orders.length > 0 && selectedorder != undefined) {
      //make our check

      /*  
       //get Active Tickets
  const { data: orderTickets, refetch: refetchOrderTickets } = useGetData(
    `${apiUrl}/api/services/active/tickets/order/${orders[selectedorder]?.said}`
  );

      
      //check if finishedOn + 30 days is greator or = date.now() -> if yes set ; also set finishedOn if its still null to todays date
      if (orders[selectedorder]?.finishedOn == null) {
        console.log("not yet finishedON set");
        orders[selectedorder]?.orderstatus == 3 &&
          updateOrder({
            finishedOn: new Date(),
          });
      } else {
        //check if automatic finished should do sth -> cause its set
        console.log("finished on exists lets do the check");
        const finishstart = new Date(orders[selectedorder]?.finishedOn);

        const finishEnd = new Date(finishstart).setDate(
          new Date(finishstart).getDate() + 30
        );

        if (
          new Date().getTime() >= finishEnd &&
          orders[selectedorder]?.orderstatus < 4
        ) {
          //automaticly set orderstatus to 4
          updateOrder({
            orderstatus: 4,
          });
        }
      }*/

      setcompletedsteps(orders[selectedorder]?.orderstatus);

      setProgresses([
        {
          icon: fa1,
          title: "Auftragserteilung",
          startdate: new Date(orders[selectedorder]?.start)
            .toLocaleString("de-DE", {
              timeZone: "Europe/Berlin",
            })
            .split(",")[0],
          confirm: false,
          said: orders[selectedorder]?.said,
          orderstatus: orders[selectedorder]?.orderstatus,
        },
        {
          icon: fa2,
          title: "Durchführung",
          startdate: new Date(orders[selectedorder]?.start)
            .toLocaleString("de-DE", {
              timeZone: "Europe/Berlin",
            })
            .split(",")[0],
          enddate: new Date(orders[selectedorder]?.end)
            .toLocaleString("de-DE", {
              timeZone: "Europe/Berlin",
            })
            .split(",")[0],
          confirm: false,
          said: orders[selectedorder]?.said,
          orderstatus: orders[selectedorder]?.orderstatus,
        },
        {
          icon: fa3,
          title: "Auftragsabschluss",
          enddate: new Date(orders[selectedorder]?.end)
            .toLocaleString("de-DE", {
              timeZone: "Europe/Berlin",
            })
            .split(",")[0],
          confirm: false,
          said: orders[selectedorder]?.said,
          orderstatus: orders[selectedorder]?.orderstatus,
        },
        {
          icon: fa4,
          title: "Kundenbestätigung",
          enddate: new Date(new Date())
            .toLocaleString("de-DE", {
              timeZone: "Europe/Berlin",
            })
            .split(",")[0],
          confirm: true,
          said: orders[selectedorder]?.said,
          orderstatus: orders[selectedorder]?.orderstatus,
        },
      ]);
    }
  }, [selectedorder, orders]);

  const orderdub = [];

  const [Orders, setOrders] = useState([]);

  useEffect(() => {
    orders.length > 0 && setOrders(orders);
  }, [orders]);

  const [searchtext, setsearchtext] = useState("");

  //search
  useEffect(() => {
    if (searchtext.length === 0) return setOrders(orders);

    orders.length > 0 &&
      orders.forEach((order) => {
        if (searchtext.includes("#")) {
          order.said == searchtext.split("#")[1] && orderdub.push(order);
        } else {
          order.said == searchtext && orderdub.push(order);
        }
      });

    setOrders(orderdub);
  }, [searchtext]);

  let start = "";
  const [delTime, setdelTime] = useState(undefined);

  /*check if User has an Open Ticket for this Service
-< only check when payment-paused is null
-< also make a message in container to say its paused until issue is resolved !!!
-< if yes update with payment-paused and set it to today
-< when there is payment-paused set, but there is no more ticket for this service 
    -< continue automatic payment from the time left from when the service was paused 
    -< pay the service and set paid to 1

      const {
    data: automatedPAy,
    isloading: payautomatload,
    addjsonData: AutomaticPay,
  } = usePostJSONData(`${apiUrl}/api/payments/pay-automatic`);
 */

  /* //get User
  const { data: userInfo, refetch: refetchUserInfo } = useGetData(
    `${apiUrl}/api/users/single/${orders[selectedorder]?.srequester}`
  );
*/

  //const checkRef = useRef(true);

  useEffect(() => {
    //DAS HIER DARF NUR 1 MAL GETRIGGERED WERDEN

    if (
      selectedorder != undefined &&
      orders.length > 0 &&
      userdata.length > 0
    ) {
      const startDate = orders[selectedorder]?.start;
      //  const billStart = new Date(orders[selectedorder]?.billCreatedon);

      start = new Date(startDate);

      setdelTime(
        new Date(start).setFullYear(new Date(start).getFullYear() + 10)
      ); //Order wird nach 3 Jahren entfernt

      /*    const billEnd = new Date(billStart).setDate(
        new Date(billStart).getDate() + 30
      ); //get from billCreated in 30 Days

      //only execute once
      //Automatic Pay System gets triggered -> only should be triggered once
      if (checkRef.current) {
        Automatic_Pay(
          new Date(billStart).getTime(),
          billEnd,
          orders[selectedorder]?.payment_paused_at,
          orders[selectedorder]?.paid,
          orderTickets,
          orders[selectedorder],
          userInfo,
          updateOrder,
          AutomaticPay,
          200 //amount
        );

        console.log(
          "executed payment check function: checkref = " + checkRef.current
        );
        checkRef.current = false; //set to false to only execute once
      }*/
    }
  }, [orders, selectedorder, userdata]);

  const [shwarning, setshwarning] = useState(false);

  /*useEffect(() => {
    console.log("automatedPay was triggered: ", automatedPAy);
  }, [automatedPAy]);*/

  /*useEffect(() => {
    const currdate = new Date().getTime();

    console.log("CURRENT DEL TIME: " + delTime);
    console.log("CURRENT  TIME: " + currdate);

    //if we reached the deletion date and the Service is also paid we can remove it!!!
    if (currdate >= delTime && orders[selectedorder]?.paid) {
      deleteOrder();
    } else {
      console.log("cannot delete either date not reached or serivce not paid!");
    }
  }, [delTime]);*/

  const {
    data: settingsData,
    err: serr,
    isloading: sloading,
    refetch: fetchSettings,
  } = useGetData(
    `${apiUrl}/api/users/settings/${orders[selectedorder]?.srequester}`
  );

  useEffect(() => {
    if (orders.length > 0 && selectedorder != null) {
      console.log("selel order: ", orders[selectedorder]?.srequester);
      //  refetchUserInfo();
      fetchSettings();
    }
  }, [orders, selectedorder]);

  const [DropItem, setDropItem] = useState([]);

  useEffect(() => {
    if (orders.length > 0 && selectedorder != undefined) {
      setDropItem([
        {
          id: 1,
          icon: faTrashCan,
          title: "Löschen",
          action: () => {
            console.log("Bestellung gelöscht!");
           setshDelOv(true)
          },
        },
      ]);
    }
  }, [orders, selectedorder]);

  const [shDelOv, setshDelOv] = useState(false);

  return (
    <>

<ConfirmDelete
        shov={shDelOv}
        setshov={setshDelOv}
        img="trash.jpg"
        title="Willst du deine Bestellung wirklich löschen ?"
        message="Diese Bestellung wird unwiederruflich gelöscht."
        action={() => {
          deleteOrder({
            deleted_by_customer: 1
          });
        }}
        isdelete
      />


      <div className="pending-service-wrapper">
        <div className="s-left">
          <div className="left">
            <div className="top">
              <h2>Auftragsliste</h2>
              <div className="search-top">
                <div className="input-wrapper">
                  <div className="icon">
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                  <input
                    type="text"
                    name="search"
                    placeholder="Suche Auftrag ..."
                    onChange={(e) => {
                      setsearchtext(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="orders-wrapper">
              {Orders.length > 0 ? (
                Orders.map((order, index) => {
                  return (
                    <OrderItem
                      id={order.said}
                      startdate={new Date(order.start).toLocaleString("de-DE", {
                        timeZone: "Europe/Berlin",
                      })}
                      enddate={new Date(order.end).toLocaleString("de-DE", {
                        timeZone: "Europe/Berlin",
                      })}
                      setselectedorder={setselectedorder}
                      index={index}
                      ordercompletion={order.ordercompletion}
                      orders={orders}
                      setshwarning={setshwarning}
                    />
                  );
                })
              ) : (
                <p>Keine Bestellung gefunden</p>
              )}
            </div>
          </div>
        </div>

        <div className="right">
          {orders[selectedorder] ? (
            <div className="right-detail-wrapper">
              <div
                className="warning-wrapper"
                style={{ display: shwarning ? "flex" : "none" }}
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faWarning} />
                </div>
                <p>
                  Achtung, diese Bestellung wird automatisch am{" "}
                  {new Date(delTime).toLocaleString("de-DE", {
                    timeZone: "Europe/Berlin",
                  })}{" "}
                  gelöscht.
                </p>

                <div
                  className="del"
                  onClick={() => {
                    setshwarning(!shwarning);
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </div>

              <div className="top">
                <div className="left">
                  <h2>Auftragsnummer</h2>
                  <p>
                    <span>#</span>
                    {orders[selectedorder]?.said}
                  </p>
                </div>

                <div className="right">
                  <span
                    style={{
                      backgroundColor:
                        orders[selectedorder]?.ordercompletion === 1
                          ? "rgb(228, 228, 123)"
                          : orders[selectedorder]?.ordercompletion === 2
                          ? "rgb(130, 214, 130)"
                          : "rgb(219, 94, 94)",
                      color:
                        orders[selectedorder]?.ordercompletion === 1
                          ? "rgb(172, 172, 46)"
                          : orders[selectedorder]?.ordercompletion === 2
                          ? "rgb(78, 143, 78)"
                          : "rgb(165, 21, 21)",
                      boxShadow:
                        orders[selectedorder]?.ordercompletion === 1
                          ? "0 0 7px 1px rgb(167, 167, 74)"
                          : orders[selectedorder]?.ordercompletion === 2
                          ? "0 0 7px 1px rgb(78, 143, 78)"
                          : "0 0 7px 1px rgb(165, 21, 21)",
                    }}
                  >
                    {orders[selectedorder]?.ordercompletion === 1
                      ? "In Bearbeitung"
                      : orders[selectedorder]?.ordercompletion === 2
                      ? "Abgeschlossen"
                      : "Abgelehnt"}
                  </span>

                  <EditIcon DropItem={DropItem} />
                </div>
              </div>

              <div className="progress-wrapper">
                {Progresses.length > 0 &&
                  Progresses.map((progress, index) => {
                    return (
                      <ProgressItem
                        icon={index < completedsteps ? faCheck : progress.icon}
                        title={progress.title}
                        startdate={progress.startdate}
                        enddate={progress.enddate}
                        confirm={
                          progress.confirm && completedsteps == index
                            ? true
                            : false
                        } //confirm is if confirm is set for item and the item before is completed
                        completed={index < completedsteps ? true : false}
                        id={progress.said}
                        orderstatus={progress.orderstatus}
                        refetchOrders={refetchOrders}
                      />
                    );
                  })}
              </div>

              <div className="detail-info">
                <div className="info-part">
                  <div className="part-h">
                    <div className="icon">
                      <FontAwesomeIcon icon={faCube} />
                    </div>
                    <p>Bestell Informationen</p>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Startdatum</p>
                    </div>
                    <div className="bottom">
                      <p>
                        {new Date(orders[selectedorder]?.start).toLocaleString(
                          "de-DE",
                          {
                            timeZone: "Europe/Berlin",
                          }
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Enddatum</p>
                    </div>
                    <div className="bottom">
                      <p>
                        {new Date(orders[selectedorder]?.end).toLocaleString(
                          "de-DE",
                          {
                            timeZone: "Europe/Berlin",
                          }
                        )}
                      </p>
                    
                    </div>
                  </div>
                </div>

                <div className="info-part">
                  <div className="part-h">
                    <div className="icon">
                      <FontAwesomeIcon icon={faLocationArrow} />
                    </div>
                    <p>Standort</p>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Kunden Standort</p>
                    </div>
                    <div className="bottom">
                      <p>{orders[selectedorder]?.location}</p>
                    </div>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Anbieter Standort</p>
                    </div>
                    <div className="bottom">
                      <p>{orders[selectedorder]?.sproviderloc}</p>
                    </div>
                  </div>
                </div>

                <div className="info-part">
                  <div className="part-h">
                    <div className="icon">
                      <FontAwesomeIcon icon={faUsersRays} />
                    </div>
                    <p>Kunden Informationen</p>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Nutzername</p>
                    </div>
                    <div className="bottom">
                      <p>{orders[selectedorder]?.username}</p>
                    </div>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Email</p>
                    </div>
                    <div className="bottom">
                      <p>{orders[selectedorder]?.email}</p>
                    </div>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Telefonnummer</p>
                    </div>
                    <div className="bottom">
                      <p>{orders[selectedorder]?.tel}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bill-content-wrapper">
                <div className="content-top">
                  <div className="top">
                    <div className="icon">
                      <FontAwesomeIcon icon={faMoneyBill} />
                    </div>
                    <p>Rechnung</p>
                  </div>

                  <div
                    className="paid-sec"
                    style={{
                      display:
                        orders[selectedorder]?.ordercompletion == 2
                          ? "flex"
                          : "none",
                    }}
                  >
                    <p>&#x2022;</p>

                    <div
                      className="paid-wrapper"
                      style={{
                        border:
                          orders[selectedorder]?.paid === 0
                            ? "1px solid rgb(209, 70, 70)"
                            : "1px solid rgb(90, 168, 90) ",
                        color:
                          orders[selectedorder]?.paid === 0
                            ? "rgb(212, 120, 120)"
                            : "rgb(90, 168, 90) ",
                      }}
                    >
                      <div className="icon">
                        <FontAwesomeIcon
                          icon={
                            orders[selectedorder]?.paid === 0
                              ? faXmarkSquare
                              : faCheckSquare
                          }
                        />
                      </div>
                      <p>
                        {orders[selectedorder]?.paid === 0
                          ? "Unbezahlt"
                          : "Bezahlt"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bill-wrapper">
                  <div
                    className="bill"
                    style={{
                      display:
                        orders[selectedorder]?.ordercompletion === 2
                          ? "flex"
                          : "none",
                    }}
                  >
                    <div className="icon">
                      <FontAwesomeIcon icon={faFilePdf} />
                    </div>
                    <div className="right">
                      <p>
                        Rechnung_#{orders[selectedorder]?.said}_
                        {new Date().toISOString().split("T")[0]}
                      </p>

                      <div className="bottom">
                        <p
                          onClick={() => {
                            pdfGenerator(orders[selectedorder]);
                          }}
                        >
                          <div className="icon">
                            <FontAwesomeIcon icon={faDownload} />
                          </div>{" "}
                          Download
                        </p>

                        <p
                          style={{
                            display:
                              orders[selectedorder]?.paid === 0
                                ? "flex"
                                : "none",
                          }}
                          onClick={() => {
                            navigate(
                              `/checkout/${orders[selectedorder]?.said}`
                            );
                          }}
                        >
                          <div className="icon">
                            <FontAwesomeIcon icon={faCreditCard} />
                          </div>{" "}
                          Jetzt bezahlen
                        </p>
                      </div>
                    </div>
                  </div>

                  <p
                    style={{
                      display:
                        orders[selectedorder]?.ordercompletion != 2
                          ? "flex"
                          : "none",
                    }}
                  >
                    Der Serivce ist noch nicht abgeschlossen oder wurde
                    abgewiesen, daher wurde keine Rechnung generiert.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <p>Wähle eine Bestellung aus, um Dir mehr Details anzuzeigen.</p>
          )}
        </div>
      </div>
    </>
  );
};
