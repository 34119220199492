import { faStripe } from "@fortawesome/free-brands-svg-icons";
import "./Css/checkout.css";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBarsProgress,
  faCheck,
  faCircleExclamation,
  faCircleNotch,
  faExclamationTriangle,
  faLocationDot,
  faMailBulk,
  faPaperPlane,
  faPhone,
  faStar,
  faTicket,
} from "@fortawesome/free-solid-svg-icons";
import { useContext, useEffect, useState } from "react";
import { usePostJSONData } from "./Hooks/postjsonData";
import { useNavigate, useParams } from "react-router-dom";
import { useGetData } from "./Hooks/getData";
import { SkillContext } from "./App";
import { useUpdateJSONData } from "./Hooks/updatejsonData";
import { toast } from "react-toastify";
import { ConfirmDelete } from "./Settings";

export const CheckOut = () => {
  const { isloggedin, setisloggedin, userdata, apiUrl } = useContext(SkillContext);

  //CHECK IF USER IS LOGGEDIN
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );

  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  const stripePromise = loadStripe(
    process.env.REACT_APP_FIND_MY_SERVICE_STRIPE_PUB_KEY
  );

  const [paymentchoose, setpaymentchoose] = useState(1);

  const params = useParams();

  const orderID = params.orderid;

  const { data: updatedorder, update: updateOrder } = useUpdateJSONData(
    `${apiUrl}/api/services/orders/${orderID}`,
    () => {
      refetchOrders();
    }
  );

  const handleSumbit = async (
    event,
    stripe,
    elements,
    clientSecret,
    seterr,
    setisloading,
    paymentElement
  ) => {
    event.preventDefault();

    setisloading(true);

    if (!stripe || !elements) return;

    if (!paymentElement) {
      console.error("PaymentElement is not found.");
      return;
    }

    const { error } = await elements.submit();

    if (error) {
      seterr(error);
      setisloading(false);
      return;
    }

    const { error: err, paymentIntent } = await stripe.confirmPayment({
      elements,
      clientSecret,
      confirmParams: {
        return_url: `http://localhost:3000/checkout/${orderID}`,
        // receipt_email: "tizian.grabert@web.de",
      },
      redirect: "if_required",
    });

    if (err) {
      seterr(err.message);
      toast.error("Es gab ein Problem bei Ihrer Bezahlung: " + err.message)
      console.log("Problem by confirming setup: " + err.message);
    } else {
      // The SetupIntent has been confirmed and payment method is saved
      toast.success("Bezahlung erfolgreich.")
      console.log("Payment successfull"); 
      //refresh site
      setTimeout(() => {
        window.location.href = window.location.href;  // Seite wird nach 3 Sekunden neu geladen
      }, 3000);  // 3000 Millisekunden = 3 Sekunden
    }

    setisloading(false);
  };

  //get the single order
  const { data: orders, refetch: refetchOrders } = useGetData(
    `${apiUrl}/api/services/orders/${orderID}`
  );

  useEffect(() => {
    console.log("orders", orders);
    console.log(orders);
  }, [orders]);

  const [amount, setamount] = useState(0.01);
  const [ust, setust] = useState(0);
  const [pauschale, setpauschale] = useState(0);
  const [fullprice, setfullprice] = useState(0);

  useEffect(() => {
    if (orders && orders.length > 0) setamount(orders[0]?.price);
  }, [orders]);

  
  useEffect(() => {
    console.log("current amount");
    console.log(amount);
    setust(Math.round((amount - amount * 0.81) * 100) / 100);
    setpauschale(Math.round((amount - amount * 0.95) * 100) / 100);
  }, [amount]);

  useEffect(() => {
    setfullprice(Math.round((amount + ust + pauschale)  * 100 ) / 100);
  }, [amount, ust, pauschale]);

  useEffect(() => {
    console.log("fullprice:", fullprice)
    console.log("fullprice:", Math.round(fullprice * 100))
  }, [fullprice]);

  const navigate = useNavigate();

  const {
    data: externalPayment,
    isloading: externalloading,
    addjsonData: payExternal,
  } = usePostJSONData(`${apiUrl}/api/payments/payment/external`);

  useEffect(() => {
    console.log("external", externalPayment);
    console.log(externalPayment);

    if(externalPayment.length === 0) return;

    if (externalPayment?.err) {
      toast.error(externalPayment?.err);
    } else {
      toast.success(externalPayment?.msg);
          //refresh site
          setTimeout(() => {
            window.location.href = window.location.href;  // Seite wird nach 3 Sekunden neu geladen
          }, 3000);  // 3000 Millisekunden = 3 Sekunden
    }
  }, [externalPayment]);

  const [shDelOv, setshDelOv] = useState(false);

  return (
    <>

<ConfirmDelete
        shov={shDelOv}
        setshov={setshDelOv}
        img="card.svg"
        title="Automatische Karten Zahlung "
        message="Bist du Dir sicher, dass du diesen Service mit deiner hinterlegten Karte zahlen möchtest?"
        isdelete={false}
        action={() => {
         //pay
         payExternal({
          source: userdata[0]?.customer_id,
          destination: "acct_1P68gKGXIA5cCC89", //thats me alias my sacrow
          amount: Math.round(fullprice * 100),
          metadata: {
            customer_token: orders[0]?.srequester, //service requester
            provider_token: orders[0]?.AnbieterToken, //service provider
            noti_data: JSON.stringify({
              typ: [4, 4],
              title: orders[0]?.title,
              price: fullprice,
              start: orders[0]?.start,
              end: orders[0]?.end,
              sender: ["system", "system"],
              reicever: [
                orders[0]?.srequester,
                orders[0]?.sprovider,
              ],
              choosenPlan: orders[0]?.choosed_plan,
              sidref: orders[0]?.sidref,
              nusecase: 0,
              orderID: orders[0]?.said,
            }),
          },
        });
        }}
      />


      {userdata[0]?.usertoken != orders[0]?.usertoken ? (
        <>
        <div className="fetch-err-box" >
                      <div className="icon" style={{backgroundColor: "red"}}>
                        <FontAwesomeIcon icon={faExclamationTriangle}/>
                      </div>
                      <p>HOPPLA, das ist wohl nicht Ihre Bestellung.</p>
                    </div>
          
        </>
      ) : orders[0]?.paid ? (
        <>
        <div className="fetch-err-box" >
                      <div className="icon" style={{backgroundColor: "lightgreen"}}>
                        <FontAwesomeIcon icon={faCheck}/>
                      </div>
                      <p>Diese Bestellung ist bereits bezahlt.</p>
                    </div>
       
        </>
      ) : !orders[0]?.billCreatedon || orders[0]?.billCreatedon === "null" ? (
        <>
         <div className="fetch-err-box" style={{backgroundColor: "lightblue"}}>
                      <div className="icon">
                        <FontAwesomeIcon icon={faBarsProgress}/>
                      </div>
                      <p>Hoppla, dieser Service ist noch in Bearbeitung</p>
                    </div>
          
        </>
      ) : (
        <div className="checkout-wrapper">
          <div className="left">
            <div className="pay-section">
              <div className="top">
                <h1>
                  Bestellung{" "}
                  <span>
                    #{orders[0]?.said} ( {orders[0]?.title})
                  </span>
                </h1>

                <div className="bottom">
                  <span>Unbezahlt </span>
                  <p>&#x2022;</p>
                  <p>
                    (Zahlung Fällig:{" "}
                    <span>
                      {" "}
                      {
                        new Date(
                          new Date(orders[0]?.billCreatedon).setDate(
                            new Date(orders[0]?.billCreatedon).getDate() + 30
                          )
                        )
                          .toLocaleString("de-DE", {
                            timeZone: "Europe/Berlin",
                          })
                          .split(",")[0]
                      }
                    </span>
                    ){" "}
                  </p>
                </div>
              </div>

              <div className="pay-btm-btns">
                <button
                  onClick={() => {
                    setpaymentchoose(0);
                  }}
                >
                  Mit hinterlegter
                  <span>
                    <FontAwesomeIcon icon={faStripe} />
                  </span>
                  Karte zahlen
                </button>

                <button
                  onClick={() => {
                    setpaymentchoose(1);
                  }}
                >
                  Mit anderen Daten zahlen
                </button>
              </div>
            </div>

            <div className="pay-section">
              {paymentchoose === 0 ? (
                <button
                  onClick={() => {
                    setshDelOv(true);
                  }}
                >
                  
            {fullprice} €  (inkl. 19 % MwSt. und 5 % Servicegebühr) jetzt bezahlen
                </button>
              ) : (
                <>
                  <Elements
                    stripe={stripePromise}
                    options={{
                      appearance: {
                        theme: "night",
                        variables: {
                          colorPrimary: "rgb(123, 102, 206)",
                        },
                      },
                      mode: "payment",
                      amount: Math.round(fullprice * 100),
                      currency: "eur",
                    }}
                  >
                    <CheckoutPage
                      amount={Math.round(fullprice * 100)}
                      handleSumbit={handleSumbit}
                      btnaction={`${fullprice}€ (inkl. 19 % MwSt. und 5 % Servicegebühr) jetzt bezahlen`}
                      intent_type="now"
                      metadata={{
                        customer_token: orders[0]?.srequester, //service requester
                        provider_token: orders[0]?.AnbieterToken, //service provider
                        noti_data: JSON.stringify({
                          typ: [4, 4],
                          title: orders[0]?.title,
                          price: fullprice,
                          start: orders[0]?.start,
                          end: orders[0]?.end,
                          sender: ["system", "system"],
                          reicever: [
                            orders[0]?.srequester,
                            orders[0]?.sprovider,
                          ],
                          choosenPlan: orders[0]?.choosed_plan,
                          sidref: orders[0]?.sidref,
                          nusecase: 0,
                          orderID: orders[0]?.said,
                        }),
                      }}
                    />
                  </Elements>
                </>
              )}
            </div>
          </div>

          <div className="right">
            <div className="pay-section">
              <h2>Anbieter Details</h2>

              <div className="detail-item">
                <img src="./images/truck.jpg" alt="test" />
                <p>{orders[0]?.Anbieter}</p>
              </div>

              <div className="detail-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faPaperPlane} />
                </div>
                <p>{orders[0]?.AnbieterMail}</p>
              </div>

              <div className="detail-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faPhone} />
                </div>
                <p>{orders[0]?.AnbieterTel}</p>
              </div>

              <div className="detail-item">
                <div className="icon">
                  <FontAwesomeIcon icon={faLocationDot} />
                </div>
                <p>{orders[0]?.AnbieterLoc}</p>
              </div>
            </div>

            <div className="pay-section">
              <div className="icon">
                <FontAwesomeIcon icon={faTicket} />
              </div>

              <h2>Support</h2>
              <p>
                Bei Irgendwelchen Fragen, Problemen mit oder während des
                Services hilft unser Support Ihnen jederzeit gerne aus.
              </p>

              <button
                onClick={() => {
                  navigate("/support/tickets");
                }}
              >
                Frage stellen
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export const CheckoutPage = (props) => {
  const { apiUrl } = useContext(SkillContext);

  const stripe = useStripe();
  const elements = useElements();

  const [err, seterr] = useState("");
  const [isloading, setisloading] = useState(false);
  const [clientSecret, setclientSecret] = useState("");

  const {
    data: client_Secret,
    isloading: secretLoading,
    addjsonData: getClientSecret,
  } = usePostJSONData(`${apiUrl}/api/payments/payment/secret`);

  useEffect(() => {
    getClientSecret({
      amount: props.amount,
      intent_type: props.intent_type,
      metadata: props.metadata,
    });
  }, [props.amount]);

  useEffect(() => {
    console.log("SECREAT", client_Secret);
    console.log(client_Secret);
    console.log("INTENTID:");
    console.log(client_Secret.paymentIntentId);

    setclientSecret(client_Secret.clientSecret);
  }, [client_Secret]);

  const paymentElement =
    stripe && elements ? elements.getElement(PaymentElement) : null;

  return (
    <form
      onSubmit={(e) => {
        props.handleSumbit(
          e,
          stripe,
          elements,
          clientSecret,
          seterr,
          setisloading,
          paymentElement,
          client_Secret.paymentIntentId
        );
      }}
    >
      {secretLoading ? (
        <div className="loader">
          <FontAwesomeIcon icon={faCircleNotch} spin />
        </div>
      ) : (
        clientSecret && <PaymentElement />
      )}

      {err != "" && <p>{err.message}</p>}

      <button disabled={!stripe || !elements ? true : false}>
        {isloading ? (
          <div className="loader">
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        ) : (
          props.btnaction
        )}
      </button>
    </form>
  );
};

export const CardCheckout = (props) => {
  const { apiUrl } = useContext(SkillContext);

  const stripe = useStripe();
  const elements = useElements();

  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [customerId, setCustomerId] = useState(null);
  const [clientSecret, setclientSecret] = useState("");

  const CardElem = stripe && elements ? elements.getElement(CardElement) : null;

  const cardStyle = {
    style: {
      base: {
        color: "#fff", // Change text color to white
        fontFamily: "Arial, sans-serif",
        fontSize: "16px",
        "::placeholder": {
          color: "#888", // Customize placeholder color
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const {
    data: client_Secret,
    isloading: secretLoading,
    addjsonData: getClientSecret,
  } = usePostJSONData(`${apiUrl}/api/payments/payment/secret`);

  useEffect(() => {
    getClientSecret({
      amount: props.amount,
      intent_type: props.intent_type,
      metadata: props.metadata,
    });
  }, [props.amount]);

  useEffect(() => {
    setclientSecret(client_Secret.clientSecret);

    console.log(client_Secret);
  }, [client_Secret]);

  return (
    <form
      onSubmit={(e) => {
        props.handleSumbit(
          e,
          stripe,
          elements,
          setIsLoading,
          setError,
          CardElem,
          clientSecret,
          client_Secret.paymentIntentId
        );
      }}
      className="card-form"
    >
      <CardElement options={cardStyle} />
      {error && <div style={{ color: "red" }}>{error}</div>}
      <button disabled={!stripe || !elements ? true : false}>
        {isLoading ? (
          <div className="loader">
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        ) : (
          props.btnaction
        )}
      </button>
    </form>
  );
};
