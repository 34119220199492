import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Css/inbox.css";
import {
  faBagShopping,
  faBasketShopping,
  faBell,
  faCheck,
  faChevronDown,
  faChevronUp,
  faCircleExclamation,
  faCircleNotch,
  faEye,
  faEyeSlash,
  faHand,
  faInbox,
  faMessage,
  faMinus,
  faMoneyBill,
  faPencil,
  faReply,
  faRoadBarrier,
  faThumbsUp,
  faTrash,
  faWarning,
  faX,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { useGetData } from "./Hooks/getData";
import { banDates, banDateTime, sendMail, SkillContext } from "./App";
import { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Label } from "./Service";
import { usePostJSONData } from "./Hooks/postjsonData";
import DatePicker from "react-datepicker";
import { ServiceInput } from "./ServiceAdd";
import { toast } from "react-toastify";
import { useUpdateData } from "./Hooks/updateData";
import { useUpdateJSONData } from "./Hooks/updatejsonData";
import { useDeleteData } from "./Hooks/deleteData";
import emailjs from "@emailjs/browser";
import { ConfirmDelete } from "./Settings";

export const Inbox = () => {
  const { isloggedin, setisloggedin, userdata, apiUrl } =
    useContext(SkillContext);
  const navigate = useNavigate();

  /*CHECK IF USER IS LOGGEDIN*/
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );

  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  const params = useParams();

  const {
    data,
    err,
    isloading,
    refetch: refetchNotifications,
  } = useGetData(
    `${apiUrl}/api/users/user/${params.userid}/notifications/null`
  );

  useEffect(() => {
    console.log(data);
  }, [data]);

  //calc Unread messages

  const [unreadcount, setunreadcount] = useState(0);
  const [readcount, setreadcount] = useState(0);
  let ucounter = 0;
  let rcounter = 0;

  useEffect(() => {
    data.length > 0 &&
      data.forEach((note) => {
        !note.nisread ? ucounter++ : rcounter++;
      });

    setunreadcount(ucounter);
    setreadcount(rcounter);
  }, [data]);

  const [requestdesc, setrequestdesc] = useState("");
  const [subject, setsubject] = useState("");
  const [shsrequest, setshsrequest] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setendDate] = useState("");
  const [replytype, setreplytype] = useState(0);

  //Update the Notifications of this User

  const [currnid, setcurrnid] = useState(0);

  const {
    data: updatedNotification,
    err: uerror,
    isloading: uloading,
    update: updateNotification,
  } = useUpdateJSONData(
    ` ${apiUrl}/api/users/user/${params.userid}/notifications/${currnid}`,
    () => {
      refetchNotifications();
      console.log("refetching data");
    }
  );

  //Fetch the Notifications of this User
  const {
    data: addedNotification,
    err: nerror,
    isloading: nloading,
    addjsonData: addNotification,
  } = usePostJSONData(
    `${apiUrl}/api/users/user/${params.userid}/notifications/null`,
    () => {
      refetchNotifications();
    }
  );

  useEffect(() => {
    !addedNotification?.err && updateNotification({ optionchoosed: 1 });
  }, [addedNotification]);

  const [replyinfo, setreplyinfo] = useState([]);

  useEffect(() => {
    addedNotification?.err
      ? toast.error(addedNotification?.err)
      : toast.success(addedNotification?.msg);
  }, [addedNotification]);

  //Delete the Notifications of this User
  const {
    data: deletedNotification,
    err: derror,
    isloading: dloading,
    deleteData: deleteNotification,
  } = useDeleteData(
    ` ${apiUrl}/api/users/user/${params.userid}/notifications/${replyinfo[0]?.nid}`,
    () => {
      refetchNotifications();
    }
  );

  const {
    data: addedappointments,
    err: aerr,
    isloading: aloading,
    addjsonData: addAppointment,
  } = usePostJSONData(
    `${apiUrl}/api/services/appointments/${replyinfo[0]?.sidref}`
  );

  useEffect(() => {
    addedappointments?.err
      ? toast.error(addedappointments?.err)
      : toast.success(addedappointments?.msg);
  }, [addedappointments]);

  const [dubnotifications, setdubnotifications] = useState([]);
  const [inboxtyp, setinboxtyp] = useState(0);

  useEffect(() => {
    console.log("notis", data);
    console.log(data);

    data.length > 0 && setdubnotifications(data);
  }, [data]);

  useEffect(() => {
    if (inboxtyp === 0) {
      setdubnotifications(data);
    } else if (inboxtyp === 1) {
      data.length > 0 &&
        setdubnotifications(data.filter((note) => note.nisread == 0));
    } else {
      data.length > 0 &&
        setdubnotifications(data.filter((note) => note.nisread == 1));
    }
  }, [inboxtyp]);

  const [bannedDates, setbanneddates] = useState([]);

  const { data: appointments, refetch: refetchAppointments } = useGetData(
    `${apiUrl}/api/services/appointments/${replyinfo[0]?.sidref}`
  );

  const [amount, setamount] = useState(0.01);
  const [ust, setust] = useState(0);
  const [pauschale, setpauschale] = useState(0);
  const [fullprice, setfullprice] = useState(0);

  useEffect(() => {
    console.log("replyinfo: ", replyinfo);
    if (replyinfo.length > 0) {
      refetchAppointments();
      setamount(replyinfo[0]?.price);
    }
  }, [replyinfo]);

  useEffect(() => {
    console.log("current amount");
    console.log(amount);
    setust(Math.round((amount - amount * 0.81) * 100) / 100);
    setpauschale(Math.round((amount - amount * 0.95) * 100) / 100);
  }, [amount]);

  useEffect(() => {
    console.log("fullprice: " + fullprice);
    setfullprice(Math.round((amount + ust + pauschale) * 100) / 100);
  }, [amount, ust, pauschale]);

  const excludedTimes = [];

  useEffect(() => {
    if (appointments && appointments.length > 0)
      banDates(appointments, setbanneddates, bannedDates);
  }, [appointments]);

  useEffect(() => {
    startDate &&
      banDateTime(excludedTimes, startDate, bannedDates, appointments);
  }, [startDate, appointments, bannedDates]);

  useEffect(() => {
    endDate && banDateTime(excludedTimes, endDate, bannedDates, appointments);
  }, [endDate, appointments, bannedDates]);

  const { data: updatedappointments, update: updateAppointments } =
    useUpdateJSONData(
      `${apiUrl}/api/services/appointments/${replyinfo[0]?.sidref}`
    );

  useEffect(() => {
    updatedappointments?.err
      ? toast.error(updatedappointments?.err)
      : toast.success(updatedappointments?.msg);
  }, [updatedappointments]);

  const {
    data: settingsData,
    err: serr,
    isloading: sloading,
    refetch: fetchSettings,
  } = useGetData(`${apiUrl}/api/users/settings/${replyinfo[0]?.nsender}`);

  useEffect(() => {
    if (replyinfo.length > 0) {
      console.log("replyinfo", replyinfo);

      if (replyinfo[0]?.ntyp === 1) {
        console.log("Wir haben eine Anfrage kunde = sender");
        console.log("sender:" + replyinfo[0]?.nsender);
        console.log("receiver:" + replyinfo[0]?.nreicever);
      } else if (replyinfo[0]?.ntyp === 6) {
        console.log("Wir haben eine Änderung kunde = receiver?");
        console.log("sender:" + replyinfo[0]?.nsender);
        console.log("receiver:" + replyinfo[0]?.nreicever);
      }

      fetchSettings();
    }
  }, [replyinfo]);

  const {
    data: createdPayment,
    isloading: customercreatingloading,
    addjsonData: capturePayment,
  } = usePostJSONData(`${apiUrl}/api/payments/create-payment`);

  useEffect(() => {
    console.log("PAyment captured: ", createdPayment);
  }, [createdPayment]);

  const { data: sentMail, addjsonData: sendMail } = usePostJSONData(
    `${apiUrl}/api/services/user/send/mail`
  );

  /*
  useEffect(() => {
    if (createdPayment.length > 0) {
      // Handle the result of the payment
      if (createdPayment[0]?.status === "succeeded") {
        console.log("Payment successful");
        toast.success("Anfrage angenommen, Zahlung abgewickelt.");

        //add Appointment with paid 0
        !addedNotification?.err &&
          addAppointment({
            startdate: replyinfo[0]?.nstartdate,
            enddate: replyinfo[0]?.nenddate,
            title: `${replyinfo[0]?.nsubject
              .match(/"([^"]*)"/g)[0]
              .slice(
                1,
                replyinfo[0]?.nsubject.match(/"([^"]*)"/g)[0].length - 1
              )} `,
            srequester: replyinfo[0]?.nsender,
            paid: 1, //paid 1 == its already paid!
          });
      } else {
        console.error("Payment failed");
        console.log("PAyment failed");
        toast.error(
          "Anfrage angenommen, Zahlung aber verweigert. Sie müssen Nun später zahlen."
        );
        //add Appointment with paid 0
        !addedNotification?.err &&
          addAppointment({
            startdate: replyinfo[0]?.nstartdate,
            enddate: replyinfo[0]?.nenddate,
            title: `${replyinfo[0]?.nsubject
              .match(/"([^"]*)"/g)[0]
              .slice(
                1,
                replyinfo[0]?.nsubject.match(/"([^"]*)"/g)[0].length - 1
              )} `,
            srequester: replyinfo[0]?.nsender,
            paid: 0, //0 = not paid yet!
            choosed_plan: replyinfo[0]?.choosenPlan,
          });
      }
    } else {
      console.log("creteadpayment empty");
    }
  }, [createdPayment]);
  */

  const [shov, setshov] = useState(false);

  return (
    <>
      <MessageOverlay
        shov={shov}
        setshov={setshov}
        data={replyinfo}
        setshsrequest={setshsrequest}
        setreplytype={setreplytype}
        setreplyinfo={setreplyinfo}
        nid={currnid}
      />

      <div
        className="request-overlay"
        style={{ display: shsrequest ? "flex" : "none" }}
      >
        <div className="service-request-modal">
          <div className="request-modal-top">
            <h2>
              {replytype === 0
                ? "Ich stimme zu ..."
                : replytype === 1
                ? "Ich ändere ab ..."
                : replytype === 2
                ? "Ich lehne ab ..."
                : "Antwort"}
            </h2>

            <div
              className="close"
              onClick={() => {
                setshsrequest(false);
              }}
            >
              <FontAwesomeIcon icon={faXmark} />
            </div>
          </div>

          <div className="reqest-modal-bottom">
            <div className="request-desc-wrapper">
              {replytype === 0 ? (
                <>
                  <div className="desc-area">
                    <label>
                      {" "}
                      Beschreibung <span>*</span>
                    </label>
                    <div className="text-area">
                      <textarea
                        value={requestdesc}
                        placeholder="Zusätzliche Infomrationen, die der Kunde bei Ihrer Zusage beachten sollte ..."
                        onChange={(e) => {
                          setrequestdesc(e.target.value);
                        }}
                      ></textarea>
                      <p>
                        <span
                          style={{
                            color:
                              requestdesc.length > 3000
                                ? "rgb(207, 70, 70)"
                                : "white",
                          }}
                        >
                          {requestdesc.length}
                        </span>{" "}
                        / 3000
                      </p>
                    </div>
                  </div>
                </>
              ) : replytype === 1 ? (
                <>
                  <div className="desc-area">
                    <label>
                      {" "}
                      Beschreibung <span>*</span>
                    </label>
                    <div className="text-area">
                      <textarea
                        value={requestdesc}
                        placeholder="Zusätzliche Infomrationen, die der Kunde aufgrund der Abänderung beachten sollte ..."
                        onChange={(e) => {
                          setrequestdesc(e.target.value);
                        }}
                      ></textarea>
                      <p>
                        <span
                          style={{
                            color:
                              requestdesc.length > 3000
                                ? "rgb(207, 70, 70)"
                                : "white",
                          }}
                        >
                          {requestdesc.length}
                        </span>{" "}
                        / 3000
                      </p>
                    </div>
                  </div>

                  <div className="date-area">
                    <div className="date-wrapper">
                      <label>
                        Terminstart <span>*</span>
                      </label>

                      <DatePicker
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                        excludeTimes={excludedTimes}
                        placeholderText="Wähle einen Starttermin"
                        dateFormat="yyyy-MM-dd"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                      />
                    </div>

                    <div className="date-wrapper">
                      <label>
                        Terminende <span>*</span>
                      </label>
                      <DatePicker
                        selected={endDate}
                        minDate={startDate}
                        onChange={(date) => setendDate(date)}
                        excludeTimes={excludedTimes}
                        placeholderText="Wähle einen Endtermin"
                        dateFormat="yyyy-MM-dd"
                        showTimeSelect
                        timeFormat="HH:mm"
                        timeIntervals={15}
                      />
                    </div>
                  </div>
                </>
              ) : replytype === 2 ? (
                <>
                  <div className="desc-area">
                    <label> Beschreibung</label>
                    <div className="text-area">
                      <textarea
                        value={requestdesc}
                        placeholder="Warum ich absage ..."
                        onChange={(e) => {
                          setrequestdesc(e.target.value);
                        }}
                      ></textarea>
                      <p>
                        <span
                          style={{
                            color:
                              requestdesc.length > 3000
                                ? "rgb(207, 70, 70)"
                                : "white",
                          }}
                        >
                          {requestdesc.length}
                        </span>{" "}
                        / 3000
                      </p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <ServiceInput
                    label="Betreff"
                    type="text"
                    name="subject"
                    important={true}
                    setvalue={setsubject}
                  />

                  <div className="desc-area">
                    <label>
                      {" "}
                      Beschreibung <span>*</span>
                    </label>
                    <div className="text-area">
                      <textarea
                        value={requestdesc}
                        placeholder="Was ich Ihnen sagen wollte ..."
                        onChange={(e) => {
                          setrequestdesc(e.target.value);
                        }}
                      ></textarea>
                      <p>
                        <span
                          style={{
                            color:
                              requestdesc.length > 3000
                                ? "rgb(207, 70, 70)"
                                : "white",
                          }}
                        >
                          {requestdesc.length}
                        </span>{" "}
                        / 3000
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>

            <div className="step-wrapper">
              <button
                onClick={() => {
                  if (replytype === 0) {
                    if (replyinfo[0]?.nusecase === 0) {
                      //add APPOINTMENT
                      addNotification({
                        typ: 4,
                        subject: `Anfrage für Service "${replyinfo[0]?.nsubject
                          .match(/"([^"]*)"/g)[0]
                          .slice(
                            1,
                            replyinfo[0]?.nsubject.match(/"([^"]*)"/g)[0]
                              .length - 1
                          )}" angenommen.`,
                        text: requestdesc,
                        startdate: replyinfo[0]?.nstartdate,
                        enddate: replyinfo[0]?.nenddate, //äquivalent zu null nur für datum
                        sender: userdata[0]?.usertoken,
                        reicever: replyinfo[0]?.nsender,
                        choosenPlan: replyinfo[0]?.choosenPlan,
                        sidref: replyinfo[0]?.sidref,
                        nusecase: 0,
                        nlink: null,
                        paymentOptionchoosed:
                          replyinfo[0]?.paymentOptionchoosed, //1 = später zahlen, 0 = direkt zahlen
                      });

                      if (
                        !addedNotification?.err &&
                        settingsData[2]?.active &&
                        settingsData.length > 0
                      ) {
                        /* sendMail({
              to: replyinfo[0]?.email,
              subject: `Anfrage für Service "${replyinfo[0]?.nsubject
                .match(/"([^"]*)"/g)[0]
                .slice(
                  1,
                  replyinfo[0]?.nsubject.match(/"([^"]*)"/g)[0].length - 1
                )}" angenommen.`,
              text: requestdesc,
              html: "Test",
            });*/
                      }

                      //add Appointment with paid 1
                      !addedNotification?.err &&
                        addAppointment({
                          startdate: replyinfo[0]?.nstartdate,
                          enddate: replyinfo[0]?.nenddate,
                          title: `${replyinfo[0]?.nsubject
                            .match(/"([^"]*)"/g)[0]
                            .slice(
                              1,
                              replyinfo[0]?.nsubject.match(/"([^"]*)"/g)[0]
                                .length - 1
                            )} `,
                          srequester:
                            replyinfo[0]?.ntyp === 1
                              ? replyinfo[0]?.nsender
                              : replyinfo[0]?.ntyp === 6
                              ? replyinfo[0]?.nreicever
                              : "",
                          paid: 0, //default 0 (if payment gets payed it is set to 1 in BackEnd)
                          choosed_plan: replyinfo[0]?.choosenPlan,
                        });

                      /*!!! CAPTURE OF PAYMENT !!!*/
                      capturePayment({
                        amount: fullprice * 100,
                        curr: "eur",
                        customerID: replyinfo[0]?.SenderCustomer_id,
                        metadata: {
                          customer_token:
                            replyinfo[0]?.ntyp === 1
                              ? replyinfo[0]?.nsender
                              : replyinfo[0]?.ntyp === 6
                              ? replyinfo[0]?.nreicever
                              : "", //service requester
                          provider_token:
                            replyinfo[0]?.ntyp === 1
                              ? replyinfo[0]?.nreicever
                              : replyinfo[0]?.ntyp === 6
                              ? replyinfo[0]?.nsender
                              : "", //service provider
                          noti_data: JSON.stringify({
                            typ: [4], //sucess to sender
                            title: replyinfo[0]?.subject,
                            price: fullprice,
                            start: replyinfo[0]?.nstartdate,
                            end: replyinfo[0]?.nenddate,
                            sender: [
                              replyinfo[0]?.ntyp === 1
                                ? replyinfo[0]?.nreicever
                                : replyinfo[0]?.ntyp === 6
                                ? replyinfo[0]?.nsender
                                : "",
                            ],
                            reicever: [
                              replyinfo[0]?.ntyp === 1
                                ? replyinfo[0]?.nsender
                                : replyinfo[0]?.ntyp === 6
                                ? replyinfo[0]?.nreicever
                                : "",
                            ],
                            choosenPlan: replyinfo[0]?.choosenPlan,
                            sidref: replyinfo[0]?.sidref,
                            nusecase: 0,
                            text: requestdesc,
                            payment_mode: "now", //SET ORDER ID HERE!!!!
                          }),
                        },
                      });
                    } else {
                      //update APPOINTMENT
                      if (
                        !addedNotification?.err &&
                        settingsData[2]?.active &&
                        settingsData.length > 0
                      ) {
                        /*   sendMail({
                          to: replyinfo[0]?.email,
                          subject: `Termin für Service "${replyinfo[0]?.nsubject
                            .match(/"([^"]*)"/g)[0]
                            .slice(
                              1,
                              replyinfo[0]?.nsubject.match(/"([^"]*)"/g)[0].length - 1
                            )}" geändert auf ${replyinfo[0]?.nstartdate} - ${
                            replyinfo[0]?.nenddate
                          }.`,
                          text: requestdesc,
                          html: "Test",
                        });*/
                      }

                      //NOCH SCHREIBEN!!!! -> PRIMARY HIER PER BODY ÜBER>GEBEN???
                      !addedNotification?.err &&
                        updateAppointments({
                          startdate: replyinfo[0]?.nstartdate,
                          enddate: replyinfo[0]?.nenddate,
                          title: `${replyinfo[0]?.nsubject
                            .match(/"([^"]*)"/g)[0]
                            .slice(
                              1,
                              replyinfo[0]?.nsubject.match(/"([^"]*)"/g)[0]
                                .length - 1
                            )} `,
                          srequester: replyinfo[0]?.nsender,
                          said: replyinfo[0]?.appointmentref,
                        });

                      addNotification({
                        typ: 4,
                        subject: `Termin für Service "${replyinfo[0]?.nsubject
                          .match(/"([^"]*)"/g)[0]
                          .slice(
                            1,
                            replyinfo[0]?.nsubject.match(/"([^"]*)"/g)[0]
                              .length - 1
                          )}" geändert auf ${replyinfo[0]?.nstartdate} - ${
                          replyinfo[0]?.nenddate
                        }.`,
                        text: requestdesc,
                        startdate: replyinfo[0]?.nstartdate,
                        enddate: replyinfo[0]?.nenddate, //äquivalent zu null nur für datum
                        sender: userdata[0]?.usertoken,
                        reicever: replyinfo[0]?.nsender,
                        choosenPlan: replyinfo[0]?.choosenPlan,
                        sidref: replyinfo[0]?.sidref,
                        nusecase: 1,
                        nlink: null,
                        paymentOptionchoosed:
                          replyinfo[0]?.paymentOptionchoosed, //1 = später zahlen, 0 = direkt zahlen
                      });
                    }

                    !addedNotification?.err &&
                      updateNotification({ optionchoosed: 1 });
                  } else if (replytype === 1) {
                    if (replyinfo[0]?.nusecase === 0) {
                      addNotification({
                        typ: 6,
                        subject: `Bedingung für Anfrage für Service "${replyinfo[0]?.nsubject
                          .match(/"([^"]*)"/g)[0]
                          .slice(
                            1,
                            replyinfo[0]?.nsubject.match(/"([^"]*)"/g)[0]
                              .length - 1
                          )}" angepasst.`,
                        text: requestdesc,
                        startdate: startDate,
                        enddate: endDate,
                        sender: userdata[0]?.usertoken,
                        reicever: replyinfo[0]?.nsender,
                        choosenPlan: replyinfo[0]?.choosenPlan,
                        sidref: replyinfo[0]?.sidref,
                        nusecase: 0,
                        nlink: null,
                        paymentOptionchoosed:
                          replyinfo[0]?.paymentOptionchoosed, //1 = später zahlen, 0 = direkt zahlen
                      });
                    } else {
                      addNotification({
                        typ: 6,
                        subject: `Gegenvorschlag für Terminänderung für Service "${replyinfo[0]?.nsubject
                          .match(/"([^"]*)"/g)[0]
                          .slice(
                            1,
                            replyinfo[0]?.nsubject.match(/"([^"]*)"/g)[0]
                              .length - 1
                          )}".`,
                        text: requestdesc,
                        startdate: replyinfo[0]?.nstartdate,
                        enddate: replyinfo[0]?.nenddate, //äquivalent zu null nur für datum
                        sender: userdata[0]?.usertoken,
                        reicever: replyinfo[0]?.nsender,
                        choosenPlan: replyinfo[0]?.choosenPlan,
                        sidref: replyinfo[0]?.sidref,
                        nusecase: 1,
                        nlink: null,
                        paymentOptionchoosed:
                          replyinfo[0]?.paymentOptionchoosed, //1 = später zahlen, 0 = direkt zahlen
                      });
                    }

                    !addedNotification?.err &&
                      updateNotification({ optionchoosed: 1 });
                  } else if (replytype === 2) {
                    if (replyinfo[0]?.nusecase === 0) {
                      addNotification({
                        typ: 5,
                        subject: `Anfrage für Service "${replyinfo[0]?.nsubject
                          .match(/"([^"]*)"/g)[0]
                          .slice(
                            1,
                            replyinfo[0]?.nsubject.match(/"([^"]*)"/g)[0]
                              .length - 1
                          )}" abgelehnt.`,
                        text: requestdesc,
                        startdate: "0000-00-00",
                        enddate: "0000-00-00", //äquivalent zu null nur für datum
                        sender: userdata[0]?.usertoken,
                        reicever: replyinfo[0]?.nsender,
                        choosenPlan: replyinfo[0]?.choosenPlan,
                        sidref: replyinfo[0]?.sidref,
                        nusecase: 0,
                        nlink: null,
                        paymentOptionchoosed:
                          replyinfo[0]?.paymentOptionchoosed, //1 = später zahlen, 0 = direkt zahlen
                      });
                    } else {
                      addNotification({
                        typ: 5,
                        subject: `Anfrage für Terminänderung des Service "${replyinfo[0]?.nsubject
                          .match(/"([^"]*)"/g)[0]
                          .slice(
                            1,
                            replyinfo[0]?.nsubject.match(/"([^"]*)"/g)[0]
                              .length - 1
                          )}" abgelehnt.`,
                        text: requestdesc,
                        startdate: replyinfo[0]?.nstartdate,
                        enddate: replyinfo[0]?.nenddate, //äquivalent zu null nur für datum
                        sender: userdata[0]?.usertoken,
                        reicever: replyinfo[0]?.nsender,
                        choosenPlan: replyinfo[0]?.choosenPlan,
                        sidref: replyinfo[0]?.sidref,
                        nusecase: 1,
                        nlink: null,
                        paymentOptionchoosed:
                          replyinfo[0]?.paymentOptionchoosed, //1 = später zahlen, 0 = direkt zahlen
                      });

                      !addedNotification?.err &&
                        updateNotification({ optionchoosed: 1 });
                    }
                  } else {
                    addNotification({
                      typ: 2,
                      subject: subject,
                      text: requestdesc,
                      sender: userdata[0]?.usertoken,
                      startdate: "0000-00-00", //äquivalent zu null nur für datum
                      enddate: "0000-00-00", //äquivalent zu null nur für datum
                      reicever: replyinfo[0]?.nsender,
                      choosenPlan: null, //when that is Null dont show?!
                      sidref: replyinfo[0]?.sidref,
                      nusecase: 0,
                      nlink: null,
                      paymentOptionchoosed: replyinfo[0]?.paymentOptionchoosed, //1 = später zahlen, 0 = direkt zahlen
                    });
                  }

                  //onClick of 'Abschicken', close the overlay
                  setshsrequest(false);
                  setshov(false);
                }}
              >
                Abschicken
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="inbox-wrapper">
        <h2>Posteingang</h2>
        <div className="inbox-top">
          <div
            className="inbox-item"
            style={{
              borderBottom:
                inboxtyp === 0
                  ? "2px solid var(--main-bg-accent-light)"
                  : "2px solid transparent",
            }}
            onClick={() => {
              setinboxtyp(0);
            }}
          >
            <p>Alle</p>
            <p>{data.length > 0 ? data.length : 0}</p>
          </div>

          <div
            className="inbox-item"
            style={{
              borderBottom:
                inboxtyp === 1
                  ? "2px solid var(--main-bg-accent-light)"
                  : "2px solid transparent",
            }}
            onClick={() => {
              setinboxtyp(1);
            }}
          >
            <p>Ungelesen</p>
            <p>{unreadcount}</p>
          </div>

          <div
            className="inbox-item"
            style={{
              borderBottom:
                inboxtyp === 2
                  ? "2px solid var(--main-bg-accent-light)"
                  : "2px solid transparent",
            }}
            onClick={() => {
              setinboxtyp(2);
            }}
          >
            <p>Gelesen</p>
            <p>{readcount}</p>
          </div>
        </div>

        <div className="inbox-bottom">
          <table>
            <thead>
              <tr>
                <th>Nachricht</th>
                <th>Absender</th>
                <th>Absendedatum</th>
                <th>Optionen</th>
              </tr>
            </thead>

            <tbody>
              {dubnotifications.length > 0 ? (
                dubnotifications.map((data) => {
                  return (
                    <MessageItem
                      setshinfo={setshov}
                      data={data}
                      nid={data.nid}
                      typ={data.ntyp}
                      subject={data.nsubject}
                      sender={data.username}
                      senderimg={data.uimg}
                      senddate={
                        new Date(data.nsenddate)
                          .toLocaleString("de-DE", {
                            timeZone: "Europe/Berlin",
                          })
                          .split(",")[0]
                      }
                      sendermail={data.email}
                      text={data.ntext}
                      startdate={new Date(data.nstartdate).toLocaleString(
                        "de-DE",
                        {
                          timeZone: "Europe/Berlin",
                        }
                      )}
                      enddate={new Date(data.nenddate).toLocaleString("de-DE", {
                        timeZone: "Europe/Berlin",
                      })}
                      isread={data.nisread}
                      pid={data.choosenPlan}
                      optionchoosed={data.optionchoosed}
                      userid={params.userid}
                      shsrequest={shsrequest}
                      setshsrequest={setshsrequest}
                      setreplytype={setreplytype}
                      setreplyinfo={setreplyinfo}
                      refetch={refetchNotifications}
                      setcurrnid={setcurrnid}
                      link={data.nlink}
                      ptitle={data.plan}
                      pprice={data.price}
                    />
                  );
                })
              ) : (
                <td colSpan={4}>
                  <div className="fetch-err-box">
                    <div className="icon">
                      <FontAwesomeIcon icon={faInbox} />
                    </div>
                    <p>Dein Posteingang ist momentan leer.</p>
                  </div>
                </td>
              )}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

const MessageOverlay = (props) => {
  const { apiUrl } = useContext(SkillContext);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(
    `${apiUrl}/api/users/images/${props.data[0]?.uimg}/type/users`
  );

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  useEffect(() => {
    refetchImage();
  }, [props.data]);

  return (
    <div
      className="ticket-add-overlay"
      style={{ display: props.shov ? "flex" : "none" }}
    >
      <div className="overlay-adder-wrapper">
        {props.data.length === 0 ? (
          <div className="loader">
            <FontAwesomeIcon icon={faCircleNotch} spin />
          </div>
        ) : (
          <>
            <div className="content-top">
              <div className="left">
                <h2>Nachricht</h2>

                <p>
                  {
                    new Date(props.data[0]?.nsenddate)
                      .toLocaleString("de-DE", {
                        timeZone: "Europe/Berlin",
                      })
                      .split(",")[0]
                  }
                </p>
              </div>

              <div className="right">
                <div
                  className="icon"
                  onClick={() => {
                    props.setshov(false);
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </div>
            </div>
            <div className="table-msg-wrapper">
              <div className="msg-wrapper-top">
                <div className="msg-top-inf">
                  <div className="left">
                    <div className="sender">
                      {isImageLoading ? (
                        <div className="loader">
                          <FontAwesomeIcon icon={faCircleNotch} spin />
                        </div>
                      ) : (
                        <img src={Image} alt="user-img" loading="lazy" />
                      )}

                      <div className="s-text">
                        <p>{props.data[0]?.username}</p>
                        <p>{props.data[0]?.email}</p>
                      </div>
                    </div>
                  </div>

                  <div className="right">
                    <p>
                      {props.data[0]?.ntyp === 1
                        ? "Gewählter Terminraum:"
                        : props.data[0]?.ntyp === 6
                        ? "neuer Termin:"
                        : props.data[0]?.ntyp === 3
                        ? "Rechnungsdatum:"
                        : props.data[0]?.ntyp === 4
                        ? "Bestätigungsdatum:"
                        : "Erstellungsdatum:"}{" "}
                      <span
                        style={{
                          display:
                            props.data[0]?.ntyp === 1 ||
                            props.data[0]?.ntyp === 6
                              ? "inline"
                              : "none",
                        }}
                      >
                        {
                          new Date(props.data[0]?.nstartdate)
                            .toLocaleString("de-DE", {
                              timeZone: "Europe/Berlin",
                            })
                            .split(",")[0]
                        }{" "}
                      </span>
                      <span
                        style={{
                          display:
                            props.data[0]?.ntyp === 1 ||
                            props.data[0]?.ntyp === 6
                              ? "inline"
                              : "none",
                        }}
                      >
                        {" "}
                        -
                      </span>{" "}
                      <span
                        style={{
                          display:
                            props.data[0]?.ntyp === 1 ||
                            props.data[0]?.ntyp === 6
                              ? "inline"
                              : "none",
                        }}
                      >
                        {
                          new Date(props.data[0]?.nenddate)
                            .toLocaleString("de-DE", {
                              timeZone: "Europe/Berlin",
                            })
                            .split(",")[0]
                        }
                      </span>
                      <span
                        style={{
                          display:
                            props.data[0]?.ntyp != 1 && props.data[0]?.ntyp != 6
                              ? "inline"
                              : "none",
                        }}
                      >
                        {
                          new Date(props.data[0]?.nsenddate)
                            .toLocaleString("de-DE", {
                              timeZone: "Europe/Berlin",
                            })
                            .split(",")[0]
                        }
                      </span>
                    </p>
                  </div>
                </div>

                <div className="subj-wrapper">
                  <div className="msg-subj">
                    <h2>Betreff: </h2>
                    <p>{props.data[0]?.nsubject}</p>
                  </div>
                </div>
              </div>

              <div className="msg-wrapper-content">
                <p>{props.data[0]?.ntext}</p>

                <div
                  className="content-bottom"
                  style={{
                    justifyContent:
                      (props.data[0]?.ntyp != 1 && props.data[0]?.ntyp != 6) ||
                      props.data[0]?.optionchoosed
                        ? "end"
                        : "space-between",
                  }}
                >
                  <div
                    className="provid-options"
                    style={{
                      display:
                        (props.data[0]?.ntyp === 1 ||
                          props.data[0]?.ntyp === 6) &&
                        !props.data[0]?.optionchoosed
                          ? "flex"
                          : "none",
                    }}
                  >
                    <span
                      onClick={() => {
                        props.setshsrequest(true);
                        props.setreplytype(0);
                      }}
                    >
                      {" "}
                      <div className="icon">
                        <FontAwesomeIcon icon={faCheck} />{" "}
                      </div>{" "}
                      Zusagen
                    </span>
                    <span
                      onClick={() => {
                        props.setshsrequest(true);
                        props.setreplytype(1);
                      }}
                    >
                      {" "}
                      <div className="icon">
                        <FontAwesomeIcon icon={faPencil} />{" "}
                      </div>{" "}
                      Anpassen
                    </span>
                    <span
                      onClick={() => {
                        props.setshsrequest(true);
                        props.setreplytype(2);
                      }}
                    >
                      {" "}
                      <div className="icon">
                        <FontAwesomeIcon icon={faX} />{" "}
                      </div>{" "}
                      Absagen
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

const MessageItem = (props) => {
  const { apiUrl } = useContext(SkillContext);

  //Delete the Notifications of this User
  const {
    data: deletedNotification,
    err: derror,
    isloading: dloading,
    deleteData: deleteNotification,
  } = useDeleteData(
    ` ${apiUrl}/api/users/user/${props.userid}/notifications/${props.nid}`,
    () => {
      props.refetch();
    }
  );

  //Update the Notifications of this User
  const {
    data: updatedNotification,
    err: uerror,
    isloading: uloading,
    update: updateNotification,
  } = useUpdateJSONData(
    ` ${apiUrl}/api/users/user/${props.userid}/notifications/${props.nid}`,
    () => {
      props.refetch();
    }
  );

  useEffect(() => {
    deletedNotification?.err
      ? toast.error(deletedNotification?.err)
      : toast.success(deletedNotification?.msg);
  }, [deletedNotification]);

  useEffect(() => {
    console.log("choosenplan:");
    console.log(props.pid);
  }, []);

  const [shDelOv, setshDelOv] = useState(false);

  const {
    data: Image,
    setdata: setImage,
    isloading: isImageLoading,
    refetch: refetchImage,
  } = useGetData(`${apiUrl}/api/users/images/${props.senderimg}/type/users`);

  useEffect(() => {
    console.log("received image = ", Image);
    console.log(Image);

    return () => {
      URL.revokeObjectURL(Image);
    };
  }, [Image]);

  return (
    <>
      <ConfirmDelete
        shov={shDelOv}
        setshov={setshDelOv}
        img="trash.jpg"
        title="Willst du diese Nachricht wirklich lösen ?"
        message="Wenn du diese Nachricht löscht wird sie unwiederruflich entfernt."
        action={() => {
          deleteNotification();
        }}
        isdelete
      />

      <tr>
        <td
          onClick={() => {
            props.setshinfo(true);
            props.setcurrnid(props.nid);
            props.setreplyinfo([props.data]);
          }}
        >
          <div className="notify-text">
            <div
              className="icon"
              style={{
                backgroundColor:
                  props.typ === 1
                    ? "rgb(194, 187, 88)"
                    : props.typ === 2
                    ? "rgb(88, 125, 194)"
                    : props.typ === 3
                    ? "rgb(163, 116, 185)"
                    : props.typ === 4
                    ? "rgb(116, 185, 116)"
                    : props.typ === 6
                    ? "rgb(216, 171, 88)"
                    : "rgb(194, 88, 88)",

                color:
                  props.typ === 1
                    ? "rgb(150, 133, 58)"
                    : props.typ === 2
                    ? "rgb(58, 98, 150)"
                    : props.typ === 3
                    ? "rgb(139, 58, 150)"
                    : props.typ === 4
                    ? "rgb(76, 150, 58)"
                    : props.typ === 6
                    ? "rgb(155, 122, 61)"
                    : "rgb(150, 58, 58)",
              }}
            >
              <FontAwesomeIcon
                icon={
                  props.typ === 1
                    ? faHand
                    : props.typ === 2
                    ? faMessage
                    : props.typ === 3
                    ? faMoneyBill
                    : props.typ === 4
                    ? faCheck
                    : props.typ === 6
                    ? faRoadBarrier
                    : faWarning
                }
              />
            </div>

            <div className="notify-msg">
              <h2>Betreff: </h2>
              <p>{props.subject}</p>
            </div>
          </div>
        </td>

        <td
          onClick={() => {
            props.setshinfo(true);
            props.setcurrnid(props.nid);
            props.setreplyinfo([props.data]);
          }}
        >
          <div className="notfiy-sender">
            {isImageLoading ? (
              <div className="loader">
                <FontAwesomeIcon icon={faCircleNotch} spin />
              </div>
            ) : (
              <img src={Image} alt="user-img" loading="lazy" />
            )}

            <p>{props.sender}</p>
          </div>
        </td>

        <td
          onClick={() => {
            props.setshinfo(true);
            props.setcurrnid(props.nid);
            props.setreplyinfo([props.data]);
          }}
        >
          {props.senddate}
        </td>

        <td>
          <div className="options">
            <div
              className="icon"
              onClick={() => {
                updateNotification({ isread: props.isread ? 0 : 1 });
              }}
            >
              <FontAwesomeIcon icon={props.isread ? faEyeSlash : faEye} />
              <Label text={props.isread ? "gelesen" : "nicht gelesen"} />
            </div>

            <div
              className="icon"
              onClick={() => {
                props.setshsrequest(true);
                props.setreplytype(3);
                props.setreplyinfo([props.data]);
              }}
            >
              <FontAwesomeIcon icon={faReply} />
              <Label text="Antworten" />
            </div>
            <div
              className="icon"
              onClick={() => {
                setshDelOv(true);
              }}
            >
              <FontAwesomeIcon icon={faTrash} />
              <Label text="Löschen" />
            </div>
          </div>
        </td>
      </tr>
    </>
  );
};
