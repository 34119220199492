import "./Css/servicepending.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  fa1,
  fa2,
  fa3,
  fa4,
  faCheck,
  faCheckSquare,
  faChevronRight,
  faCircleQuestion,
  faCube,
  faDownload,
  faFilePdf,
  faForward,
  faForwardStep,
  faLocation,
  faLocationArrow,
  faLocationPin,
  faMinus,
  faMoneyBill,
  faSearch,
  faTrashCan,
  faUsersRays,
  faWarning,
  faX,
  faXmark,
  faXmarkSquare,
} from "@fortawesome/free-solid-svg-icons";

import { useContext, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useGetData } from "./Hooks/getData";
import { sendMail, SkillContext } from "./App";
import { ServiceInput } from "./ServiceAdd";
import { Label } from "./Service";
import { useUpdateJSONData } from "./Hooks/updatejsonData";
import { toast } from "react-toastify";
import { pdfGenerator } from "./pdfGenerator";
import { usePostJSONData } from "./Hooks/postjsonData";
import emailjs from "@emailjs/browser";
import { Automatic_Pay } from "./UsersPendingService";
import { EditIcon } from "./TicketDashboard";
import { useDeleteData } from "./Hooks/deleteData";
import { ConfirmDelete } from "./Settings";

export const ServicePending = () => {
  const { isloggedin, setisloggedin, userdata, apiUrl } = useContext(SkillContext);
  const navigate = useNavigate();
  const params = useParams();

  /*CHECK IF USER IS LOGGEDIN*/
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );
  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  const [search, setsearch] = useState("");

  const [completedsteps, setcompletedsteps] = useState(2);

  const [Progresses, setProgresses] = useState([]);

  const { data: orders, refetch: refetchOrders } = useGetData(
    `${apiUrl}/api/services/appointments/${params.sid}`
  );

  useEffect(() => {
    console.log("orders");
    console.log(orders);
  }, [orders]);

  const [selectedorder, setselectedorder] = useState(null);

  const { data: updatedorder, update: updateOrder } = useUpdateJSONData(
    `${apiUrl}/api/services/orders/${orders[selectedorder]?.said}`,
    () => {
      refetchOrders();
    }
  );

  //Fetch the Notifications of this User
  const {
    data: addedNotification,
    err: nerror,
    isloading: nloading,
    addjsonData: addNotification,
  } = usePostJSONData(
    `${apiUrl}/api/users/user/null/notifications/null`
  );

  const {
    data: settingsData,
    err: serr,
    isloading: sloading,
    refetch: fetchSettings,
  } = useGetData(
    `${apiUrl}/api/users/settings/${orders[selectedorder]?.srequester}`
  );

  const { data: sentMail, addjsonData: sendMail } = usePostJSONData(
    `${apiUrl}/api/services/user/send/mail`
  );

  useEffect(() => {
    console.log("selected orders");
    console.log(selectedorder);

    if (orders.length > 0 && selectedorder != undefined) {
      //make our check

      //check if finishedOn + 30 days is greator or = date.now() -> if yes set ; also set finishedOn if its still null to todays date
  /*    if (orders[selectedorder]?.finishedOn == null) {
        console.log("not yet finishedON set");
        orders[selectedorder]?.orderstatus == 3 &&
          updateOrder({
            finishedOn: new Date(),
          });
      } else {
        //check if automatic finished should do sth -> cause its set
        const finishstart = new Date(orders[selectedorder]?.finishedOn);
        const finishEnd = new Date(finishstart).setDate(
          new Date(finishstart).getDate() + 30
        );

        if (
          new Date().getTime() >= finishEnd &&
          orders[selectedorder]?.orderstatus < 4
        ) {
          console.log("updating");
          //automaticly set orderstatus to 4
          updateOrder({
            orderstatus: 4,
          });
        }
      }*/

      setcompletedsteps(orders[selectedorder]?.orderstatus);
    }

    if (
      orders[selectedorder]?.orderstatus == 3 &&
      !orders[selectedorder]?.cconfirmed
    ) {
     /* addNotification({
        typ: 2,
        subject: `Bestätigung des Auftrags #${orders[selectedorder]?.said}`,
        text: "Wir bitten Sie den durchgeführten Auftrag zu bestätigen, falls Sie Mängel oder Probleme festgestellt haben, melden Sie dies dem Support. Bei keiner Rückmeldung innerhalb 30 Tage wird dieser Service automatisch abgeshlossen und Sie erhalten die Rechnung",
        sender: "$2b$08$BjHnZJGVjILmHBfK.6hWEuczRLNnzvIQjUumzzasTnDce9nQKFBEa", //THIS IS A SYSTEM ACCOUNT?!?!?
        startdate: orders[selectedorder]?.start, //äquivalent zu null nur für datum
        enddate: orders[selectedorder]?.end, //äquivalent zu null nur für datum
        reicever: orders[selectedorder]?.srequester,
        choosenPlan: null, //when that is Null dont show?!
        sidref: orders[selectedorder]?.sidref,
        nusecase: 0,
        nlink: `/users/service/pending`, //für user seite machen wo alle rechnugnen für  services aufgelistet werden und darauf dann verlinken
      });

      updateOrder({
        cconfirmed: 1,
      });*/
    }

    setProgresses([
      {
        icon: fa1,
        title: "Auftragserteilung",
        startdate: new Date(orders[selectedorder]?.start)
          .toLocaleString("de-DE", {
            timeZone: "Europe/Berlin",
          })
          .split(",")[0],
        confirm: false,
        said: orders[selectedorder]?.said,
        orderstatus: orders[selectedorder]?.orderstatus,
      },
      {
        icon: fa2,
        title: "Durchführung",
        startdate: new Date(orders[selectedorder]?.start)
          .toLocaleString("de-DE", {
            timeZone: "Europe/Berlin",
          })
          .split(",")[0],
        enddate: new Date(orders[selectedorder]?.end)
          .toLocaleString("de-DE", {
            timeZone: "Europe/Berlin",
          })
          .split(",")[0],
        confirm: true,
        said: orders[selectedorder]?.said,
        orderstatus: orders[selectedorder]?.orderstatus,
      },
      {
        icon: fa3,
        title: "Auftragsabschluss",
        enddate: new Date(orders[selectedorder]?.end)
          .toLocaleString("de-DE", {
            timeZone: "Europe/Berlin",
          })
          .split(",")[0],
        confirm: true,
        said: orders[selectedorder]?.said,
        orderstatus: orders[selectedorder]?.orderstatus,
      },
      {
        icon: fa4,
        title: "Kundenbestätigung",
        enddate: new Date(new Date())
          .toLocaleString("de-DE", {
            timeZone: "Europe/Berlin",
          })
          .split(",")[0],
        confirm: false,
        said: orders[selectedorder]?.said,
        orderstatus: orders[selectedorder]?.orderstatus,
      },
    ]);
  }, [selectedorder, orders]);

  

  const orderdub = [];

  const [Orders, setOrders] = useState([]);

  useEffect(() => {
    orders.length > 0 && setOrders(orders);
  }, [orders]);

  const [searchtext, setsearchtext] = useState("");

  //search
  useEffect(() => {
    if (searchtext.length === 0) return setOrders(orders);

    orders.length > 0 &&
      orders.forEach((order) => {
        if (searchtext.includes("#")) {
          order.said == searchtext.split("#")[1] && orderdub.push(order);
        } else {
          order.said == searchtext && orderdub.push(order);
        }
      });

    setOrders(orderdub);
  }, [searchtext]);

  const [shwarning, setshwarning] = useState(false);
  const start = new Date(orders[selectedorder]?.start);
  const delDate = new Date(
    new Date(start).setFullYear(new Date(start).getFullYear() + 10)
  );

  const delTime = delDate.toLocaleString("de-DE", {
    timeZone: "Europe/Berlin",
  });

  /*check if User has an Open Ticket for this Service
-< only check when payment-paused is null
-< also make a message in container to say its paused until issue is resolved !!!
-< if yes update with payment-paused and set it to today
-< when there is payment-paused set, but there is no more ticket for this service 
    -< continue automatic payment from the time left from when the service was paused 
    -< pay the service and set paid to 1

     //get Active Tickets
  const { data: orderTickets, refetch: refetchOrderTickets } = useGetData(
    `${apiUrl}/api/services/active/tickets/order/${orders[selectedorder]?.said}`
  );

 */

  //get User
  /* 
 const {
    data: automatedPAy,
    isloading: payautomatload,
    addjsonData: AutomaticPay,
  } = usePostJSONData(`${apiUrl}/api/payments/pay-automatic`);

 
 
 const { data: userInfo, refetch: refetchUserInfo } = useGetData(
    `${apiUrl}/api/users/single/${orders[selectedorder]?.srequester}`
  );
*/
  useEffect(() => {
    if (orders.length > 0 && selectedorder != null) {
      console.log("selel order: ", orders[selectedorder]?.srequester);
      // refetchUserInfo();
      // refetchOrderTickets();
      fetchSettings();
    }
  }, [orders, selectedorder]);

  // const checkRef = useRef(true);

  useEffect(() => {
    if (
      selectedorder != undefined &&
      orders.length > 0 &&
      userdata.length > 0
    ) {
      /*
 
       const billStart = new Date(orders[selectedorder]?.billCreatedon);
 const billEnd = new Date(billStart).setDate(
        new Date(billStart).getDate() + 30
      ); //get from billCreated in 30 Days

      //Automatic Pay System
      if (checkRef.current) {
        Automatic_Pay(
          new Date(billStart).getTime(),
          billEnd,
          orders[selectedorder]?.payment_paused_at,
          orders[selectedorder]?.paid,
          orderTickets,
          orders[selectedorder],
          userInfo,
          updateOrder,
          AutomaticPay,
          200 //amount
        );

        console.log("executed payment function");
        checkRef.current = false; //set to false to only execute once
      }*/
    }
  }, [orders, selectedorder, userdata]);

  const [DropItem, setDropItem] = useState([]);

  const {
    data: removedOrder,
    err: oerr,
    isloading: oloading,
    deleteData: deleteOrder,
  } = useDeleteData(
    `  ${apiUrl}/api/services/orders/${orders[selectedorder]?.said}`,
    () => {
      refetchOrders();
    }
  );

  useEffect(() => {
    removedOrder?.err
      ? toast.error(removedOrder?.err)
      : toast.success(removedOrder?.msg);
  }, [removedOrder]);

  useEffect(() => {
    if (orders.length > 0 && selectedorder != undefined) {
      setDropItem([
        {
          id: 1,
          icon: faTrashCan,
          title: "Löschen",
          action: () => {
            console.log("Bestellung gelöscht!");
            setshDelOv(true)
          },
        },
      ]);
    }
  }, [orders, selectedorder]);


  const [shDelOv, setshDelOv] = useState(false);

  return (
    <>

<ConfirmDelete
        shov={shDelOv}
        setshov={setshDelOv}
        img="trash.jpg"
        title="Willst du deinen Bestellauftrag wirklich löschen ?"
        message="Dieser Auftrag wird unwiederruflich gelöscht."
        action={() => {
          deleteOrder({
            deleted_by_provider: 1
          });
        }}
        isdelete
      />


      <div className="pending-service-wrapper">
        <div className="s-left">
          <div className="left">
            <div className="top">
              <h2>Auftragsliste</h2>

              <div className="search-top">
                <div className="input-wrapper">
                  <div className="icon">
                    <FontAwesomeIcon icon={faSearch} />
                  </div>
                  <input
                    type="text"
                    name="search"
                    placeholder="Suche Auftrag ..."
                    onChange={(e) => {
                      setsearchtext(e.target.value);
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="orders-wrapper">
              {Orders.length > 0 ? (
                Orders.map((order, index) => {
                  return (
                    <OrderItem
                      id={order.said}
                      startdate={new Date(order.start).toLocaleString("de-DE", {
                        timeZone: "Europe/Berlin",
                      })}
                      enddate={new Date(order.end).toLocaleString("de-DE", {
                        timeZone: "Europe/Berlin",
                      })}
                      setselectedorder={setselectedorder}
                      index={index}
                      ordercompletion={order.ordercompletion}
                      orders={orders}
                      setshwarning={setshwarning}
                    />
                  );
                })
              ) : (
                <p>Keine Bestellung gefunden</p>
              )}
            </div>
          </div>
        </div>

        <div className="right">
          {orders[selectedorder] ? (
            <div className="right-detail-wrapper">
              <div
                className="warning-wrapper"
                style={{ display: shwarning ? "flex" : "none" }}
              >
                <div className="icon">
                  <FontAwesomeIcon icon={faWarning} />
                </div>
                <p>
                  Achtung, diese Bestellung wird automatisch am {delTime}{" "}
                  gelöscht.
                </p>

                <div
                  className="del"
                  onClick={() => {
                    setshwarning(!shwarning);
                  }}
                >
                  <FontAwesomeIcon icon={faXmark} />
                </div>
              </div>

              <div className="top">
                <div className="left">
                  <h2>Auftragsnummer</h2>
                  <p>
                    <span>#</span>
                    {orders[selectedorder]?.said}
                  </p>
                </div>

                <div className="right">
                  <span
                    style={{
                      backgroundColor:
                        orders[selectedorder]?.ordercompletion === 1
                          ? "rgb(228, 228, 123)"
                          : orders[selectedorder]?.ordercompletion === 2
                          ? "rgb(130, 214, 130)"
                          : "rgb(219, 94, 94)",
                      color:
                        orders[selectedorder]?.ordercompletion === 1
                          ? "rgb(172, 172, 46)"
                          : orders[selectedorder]?.ordercompletion === 2
                          ? "rgb(78, 143, 78)"
                          : "rgb(165, 21, 21)",
                      boxShadow:
                        orders[selectedorder]?.ordercompletion === 1
                          ? "0 0 7px 1px rgb(167, 167, 74)"
                          : orders[selectedorder]?.ordercompletion === 2
                          ? "0 0 7px 1px rgb(78, 143, 78)"
                          : "0 0 7px 1px rgb(165, 21, 21)",
                    }}
                  >
                    {orders[selectedorder]?.ordercompletion === 1
                      ? "In Bearbeitung"
                      : orders[selectedorder]?.ordercompletion === 2
                      ? "Abgeschlossen"
                      : "Abgelehnt"}
                  </span>

                  <EditIcon DropItem={DropItem} />
                </div>
              </div>

              <div className="progress-wrapper">
                {Progresses.length > 0 &&
                  Progresses.map((progress, index) => {
                    return (
                      <ProgressItem
                        icon={index < completedsteps ? faCheck : progress.icon}
                        title={progress.title}
                        startdate={progress.startdate}
                        enddate={progress.enddate}
                        confirm={
                          progress.confirm && completedsteps == index
                            ? true
                            : false
                        } //confirm is if confirm is set for item and the item before is completed
                        completed={index < completedsteps ? true : false}
                        id={progress.said}
                        orderstatus={progress.orderstatus}
                        refetchOrders={refetchOrders}
                      />
                    );
                  })}
              </div>

              <div className="detail-info">
                <div className="info-part">
                  <div className="part-h">
                    <div className="icon">
                      <FontAwesomeIcon icon={faCube} />
                    </div>
                    <p>Bestell Informationen</p>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Startdatum</p>
                    </div>
                    <div className="bottom">
                      <p>
                        {orders[selectedorder]?.start ? new Date(orders[selectedorder]?.start).toLocaleString(
                          "de-DE",
                          {
                            timeZone: "Europe/Berlin",
                          }
                        ) : "Entfernt"}
                      </p>
                    </div>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Enddatum</p>
                    </div>
                    <div className="bottom">
                      <p>
                         { orders[selectedorder]?.end ? new Date(orders[selectedorder]?.end).toLocaleString(
                          "de-DE",
                          {
                            timeZone: "Europe/Berlin",
                          }
                        ) : "Entfernt"}
                      </p>
                     
                    </div>
                  </div>
                </div>

                <div className="info-part">
                  <div className="part-h">
                    <div className="icon">
                      <FontAwesomeIcon icon={faLocationArrow} />
                    </div>
                    <p>Standort</p>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Kunden Standort</p>
                    </div>
                    <div className="bottom">
                      <p>{orders[selectedorder]?.location ? orders[selectedorder]?.location : "Entfernt"}</p>
                    </div>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Anbieter Standort</p>
                    </div>
                    <div className="bottom">
                      <p>{orders[selectedorder]?.sproviderloc ? orders[selectedorder]?.sproviderloc : "Entfernt"}</p>
                    </div>
                  </div>
                </div>

                <div className="info-part">
                  <div className="part-h">
                    <div className="icon">
                      <FontAwesomeIcon icon={faUsersRays} />
                    </div>
                    <p>Kunden Informationen</p>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Nutzername</p>
                    </div>
                    <div className="bottom">
                      <p>{orders[selectedorder]?.username}</p>
                    </div>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Email</p>
                    </div>
                    <div className="bottom">
                      <p>{orders[selectedorder]?.email}</p>
                    </div>
                  </div>

                  <div className="part-item">
                    <div className="top">
                      <p>Telefonnummer</p>
                    </div>
                    <div className="bottom">
                      <p>{orders[selectedorder]?.tel ? orders[selectedorder]?.tel : "/"}</p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="bill-content-wrapper">
                <div className="content-top">
                  <div className="top">
                    <div className="icon">
                      <FontAwesomeIcon icon={faMoneyBill} />
                    </div>
                    <p>Rechnung</p>
                  </div>

                  <div
                    className="paid-sec"
                    style={{
                      display:
                        orders[selectedorder]?.ordercompletion == 2
                          ? "flex"
                          : "none",
                    }}
                  >
                    <p>&#x2022;</p>

                    <div
                      className="paid-wrapper"
                      style={{
                        border:
                          orders[selectedorder]?.paid === 0
                            ? "1px solid rgb(209, 70, 70)"
                            : "1px solid rgb(90, 168, 90) ",
                        color:
                          orders[selectedorder]?.paid === 0
                            ? "rgb(212, 120, 120)"
                            : "rgb(90, 168, 90) ",
                      }}
                    >
                      <div className="icon">
                        <FontAwesomeIcon
                          icon={
                            orders[selectedorder]?.paid === 0
                              ? faXmarkSquare
                              : faCheckSquare
                          }
                        />
                      </div>
                      <p>
                        {orders[selectedorder]?.paid === 0
                          ? "Unbezahlt"
                          : "Bezahlt"}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="bill-wrapper">
                  <div
                    className="bill"
                    style={{
                      display:
                        orders[selectedorder]?.ordercompletion === 2
                          ? "flex"
                          : "none",
                    }}
                  >
                    <div className="icon">
                      <FontAwesomeIcon icon={faFilePdf} />
                    </div>
                    <div className="right">
                      <p>
                        Rechnung_#{orders[selectedorder]?.said}_
                        {new Date().toISOString().split("T")[0]}
                      </p>
                      <p
                        onClick={() => {
                          pdfGenerator(orders[selectedorder]);
                        }}
                      >
                        <div className="icon">
                          <FontAwesomeIcon icon={faDownload} />
                        </div>{" "}
                        Download
                      </p>
                    </div>
                  </div>

                  <p
                    style={{
                      display:
                        orders[selectedorder]?.ordercompletion != 2
                          ? "flex"
                          : "none",
                    }}
                  >
                    Der Serivce ist noch nicht abgeschlossen oder wurde
                    abgewiesen, daher wurde keine Rechnung generiert.
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <p>Wähle eine Bestellung aus, um Dir mehr Details anzuzeigen.</p>
          )}
        </div>
      </div>
    </>
  );
};

export const OrderItem = (props) => {
  const [currindex, setcurrindex] = useState(null);

  useEffect(() => {
    //get correct index of selected item
    if (props.orders && props.orders.length > 0) {
      props.orders.forEach((order, index) => {
        order.said == props.id && setcurrindex(index);
      });
    }

    //get index out of base arr (=orders)
  }, [props.id, props.orders]);

  return (
    <div
      className="order-item"
      onClick={() => {
        props.setselectedorder(currindex); //get said
        props.setshwarning(false);
        //set references of check to true
        //  props.checkRef.current = true;
      }}
    >
      <div className="left">
        <h3>
          <span>#</span>
          {props.id}
        </h3>

        <div className="range">
          <p>{props.startdate.split(",")[0]}</p>
          <p>
            <FontAwesomeIcon icon={faMinus} />
          </p>
          <p>{props.enddate.split(",")[0]}</p>
        </div>
      </div>

      <div className="right">
        <span
          style={{
            backgroundColor:
              props.ordercompletion === 1
                ? "rgb(228, 228, 123)"
                : props.ordercompletion === 2
                ? "rgb(130, 214, 130)"
                : "rgb(219, 94, 94)",
            color:
              props.ordercompletion === 1
                ? "rgb(172, 172, 46)"
                : props.ordercompletion === 2
                ? "rgb(78, 143, 78)"
                : "rgb(165, 21, 21)",
            boxShadow:
              props.ordercompletion === 1
                ? "0 0 7px 1px rgb(167, 167, 74)"
                : props.ordercompletion === 2
                ? "0 0 7px 1px rgb(78, 143, 78)"
                : "0 0 7px 1px rgb(165, 21, 21)",
          }}
        >
          {props.ordercompletion === 1
            ? "In Bearbeitung"
            : props.ordercompletion === 2
            ? "Abgeschlossen"
            : "Abgelehnt"}
        </span>
      </div>
    </div>
  );
};

export const ProgressItem = (props) => {
  const { apiUrl } = useContext(SkillContext);

  const { data: updatedorder, update: updateOrder } = useUpdateJSONData(
    `${apiUrl}/api/services/orders/${props.id}`,
    () => {
      props.refetchOrders();
    }
  );

  useEffect(() => {
    updatedorder?.err
      ? toast.error(updatedorder?.err)
      : toast.success(updatedorder?.msg);
  }, [updatedorder]);

  return (
    <div className="progress-item">
      <div className="left">
        <div className="left-top">
          <div className="icon-l">
            <FontAwesomeIcon icon={faForwardStep} />
          </div>
          <div className="lt-line"></div>
          <div
            className="icon"
            style={{
              backgroundColor: props.completed
                ? "rgb(71, 134, 71)"
                : "var(--main-bg-accent-light)",
              color: props.completed ? "rgb(31, 179, 31)" : "white",
              border: props.completed
                ? "1px solid rgb(31, 179, 31)"
                : "1px solid var( --light-bg-accent)",
            }}
          >
            <FontAwesomeIcon icon={props.icon} />
          </div>
          <div className="lt-line"></div>
          <div className="icon-r">
            <FontAwesomeIcon icon={faForwardStep} />
          </div>
        </div>

        <div className="info-text">
          <h3>{props.title}</h3>
          <div className="date-range">
            <p style={{ display: props.startdate ? "block" : "none" }}>
              {props.startdate}
            </p>
            <p
              style={{
                display: props.startdate && props.enddate ? "block" : "none",
              }}
            >
              -
            </p>
            <p style={{ display: props.enddate ? "block" : "none" }}>
              {props.enddate}
            </p>
          </div>

          <span
            style={{
              display: props.confirm && !props.completed ? "block" : "none",
            }}
            onClick={() => {
              updateOrder({ orderstatus: props.orderstatus + 1 });
            }}
          >
            Erledigen
          </span>
        </div>
      </div>
    </div>
  );
};
