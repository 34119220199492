import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Css/serviceadd.css";
import {
  faAdd,
  faArrowRight,
  faBold,
  faBox,
  faBoxesPacking,
  faBoxesStacked,
  faBrush,
  faCheck,
  faGripLines,
  faHeader,
  faHighlighter,
  faImage,
  faImages,
  faItalic,
  faLink,
  faListOl,
  faTurnDown,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { ContentTable, FAQWrapper } from "./ServiceDescription";
import { useContext, useEffect, useState } from "react";
import { usePostData } from "./Hooks/postData";
import { SkillContext } from "./App";
import { useGetData } from "./Hooks/getData";
import { useNavigate, useParams } from "react-router-dom";
import { useUpdateData } from "./Hooks/updateData";
import {
  EditorDraft,
  MyEditor,
  Notification,
  PlanItem,
  ServiceInput,
} from "./ServiceAdd";
import { EditorState, convertToRaw, convertFromRaw, RichUtils } from "draft-js";
import "draft-js/dist/Draft.css";
import { toast } from "react-toastify";

export const EditService = () => {
  const [addfaq, setaddfaq] = useState(false);
  const params = useParams();

  //faq and comp arr
  const [faqarr, setfaqarr] = useState([]);
  const [trows, settrows] = useState([]);

  const [answer, setanswer] = useState("");
  const [question, setquestion] = useState("");

  const [img, setimg] = useState("");
  const [sname, setsname] = useState("");
  const [smalldesc, setsmalldesc] = useState("");

  const [htmlContent, sethtmlcontent] = useState(EditorState.createEmpty());
  const [htmlraw, sethtmlraw] = useState("");
  const [region, setregion] = useState("");
  const [city, setcity] = useState("");
  const [service, setservice] = useState("");

  const [plans, setplans] = useState([]);

  const { data: fetchedplans, refetch: refetchPlans } = useGetData(
    `${apiUrl}/api/services/service/${params.serviceid}/plan`
  );

  //Plans
  const [bplan, setbplan] = useState(fetchedplans[0]?.price);
  const [splan, setsplan] = useState(fetchedplans[1]?.price);
  const [aiplan, setaiplan] = useState(fetchedplans[2]?.price);

  useEffect(() => {
    if (fetchedplans.length > 0) {
      setbplan(fetchedplans[0]?.price);
      setsplan(fetchedplans[1]?.price);
      setaiplan(fetchedplans[2]?.price);

      console.log("fetchedplans", fetchedplans);
      console.log(fetchedplans);
    }
  }, [fetchedplans]);

  const [updatedplans, setupdatedplans] = useState([]);

  useEffect(() => {
    setupdatedplans([
      {
        sppid: fetchedplans[0]?.sppid,
        plan: 1,
        price: bplan,
      },
      {
        sppid: fetchedplans[1]?.sppid,
        plan: 2,
        price: splan,
      },
      {
        sppid: fetchedplans[2]?.sppid,
        plan: 3,
        price: aiplan,
      },
    ]);
  }, [bplan, splan, aiplan, fetchedplans]);

  const formData = new FormData();
  formData.append("image", img);
  formData.append("sname", sname);
  formData.append("ssmalldesc", smalldesc);
  formData.append("sbigdesc", htmlraw);
  formData.append("faqs", JSON.stringify(faqarr));
  formData.append("comparr", JSON.stringify(plans)); //-< arr gets stringified -< just sent faqs seperatly?
  formData.append("sregion", region);
  formData.append("scity", city);
  formData.append("scategory", service);
  formData.append("plans", JSON.stringify(updatedplans));

  //htmlContent = editorState
  useEffect(() => {
    console.log("editorState:", htmlContent);

    sethtmlraw(JSON.stringify(convertToRaw(htmlContent.getCurrentContent())));
  }, [htmlContent]);
  /*SHOWNOTIFICATIOn -> put in context hook*/
  const [shnotify, setshnotify] = useState(false);

  const { isloggedin, setisloggedin, Region, City, Service, apiUrl } =
    useContext(SkillContext);
  const navigate = useNavigate();

  /*CHECK IF USER IS LOGGEDIN*/
  const { data: checkedSession, refetch } = useGetData(
    ` ${apiUrl}/api/users/session/check`
  );

  useEffect(() => {
    if (!checkedSession?.err) {
      setisloggedin(true); //this will fetch the user data again and this updates the userdata
    } else {
      isloggedin && setisloggedin(false);
      navigate("/auth/login");
    }
  }, [checkedSession]);
  /*CHECK IF USER IS LOGGEDIN*/

  //getServiceData
  //get param

  //Fetch the Service of this serviceID
  const { data: sdata, isloading: sloading } = useGetData(
    `${apiUrl}/api/services/single/${params.serviceid}`
  );

  //get ComparePlans
  const {
    data: cdata,
    err: cerr,
    isloading: cisloading,
  } = useGetData(
    `${apiUrl}/api/services/compares/${params.serviceid}`
  );

  //get FAQ ITEMS
  const {
    data: fdata,
    err: ferr,
    isloading: fisloading,
  } = useGetData(`${apiUrl}/api/services/faqs/${params.serviceid}`);

  //update Service
  const {
    data,
    isloading: uploading,
    updateData,
  } = useUpdateData(
    `${apiUrl}/api/services/single/${params.serviceid}`
  );

  useEffect(() => {
    data?.err ? toast.error(data?.err) : toast.success(data?.msg);
  }, [data]);

  useEffect(() => {
    console.log("UPDATE:");
    console.log(data);
  }, [data]);

  useEffect(() => {
    sdata[0]?.smaindesc &&
      sethtmlcontent(
        EditorState.createWithContent(
          convertFromRaw(JSON.parse(sdata[0]?.smaindesc))
        )
      );

    setfaqarr(fdata.length > 0 ? fdata : []);

    console.log("FDATA");
    console.log(fdata);

    console.log("comparr");
    console.log(cdata);
  }, [sdata, cdata, fdata]);

  const RevertRows = (data) => {
    let Rows = [];
    let Plans = [];
    let title = "";
    let id = 0;

    for (let i = 0; i < data.length; i++) {
      Plans = [];
      title = data[i][data[i].length - 1].ctitle;
      id = data[i][data[i].length - 1].pid;

      data[i].forEach((el) => {
        Plans.push(el.isincluded);
      });

      Rows.push({
        id: i,
        service: title,
        plans: Plans,
      });
    }

    console.log("REVERTED ROWS");
    console.log(Rows);
    setplans(Rows);

    setplans(Rows);
  };

  useEffect(() => {
    console.log("cdata: ", cdata);

    cdata.length > 0 && RevertRows(cdata);
  }, [cdata]);

  useEffect(() => {
    if (sdata.length > 0) {
      setregion(sdata[0]?.sregion ? sdata[0]?.sregion : "");
      setcity(sdata[0]?.scity ? sdata[0]?.scity : "");
      setservice(sdata[0]?.scategory ? sdata[0]?.scategory : "");
    }
  }, [sdata]);

  //postServiceData
  const [tdata, setdata] = useState([]);

  return (
    <div className="service-add-wrapper">
      <div className="service-add-top">
        <h1>Service bearbeiten</h1>
        <p>
          Bearbeite deine Dienstleistung, passe Sie genau so an, wie du es
          benötigst 🛠️.
        </p>
      </div>

      <div className="service-content-wrapper">
        {shnotify && (
          <Notification msg={data} notify={shnotify} setnotify={setshnotify} />
        )}

        <div className="service-content-part">
          <div className="service-choose">
            <div className="circle">1</div>
            <div className="c-line"></div>
          </div>

          <div className="service-grid">
            <div className="service-img-wrapper">
              <label htmlFor="image">
                {sdata[0]?.simg ? (
                  <span>{sdata[0]?.simg}</span>
                ) : (
                  <div className="icon">
                    <FontAwesomeIcon icon={faImages} />
                  </div>
                )}

                <p>Drag and drop oder nach Bildern suchen</p>
                <p>PNG, JPG, PDF, SVG</p>
              </label>

              <input
                type="file"
                name="image"
                id="image"
                onChange={(e) => {
                  setimg(e.target.files[0]);
                }}
              />
            </div>

            <ServiceInput
              label="Service Name"
              type="text"
              placeholder={sdata[0]?.stitle}
              name="title"
              important={true}
              setvalue={setsname}
            />
            <ServiceInput
              label="kurze  Beschreibung"
              type="text"
              placeholder={sdata[0]?.ssmalldesc}
              name="smalldesc"
              important={true}
              setvalue={setsmalldesc}
            />

            <EditorDraft
              editorState={htmlContent}
              seteditorState={sethtmlcontent}
            />

            <div className="options">
              <div className="select-drop">
                <label htmlFor="region">
                  <p>Region</p> <span>*</span>
                </label>
                <select
                  onChange={(e) => {
                    setregion(e.target.value);
                  }}
                  value={region}
                  name="region"
                >
                  <option value={""}>Wähle eine Region</option>
                  {Region.length > 0 &&
                    Region.map((region) => (
                      <option value={region.id}>{region.name}</option>
                    ))}
                </select>
              </div>

              <div className="select-drop">
                <label htmlFor="city">
                  <p>City</p> <span>*</span>
                </label>
                <select
                  onChange={(e) => {
                    setcity(e.target.value);
                  }}
                  value={city}
                  name="city"
                >
                  <option value={""}>Wähle eine Stadt</option>
                  {City.length > 0 &&
                    City.map((city) => {
                      if (region != "" && region == city.regionref) {
                        return <option value={city.cid}>{city.cname}</option>;
                      }
                    })}
                </select>
              </div>

              <div className="select-drop">
                <label htmlFor="category">
                  <p>Kategorie</p> <span>*</span>
                </label>
                <select
                  onChange={(e) => {
                    setservice(e.target.value);
                  }}
                  value={service}
                  name="category"
                >
                  <option value={""}>Wähle eine Kategorie</option>
                  {Service.length > 0 &&
                    Service.map((service) => (
                      <option value={service.id}>{service.name}</option>
                    ))}
                </select>
              </div>
            </div>
          </div>
        </div>

        <div className="add-faq">
          <div className="service-choose">
            <div className="circle">2</div>
            <div className="c-line"></div>
          </div>

          <div className="faq-content">
            <div className="top">
              <div className="left">
                <div className="right">
                  <h3>FAQ</h3>
                  <p>
                    Füge ein FAQ hinzu, um mehr transparenz zu deinen Kunden
                    aufzubauen
                  </p>
                </div>
              </div>

             

              <div
                      className="icon"
              
                    >
                  <div  onClick={() => {
                        setaddfaq(!addfaq);
                      }}>
                  <FontAwesomeIcon icon={addfaq ? faXmark : faAdd} />
                    </div>    
                 
                      <div
                      className="faq-add-overlay"
                      style={{ display: addfaq ? "flex" : "none" }}
                    >
                      <div className="arrow-left"></div>

                      <ServiceInput
                        label="Frage"
                        type="text"
                        placeholder="Komme ich auch am Wochenende?"
                        name="quest"
                        setvalue={setquestion}
                      />

                      <ServiceInput
                        label="Antwort"
                        type="text"
                        placeholder="Klar. Je nach Absprache."
                        name="reply"
                        setvalue={setanswer}
                      />

                      <button
                      type="button"
                        onClick={() => {
                          setfaqarr([
                            ...faqarr,
                            {
                              fquestion: question,
                              fanswer: answer,
                              fid: faqarr.length,
                            },
                          ]);
                        }}
                      >
                        Hinzufügen
                      </button>
                    </div>
                    </div>
            </div>

            <div className="bottom">
              {faqarr.length > 0 ? (
                faqarr.map((elem, index) => {
                  return (
                    <div className="faq-nexto-wrapper">
                      <p>{index + 1}.</p>
                      <FAQWrapper
                        id={elem.fid}
                        title={elem.fquestion}
                        answer={elem.fanswer}
                        setfaqarr={setfaqarr}
                        faqarr={faqarr}
                        close={true}
                      />
                    </div>
                  );
                })
              ) : (
                <p>Noch kein FAQ Element hinzugefügt. 😞</p>
              )}
            </div>
          </div>
        </div>

        <div className="compare-wrapper">
          <div className="service-choose">
            <div className="circle">3</div>
            <div className="c-line"></div>
          </div>

          <div className="plan-choose-wrapper">
            <div className="plans">
              <h2>Pläne anpassen</h2>
              <p>
                Wähle deinen Preis für dein Paket. Überlege Dir was du genau
                anbieten möchtest für einen bestimmten Preis.
              </p>

              <div className="plan-flex">
                {fetchedplans.length > 0 &&
                  fetchedplans.map((plan, index) => {
                    return (
                      <PlanItem
                        icon={
                          index === 0
                            ? faBox
                            : index === 1
                            ? faBoxesStacked
                            : faBoxesPacking
                        }
                        title={plan.plan}
                        price={
                          index === 0 ? bplan : index === 1 ? splan : aiplan
                        }
                        setprice={
                          index === 0
                            ? setbplan
                            : index === 1
                            ? setsplan
                            : setaiplan
                        }
                      />
                    );
                  })}
              </div>
            </div>

            <div className="compare-content">
              <div className="top">
                <div className="left">
                  <h3>Vergleich</h3>
                  <p>
                    Biete deinen möglichen Kunden die Möglichkeit zwischen all
                    deinen angebotenen Plänen zu unterscheiden.
                  </p>
                </div>
              </div>

              <ContentTable edit={true} trows={plans} settrows={setplans} />
            </div>
          </div>
        </div>

        <div className="add-service-wrapper">
          <div className="service-choose">
            <div className="circle">
              <FontAwesomeIcon icon={faArrowRight} />
            </div>
            <div className="line"></div>
          </div>

          <button
            onClick={() => {
              updateData(formData);
            }}
          >
            Updaten
          </button>
        </div>
      </div>
    </div>
  );
};
